<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content class="dialog-pdf">
  {{ data.content }}

  <object
    class="PDFdoc"
    width="100%"
    height="100%"
    type="application/pdf"
    [data]="data.file"
  ></object>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="data.buttonAccept"
    mat-raised-button
    [color]="'primary'"
    (click)="accept($event)"
  >
    {{ data.buttonAccept }}
  </button>
  <button
    *ngIf="data.buttonAcceptAndAction"
    mat-raised-button
    [color]="'primary'"
    (click)="acceptAndAction($event)"
  >
    {{ data.buttonAcceptAndAction }}
  </button>
  <button
    *ngIf="data.buttonCancel"
    mat-raised-button
    [color]="'warn'"
    (click)="cancel($event)"
  >
    {{ data.buttonCancel }}
  </button>
</div>
