/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IPTreeData } from '@ic-models/trees/inspection-pronouncements-tree.model';

import { IActionEmit } from './tree-action-button/tree-action-button.component';

@Component({
  selector: 'ic-inspection-tree',
  templateUrl: './inspection-tree.component.html'
})
export class InspectionTreeComponent {
  @Input() onlySee?: boolean;
  @Input() nestedDataSource: any;
  @Input() nestedTreeControl: any;
  @Output() nodeButtonSelected = new EventEmitter<IActionEmit>();

  customColorClassName: any;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      't_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/t_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'acm_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/acm_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'el_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/el_letter.svg'
      )
    );
  }

  hasNestedChild = (_: number, nodeData: IPTreeData): boolean | undefined =>
    nodeData && nodeData.children && nodeData.children.length > 0;

  nodeButtonPressed(action: IActionEmit): void {
    console.log('Botón árbol pulsado', JSON.stringify(action));
    this.nodeButtonSelected.emit(action);
  }

  // updateCustomColorClassName(string: )
}
