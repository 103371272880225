import { Routes } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-app/core/guards/auth.guard';

import { OmissionAdministrativeFilesModule } from './administrative-files/omission-administrative-files.module';
import { OmissionOperationalComponent } from './omission-operational.component';
import { OmissionSignaturesModule } from './signatures/omission-signatures.module';

export const OMISSION_OPERATIONAL_ROUTE: Routes = [
  {
    path: '',
    component: OmissionOperationalComponent,
    data: {
      role: [Authority.OMISSION, Authority.OMISSION_READER],
      title: 'sidebar.menu.label.omission-inspection.operational'
    },
    canActivate: [CustomAuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/omissioninspection/operational/administrative-file/list',
        pathMatch: 'full'
      },
      {
        path: 'administrative-file',
        loadChildren: async (): Promise<
          typeof OmissionAdministrativeFilesModule
        > =>
          (
            await import(
              './administrative-files/omission-administrative-files.module'
            )
          ).OmissionAdministrativeFilesModule
      },
      {
        path: 'signatures',
        loadChildren: async (): Promise<typeof OmissionSignaturesModule> =>
          (await import('./signatures/omission-signatures.module'))
            .OmissionSignaturesModule
      }
    ]
  }
];
