/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DialogPDFComponent } from '@ic-components/components.index';
import { getDateFromTimeStampInLocaleFormat } from '@ic-core/util/global-util';
import { getTimestampFromDate } from '@ic-core/util/request-util';
import { IFiscalYear } from '@ic-models/fiscal-year.model';
import { NavService } from '@ic-services/nav.service';
import { PDFService } from '@ic-services/pdf.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, catchError, finalize, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'ic-omission-reports',
  templateUrl: './omission-reports.component.html'
})
export class OmissionReportsComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  reportsForm: FormGroup;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls?: any;
  fiscalYear?: IFiscalYear;
  initYear?: Date;
  endYear?: Date;
  generateReportClicked = false;
  isFormEmpty = true;

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private fb: FormBuilder,
    private pdfService: PDFService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    @Inject(LOCALE_ID) private readonly localeId: string
  ) {
    this.reportsForm = this.crearFormulario();
    this.initControlsObject();
  }

  ngOnInit(): void {
    const mainTitle = this.translate.instant(
      'sidebar.menu.label.omission-inspection.title'
    ) as string;
    const title = (mainTitle + ' - ').concat(
      this.translate.instant(
        this.route.snapshot.data['title'] as string
      ) as string
    );
    this.navService.updateTitle(title);
    this.reportsForm.valueChanges.subscribe(() => {
      this.isFormEmpty = this.isFormEmptyFn();
    });
  }

  crearFormulario(): FormGroup {
    return this.fb.group({
      dateStartInput: new FormControl(),
      dateEndInput: new FormControl()
    });
  }

  initControlsObject(): void {
    this.controls = {
      dateStart: this.reportsForm?.get('dateStartInput'),
      dateEnd: this.reportsForm?.get('dateEndInput')
    };
  }

  submitDateRange(): void {
    if (
      !this.generateReportClicked &&
      (this.controls?.dateStart?.value || !this.controls?.dateEnd?.value)
    ) {
      this.generateReportClicked = true;
      // TODO: Sustituimos moment
      // Convertimos las fechas a formato numérico
      // const startDateMoment = moment(
      //   this.controls.dateStart.value,
      //   'DD-MM-YYYY'
      // );
      // const startDate = getTimestampFromMoment(startDateMoment);
      // const endDateMoment = moment(this.controls.dateEnd.value, 'DD-MM-YYYY');
      // const endDate = getTimestampFromMoment(endDateMoment);

      // const startDateString = startDateMoment.local().format('DD-MM-YYYY');
      // const endDateString = endDateMoment.local().format('DD-MM-YYYY');

      const startDate = getTimestampFromDate(
        this.controls?.dateStart?.value as Date
      );

      const endDate = getTimestampFromDate(
        this.controls?.dateEnd?.value as Date
      );

      const startDateString = getDateFromTimeStampInLocaleFormat(
        startDate,
        this.localeId
      );
      const endDateString = getDateFromTimeStampInLocaleFormat(
        endDate,
        this.localeId
      );

      this.pdfService
        .generateStatisticsReport(
          startDate,
          endDate,
          startDateString,
          endDateString
        )
        .pipe(
          takeUntil(this.unsubscribe$),
          catchError((error: Error) => {
            this.showMessage(
              this.translate.instant(
                'reports.generate-statistics-report.error'
              ) as string,
              this.translate.instant('common.button.accept') as string
            );
            this.generateReportClicked = false;
            return throwError(() => new Error(error.message));
          }),
          finalize(() => {
            this.generateReportClicked = false;
          })
        )
        .subscribe((response: any) => {
          const file = new Blob([response], {
            type: 'application/pdf'
          });
          const fileURL = window.URL.createObjectURL(file);

          this.generateReportClicked = false;
          this.openModal(fileURL);
        });
    }
  }

  isFormEmptyFn(): boolean {
    if (
      this.reportsForm?.get('dateStartInput')?.value !== undefined &&
      this.reportsForm?.get('dateStartInput')?.value !== null &&
      this.reportsForm?.get('dateStartInput')?.value !== '' &&
      this.reportsForm?.get('dateEndInput')?.value !== undefined &&
      this.reportsForm?.get('dateEndInput')?.value !== null &&
      this.reportsForm?.get('dateEndInput')?.value !== ''
    ) {
      return false;
    }
    return true;
  }

  openModal(fileUrl: any): void {
    this.dialog
      .open(DialogPDFComponent, {
        data: {
          title: this.translate.instant('reports.title') as string,
          file: this.sanitizer.bypassSecurityTrustResourceUrl(
            fileUrl as string
          ),
          buttonCancel: this.translate.instant(
            'reports.generate-statistics-report.cancel'
          ) as string
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {});
  }

  showMessage(text: string, button: string): void {
    this._snackBar.open(text, button, {
      duration: 5000
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
