import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAutoCompleteElement } from '@ic-app/components/form/input-autocomplete/input-autocomplete.component';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import { IAuthoritiesFilter } from '@ic-app/models/settings/authorities/authorities-list-filter.model';
import { IAuthoritiesList } from '@ic-app/models/settings/authorities/authorities-list.model';
import { AuthorityData } from '@ic-app/models/settings/authorities/authority.model';
import { IAuthorityDetailData } from '@ic-app/models/settings/authorities/detail/authoritiy.detail.model';
import { IAuthorityEntity } from '@ic-app/models/settings/authorities/panel/authorities-entity.model';
import { AppConfigService } from '@ic-services/app-config.service';
import { environment } from 'environments/environment';
import { Observable, catchError, map, tap, throwError } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsAuthoritiesService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Lista todos los órganos y/o los filtra
   * @param filter
   * @param pageNumber
   * @param pageSize
   * @param filteredEntityId
   * @returns
   */
  findAuthorities(
    filter: IAuthoritiesFilter,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<IAuthoritiesList[]> {
    const req = {
      searchBox: filter.searchBox,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      authorityType:
        !!filter.authorityType && filter.authorityType.length > 0
          ? JSON.stringify(filter.authorityType)
          : undefined,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAuthoritiesList[]>(`${baseUrl}/settings/findAuthorities`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IAuthoritiesList[]>) =>
            resp.body as IAuthoritiesList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los órganos FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Cuenta los órganos totales teniendo en cuenta los filtros
   * @param filter
   * @param mainTable
   * @param filteredEntityId
   * @returns
   */
  countAuthorities(
    filter: IAuthoritiesFilter,
    mainTable: boolean,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      mainTable: mainTable,
      authorityType:
        !!filter.authorityType && filter.authorityType.length > 0
          ? JSON.stringify(filter.authorityType)
          : undefined,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/settings/countAuthorities`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los órganos de configuracion FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Funcionalidad para rellenar el autocomplete de entidad principal en el filtro
   *
   * @returns
   */
  loadAutocompleteTenantEntitiesFilter(): Observable<
    IAutoCompleteElement[] | undefined
  > {
    return this.http
      .get<IAutoCompleteElement[]>(
        `${baseUrl}/autocompleteTenantEntitiesFilter`
      )
      .pipe(
        tap((data: IAutoCompleteElement[] | undefined) => {
          this.appConfig.autocompleteTenantEntitiesFilterData = [];
          Object.assign(
            this.appConfig.autocompleteTenantEntitiesFilterData,
            data
          );
        }),
        catchError((err: Error) => {
          console.error(
            'Error al obtener las entidades principales del autocomplete del filtro'
          );
          throw err;
        })
      );
  }

  /**
   * Funcionalidad para rellenar el autocomplete de entidad en el filtro
   *
   * @returns
   */
  loadAutocompleteEntitiesFilter(
    tenantId: number
  ): Observable<IAutoCompleteElement[] | undefined> {
    const req = {
      tenantId: tenantId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocompleteEntitiesFilter`, {
        params: options
      })
      .pipe(
        tap((data: IAutoCompleteElement[] | undefined) => {
          this.appConfig.autocompleteEntitiesFilterData = [];
          Object.assign(this.appConfig.autocompleteEntitiesFilterData, data);
        }),
        catchError((err: Error) => {
          console.error(
            'Error al obtener las entidades del autocomplete del filtro'
          );
          throw err;
        })
      );
  }

  /**
   * Funcionalidad para guardar un Órgano de configuración FRB
   * @param authorityData
   * @returns
   */
  saveAuthority(
    authorityData: AuthorityData,
    isEdit: boolean
  ): Observable<boolean> {
    const req = {
      isEdit: isEdit
    };
    const options = createRequestOption(req);
    return this.http
      .post<boolean>(`${baseUrl}/settings/saveAuthority`, authorityData, {
        headers: HttpConstants.GET_HEADERS,
        params: options
      })
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al guardar el órgano en el servidor::',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al guardar el órgano en el servidor:',
              err.message
            );
            return new Error('settings.error-messages.save-authority');
          });
        })
      );
  }

  /**
   * Devuelve los datos del órgano a mostrar
   * @param authorityId
   * @returns
   */
  findAuthorityDetailById(
    authorityId: number
  ): Observable<IAuthorityDetailData> {
    const req = {
      authorityId: authorityId
    };

    const options = createRequestOption(req);

    return this.http
      .get<IAuthorityDetailData>(
        `${baseUrl}/settings/findAuthorityDetailById`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAuthorityDetailData>) =>
            resp.body as IAuthorityDetailData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el órgano de configuración del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Comprueba si el órgano está asignado a algún expediente para la entidad a la que pertenece
   * @param authorityId
   * @param entityId
   * @returns
   */
  checkIfAuthorityAssigned(
    authorityId: number,
    entityId: number
  ): Observable<boolean> {
    const req = {
      organizationalUnitId: authorityId,
      entityId: entityId
    };

    const options = createRequestOption(req);

    return this.http
      .get<boolean>(`${baseUrl}/settings/checkIfAuthorityAssigned`, {
        headers: HttpConstants.GET_HEADERS,
        params: options
      })
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al comprobar si el órgano está asignado a un expediente:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Desactiva el órgano
   * @param organizationalUnitId
   * @returns
   */
  deactivateAuthority(organizationalUnitId: number): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${baseUrl}/settings/deactivateAuthority`,
        organizationalUnitId
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al desactivar el órgano:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Activa el órgano
   * @param organizationalUnitId
   * @returns
   */
  activateAuthority(organizationalUnitId: number): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${baseUrl}/settings/activateAuthority`,
        organizationalUnitId
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al activar el órgano:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los datos de la entidad filtrada
   * @param filteredEntityId
   * @returns
   */
  getFilteredEntityDataAuthority(
    filteredEntityId: number
  ): Observable<IAuthorityEntity> {
    const req = {
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAuthorityEntity>(
        `${baseUrl}/settings/getFilteredEntityDataAuthority`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAuthorityEntity>) =>
            resp.body as IAuthorityEntity
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al cargar la entidad filtrada del órgano de la entidad:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
