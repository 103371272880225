import { Injectable } from '@angular/core';
import {
  ICommonFilter,
  ISearchFilter
} from '@ic-app/models/common-filter.model';
import { IAuthoritiesFilter } from '@ic-app/models/settings/authorities/authorities-list-filter.model';
import { IAuthoritiesContact } from '@ic-app/models/settings/authorities/panel/authorities-contacts.model';
import {
  ISettingsBudgetAppAdded,
  ISettingsBudgetAppsFilter
} from '@ic-app/models/settings/budget-apps/settings-budget-apps-filter.model';
import { ICategoryEntityData } from '@ic-app/models/settings/prior-inspection/category-afts/category-aft.model';
import {
  ICategoryAdministrativeFileTypesFilter,
  ISettingsPriorInsepctionCategoryAdded
} from '@ic-app/models/settings/prior-inspection/category-afts/category-afts-list-filter.model';
import {
  ISettingsPriorInsepctionObservationAdded,
  ISettingsPriorInsepctionObservationsFilter
} from '@ic-app/models/settings/prior-inspection/observations/settings-prior-inspection-observations-filter.model';
import { ITenantsEntity } from '@ic-app/models/settings/tenants/panel/tenants-entity.model';
import { ITenantsFilter } from '@ic-app/models/settings/tenants/tenants-list-filter.model';
import { IOmissionAdded } from '@ic-models/omission/omission-request.model';
import { IAdministrativeFileFilter } from '@ic-models/prior-inspection/administrative-file-filter.model';
import { IFrbAdded } from '@ic-models/prior-inspection/frb-request.model';
import { IPpcAdministrativeFileFilter } from '@ic-models/prior-operational/ppc-administrative-file-filter.model';
import { IPpcAdministrativeFileAdded } from '@ic-models/prior-operational/ppc-administrative-file.model';
import {
  IPpcReportManualItemCommentFilter,
  IPpcReportManualObservationFilter,
  IPpcReportManualSubjectiveImprovementFilter
} from '@ic-models/prior-operational/ppc-report/ppc-report.model';
import { IPpcAdded } from '@ic-models/prior-operational/ppc-request.model';
import { IPTreePpcData } from '@ic-models/trees/ppc-report-pronouncements-tree.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
  // private isLoggedUserSource = new BehaviorSubject<boolean>(false);
  private treeFileTypeIdSource = new BehaviorSubject<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private AFFilterSource =
    new BehaviorSubject<IAdministrativeFileFilter | null>(null);
  private afPpcFilterSource =
    new BehaviorSubject<IPpcAdministrativeFileFilter | null>(null);
  private afPpcReportManualObservationFilterSource =
    new BehaviorSubject<IPpcReportManualObservationFilter | null>(null);
  private afPpcReportManualSubjectiveImprovementFilterSource =
    new BehaviorSubject<IPpcReportManualSubjectiveImprovementFilter | null>(
      null
    );
  private afPpcReportManualItemCommentFilterSource =
    new BehaviorSubject<IPpcReportManualItemCommentFilter | null>(null);
  private detailCommentsSource = new BehaviorSubject(true);
  private addedCommentSource = new BehaviorSubject(null);
  private afSettingsBudgetAppFilterSource =
    new BehaviorSubject<ISettingsBudgetAppsFilter | null>(null);
  private afSettingsItemsFilterSource =
    new BehaviorSubject<ICommonFilter | null>(null);
  private flagDisableTabs = new BehaviorSubject<boolean>(false);
  private addedFrbData = new BehaviorSubject<IFrbAdded | null>(null);
  private addedPpcData = new BehaviorSubject<IPpcAdded | null>(null);
  private addedBudgetApp = new BehaviorSubject<ISettingsBudgetAppAdded | null>(
    null
  );
  private addedConfigObservation =
    new BehaviorSubject<ISettingsPriorInsepctionObservationAdded | null>(null);
  private addedPpcAdministrativeFileData =
    new BehaviorSubject<IPpcAdministrativeFileAdded | null>(null);
  private addedOmissionData = new BehaviorSubject<IOmissionAdded | null>(null);
  private addedAmendmentData = new BehaviorSubject<IFrbAdded | null>(null);
  private detailPpcCommentsSource = new BehaviorSubject(true);
  private ppcAddedCommentSource = new BehaviorSubject(null);
  private ppcAddedAmendmentData = new BehaviorSubject<IPpcAdded | null>(null);
  private ppcAdministrativeFileAddedAmendmentData =
    new BehaviorSubject<IPpcAdministrativeFileAdded | null>(null);
  private ppcSupportedReportConclusionIdSource = new BehaviorSubject<
    number | null
  >(null);
  private resultCodePpcProcedureData = new BehaviorSubject<number | null>(null);
  private isPpcReportGenerated = new BehaviorSubject<boolean | null>(null);
  private ppcAdministrativeFileAddedReformulatedData =
    new BehaviorSubject<IPpcAdministrativeFileAdded | null>(null);
  private adPpcModelObservationFilterSource = new BehaviorSubject<string>('');
  private adPpcModelSubjectiveImprovementFilterSource =
    new BehaviorSubject<string>('');
  private lastIterationPpcAdminFileData = new BehaviorSubject<number | null>(
    null
  );
  private goToReportPpcViewFromPpcDetailData = new BehaviorSubject<boolean>(
    false
  );
  private adPpcModelItemCommentFilterSource = new BehaviorSubject<string>('');
  private changePpcObservations = new BehaviorSubject<boolean | null>(false);
  private changePpcSubjectiveImprovements = new BehaviorSubject<boolean | null>(
    false
  );
  private ppcReportNodeCommentAdded = new BehaviorSubject<IPTreePpcData | null>(
    null
  );
  private reloadPpcVerificationData = new BehaviorSubject<boolean>(false);

  private changeSettingsBudgetApps = new BehaviorSubject<boolean | null>(false);

  // configuracion
  private afSettingsItemsEntityFilterSource =
    new BehaviorSubject<ISearchFilter | null>(null);

  private settingsPriorInspectionObservationEntityFilterSource =
    new BehaviorSubject<ISearchFilter | null>(null);

  private changeSettingsPriorInspectionObservationDetail =
    new BehaviorSubject<boolean>(false);

  private settingsPriorInspectionObservationsFilterSource =
    new BehaviorSubject<ISettingsPriorInsepctionObservationsFilter | null>(
      null
    );

  private changeSettingsPriorInspectionObservations = new BehaviorSubject<
    boolean | null
  >(false);

  private changeSettingsPriorInspectionCategoryAFT = new BehaviorSubject<
    boolean | null
  >(false);

  private categoryAFTsListFilterSource =
    new BehaviorSubject<ICategoryAdministrativeFileTypesFilter | null>(null);

  private returnToCreatePanelWithCategoryAFTDataId = new BehaviorSubject<
    number | null
  >(null);

  private goToManageInModelPanelFromPanelWithCategoryAFTDetailDataId =
    new BehaviorSubject<number | null>(null);

  private categoryEntityData = new BehaviorSubject<ICategoryEntityData | null>(
    null
  );

  private addedConfigCategoryAft =
    new BehaviorSubject<ISettingsPriorInsepctionCategoryAdded | null>(null);

  private categoryAftEntityFilterSource =
    new BehaviorSubject<ISearchFilter | null>(null);

  private changeItemTree = new BehaviorSubject<boolean | null>(false);

  private categoryAftDetail = new BehaviorSubject<boolean>(false);

  private categoryAftFoundedNode = new BehaviorSubject<boolean>(false);

  private authoritiesListFilterSource =
    new BehaviorSubject<IAuthoritiesFilter | null>(null);

  private authoritiesListSource = new BehaviorSubject<boolean | null>(false);

  private authoritiesContactAdded =
    new BehaviorSubject<IAuthoritiesContact | null>(null);

  private authoritiesDetailSource = new BehaviorSubject<boolean | null>(false);

  private tenantsListFilterSource = new BehaviorSubject<ITenantsFilter | null>(
    null
  );

  private tenantsListSource = new BehaviorSubject<boolean | null>(false);

  private tenantsEntityAdded = new BehaviorSubject<ITenantsEntity | null>(null);

  private tenantsEntityEdited = new BehaviorSubject<boolean | null>(false);

  private editedTenantEntityNotSaved = new BehaviorSubject<boolean | null>(
    false
  );

  private tenantEntitiesTableActionsSource = new BehaviorSubject<
    boolean | null
  >(false);

  private entityEditedSource = new BehaviorSubject<boolean | null>(false);

  private comeFromTenantDetailSource = new BehaviorSubject<boolean | null>(
    null
  );

  private settingsFilteredEntityIdSource = new BehaviorSubject<number | null>(
    null
  );

  private settingsFilteredEntityTextSource = new BehaviorSubject<string>('');

  private itemAdministrativeFileSource = new BehaviorSubject<boolean | null>(
    false
  );

  // isLoggedUserSourceChanged$ = this.isLoggedUserSource.asObservable();
  treeFileTypeIdSourceChanged$ = this.treeFileTypeIdSource.asObservable();

  afFilterSourceChanged$ = this.AFFilterSource.asObservable();

  afPpcFilterSourceChanged$ = this.afPpcFilterSource.asObservable();

  afSettingsBudgetAppsSourceChanged$ =
    this.afSettingsBudgetAppFilterSource.asObservable();

  afSettingsItemsSourceChanged$ =
    this.afSettingsItemsFilterSource.asObservable();

  detailCommentsData$ = this.detailCommentsSource.asObservable();

  addedCommentChanged$ = this.addedCommentSource.asObservable();

  flagDisableTabs$ = this.flagDisableTabs.asObservable();

  addedFrbData$ = this.addedFrbData.asObservable();

  addedOmissionData$ = this.addedOmissionData.asObservable();

  addedAmendmentData$ = this.addedAmendmentData.asObservable();

  addedPpcData$ = this.addedPpcData.asObservable();

  addedBudgetApp$ = this.addedBudgetApp.asObservable();

  addedConfigObservation$ = this.addedConfigObservation.asObservable();

  detailPpcCommentsData$ = this.detailPpcCommentsSource.asObservable();

  ppcAddedCommentChanged$ = this.ppcAddedCommentSource.asObservable();

  ppcAddedAmendmentData$ = this.ppcAddedAmendmentData.asObservable();

  addedPpcAdministrativeFileData$ =
    this.addedPpcAdministrativeFileData.asObservable();

  ppcAdministrativeFileAddedAmendmentData$ =
    this.ppcAdministrativeFileAddedAmendmentData.asObservable();

  ppcSupportedReportConclusionIdSourceChanged$ =
    this.ppcSupportedReportConclusionIdSource.asObservable();

  resultCodePpcProcedureDataChanged$ =
    this.resultCodePpcProcedureData.asObservable();

  isPpcReportGenerated$ = this.isPpcReportGenerated.asObservable();

  ppcAdministrativeFileAddedReformulatedData$ =
    this.ppcAdministrativeFileAddedReformulatedData.asObservable();

  adPpcModelObservationFilterSourceChanged$ =
    this.adPpcModelObservationFilterSource.asObservable();

  adPpcModelSubjectiveImprovementFilterSourceChanged$ =
    this.adPpcModelSubjectiveImprovementFilterSource.asObservable();

  lastIterationPpcAdminFileDataChanged$ =
    this.lastIterationPpcAdminFileData.asObservable();

  goToReportPpcViewFromPpcDetailDataChanged$ =
    this.goToReportPpcViewFromPpcDetailData.asObservable();

  adPpcModelItemCommentFilterSourceChanged$ =
    this.adPpcModelItemCommentFilterSource.asObservable();

  ppcObservationsChanged$ = this.changePpcObservations.asObservable();

  changeSettingsBudgetApps$ = this.changeSettingsBudgetApps.asObservable();

  ppcSubjectiveImprovementsChanged$ =
    this.changePpcSubjectiveImprovements.asObservable();

  ppcReportNodeCommentAddedSourceChanged$ =
    this.ppcReportNodeCommentAdded.asObservable();

  reloadPpcVerificationDataChanged$ =
    this.reloadPpcVerificationData.asObservable();

  settingsPriorInspectionObservationsSourceChanged$ =
    this.settingsPriorInspectionObservationsFilterSource.asObservable();

  changeSettingsPriorInspectionObservations$ =
    this.changeSettingsPriorInspectionObservations.asObservable();

  changeSettingsPriorInspectionObservationDetail$ =
    this.changeSettingsPriorInspectionObservationDetail.asObservable();

  categoryAFTsListSourceChanged$ =
    this.categoryAFTsListFilterSource.asObservable();

  changeSettingsPriorInspectionCategoryAFT$ =
    this.changeSettingsPriorInspectionCategoryAFT.asObservable();

  changeItemTree$ = this.changeItemTree.asObservable();

  // updateLoggedUser(isLogged: boolean) {
  //   this.isLoggedUserSource.next(isLogged);
  // }

  // configuracion

  afSettingsItemsEntitySourceChanged$ =
    this.afSettingsItemsEntityFilterSource.asObservable();

  settingsPriorInspectionObservationEntityFilterChanged$ =
    this.settingsPriorInspectionObservationEntityFilterSource.asObservable();

  returnToCreatePanelWithCategoryAFTDataId$ =
    this.returnToCreatePanelWithCategoryAFTDataId.asObservable();

  goToManageInModelPanelFromPanelWithCategoryAFTDetailDataId$ =
    this.goToManageInModelPanelFromPanelWithCategoryAFTDetailDataId.asObservable();

  categoryEntityData$ = this.categoryEntityData.asObservable();

  addedConfigCategoryAft$ = this.addedConfigCategoryAft.asObservable();

  categoryAftEntityFilterChanged$ =
    this.categoryAftEntityFilterSource.asObservable();

  categoryAftDetailUpdated$ = this.categoryAftDetail.asObservable();

  categoryAftFoundedNodeUpdated$ = this.categoryAftFoundedNode.asObservable();

  authoritiesListFilterSourceChanged$ =
    this.authoritiesListFilterSource.asObservable();

  authoritiesListSourceChanged$ = this.authoritiesListSource.asObservable();

  authoritiesContactAddedSourceChanged$ =
    this.authoritiesContactAdded.asObservable();

  authoritiesDetailSourceChanged$ = this.authoritiesDetailSource.asObservable();

  tenantsListFilterSourceChanged$ = this.tenantsListFilterSource.asObservable();

  tenantsListSourceChanged$ = this.tenantsListSource.asObservable();

  tenantsEntityAddedSourceChanged$ = this.tenantsEntityAdded.asObservable();

  tenantsEntityEditedSourceChanged$ = this.tenantsEntityEdited.asObservable();

  editedTenantEntityNotSavedSourceChanged$ =
    this.editedTenantEntityNotSaved.asObservable();

  tenantEntitiesTableActionsSourceChanged$ =
    this.tenantEntitiesTableActionsSource.asObservable();

  entityEditedSourceChanged$ = this.entityEditedSource.asObservable();

  comeFromTenantDetailSourceChanged$ =
    this.comeFromTenantDetailSource.asObservable();

  settingsFilteredEntityIdSourceChanged$ =
    this.settingsFilteredEntityIdSource.asObservable();

  settingsFilteredEntityTextSourceChanged$ =
    this.settingsFilteredEntityTextSource.asObservable();

  itemAdministrativeFileSourceChanged$ =
    this.itemAdministrativeFileSource.asObservable();

  updateTreeFileTypeId(id: number): void {
    this.treeFileTypeIdSource.next(id);
  }

  updateAdministrativeFileFilter(AFFilter: IAdministrativeFileFilter): void {
    this.AFFilterSource.next(AFFilter);
  }

  updateDetailCommentsSource(updateComments: boolean): void {
    this.detailCommentsSource.next(updateComments);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateAddedCommentSource(comment: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.addedCommentSource.next(comment);
  }

  updateFlagDisableTabs(disabled: boolean): void {
    this.flagDisableTabs.next(disabled);
  }

  updateAddedFrbData(addedFrb: IFrbAdded | null): void {
    this.addedFrbData.next(addedFrb);
  }

  updateAddedOmissionData(addedOmission: IOmissionAdded): void {
    this.addedOmissionData.next(addedOmission);
  }

  updateAddedAmendmentData(addedAmendment: IFrbAdded | null): void {
    this.addedAmendmentData.next(addedAmendment);
  }

  updateAddedPpcData(addedPpc: IPpcAdded | null): void {
    this.addedPpcData.next(addedPpc);
  }

  updateAddedBudgetApp(budgetAppAdded: ISettingsBudgetAppAdded | null): void {
    this.addedBudgetApp.next(budgetAppAdded);
  }

  updateAddedConfigObservation(
    configObservationAdded: ISettingsPriorInsepctionObservationAdded | null
  ): void {
    this.addedConfigObservation.next(configObservationAdded);
  }

  updatePpcAdministrativeFileFilter(
    AFFilter: IPpcAdministrativeFileFilter
  ): void {
    this.afPpcFilterSource.next(AFFilter);
  }

  updateSettingsBudgetAppsFilter(AFFilter: ISettingsBudgetAppsFilter): void {
    this.afSettingsBudgetAppFilterSource.next(AFFilter);
  }

  updateSettingsItemsFilter(AFFilter: ICommonFilter): void {
    this.afSettingsItemsFilterSource.next(AFFilter);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatePpcAddedCommentSource(ppcComment: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.ppcAddedCommentSource.next(ppcComment);
  }

  updatePpcDetailCommentsSource(updatePpcComments: boolean): void {
    this.detailPpcCommentsSource.next(updatePpcComments);
  }

  updatePpcAddedAmendmentData(ppcAddedAmendment: IPpcAdded | null): void {
    this.ppcAddedAmendmentData.next(ppcAddedAmendment);
  }

  updatePpcReportManualSubjectiveImprovement(
    AFFilter: IPpcReportManualSubjectiveImprovementFilter
  ): void {
    this.afPpcReportManualSubjectiveImprovementFilterSource.next(AFFilter);
  }

  updatePpcReportManualItemComment(
    AFFilter: IPpcReportManualItemCommentFilter
  ): void {
    this.afPpcReportManualItemCommentFilterSource.next(AFFilter);
  }

  updatePpcReportManualObservation(
    AFFilter: IPpcReportManualObservationFilter
  ): void {
    this.afPpcReportManualObservationFilterSource.next(AFFilter);
  }

  updatePpcModelObservationFilter(filter: string): void {
    this.adPpcModelObservationFilterSource.next(filter);
  }

  updatePpcModelSubjectiveImprovementFilter(filter: string): void {
    this.adPpcModelSubjectiveImprovementFilterSource.next(filter);
  }

  updatePpcModelItemCommentFilter(filter: string): void {
    this.adPpcModelItemCommentFilterSource.next(filter);
  }

  updateAddedPpcAdministrativeFileData(
    addedPpcAdministrativeFile: IPpcAdministrativeFileAdded | null
  ): void {
    this.addedPpcAdministrativeFileData.next(addedPpcAdministrativeFile);
  }

  updatePpcAdministrativeFileAddedAmendmentData(
    ppcAdministrativeFileAddedAmendment: IPpcAdministrativeFileAdded | null
  ): void {
    this.ppcAdministrativeFileAddedAmendmentData.next(
      ppcAdministrativeFileAddedAmendment
    );
  }

  updatePpcSupportedReportConclusionId(id: number | null): void {
    this.ppcSupportedReportConclusionIdSource.next(id);
  }

  updateResultCodePpcProcedureData(id: number | null): void {
    this.resultCodePpcProcedureData.next(id);
  }

  updateIsPpcReportGenerated(isPpcReportGenerated: boolean | null): void {
    this.isPpcReportGenerated.next(isPpcReportGenerated);
  }

  updatePpcAdministrativeFileAddedReformulatedData(
    ppcAdministrativeFileAddedReformulated: IPpcAdministrativeFileAdded | null
  ): void {
    this.ppcAdministrativeFileAddedReformulatedData.next(
      ppcAdministrativeFileAddedReformulated
    );
  }

  updateLastIterationPpcAdminFileData(
    lastIterationPpcAdminFile: number | null
  ): void {
    this.lastIterationPpcAdminFileData.next(lastIterationPpcAdminFile);
  }

  updateGoToReportPpcViewFromPpcDetailData(
    goToReportPpcViewFromPpcDetail: boolean
  ): void {
    this.goToReportPpcViewFromPpcDetailData.next(
      goToReportPpcViewFromPpcDetail
    );
  }

  updatePpcObservationsReport(changePpcObservations: boolean | null): void {
    this.changePpcObservations.next(changePpcObservations);
  }

  updatePpcSubjectiveImprovementsReport(
    changePpcSubjectiveImprovements: boolean | null
  ): void {
    this.changePpcSubjectiveImprovements.next(changePpcSubjectiveImprovements);
  }

  updatePpcReportNodeCommentAdded(
    ppcReportNodeCommentAdded: IPTreePpcData | null
  ): void {
    this.ppcReportNodeCommentAdded.next(ppcReportNodeCommentAdded);
  }

  updateReloadPpcVerificationData(reloadPpcVerificationData: boolean): void {
    this.reloadPpcVerificationData.next(reloadPpcVerificationData);
  }

  //#region modulo configuracion

  updateSettingsBudgetApps(changeSettingsBudgetApps: boolean | null): void {
    this.changeSettingsBudgetApps.next(changeSettingsBudgetApps);
  }

  updateSettingsPriorInsepctionObservationsFilter(
    AFFilter: ISettingsPriorInsepctionObservationsFilter
  ): void {
    this.settingsPriorInspectionObservationsFilterSource.next(AFFilter);
  }

  updateSettingsPriorInspectionObservations(
    changeSettingsPriorInspectionObservations: boolean | null
  ): void {
    this.changeSettingsPriorInspectionObservations.next(
      changeSettingsPriorInspectionObservations
    );
  }

  updateCategoryAFTsListFilter(
    categoryAFTsListFilter: ICategoryAdministrativeFileTypesFilter
  ): void {
    this.categoryAFTsListFilterSource.next(categoryAFTsListFilter);
  }

  updateSettingsPriorInspectionCategoryAFT(
    changeSettingsPriorInspectionCategoryAFT: boolean | null
  ): void {
    this.changeSettingsPriorInspectionCategoryAFT.next(
      changeSettingsPriorInspectionCategoryAFT
    );
  }

  updateSettingsItemsEntityFilter(AFFilter: ISearchFilter): void {
    this.afSettingsItemsEntityFilterSource.next(AFFilter);
  }

  updateSettingsObservationsEntityFilter(AFFilter: ISearchFilter): void {
    this.settingsPriorInspectionObservationEntityFilterSource.next(AFFilter);
  }

  updateSettingsPriorInspectionObservationDetail(
    changeSettingsPriorInspectionObservationDetail: boolean
  ): void {
    this.changeSettingsPriorInspectionObservationDetail.next(
      changeSettingsPriorInspectionObservationDetail
    );
  }

  updateItemTree(changeItemTree: boolean | null): void {
    this.changeItemTree.next(changeItemTree);
  }

  updateReturnToCreatePanelWithCategoryAFTDataId(
    categoryAFTDataId: number | null
  ): void {
    this.returnToCreatePanelWithCategoryAFTDataId.next(categoryAFTDataId);
  }

  updateGoToManageInModelPanelFromPanelWithCategoryAFTDetailDataId(
    categoryAFTDetailDataId: number | null
  ): void {
    this.goToManageInModelPanelFromPanelWithCategoryAFTDetailDataId.next(
      categoryAFTDetailDataId
    );
  }

  updateCategoryEntityData(
    categoryEntityData: ICategoryEntityData | null
  ): void {
    this.categoryEntityData.next(categoryEntityData);
  }

  updateAddedConfigCategoryAft(
    configCategoryAftAdded: ISettingsPriorInsepctionCategoryAdded | null
  ): void {
    this.addedConfigCategoryAft.next(configCategoryAftAdded);
  }

  updateCategoryAftEntityFilter(categoryAftEntityFilter: ISearchFilter): void {
    this.categoryAftEntityFilterSource.next(categoryAftEntityFilter);
  }

  updateCategoryAftDetail(categoryAftDetail: boolean): void {
    this.categoryAftDetail.next(categoryAftDetail);
  }

  updateCategoryAftFoundedNode(categoryAftFoundedNode: boolean): void {
    this.categoryAftFoundedNode.next(categoryAftFoundedNode);
  }

  updateAuthoritiesListFilter(authoritiesListFilter: IAuthoritiesFilter): void {
    this.authoritiesListFilterSource.next(authoritiesListFilter);
  }

  updateAuthoritiesList(authoritiesList: boolean): void {
    this.authoritiesListSource.next(authoritiesList);
  }

  updateAuthoritiesContactAdded(
    authoritiesContactAdded: IAuthoritiesContact | null
  ): void {
    this.authoritiesContactAdded.next(authoritiesContactAdded);
  }

  updateAuthoritiesDetail(authoritiesDetail: boolean): void {
    this.authoritiesDetailSource.next(authoritiesDetail);
  }

  updateTenantsListFilter(tenantsListFilter: ITenantsFilter): void {
    this.tenantsListFilterSource.next(tenantsListFilter);
  }

  updateTenantsList(tenantsList: boolean): void {
    this.tenantsListSource.next(tenantsList);
  }

  updateTenantsEntityAdded(tenantsEntityAdded: ITenantsEntity | null): void {
    this.tenantsEntityAdded.next(tenantsEntityAdded);
  }

  updateTenantsEntityEdited(tenantsEntityEdited: boolean | null): void {
    this.tenantsEntityEdited.next(tenantsEntityEdited);
  }

  updateEditedTenantEntityNotSaved(
    editedTenantEntityNotSaved: boolean | null
  ): void {
    this.editedTenantEntityNotSaved.next(editedTenantEntityNotSaved);
  }

  updateTenantEntitiesTableActions(tenantEntitiesTableActions: boolean): void {
    this.tenantEntitiesTableActionsSource.next(tenantEntitiesTableActions);
  }

  updateEntityEdited(entityEdited: boolean | null): void {
    this.entityEditedSource.next(entityEdited);
  }

  updateComeFromTenantDetail(comeFromTenantDetail: boolean | null): void {
    this.comeFromTenantDetailSource.next(comeFromTenantDetail);
  }

  updateSettingsFilteredEntityId(filteredEntityId: number | null): void {
    this.settingsFilteredEntityIdSource.next(filteredEntityId);
  }

  updateSettingsFilteredEntityTextSource(filteredEntityText: string): void {
    this.settingsFilteredEntityTextSource.next(filteredEntityText);
  }

  updateItemAdministrativeFile(itemAdministrativeFile: boolean | null): void {
    this.itemAdministrativeFileSource.next(itemAdministrativeFile);
  }

  //#endregion
}
