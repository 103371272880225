<div class="ic-between-inputs-container">
  <ic-input-currency
    [inputClass]="'ic-input-container-form-input'"
    [inputCurrencyLabel]="placeHolderText1"
    [autoCompleteConfig]="'off'"
    [currencyFormControl]="inputFormControl1"
    [inputClass]="inputClass"
  >
  </ic-input-currency>

  <ic-input-currency
    [inputClass]="'ic-input-container-form-input'"
    [inputCurrencyLabel]="placeHolderText2"
    [autoCompleteConfig]="'off'"
    [currencyFormControl]="inputFormControl2"
    [inputClass]="inputClass"
  >
  </ic-input-currency>
</div>
