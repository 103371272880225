import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { OmissionAfCorrectionRequest } from '@ic-app/models/omission/panel-correction/omission-af-correction-data.model';
import { getDateFromTimeStampInLocaleFormat } from '@ic-core/util/global-util';
import {
  createRequestOption,
  getTimestampFromNow
} from '@ic-core/util/request-util';
import {
  AdministrativeFileCorrectionRequest,
  AdministrativeFileReturn
} from '@ic-models/prior-inspection/administrative-file.model';
import { FrbCorrectionRequest } from '@ic-models/prior-inspection/frb-request.model';
import {
  PpcAdministrativeFileCorrectionRequest,
  PpcAdministrativeFileReturn
} from '@ic-models/prior-operational/ppc-administrative-file.model';
import { PpcCorrectionRequest } from '@ic-models/prior-operational/ppc-request.model';
import { environment } from 'environments/environment';
// import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class PDFService {
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private readonly localeId: string
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  generateInspectionReport(
    administrativeFileId: number,
    procedureId: number,
    administrativeFileTypeId: number,
    resultCode: number
  ): Observable<any> {
    const req = {
      administrativeFileTypeId: administrativeFileTypeId,
      resultCode: resultCode
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };

    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .get<any>(
        `${baseUrl}/pdfGenerator/inspectionReport/${administrativeFileId}/${procedureId}`,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
    /* eslint-enable */
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  generateInspectionOmissionReport(
    administrativeFileId: number,
    procedureId: number,
    administrativeFileTypeId: number,
    conclusionId: number
  ): Observable<any> {
    const req = {
      administrativeFileTypeId: administrativeFileTypeId,
      conclusionId: conclusionId
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };

    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .get<any>(
        `${baseUrl}/pdfGenerator/inspectionReport/omission/${administrativeFileId}/${procedureId}`,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
    /* eslint-enable */
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  generateFrbCorrectionReport(
    frbCorrectionData: FrbCorrectionRequest
  ): Observable<any> {
    const req = {
      // TODO: Sustituimos moment
      // actualDate: moment().format('DD/MM/YYYY')
      actualDate: getDateFromTimeStampInLocaleFormat(
        getTimestampFromNow(),
        this.localeId
      )
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef, @typescript-eslint/no-explicit-any,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .post(
        `${baseUrl}/pdfGenerator/previsualizeFrbCorrectionReport`,
        frbCorrectionData,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
    /* eslint-enable */
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  generateAdministrativeFileCorrectionReport(
    administrativeFileCorrectionData: AdministrativeFileCorrectionRequest
  ): Observable<any> {
    const req = {
      // TODO: Sustituimos moment
      // actualDate: moment().format('DD/MM/YYYY')
      actualDate: getDateFromTimeStampInLocaleFormat(
        getTimestampFromNow(),
        this.localeId
      )
    };
    const options = createRequestOption(req);

    const customBody = {
      customText: administrativeFileCorrectionData.customText,
      fileId: administrativeFileCorrectionData.fileId,
      templateUri: administrativeFileCorrectionData.templateUri,
      organizationalUnitName:
        administrativeFileCorrectionData.organizationalUnitName,
      fileNumber: administrativeFileCorrectionData.fileNumber,
      customFileNumber: administrativeFileCorrectionData.customFileNumber,
      processingEntity: administrativeFileCorrectionData.processingEntity,
      eniOrganizationalUnitCode:
        administrativeFileCorrectionData.eniOrganizationalUnitCode,
      eniProcessingEntityCode:
        administrativeFileCorrectionData.eniProcessingEntityCode,
      subject: administrativeFileCorrectionData.subject
    };

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .post(
        `${baseUrl}/pdfGenerator/previsualizeAdministrativeFileCorrectionReport`,
        customBody,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
    /* eslint-enable */
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  generateAdministrativeFileReturnReport(
    administrativeFileCorrectionData: AdministrativeFileReturn
  ): Observable<any> {
    const req = {
      // TODO: Sustituimos moment
      // actualDate: moment().format('DD/MM/YYYY')
      actualDate: getDateFromTimeStampInLocaleFormat(
        getTimestampFromNow(),
        this.localeId
      )
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .post(
        `${baseUrl}/pdfGenerator/previsualizeAdministrativeFileReturnReport`,
        administrativeFileCorrectionData,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
    /* eslint-enable */
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  generateStatisticsReport(
    initDate: number,
    endDate: number,
    initDateString: string,
    endDateString: string
  ): Observable<any> {
    const req = {
      initDate: initDate,
      endDate: endDate,
      initDateString: initDateString,
      endDateString: endDateString
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .get<any>(`${baseUrl}/pdfGenerator/statisticsReport`, httpOptions)
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  generatePpcCorrectionReport(
    ppcCorrectionData: PpcCorrectionRequest
  ): Observable<any> {
    const req = {
      // TODO: Sustituimos moment
      // actualDate: moment().format('DD/MM/YYYY')
      actualDate: getDateFromTimeStampInLocaleFormat(
        getTimestampFromNow(),
        this.localeId
      )
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    return this.http
      .post(
        `${baseUrl}/pdfGenerator/previsualizePpcCorrectionReport`,
        ppcCorrectionData,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  generatePpcAdministrativeFileCorrectionReport(
    ppcAdministrativeFileCorrectionData: PpcAdministrativeFileCorrectionRequest
  ): Observable<any> {
    const req = {
      actualDate: getDateFromTimeStampInLocaleFormat(
        getTimestampFromNow(),
        this.localeId
      )
    };
    const options = createRequestOption(req);

    const customBody = {
      customText: ppcAdministrativeFileCorrectionData.customText,
      fileId: ppcAdministrativeFileCorrectionData.fileId,
      templateUri: ppcAdministrativeFileCorrectionData.templateUri,
      organizationalUnitName:
        ppcAdministrativeFileCorrectionData.organizationalUnitName,
      fileNumber: ppcAdministrativeFileCorrectionData.fileNumber,
      customFileNumber: ppcAdministrativeFileCorrectionData.customFileNumber,
      processingEntity: ppcAdministrativeFileCorrectionData.processingEntity,
      eniOrganizationalUnitCode:
        ppcAdministrativeFileCorrectionData.eniOrganizationalUnitCode,
      eniProcessingEntityCode:
        ppcAdministrativeFileCorrectionData.eniProcessingEntityCode,
      subject: ppcAdministrativeFileCorrectionData.subject
    };

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    return this.http
      .post(
        `${baseUrl}/pdfGenerator/previsualizePpcAdministrativeFileCorrectionReport`,
        customBody,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  generatePpcAdministrativeFileReturnReport(
    ppcAdministrativeFileCorrectionData: PpcAdministrativeFileReturn
  ): Observable<any> {
    const req = {
      actualDate: getDateFromTimeStampInLocaleFormat(
        getTimestampFromNow(),
        this.localeId
      )
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    return this.http
      .post(
        `${baseUrl}/pdfGenerator/previsualizePpcAdministrativeFileReturnReport`,
        ppcAdministrativeFileCorrectionData,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  generatePpcReport(
    ppcAdministrativeFileId: number,
    ppcProcedureId: number,
    ppcAdministrativeFileTypeId: number,
    ppcReportProcedureId: number,
    ppcReportConclusionCode: number,
    resultCodePpcProcedure: number
  ): Observable<any> {
    const req = {
      ppcAdministrativeFileTypeId: ppcAdministrativeFileTypeId,
      ppcReportProcedureId: ppcReportProcedureId,
      ppcReportConclusionCode: ppcReportConclusionCode,
      resultCodePpcProcedure: resultCodePpcProcedure
    };
    const options = createRequestOption(req);

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };

    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .get<any>(
        `${baseUrl}/pdfGenerator/ppcReport/${ppcAdministrativeFileId}/${ppcProcedureId}`,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
    /* eslint-enable */
  }

  /**
   * Genera el pdf de solicitud de información complementaria para omisión
   * @param omissionAfCorrectionData
   * @returns
   */
  generateOmissionAfCorrectionReport(
    omissionAfCorrectionData: OmissionAfCorrectionRequest
  ): Observable<any> {
    const req = {
      actualDate: getDateFromTimeStampInLocaleFormat(
        getTimestampFromNow(),
        this.localeId
      )
    };
    const options = createRequestOption(req);

    const customBody = {
      customText: omissionAfCorrectionData.customText,
      fileId: omissionAfCorrectionData.fileId,
      templateUri: omissionAfCorrectionData.templateUri,
      organizationalUnitName: omissionAfCorrectionData.organizationalUnitName,
      fileNumber: omissionAfCorrectionData.fileNumber,
      customFileNumber: omissionAfCorrectionData.customFileNumber,
      processingEntity: omissionAfCorrectionData.processingEntity,
      eniOrganizationalUnitCode:
        omissionAfCorrectionData.eniOrganizationalUnitCode,
      eniProcessingEntityCode: omissionAfCorrectionData.eniProcessingEntityCode,
      subject: omissionAfCorrectionData.subject
    };

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    /* eslint-disable @typescript-eslint/no-unsafe-return,
       @typescript-eslint/typedef,
       @typescript-eslint/no-unsafe-member-access */
    return this.http
      .post(
        `${baseUrl}/pdfGenerator/previsualizeOmissionAfCorrectionReport`,
        customBody,
        httpOptions
      )
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al generar el informe:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
    /* eslint-enable */
  }
}
