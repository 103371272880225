/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { SharedService } from '@ic-app/services/shared.service';
import { ISelectOption } from '@ic-models/select-option.model';
import { Subject, map, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'ic-top-nav-autocomplete',
  templateUrl: './top-nav-autocomplete.component.html'
})
export class TopNavAutocompleteComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() dataToPick: ISelectOption[] = [];
  @Input() dataPicked?: ISelectOption;

  @Output() dataSelected: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(MatAutocompleteTrigger)
  autoCompleteTrigger!: MatAutocompleteTrigger;
  @ViewChildren(MatOption) matOptions!: QueryList<MatOption>; // Acceso a las opciones del autocomplete

  searchControl = new FormControl();
  filteredDataToPick: ISelectOption[] = [];
  allOption: ISelectOption = { id: 0, text: 'Todos los Ayuntamientos' };

  private optionToSimulateSelection: string | null = null;
  private unsubscribe$ = new Subject<void>();

  constructor(private sharedService: SharedService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.filteredDataToPick = this.dataToPick;
    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => this._filter(value))
      )
      .subscribe((filtered) => {
        this.filteredDataToPick = filtered;
      });

    //Nos subsrcibimos si venimos del detalle del Tenant al pulsar Organismos en una entidad
    // para filtrar por dicha entidad
    this.sharedService.settingsFilteredEntityTextSourceChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedOption: string) => {
        if (selectedOption !== '') {
          this.onDataPickerChanged(selectedOption);
          // Actualiza el FormControl con el valor seleccionado
          this.searchControl.setValue(selectedOption, { emitEvent: false });
          // Guarda la opción que necesitas seleccionar para cuando el panel esté abierto
          this.optionToSimulateSelection = selectedOption;
          // Aplica el filtro con la opción seleccionada
          this.filteredDataToPick = this._filter(selectedOption);
          // Abre el panel del autocomplete
          this.autoCompleteTrigger.openPanel();
        }
      });
  }

  // Detectar si el panel está abierto y simular la selección, cuando venimos de la pantalla de tenants
  // y pulsamos sobre la opción Organismos en el detalle de uno de ellos
  ngAfterViewChecked(): void {
    if (this.autoCompleteTrigger.panelOpen && this.optionToSimulateSelection) {
      this.simulateSelection(this.optionToSimulateSelection);
      this.optionToSimulateSelection = null; // Resetea para evitar múltiples selecciones
    }
  }

  /**
   * Se ejecuta cuando se selecciona una opción del mat-autocomplete
   * @param event
   */
  onOptionSelected(event: any): void {
    const selectedOption = event.option.value;
    if (selectedOption === this.allOption.text) {
      this.selectAll();
    } else {
      this.onDataPickerChanged(selectedOption);
    }
  }

  /**
   * Selecciona la opción marcada por el usuario
   * @param optionSelected
   */
  onDataPickerChanged(optionSelected: string): void {
    const selectedObject = this.dataToPick.find(
      (option) => option.text.toLowerCase() === optionSelected.toLowerCase()
    );

    if (selectedObject) {
      this.dataSelected.emit(selectedObject.id);
    }
  }

  /**
   * Función que selecciona la opción Todos los Ayuntamientos
   */
  selectAll(): void {
    this.dataSelected.emit(this.allOption.id);
    //Lógica adicional para seleccionar todos los ayuntamientos
    console.log('Todos los Ayuntamientos seleccionados');
  }

  /**
   * Función para simular la selección de una opción para marcar el check
   * @param value
   */
  simulateSelection(value: string) {
    // setTimeOut para evitar el ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      const optionToSelect = this.matOptions.find(
        (option) =>
          option.viewValue.trim().toLowerCase() === value.toLowerCase()
      );

      const optionToDeselect = this.matOptions.find(
        (option) =>
          option.viewValue.trim().toLowerCase() ===
          this.allOption.text.toLowerCase()
      );

      if (optionToSelect) {
        // Selecciona la opción para marcar el check
        optionToSelect.select();
      }

      if (optionToDeselect?.selected) {
        // Deselecciona la opción para quitar el check
        optionToDeselect.deselect();
      }
    }, 0);
  }

  /**
   * Función para filtrar las opciones del autocomplete
   * @param value
   * @returns
   */
  private _filter(value: string | ISelectOption): ISelectOption[] {
    let filterValue: string;

    //Comprueba si `value` es de tipo `ISelectOption` o `string`
    if (typeof value === 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.text.toLowerCase();
    }

    //Filtra las opciones en función de `filterValue`
    return this.dataToPick.filter((option) =>
      option.text.toLowerCase().includes(filterValue)
    );
  }
}
