<div class="simple-dialog">
  <div class="dialog-title">
    <div class="dialog-title-container">
      <div
        class="flex-child"
        [style.max-width]="
          data.titleStyle && data.titleStyle.maxWidth ? 'auto' : '80%'
        "
        [style.flex]="
          data.titleStyle && data.titleStyle.maxWidth ? '1 1 auto' : 'none'
        "
        [innerHTML]="data.title"
        [style]="titleStyleString ? titleStyleString : ''"
      >
        <h4 mat-dialog-title class="dialog-title">{{ data.title }}</h4>
      </div>
    </div>
  </div>
  <mat-dialog-content class="dialog-content ic-pt-8 ic-pr-24 ic-pl-24">
    <p
      class="dialog-content-text"
      [innerHTML]="data.content"
      [style]="contentStyleString ? contentStyleString : ''"
    >
      {{ data.content }}
    </p>
    <p
      class="dialog-content-text"
      [innerHTML]="data.content2"
      [style]="content2StyleString ? content2StyleString : ''"
    >
      {{ data.content2 }}
    </p>
    <p
      class="dialog-content-text"
      [innerHTML]="data.content3"
      [style]="content3StyleString ? content3StyleString : ''"
    >
      {{ data.content3 }}
    </p>
    <mat-dialog-actions class="dialog-actions ic-pt-28 ic-mb-20">
      <button
        type="button"
        mat-raised-button
        [color]="'primary'"
        (click)="accept($event)"
      >
        {{ data.buttonAccept }}
      </button>
      <button
        type="button"
        *ngIf="data.buttonAcceptAndAction"
        mat-raised-button
        [color]="'primary'"
        (click)="acceptAndAction($event)"
        cdkFocusInitial
      >
        {{ data.buttonAcceptAndAction }}
      </button>
      <button
        type="button"
        *ngIf="data.buttonCancel"
        mat-raised-button
        [color]="'warn'"
        (click)="cancel($event)"
      >
        {{ data.buttonCancel }}
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
