<ic-top-nav class="fixed-topnav gt-xs"></ic-top-nav>
<mat-sidenav-container>
  <mat-sidenav class="ic-navbar" #appDrawer mode="side" opened="true">
    <mat-nav-list>
      <ic-menu-list-item
        *ngFor="let item of navItems"
        [item]="item"
      ></ic-menu-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ic-top-nav class="xs"></ic-top-nav>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <!-- <div fxFlex></div> -->
  <!-- <span class="version-info">Current build: {{version.full ? version.full : version}}</span> -->
</mat-sidenav-container>
