<mat-form-field class="row-one-element">
  <mat-label>{{ 'components.table-filter.filter' | translate }}</mat-label>
  <input
    title="input"
    matInput
    (keyup)="applyFilter($event)"
    [placeholder]="'components.table-filter.placeholder' | translate"
    #input
  />
</mat-form-field>
