import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  IAutoCompleteElement
} from '@ic-app/components/form/input-autocomplete/input-autocomplete.component';
import { Observable, of } from 'rxjs';

import { AppConfigService } from '../app-config.service';

@Injectable()
export class AutoCompleteEntitiesNotSelectedService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteEntitiesNotSelectedByEntitiesIdsData
        .filter((autocompleteCategories: IAutoCompleteElement) => {
          return autocompleteCategories.name
            .toLowerCase()
            .includes(filter.searchValue.toLowerCase());
        })
        .map((autocompleteCategories: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteCategories.id,
            autocompleteCategories.name,
            autocompleteCategories.parentName,
            autocompleteCategories.code
          );
        })
    );
  }
}
