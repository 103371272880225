/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileTypeItemsTreeDataSource } from '@ic-models/trees/file-type-items-tree.datasource';

@Component({
  selector: 'ic-input-tree',
  templateUrl: './input-tree.component.html'
})
export class InputTreeComponent {
  @Input() nestedDataSource: any;
  @Input() nestedTreeControl: any;

  @Output() parentSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() nodeSelected: EventEmitter<any> = new EventEmitter<any>();

  hasNestedChild = (_: number, nodeData: any): boolean =>
    (nodeData && nodeData.children && nodeData.children.length > 0) ||
    nodeData.expandable ||
    (!nodeData.expandable &&
      this.nestedDataSource instanceof FileTypeItemsTreeDataSource);

  selectedNode(node: any): void {
    this.nodeSelected.emit(node);
  }

  selectedParent(node: any): void {
    if (
      node.parentId === null &&
      node.treeId === null &&
      node.id === null &&
      !this.nestedTreeControl.isExpanded(node)
    ) {
      this.nestedTreeControl.collapseDescendants(node);
    }
    this.parentSelected.emit(node);
  }
}
