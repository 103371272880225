<mat-form-field class="ic-input-container" [ngClass]="inputClass">
  <mat-label>{{ inputCurrencyLabel ? inputCurrencyLabel : null }}</mat-label>
  <input
    #inputCurrency
    matInput
    class="ic-input-container-form-input"
    type="text"
    [placeholder]="placeHolderText ?? ''"
    [formControl]="currencyFormControl"
    [autocomplete]="autoCompleteConfig"
    currencyInput
    [maxDigits]="maxDigits"
    [currencyCode]="currencyCode"
    [currencyDigitsInfo]="currencyDigitsInfo"
    [currencyLocale]="currencyLocale"
    [currencySymbol]="currencySymbol"
    [fractionSeparator]="fractionSeparator"
    (change)="onChange()"
    (paste)="onPaste()"
  />
  <mat-hint>{{ inputHint ? inputHint : '' }}</mat-hint>
  <mat-error
    *ngIf="
      currencyFormControl.hasError('required') ||
      currencyFormControl.hasError('nullValidator')
    "
    >{{ 'components.input-currency.errors.required' | translate }}</mat-error
  >
</mat-form-field>
