import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@ic-material/material.module';
import { OperationalModule } from '@ic-pages/prior-inspection/operational/operational.module';
import { PriorInspectionComponent } from '@ic-pages/prior-inspection/prior-inspection.component';
import { PRIOR_INSPECTION_ROUTE } from '@ic-pages/prior-inspection/prior-inspection.routing';
import { ReportsModule } from '@ic-pages/prior-inspection/reports/reports.module';
import { TrackingModule } from '@ic-pages/prior-inspection/tracking/tracking.module';
import { SharedModule } from '@ic-shared/shared.module';

const PROJECT_MODULES = [
  SharedModule,
  MaterialModule,
  OperationalModule,
  ReportsModule,
  TrackingModule
];
const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [PriorInspectionComponent],
  exports: [PriorInspectionComponent],
  imports: [
    ...ANGULAR_MODULES,
    RouterModule.forChild(PRIOR_INSPECTION_ROUTE),
    ...PROJECT_MODULES
  ]
})
export class PriorInspectionModule {}
