import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlSegment,
  CanActivateChild,
  CanLoad,
  UrlTree
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { isEmptyNullOrUndefinedArray } from '@ic-core/util/global-util';
import { AppConfigService } from '@ic-services/app-config.service';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomAuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private auth: AuthService,
    private appConfig: AppConfigService,
    private router: Router
  ) {}

  canLoad(_route: Route, _segments: UrlSegment[]): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(take(1));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.redirectIfUnauthenticated(next, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.redirectIfUnauthenticated(childRoute, state);
  }

  private redirectIfUnauthenticated(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      tap(
        (loggedIn: boolean) =>
          this.manageRedirectIfUnauthenticated(loggedIn, route, state) ||
          of(false)
      )
    );
  }

  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment */
  private manageRedirectIfUnauthenticated(
    loggedIn: boolean,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
    | Observable<void> {
    if (!loggedIn) {
      return this.auth.loginWithRedirect({ appState: { target: state.url } });
    } else {
      // Cogemos los roles del usuario logueado
      const rolesLoaded = this.appConfig.authorities;
      // Comprobamos si tenemos la ruta filtrada por roles
      if (
        route.data['role'] &&
        route.data['role'].length >= 1 &&
        !isEmptyNullOrUndefinedArray(rolesLoaded)
      ) {
        // Comprobamos si alguno de los roles del usuario se corresponde
        // con los roles del filtro para la ruta
        const rolesFound = rolesLoaded.filter(
          (role: string) => route.data['role'].indexOf(role) !== -1
        );

        if (isEmptyNullOrUndefinedArray(rolesFound)) {
          // Si no encontramos roles dentro de los authorities del usuario
          // que estén dentro de los roles permitidos por el route
          // Enrutamos a una página de error 403
          console.log('NO TIENE PERMISOS', JSON.stringify(route.data['role']));

          const customRedirect = route.data['authGuardRedirect'];
          const redirect = customRedirect ? customRedirect : '/error/error403';

          this.router.navigate([redirect]);
          // devolvemos false
          return of(false);
        }
      }
      return of(true);
    }
  }
  /* eslint-enable */
}
