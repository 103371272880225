import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'ic-icon-button',
  templateUrl: './icon-button.component.html'
})
export class IconButtonComponent {
  @Input() color?: string;
  @Input() iconColor?: string;
  @Input() disabled?: boolean | null = false;
  @Input() ariaLabel?: string;
  @Input() matIcon?: string;
  @Input() cssClasses?: string;
  @Input() inline?: boolean;
  @Input() faIcon: IconProp = 'filter';
  @Input() fontAwesome = false;
  @Input() iconSize: SizeProp = 'lg';
  @Input() faIconClass?: string;
  @Input() matIconClass?: string;
  @Output() icClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  buttonClicked = false;

  getCssClasses(): string {
    if (this.fontAwesome) {
      return this.cssClasses
        ? this.cssClasses + ' ic-font-awesome-icon-class'
        : 'ic-font-awesome-icon-class';
    }
    return this.cssClasses
      ? this.cssClasses + ' ic-mat-icon-class'
      : 'ic-mat-icon-class';
  }

  onClick(event: MouseEvent): void {
    if (!this.disabled && !this.buttonClicked) {
      this.buttonClicked = true;
      console.log('Clicked!');
      this.icClick.emit(event);
      setTimeout(() => {
        this.buttonClicked = false;
      }, 1000);
    } else {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return;
    }
  }
}
