<mat-table
  [dataSource]="dataSource"
  class="ic-selectable-table mat-elevation-z8"
  matSort
  [matSortActive]="defaultSortColumn"
  [matSortDirection]="defaultSortDirection"
  matSortDisableClear
>
  <ng-container *ngIf="addChecks" matColumnDef="addSelection">
    <mat-header-cell
      *matHeaderCellDef
      class="ic-selectable-cell-3 ic-selectable-center"
    >
      <label (click)="$event.stopPropagation()">
        <mat-icon
          [title]="'components.table.add-multiple' | translate"
          (click)="addSelected()"
        >
          add_box
        </mat-icon>
      </label>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let row"
      class="ic-selectable-cell-3 ic-selectable-center"
    >
    </mat-cell>
  </ng-container>

  <ng-container
    *ngIf="checks && !descriptionCheckBoxRow"
    matColumnDef="selection"
  >
    <mat-header-cell
      *matHeaderCellDef
      class="ic-selectable-cell-3 ic-selectable-center"
    >
      <mat-checkbox
        *ngIf="!hideMultipleChecks"
        (change)="onHeaderCheckboxChange($event)"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [color]="'primary'"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let row"
      class="ic-selectable-cell-3 ic-selectable-center"
    >
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="onRowCheckboxChange($event, row)"
        [checked]="selection.isSelected(row)"
        [color]="'primary'"
        [(ngModel)]="row.checked"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container
    *ngFor="let customColumn of customColumns"
    matColumnDef="{{ customColumn.columnDefinition }}"
  >
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!customColumn.sort"
      [ngClass]="customColumn.headerCellClass"
    >
      {{ customColumn.headerDefinition }}
    </mat-header-cell>

    <!-------------- Elementos a mostrar en función del tipo de dato de la columna -------------->

    <!-- Tipo genérico (textos y números simples) -->
    <div
      *ngIf="!customColumn.currency && !customColumn.byte && !customColumn.date"
    >
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.elementAuxTooltip ?? ''] &&
          !element[customColumn.elementTooltip ?? '']
            ? (element[customColumn.elementAuxTooltip ?? '']
              | translate
                : {
                    availableWorkingDays:
                      element[customColumn.availableWorkingDays ?? '']
                  })
            : !customColumn.multipleLevelTooltip
            ? (element[customColumn.elementTooltip ?? '']
              | translate
                : {
                    availableWorkingDays:
                      element[customColumn.availableWorkingDays ?? '']
                  })
            : element[customColumn.elementTooltip ?? ''][
                customColumn.multipleLevelTooltip
              ]
        }}"
      >
        <span *ngIf="!customColumn.secondLevelElement">
          {{ element[customColumn.element ?? ''] | translate }}</span
        >
        <span *ngIf="customColumn.secondLevelElement">
          {{
            element[customColumn.element ?? ''][customColumn.secondLevelElement]
          }}</span
        >

        <!-- Icono con colores fijos -->
        <mat-icon
          *ngIf="element[customColumn.icon ?? '']"
          [color]="customColumn.iconColor"
        >
          {{ customColumn.iconName }}
        </mat-icon>

        <!-- Icono fijo con colores variables -->
        <mat-icon
          *ngIf="customColumn.fixedIcon && !element[customColumn.hide ?? '']"
          [color]="element[customColumn.iconColor ?? '']"
        >
          {{ customColumn.iconName }}
        </mat-icon>

        <!-- <span md-colors="{color:'{{ element[customColumn.iconColor] }}'}"
        *ngIf="customColumn.fixedIcon && element[customColumn.hide]
        ">
          {{ element[customColumn.workingDays] }}
        </span> -->
        <span
          [class]="element[customColumn.iconColor ?? '']"
          *ngIf="customColumn.fixedIcon && element[customColumn.hide ?? '']"
          ><strong>
            {{ element[customColumn.workingDays ?? ''] }}
          </strong>
        </span>

        <!-- Icono variable con color invertido -->
        <mat-icon
          [class]="
            element[customColumn.invertColor ?? '']
              ? 'material-icons-outlined'
              : ''
          "
          *ngIf="customColumn.multipleIconOneColor"
          [color]="customColumn.iconColor"
        >
          {{ element[customColumn.iconName ?? ''] }}
        </mat-icon>

        <!-- Icono variable con colores variables -->
        <mat-icon
          *ngIf="customColumn.multipleIcon"
          [color]="element[customColumn.iconColor ?? '']"
        >
          {{ element[customColumn.iconName ?? ''] }}
        </mat-icon>

        <!-- Icono personalizado con colores variables -->
        <mat-icon
          *ngIf="customColumn.personalIcon"
          [color]="element[customColumn.iconColor ?? '']"
          [svgIcon]="element[customColumn.iconName ?? '']"
        >
        </mat-icon>
      </mat-cell>
    </div>

    <!-- Fechas con hora -->
    <div *ngIf="customColumn.date && customColumn.dateHour">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.element ?? ''] | date : 'dd/MM/yyyy HH:mm'
        }}"
      >
        {{ element[customColumn.element ?? ''] | date : 'dd/MM/yyyy HH:mm' }}
      </mat-cell>
    </div>

    <!-- Fechas sin hora -->
    <div *ngIf="customColumn.date && !customColumn.dateHour">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.element ?? ''] | date : 'dd/MM/yyyy HH:mm'
        }}"
      >
        {{ element[customColumn.element ?? ''] | date : 'dd/MM/yyyy' }}
      </mat-cell>
    </div>

    <!-- Cifras monetarias en euros -->
    <div *ngIf="customColumn.currency">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.element ?? ''] | currency : 'EUR'
        }}"
      >
        {{ element[customColumn.element ?? ''] | currency : 'EUR' }}
      </mat-cell>
    </div>

    <!-- Tamaño en bytes -->
    <div *ngIf="customColumn.byte">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{ element[customColumn.element ?? ''] | filesize }}"
      >
        {{ element[customColumn.element ?? ''] | filesize }}
      </mat-cell>
    </div>
  </ng-container>

  <!-------------- Fin de formato de elementos -------------->

  <!-- Columna con opciones -->
  <ng-container *ngIf="optionButtons" matColumnDef="actions" stickyEnd>
    <mat-header-cell *matHeaderCellDef class="ic-selectable-cell-5">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-selectable-cell-5">
      <ic-menu-options
        [optionsButtons]="row.actions"
        [color]="'accent'"
        [iconName]="'list'"
        (actionClicked)="clickedOption($event, row)"
      ></ic-menu-options>
    </mat-cell>
  </ng-container>

  <!-- Columna con opciones de edición y borrado -->
  <ng-container *ngIf="editAndDelete" matColumnDef="editAndDelete" stickyEnd>
    <mat-header-cell *matHeaderCellDef class="ic-selectable-cell-5">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-selectable-cell-5">
      <label (click)="$event.stopPropagation()">
        <mat-icon [title]="editButton?.btnLabel" (click)="editRow(row)">
          {{ editButton?.icon }}
        </mat-icon>
      </label>
      <label (click)="$event.stopPropagation()">
        <mat-icon [title]="deleteButton?.btnLabel" (click)="deleteRow(row)">
          {{ deleteButton?.icon }}
        </mat-icon>
      </label>
    </mat-cell>
  </ng-container>

  <!-- Columna con opcion de añadir especifica para contactos -->
  <ng-container *ngIf="addOption" matColumnDef="addOption" stickyEnd>
    <mat-header-cell *matHeaderCellDef class="ic-selectable-cell-8">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-selectable-cell-8">
      <label
        *ngIf="!row.isInContactsAlready"
        (click)="$event.stopPropagation()"
      >
        <mat-icon
          *ngIf="!row.isInContactsAlready"
          [title]="addButton?.btnLabel"
          (click)="addRow(row)"
        >
          {{ addButton?.icon }}
        </mat-icon>
      </label>
    </mat-cell>
  </ng-container>

  <!-- Columna con opciones de subir fichero y borrado -->
  <ng-container
    *ngIf="uploadAndDelete"
    matColumnDef="uploadAndDelete"
    stickyEnd
  >
    <mat-header-cell *matHeaderCellDef class="ic-selectable-cell-8">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-selectable-cell-8">
      <label
        *ngIf="row.showActions"
        for="upload{{ row.id }}"
        (click)="$event.stopPropagation()"
      >
        <mat-icon [title]="editButton?.btnLabel">
          {{ editButton?.icon }}
        </mat-icon>
        <input
          type="file"
          id="upload{{ row.id }}"
          name="upload{{ row.id }}"
          [multiple]="false"
          class="ic-input-file-table"
          (change)="getValAndUploadFile(row, $event)"
        />
      </label>
      <label *ngIf="row.showActions" (click)="$event.stopPropagation()">
        <mat-icon [title]="deleteButton?.btnLabel" (click)="deleteRow(row)">
          {{ deleteButton?.icon }}
        </mat-icon>
      </label>
    </mat-cell>
  </ng-container>

  <!-- Columna con opcion de añadir especifica para observaciones -->
  <ng-container *ngIf="addObservation" matColumnDef="addObservation" stickyEnd>
    <mat-header-cell *matHeaderCellDef class="ic-selectable-cell-5">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-selectable-cell-5">
      <mat-icon
        [title]="
          'components.table.observations.add-observation-note' | translate
        "
        (click)="addRow2(row, $event)"
      >
        post_add
      </mat-icon>
      <mat-icon
        [title]="'components.table.observations.add-observation' | translate"
        (click)="addRow(row)"
      >
        add_box
      </mat-icon>
    </mat-cell>
  </ng-container>

  <!-- Columna generica para acciones de una tabla -->
  <ng-container
    *ngIf="addActionsTable"
    matColumnDef="addActionsTable"
    stickyEnd
  >
    <mat-header-cell *matHeaderCellDef class="ic-selectable-cell-15">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-selectable-cell-15">
      <label style="cursor: pointer" *ngFor="let action of row.actions">
        <mat-icon
          [ngClass]="action.class"
          [title]="action.title | translate"
          (click)="executeActionIcons(row, action.action)"
        >
          {{ action.iconName }}
        </mat-icon>
      </label>
    </mat-cell>
  </ng-container>

  <ng-container
    *ngIf="checks && descriptionCheckBoxRow"
    matColumnDef="selection"
  >
    <mat-header-cell
      *matHeaderCellDef
      class="ic-selectable-cell-3 ic-selectable-center"
    >
      <mat-checkbox
        (change)="onHeaderCheckboxChange($event)"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [color]="'primary'"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let row"
      class="ic-selectable-cell-3 ic-selectable-center"
    >
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="onRowCheckboxChange($event, row)"
        [checked]="selection.isSelected(row)"
        [color]="'primary'"
        [(ngModel)]="row.checked"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <!-- <ng-container matColumnDef="checkboxAddAll">
    <th mat-header-cell *matHeaderCellDef>Name of the item purchased</th>
  </ng-container> -->

  <!-- Item Description Column -->

  <ng-container *ngIf="displayedColumnsHeader">
    <mat-header-row
      *matHeaderRowDef="displayedColumnsHeader; sticky: true"
      [ngClass]="rowClass"
      [ngClass]="{ 'observations-height-header': descriptionCheckBoxRow }"
    ></mat-header-row>
  </ng-container>
  <ng-container *ngIf="!displayedColumnsHeader">
    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
      [ngClass]="rowClassHeader"
    ></mat-header-row>
  </ng-container>

  <ng-container *ngIf="descriptionCheckBoxRow">
    <mat-header-row
      *matHeaderRowDef="['selection']; let displayedColumnsHeader; sticky: true"
      [ngClass]="rowClassHeader"
    ></mat-header-row>
  </ng-container>
  <mat-row
    (click)="selectRow(row)"
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{ 'mat-row-non-pointer': row.defaultCursor }"
    [class]="rowClass"
    [class.last-touched]="row.isLastTouchedRow"
    [class.obs-highlight]="row.code === 200"
  >
  </mat-row>
</mat-table>
<div
  class="empty-row"
  *ngIf="
    ((dataSource.loading$ | async) === false ||
      (dataSource.loading$ | async) === null ||
      (dataSource.loading$ | async) === undefined) &&
    getFilteredDataDataLength(dataSource) === 0 &&
    emptyMessage &&
    getLoaded(dataSource)
  "
>
  {{ emptyMessage }}
</div>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<mat-paginator
  *ngIf="paginatorActivated"
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  showFirstLastButtons
></mat-paginator>
