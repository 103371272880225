/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  Output} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IPTreePpcData } from '@ic-models/trees/ppc-report-pronouncements-tree.model';
import { FileStorageService } from '@ic-services/filestorage.service';
import { Subject } from 'rxjs';

import { IActionEmit } from '../inspection-tree/tree-action-button/tree-action-button.component';

@Component({
  selector: 'ic-ppc-tree-detail',
  templateUrl: './ppc-tree-detail.component.html'
})
export class PpcTreeDetailComponent {
  @Input() nestedDataSource: any;
  @Input() nestedTreeControl: any;
  @Output() nodeButtonSelected = new EventEmitter<IActionEmit>();

  customColorClassName: any;
  unsubscribe$ = new Subject<void>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fileStorageService: FileStorageService
  ) {
    this.matIconRegistry.addSvgIcon(
      'e_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/e_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'a_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/a_letter.svg'
      )
    );
  }

  hasNestedChild = (_: number, nodeData: IPTreePpcData): boolean | undefined =>
    nodeData && nodeData.children && nodeData.children.length > 0;

  nodeButtonPressed(action: IActionEmit): void {
    console.log('Botón árbol pulsado', JSON.stringify(action));
    this.nodeButtonSelected.emit(action);
  }

  isTitle(node: IPTreePpcData): boolean {
    console.log(node);
    return !node.parentId && !node.treeId;
  }
}
