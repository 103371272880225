import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  ICategoriesAutoCompleteElement
} from '@ic-app/components/form/input-autocomplete/input-autocomplete.component';
import { AppConfigService } from '@ic-services/app-config.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class AutoCompleteCategoriesByEntityIdService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteCategoriesByEntityIdData
        .filter((autocompleteCategories: ICategoriesAutoCompleteElement) => {
          return autocompleteCategories.name
            .toLowerCase()
            .includes(filter.searchValue.toLowerCase());
        })
        .map((autocompleteCategories: ICategoriesAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteCategories.id,
            autocompleteCategories.name,
            autocompleteCategories.parentName,
            autocompleteCategories.code,
            autocompleteCategories.treeId
          );
        })
    );
  }
}
