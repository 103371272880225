import { Injectable } from '@angular/core';

@Injectable()
export class TreeOptions {
  static readonly TREE_DECISION_UNFAVORABLE = 0;
  static readonly TREE_DECISION_FAVORABLE = 1;
  static readonly TREE_DECISION_EXPAND = 3;

  static readonly NODE_RESULT_UNFAVORABLE = 0;
  static readonly NODE_RESULT_FAVORABLE = 1;
  static readonly NODE_RESULT_CHECK_CHILDREN = 3;

  static readonly NODE_PRONOUNCEMENT_YES = 1;
  static readonly NODE_PRONOUNCEMENT_NO = 0;

  static readonly FAVORABLE_COLOR = '#0a8407';
  static readonly UNFAVORABLE_COLOR = '#e80202';
  static readonly PENDING_COLOR = '#e58824';
  static readonly DEFAULT_COLOR = '#aaaaaa';

  static readonly FAVORABLE_COLOR_NAME = 'green';
  static readonly UNFAVORABLE_COLOR_NAME = 'red';
  static readonly PENDING_COLOR_NAME = 'yellow';
  static readonly DEFAULT_COLOR_NAME = 'default';
}
