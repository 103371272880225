import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-core/guards/auth.guard';
import { OmissionReportsComponent } from '@ic-pages/omission-inspection/reports/omission-reports.component';

const routes: Routes = [
  {
    path: '',
    component: OmissionReportsComponent,
    data: {
      role: [Authority.OMISSION, Authority.OMISSION_READER],
      title: 'sidebar.menu.label.omission-inspection.reports'
    },
    canActivate: [CustomAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OmissionReportsRoutingModule {}
