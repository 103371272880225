import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  IAutoCompleteElement
} from '@ic-app/components/form/input-autocomplete/input-autocomplete.component';
import { Observable, of } from 'rxjs';

import { AppConfigService } from '../app-config.service';

@Injectable({ providedIn: 'root' })
export class AutoCompleteScipionUsersService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteScipionUsersData
        .filter((autocompleteScipionUsersData: IAutoCompleteElement) => {
          return autocompleteScipionUsersData.name
            .toLowerCase()
            .includes(filter.searchValue.toLowerCase());
        })
        .map((autocompleteScipionUsersData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteScipionUsersData.id,
            autocompleteScipionUsersData.name,
            undefined,
            undefined
          );
        })
    );
  }
}
