import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AdministrativeFileService } from '@ic-services/administrative-file.service';
import { AppConfigService } from '@ic-services/app-config.service';
import { AutoCompleteAFTService } from '@ic-services/autocomplete/autocomplete-aft.service';
import { AutoCompleteBudgetaryDescriptionService } from '@ic-services/autocomplete/autocomplete-budgetary-description.service';
import { AutoCompleteBudgetaryItemFilterService } from '@ic-services/autocomplete/autocomplete-budgetary-item-filter.service';
import { AutoCompleteBudgetaryItemService } from '@ic-services/autocomplete/autocomplete-budgetary-item.service';
import { AutoCompleteFiscalYearService } from '@ic-services/autocomplete/autocomplete-fiscal-year.service';
import { AutoCompleteOmissionAFTService } from '@ic-services/autocomplete/autocomplete-omission-aft.service';
import { AutoCompleteOUService } from '@ic-services/autocomplete/autocomplete-ou.service';
import { AutoCompleteResponsibleOUService } from '@ic-services/autocomplete/autocomplete-resposible-ou.service';
import { BudgetariesService } from '@ic-services/budgetaries.service';
import { CommentsService } from '@ic-services/comments.service';
import { FileStorageService } from '@ic-services/filestorage.service';
import { FrbService } from '@ic-services/frb.service';
import { InspectionService } from '@ic-services/inspection.service';
import { NavService } from '@ic-services/nav.service';
import { NavigationService } from '@ic-services/navigation.service';
import { ObservationsService } from '@ic-services/observations.service';
import { OmissionService } from '@ic-services/omission.service';
import { PanelTopService } from '@ic-services/panel-top.service';
import { PanelService } from '@ic-services/panel.service';
import { PDFService } from '@ic-services/pdf.service';
import { PpcAdministrativeFileService } from '@ic-services/ppc-administrative-file.service';
import { PpcCommentsService } from '@ic-services/ppc-comments.service';
import { PpcItemCommentsService } from '@ic-services/ppc-item-comment.service';
import { PpcObservationsService } from '@ic-services/ppc-observations.service';
import { PpcReportService } from '@ic-services/ppc-report.service';
import { PpcSubjectiveImprovementsService } from '@ic-services/ppc-subjective-improvement.service';
import { PpcService } from '@ic-services/ppc.service';
import { QuicksightService } from '@ic-services/quicksight.service';
import { SettingsService } from '@ic-services/settings.service';
import { SharedService } from '@ic-services/shared.service';
import { SignaturesService } from '@ic-services/signatures.service';
import { SignsService } from '@ic-services/signs.service';
import { SpinnerService } from '@ic-services/spinner.service';
import { StateStorageService } from '@ic-services/state-storage.service';
import { TreeService } from '@ic-services/tree.service';

const CORE_SERVICES = [
  AdministrativeFileService,
  AppConfigService,
  AutoCompleteAFTService,
  AutoCompleteBudgetaryDescriptionService,
  AutoCompleteBudgetaryItemFilterService,
  AutoCompleteBudgetaryItemService,
  AutoCompleteFiscalYearService,
  AutoCompleteOUService,
  AutoCompleteOmissionAFTService,
  AutoCompleteResponsibleOUService,
  BudgetariesService,
  CommentsService,
  FileStorageService,
  FrbService,
  InspectionService,
  NavService,
  NavigationService,
  ObservationsService,
  OmissionService,
  PanelService,
  PanelTopService,
  PDFService,
  QuicksightService,
  SettingsService,
  SharedService,
  SignaturesService,
  SignsService,
  SpinnerService,
  StateStorageService,
  TreeService,
  PpcService,
  PpcCommentsService,
  PpcAdministrativeFileService,
  PpcReportService,
  PpcObservationsService,
  PpcSubjectiveImprovementsService,
  PpcItemCommentsService
];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [...CORE_SERVICES]
})
export class ServicesModule {
  constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {
    if (parentModule) {
      throw new Error(
        'ServicesModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
