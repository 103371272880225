import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ic-table-filter',
  templateUrl: './table-filter.component.html'
})
export class TableFilterComponent {
  @Output() search = new EventEmitter<string>();

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.search.emit(filterValue.trim().toLowerCase());
  }
}
