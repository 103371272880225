import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PpcConstants {
  static readonly INSPECTION_REQUEST_PROCEDURE_CODE = 1000;
  static readonly INSPECTION_REQUEST_CORRECTION_PROCEDURE_CODE = 1005;
  static readonly INSPECTION_PROCEDURE_CODE = 3000;

  // DOCUMENTS_TYPE_CODE
  static readonly URGENT_DOCUMENT_TYPE_CODE = 2;
  static readonly INSPECTION_DOCUMENT_TYPE_CODE = 3;
  static readonly ADITIONAL_DOCUMENT_TYPE_CODE = 10;

  // LOAD PRONOUNCEMENTS CODE
  static readonly PRONOUNCEMENTS_NEW_INSPECTION_CODE = 0;
  static readonly PRONOUNCEMENTS_LOAD_ALL_CODE = 1;
  static readonly PRONOUNCEMENTS_LOAD_UNFAVORABLE_CODE = 2;

  //PROCEDURE_TYPE_CODE
  static readonly INSPECTION_REQUEST_TYPE = 1000;
  static readonly INSPECTION_REQUEST_AMENDMENT_TYPE = 1010;
  static readonly REPORT_REQUEST_TYPE = 2000;
  static readonly REPORT_RESPONSE_TYPE = 2020;
  static readonly INSPECTION_TYPE = 3000;
  static readonly INSPECTION_AMENDMENT_TYPE = 3010;
  static readonly INSPECTION_OMISSION_TYPE = 4000;
  static readonly OCEX_NOTIFICATION_TYPE = 5000;
  static readonly RETURN_INSPECTION_REQUEST_TYPE = 1005;
  static readonly RETURN_INSPECTION_TYPE = 3005;

  static readonly PPC_ADDITIONAL_INFO_REQUEST_TYPE = 300000;
  static readonly PPC_PROCEDURE_TYPE = 200000;
  static readonly PPC_PROCEDURE_REQUEST_TYPE = 100000;
  static readonly RETURN_PPC_ADMINISTRATIVE_FILE_TYPE = 500000;

  //PPC_RETURN_PROCEDURE_CODE
  static readonly PPC_ADMINISTRATIVE_FILE_RESULT_RETURNED_CODE = 3;

  // INTERNAL_ADMINISTRATIVE_FILE_CODES
  static readonly INTERNAL_ADMINISTRATIVE_FILE_INSPECTION_CODE = 1000;
  static readonly INTERNAL_ADMINISTRATIVE_FILE_INSPECTION_OMISSION_CODE = 2000;

  // INTERNAL_PPC_ADMINISTRATIVE_FILE_CODES
  static readonly INTERNAL_PPC_REPORT_PHASE_CODE = 103000;
  static readonly PPC_REPORT_GENERATED_PHASE_CODE = 104100;

  // PPC_REQUEST_RESULT_CODES
  static readonly PPC_REQUEST_RESULT_CODE_ACCEPTED = 1;
  static readonly PPC_REQUEST_RESULT_CODE_NOT_ACCEPTED = 0;

  static readonly PPC_REQUEST_PROCEDURE_CODE = 100000;
  static readonly PPC_PROCEDURE_CODE = 200000;

  static readonly CREATE_AMENDMENT_PPC_ADMINISTRATIVE_FILE = 0;
  static readonly CREATE_AMENDMENT_PPC_PROCEDURE = 1;
  static readonly SAVE_VALIDATE_PPC_PROCEDURE = 2;
  static readonly SAVE_VALIDATE_PPC_ADMINISTRATIVE_FILE = 3;
  static readonly REOPEN_VALIDATE_PPC_ADMINISTRATIVE_FILE = 3;
  static readonly CREATE_AND_VALIDATE_PPC_REQUEST = 4;
  static readonly CREATE_AND_VALIDATE_PPC_ADMINISTRATIVE_FILE = 5;

  static readonly CREATE_REFORMULATION_PPC_ADMINISTRATIVE_FILE = 0;

  static readonly SAVE_PPC_REQUEST = 0;
  static readonly SAVE_AND_VALIDATE_PPC_REQUEST = 1;
  static readonly PPC_REQUEST_ACCEPTED = 2;

  // PPC_REPORT_RESULT_CODES
  static readonly PPC_REPORT_RESULT_UNFAVORABLE_WITHOUT_RECOMMENDATIONS_CODE = 0;
  static readonly PPC_REPORT_RESULT_FAVORABLE_WITHOUT_RECOMMENDATIONS_CODE = 1;
  static readonly PPC_REPORT_RESULT_FAVORABLE_WITHOUT_RECOMMENDATIONS_BUT_INFRACTIONS_CODE = 2;
  static readonly PPC_REPORT_RESULT_UNFAVORABLE_WITH_RECOMMENDATIONS_CODE = 4;
  static readonly PPC_REPORT_RESULT_FAVORABLE_WITH_RECOMMENDATIONS_CODE = 5;
  static readonly PPC_REPORT_RESULT_FAVORABLE_WITH_RECOMMENDATIONS_BUT_INFRACTIONS_CODE = 6;

  // PPC REPORT CONCLUSION CODES
  static readonly CONCLUSION_FAVORABLE_CODE = 10000;
  static readonly FAVOURABLE_BUT_INFRACTIONS = 12000;
  static readonly CONCLUSION_UNFAVORABLE_CODE = 13000;
}
