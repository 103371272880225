import { Routes } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-core/guards/auth.guard';
import { ActionPlanModule } from '@ic-pages/action-plan/action-plan.module';
import { AnnualReportModule } from '@ic-pages/annual-report/annual-report.module';
import { ConcurrentControlModule } from '@ic-pages/concurrent-control/concurrent-control.module';
import { ConfigurationModule } from '@ic-pages/configuration/configuration.module';
import { Error401Module } from '@ic-pages/error/error401/error401.module';
import { Error403Module } from '@ic-pages/error/error403/error403.module';
import { Error500Module } from '@ic-pages/error/error500/error500.module';
import { Error503Module } from '@ic-pages/error/error503/error503.module';
import { OmissionInspectionModule } from '@ic-pages/omission-inspection/omission-inspection.module';
import { PagesComponent } from '@ic-pages/pages.component';
import { PermanentControlModule } from '@ic-pages/permanent-control/permanent-control.module';
import { PriorControlModule } from '@ic-pages/prior-control/prior-control.module';
import { PriorInspectionModule } from '@ic-pages/prior-inspection/prior-inspection.module';

export const PAGES_ROUTE: Routes = [
  // { path: '', redirectTo: 'priorinspection/operational/solfrb', pathMatch: 'full' },
  {
    path: '',
    component: PagesComponent,
    data: {
      role: [
        Authority.INSPECTOR,
        Authority.READER,
        Authority.OMISSION,
        Authority.OMISSION_READER,
        Authority.PPC,
        Authority.PPC_READER
      ]
    },
    canActivate: [CustomAuthGuard],
    children: [
      {
        path: 'priorinspection',
        canActivate: [CustomAuthGuard],
        data: {
          theme: ['scipion-body'],
          role: [Authority.INSPECTOR, Authority.READER],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof PriorInspectionModule> =>
          (await import('./prior-inspection/prior-inspection.module'))
            .PriorInspectionModule
      },
      {
        path: 'omissioninspection',
        canActivate: [CustomAuthGuard],
        data: {
          theme: ['omission-module'],
          role: [Authority.OMISSION, Authority.OMISSION_READER],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof OmissionInspectionModule> =>
          (await import('./omission-inspection/omission-inspection.module'))
            .OmissionInspectionModule
      },
      {
        path: 'priorcontrol',
        canActivate: [CustomAuthGuard],
        data: {
          theme: ['ppc-module'],
          role: [Authority.PPC, Authority.PPC_READER],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof PriorControlModule> =>
          (await import('./prior-control/prior-control.module'))
            .PriorControlModule
      },
      {
        path: 'permanentcontrol',
        canActivate: [CustomAuthGuard],
        data: {
          role: [],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof PermanentControlModule> =>
          (await import('./permanent-control/permanent-control.module'))
            .PermanentControlModule
      },
      {
        path: 'concurrentcontrol',
        canActivate: [CustomAuthGuard],
        data: {
          role: [],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof ConcurrentControlModule> =>
          (await import('./concurrent-control/concurrent-control.module'))
            .ConcurrentControlModule
      },
      {
        path: 'annualreport',
        canActivate: [CustomAuthGuard],
        data: {
          role: [],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof AnnualReportModule> =>
          (await import('./annual-report/annual-report.module'))
            .AnnualReportModule
      },
      {
        path: 'actionplan',
        canActivate: [CustomAuthGuard],
        data: {
          role: [],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof ActionPlanModule> =>
          (await import('./action-plan/action-plan.module')).ActionPlanModule
      },
      {
        path: 'configuration',
        canActivate: [CustomAuthGuard],
        data: {
          role: [],
          authGuardRedirect: '/error/error403'
        },
        loadChildren: async (): Promise<typeof ConfigurationModule> =>
          (await import('./configuration/configuration.module'))
            .ConfigurationModule
      },
      {
        path: 'error/error401',
        loadChildren: async (): Promise<typeof Error401Module> =>
          (await import('./error/error401/error401.module')).Error401Module
      },
      {
        path: 'error/error403',
        loadChildren: async (): Promise<typeof Error403Module> =>
          (await import('./error/error403/error403.module')).Error403Module
      },
      {
        path: 'error/error500',
        loadChildren: async (): Promise<typeof Error500Module> =>
          (await import('./error/error500/error500.module')).Error500Module
      },
      {
        path: 'error/error503',
        loadChildren: async (): Promise<typeof Error503Module> =>
          (await import('./error/error503/error503.module')).Error503Module
      }
    ]
  }
];
