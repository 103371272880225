export interface IFileTypesTreeComponent {
  id: number;
  name: string;
  parentId: number | null;
  isSelectable: boolean;
  treeId: number | null;
  expandable: boolean;
  children?: IFileTypesTreeComponent[] | null;
  description?: string | null;
  customOrder?: number;
  aftModelDefinitionId?: number;
  locked?: boolean; //Variable para bloquear los elementos del arbol de drag and drop
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  level?: number;
  color?: string; //Variable para pintar el elemento a añadir en el arbol de drag and drop
  isDeleted?: boolean;
}

export class FileTypesTreeComponent implements IFileTypesTreeComponent {
  constructor(
    public id: number,
    public name: string,
    public parentId: number | null,
    public isSelectable: boolean = false,
    public treeId: number | null = null,
    public expandable: boolean,
    public children?: IFileTypesTreeComponent[] | null,
    public description?: string | null,
    public customOrder?: number,
    public aftModelDefinitionId?: number,
    public locked?: boolean,
    public level?: number,
    public color?: string,
    public isDeleted?: boolean
  ) {}
}
