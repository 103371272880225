/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';

@Injectable()
export class CommonConstants {}

export enum InputMaxSizes {
  'MAX_SIZE_TEXT_50' = 50,
  'MAX_SIZE_TEXT_60' = 60,
  'MAX_SIZE_TEXT_240' = 240,
  'MAX_SIZE_TEXT_254' = 254,
  'MAX_SIZE_TEXT_255' = 255,
  'MAX_SIZE_TEXT_264' = 264,
  'MAX_SIZE_TEXT_280' = 280,
  'MAX_SIZE_TEXT_560' = 560,
  'MAX_SIZE_TEXT_1024' = 1024,
  'MAX_SIZE_TEXT_1680' = 1680,
  'MAX_SIZE_TEXT_2048' = 2048,
  'MAX_SIZE_TEXT_2800' = 2800,
  'MAX_SIZE_TEXT_4096' = 4096
}

export enum ButtonsString {
  'BUTTON_CONTINUE' = 'common.button.continue',
  'BUTTON_CANCEL' = 'common.button.cancel',
  'BUTTON_SAVE' = 'common.button.save',
  'BUTTON_ACCEPT' = 'common.button.accept',
  'BUTTON_DELETE' = 'common.button.delete'
}

export enum BaseModulePaths {
  FRB_BASE_PATH = '/priorinspection/operational/administrative-file/list',
  CPP_BASE_PATH = '/priorcontrol/operational/ppc-administrative-file/list',
  OMISSION_BASE_PATH = '/omissioninspection/operational/administrative-file/list'
}
