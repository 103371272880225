import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import {
  IFileTypeItemsTree,
  IFileTypesTree,
  IOrganizationalUnitsTree
} from '@ic-models/tree.model';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;
@Injectable()
export class TreeService {
  constructor(private http: HttpClient) {}

  getFileTypesRootNodes(): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de tipos de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcFileTypesRootNodes(): Observable<IFileTypesTree[]> {
    const req = {
      type: 'PPC_ADMINISTRATIVE_FILE_TYPE'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de tipos de expediente' +
              'de control permanenete previo del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOmissionFileTypesRootNodes(): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE_PHASE_O'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de tipos de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypeItemsRootNodes(
    fileTypeId: number
  ): Observable<IFileTypeItemsTree[]> {
    const req = {
      type: 'FILE_TYPE_ITEMS',
      fileTypeId: fileTypeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypeItemsTree[]>(`${baseUrl}/tree/aftItems/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypeItemsTree[]>) =>
            resp.body as IFileTypeItemsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de items de fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsRootNodes(): Observable<IOrganizationalUnitsTree[]> {
    const req = {
      type: 'ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>(`${baseUrl}/tree/ou/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsResponsibleRootNodes(): Observable<
    IOrganizationalUnitsTree[]
  > {
    const req = {
      type: 'RESPONSIBLE_ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>(`${baseUrl}/tree/ou/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypesRootNodes(): Observable<number> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE'
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/root-nodes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del arbol de tipos de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypeItemsRootNodes(fileTypeId: number): Observable<number> {
    const req = {
      type: 'FILE_TYPE_ITEMS',
      fileTypeId: fileTypeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/items/root-nodes', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del arbol de items de fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countOrganizationalUnitsRootNodes(): Observable<number> {
    const req = {
      type: 'ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/root-nodes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del árbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countOrganizationalUnitsResponsibleRootNodes(): Observable<number> {
    const req = {
      type: 'RESPONSIBLE_ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/root-nodes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del árbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypesTreeData(rootId: number): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de tipos de expedientes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypesOmissionTreeData(rootId: number): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE_PHASE_O',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de tipos de expedientes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcFileTypesTreeData(rootId: number): Observable<IFileTypesTree[]> {
    const req = {
      type: 'PPC_ADMINISTRATIVE_FILE_TYPE',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de tipos de expedientes del servidor:' +
              'de control permanenete previo del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypeItemsTreeData(rootId: number): Observable<IFileTypeItemsTree[]> {
    const req = {
      type: 'FILE_TYPE_ITEMS',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypeItemsTree[]>(`${baseUrl}/tree/aftItems/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypeItemsTree[]>) =>
            resp.body as IFileTypeItemsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de items de fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsTreeData(
    organizationalUnitTreeId: number
  ): Observable<IOrganizationalUnitsTree[]> {
    const req = {
      type: 'ORGANIZATIONAL_UNIT',
      organizationalUnitTreeId: organizationalUnitTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsResponsibleTreeData(
    organizationalUnitTreeId: number
  ): Observable<IOrganizationalUnitsTree[]> {
    const req = {
      type: 'RESPONSIBLE_ORGANIZATIONAL_UNIT',
      organizationalUnitTreeId: organizationalUnitTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypesTreeData(treeId: number): Observable<number> {
    const req = {
      treeId: treeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los tipos de expedientes en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypeItemsTreeData(fileTypeTreeId: number): Observable<number> {
    const req = {
      fileTypeTreeId: fileTypeTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los items de fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countOrganizationalUnitsTreeData(
    organizationalUnitTreeId: number
  ): Observable<number> {
    const req = {
      organizationalUnitTreeId: organizationalUnitTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los departamentos en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
