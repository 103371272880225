import { Component, OnInit } from '@angular/core';
import { NavService } from '@ic-services/nav.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ic-omission-inspection',
  templateUrl: './omission-inspection.component.html'
})
export class OmissionInspectionComponent implements OnInit {
  constructor(
    public navService: NavService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const title = this.translate.instant(
      'sidebar.menu.label.omission-inspection.title'
    ) as string;
    this.navService.updateTitle(title);
  }
}
