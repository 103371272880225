export interface IOrganizationalUnitTreeComponent {
  id: number;
  name: string;
  parentId: number | null;
  parentName: string;
  isSelectable: boolean;
  treeId: number | null;
  expandable: boolean;
  children?: IOrganizationalUnitTreeComponent[] | null;
}

export class OrganizationalUnitTreeComponent
  implements IOrganizationalUnitTreeComponent
{
  constructor(
    public id: number,
    public name: string,
    public parentId: number | null,
    public parentName: string,
    public isSelectable: boolean = true,
    public treeId: number | null = null,
    public expandable: boolean,
    public children?: IOrganizationalUnitTreeComponent[] | null
  ) {}
}
