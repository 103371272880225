/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IPTreeData } from '@ic-models/trees/inspection-pronouncements-tree.model';

import { IActionEmit } from '../inspection-tree/tree-action-button/tree-action-button.component';
import { IIconActionEmit } from '../inspection-tree/tree-icon-button/tree-icon-button.component';

@Component({
  selector: 'ic-settings-inspection-tree',
  templateUrl: './settings-inspection-tree.component.html'
})
export class SettingsInspectionTreeComponent {
  @Input() onlySee?: boolean;
  @Input() nestedDataSource: any;
  @Input() nestedTreeControl: any;
  @Input() showActionsIcons?: boolean = false;
  @Input() isTreeForSimulation?: boolean = false;
  @Output() nodeButtonSelected = new EventEmitter<IActionEmit>();
  @Output() nodeIconSelected = new EventEmitter<IIconActionEmit>();

  customColorClassName: any;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'fondo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/fondo.svg'
      )
    );
  }

  hasNestedChild = (_: number, nodeData: IPTreeData): boolean | undefined =>
    nodeData && nodeData.children && nodeData.children.length > 0;

  nodeButtonPressed(action: IActionEmit): void {
    console.log('Botón árbol pulsado', JSON.stringify(action));
    this.nodeButtonSelected.emit(action);
  }

  nodeIconPressed(action: IIconActionEmit): void {
    console.log('Botón árbol pulsado', JSON.stringify(action));
    this.nodeIconSelected.emit(action);
  }
}
