<mat-tree
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl"
  class="tree mat-tree-position"
>
  <mat-tree-node *matTreeNodeDef="let node">
    <div
      *ngIf="node.nodeVisible"
      [ngClass]="{
        'tree-parent-node': !node.parentId && node.treeId,
        'tree-node': node.parentId,
        'root-node': !node.parentId && !node.treeId
      }"
    >
      <li
        *ngIf="node.nodeVisible"
        class="mat-tree-node"
        [ngStyle]="{ color: node.color }"
      >
        <mat-icon
          *ngIf="node.itemLegalSourceId === 1"
          svgIcon="acm_letter"
          color="cross"
          class="text-bold legal-source-icon"
          style="margin-left: -{{ node.depth }}px; padding-right: {{
            node.depth
          }}px"
          matTooltip="{{ 'components.inspection-tree.acm' | translate }}"
        ></mat-icon>
        <mat-icon
          *ngIf="node.itemLegalSourceId === 2"
          svgIcon="el_letter"
          color="general"
          class="text-bold legal-source-icon"
          style="margin-left: -{{ node.depth }}px; padding-right: {{
            node.depth
          }}px"
          matTooltip="{{ 'components.inspection-tree.el' | translate }}"
        ></mat-icon>
        <mat-icon
          *ngIf="node.completed && node.result === 1"
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="node.completed && node.result === 0"
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        ><span
          [ngClass]="{
            'text-bold': !node.parentId,
            'tree-label-result':
              node.completed && (node.result === 0 || node.result === 1),
            'tree-label': !node.completed
          }"
        >
          {{ node.wording }}
        </span>
        <div class="tree-button">
          <ic-tree-action-button
            class="button"
            *ngFor="let action of node.actionButtons"
            (actionPressed)="nodeButtonPressed($event)"
            [onlySee]="onlySee"
            [labelButton]="action.btnLabel"
            [actionButton]="action.action"
            [currentNode]="node"
            [customColor]="action.color ? action.color : 'default'"
            [pushedButton]="action.pushed"
          ></ic-tree-action-button>
        </div>
      </li>
    </div>
  </mat-tree-node>
  <!-- [className]="
  node.completed && (node.result === 0 || node.result === 1)
    ? 'tree-label-result'
    : 'tree-label'
" -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <div
      *ngIf="node.nodeVisible"
      [ngClass]="{
        'tree-parent': !node.parentId && node.treeId,
        '': node.parentId || !node.treeId,
        'root-node': !node.parentId && !node.treeId
      }"
    >
      <li
        *ngIf="node.nodeVisible"
        class="mat-tree-node"
        [ngStyle]="{ color: node.color }"
      >
        <mat-icon
          style
          *ngIf="node.itemLegalSourceId === 1"
          svgIcon="acm_letter"
          color="cross"
          class="text-bold legal-source-icon"
          style="margin-left: -{{ node.depth }}px; padding-right: {{
            node.depth
          }}px"
          matTooltip="{{ 'components.inspection-tree.acm' | translate }}"
        ></mat-icon>
        <mat-icon
          *ngIf="node.itemLegalSourceId === 2"
          svgIcon="el_letter"
          color="general"
          class="text-bold legal-source-icon"
          style="margin-left: -{{ node.depth }}px; padding-right: {{
            node.depth
          }}px"
          matTooltip="{{ 'components.inspection-tree.el' | translate }}"
        ></mat-icon>
        <mat-icon
          *ngIf="node.completed && node.result === 1 && !node.parentIcon"
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="node.completed && node.result === 0 && !node.parentIcon"
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        >
        <mat-icon
          *ngIf="
            node.completed &&
            (node.result === 1 || node.result === 0) &&
            node.parentIcon
          "
          [ngStyle]="{ color: '#aaaaaa' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >subdirectory_arrow_right</mat-icon
        >
        <span
          [ngClass]="{
            'text-bold': !node.parentId,
            'tree-label-result':
              node.completed && (node.result === 0 || node.result === 1),
            'tree-label': !node.completed
          }"
        >
          {{ node.wording }}
        </span>
        <div class="tree-button">
          <ic-tree-action-button
            class="button"
            *ngFor="let action of node.actionButtons"
            (actionPressed)="nodeButtonPressed($event)"
            [onlySee]="onlySee"
            [labelButton]="action.btnLabel"
            [actionButton]="action.action"
            [currentNode]="node"
            [customColor]="action.color ? action.color : 'default'"
            [pushedButton]="action.pushed"
          ></ic-tree-action-button>
        </div>
      </li>
      <ul [class.tree-invisible]="!nestedTreeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </div>
  </mat-nested-tree-node>
</mat-tree>
