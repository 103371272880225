import { NgModule } from '@angular/core';
import {
  FaIconLibrary,
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome';
import { fab, faWatchmanMonitoring } from '@fortawesome/free-brands-svg-icons';
import {
  faClipboardList,
  faLaptop,
  fas,
  faStream
} from '@fortawesome/free-solid-svg-icons';
import {
  faCog,
  faBook,
  faClone,
  faThumbsUp,
  faHandshake,
  faStopCircle,
  faFileAlt,
  faChartLine,
  faBinoculars,
  faHistory,
  faList,
  faUser,
  faFilter,
  faSearch,
  faTimes,
  faTasks,
  faChartPie,
  faSlash,
  faCheckDouble,
  faGlasses,
  faSearchPlus,
  faDotCircle
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class MyFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
    library.addIcons(
      faCog,
      faBook,
      faClone,
      faThumbsUp,
      faHandshake,
      faStopCircle,
      faFileAlt,
      faChartLine,
      faBinoculars,
      faHistory,
      faList,
      faUser,
      faFilter,
      faSearch,
      faTimes,
      faTasks,
      faChartPie,
      faSlash,
      faCheckDouble,
      faGlasses,
      faSearchPlus,
      faWatchmanMonitoring,
      faLaptop,
      faClipboardList,
      faStream,
      faDotCircle
    );
  }
}
