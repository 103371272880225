<button mat-button class="icon-color" [matMenuTriggerFor]="dataPicker">
  {{ dataPicked?.text }}
  <mat-icon class="icon-color">arrow_drop_down</mat-icon>
</button>
<mat-menu #dataPicker="matMenu">
  <button
    mat-menu-item
    *ngFor="let dataPicked of dataToPick"
    (click)="onDataPickerChanged(dataPicked)"
  >
    {{ dataPicked.text }}
  </button>
</mat-menu>
