/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { convertToTree } from '@ic-app/core/util/tree-util';
import { ICategoryAdministrativeFileTypeDetailData } from '@ic-app/models/settings/prior-inspection/category-afts/detail/category-afts-detail.model';
import { IFileTypesTree } from '@ic-app/models/tree.model';
import {
  FileTypesTreeComponent,
  IFileTypesTreeComponent
} from '@ic-app/models/trees/file-types-tree.model';
import { AppConfigService } from '@ic-app/services/app-config.service';
import { SharedService } from '@ic-app/services/shared.service';
import { TreeService } from '@ic-app/services/tree.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  finalize,
  map,
  of,
  takeUntil
} from 'rxjs';

@Component({
  selector: 'ic-input-tree-dragndrop',
  templateUrl: './input-tree-dragndrop.component.html'
})
export class InputTreeDragndropComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  dataSource: MatTreeFlatDataSource<
    IFileTypesTreeComponent,
    FileTypesTreeComponent
  >;
  treeControl: FlatTreeControl<IFileTypesTreeComponent>;
  treeFlattener: MatTreeFlattener<
    IFileTypesTreeComponent,
    FileTypesTreeComponent
  >;
  expansionModel = new SelectionModel<any>(true);
  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  foundedNode = false;

  dataChange = new BehaviorSubject<any[]>([]);
  @Input() entityId: any;
  @Input() newNode: ICategoryAdministrativeFileTypeDetailData = {};
  @Input() isDetailView = false;
  @Input() comeFromEntityMInMTableButton = false;
  @Input() isDeleteAction = false;
  @Input() deletedCategoryAftTree: ICategoryAdministrativeFileTypeDetailData[] =
    [];

  constructor(
    private treeService: TreeService,
    private appConfig: AppConfigService,
    private sharedService: SharedService
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FileTypesTreeComponent>(
      this.getLevel,
      this.isExpandable
    );

    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  transformer = (
    node: IFileTypesTreeComponent,
    level: number
  ): FileTypesTreeComponent =>
    new FileTypesTreeComponent(
      node.id,
      node.name,
      node.parentId,
      node.isSelectable,
      node.treeId,
      !!node.children,
      node.children,
      node.description,
      node.customOrder,
      node.aftModelDefinitionId,
      node.locked,
      level,
      node.color,
      node.isDeleted
    );

  getLevel = (node: FileTypesTreeComponent): number => node.level as number;

  isExpandable = (node: FileTypesTreeComponent): boolean =>
    node.expandable as boolean;

  getChildren = (node: IFileTypesTreeComponent): Observable<any> =>
    of(node.children);

  hasChild = (_: number, nodeData: FileTypesTreeComponent): boolean =>
    nodeData.expandable;

  ngOnInit(): void {
    this.dataChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: IFileTypesTreeComponent[]) => {
        this.rebuildTreeForData(data);
      });

    if (!this.isDetailView) {
      this.loadFullTreeAndAddNewNode(this.entityId as number, this.newNode);
    } else {
      if (!this.isDeleteAction) {
        this.loadFullTreeDetailNodeView(this.entityId as number, this.newNode);
      } else {
        this.loadDeletedFullTreeDetailNodeView(
          this.entityId as number,
          this.deletedCategoryAftTree
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Carga el arbol y le añade el nuevo expediente/categoria
   *
   * @param entityId
   * @param node
   */
  loadFullTreeAndAddNewNode(
    entityId: number,
    node: ICategoryAdministrativeFileTypeDetailData
  ): void {
    const newNode = this.transformNewNode(node);
    //Cada vez que cargamos un arbol ponemos el foundedNode a false
    this.foundedNode = false;
    this.loadFullTree(entityId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: IFileTypesTreeComponent[]) => {
        //Hace falta reordenar los elementos del arbol, porque los hijos van ordeandoso por id
        data = this.reorderTreeDataNodes(data);
        //Si es una categoría padre se inserta al final del array
        if (newNode.parentId === null) {
          if (!this.comeFromEntityMInMTableButton) {
            data.push(newNode);
            this.foundedNode = true;
          } else {
            //Estamos en gestionar en modelo, por tanto buscamos el mismo nodo para pintarlo y permitir moverlo
            data.forEach((node: FileTypesTreeComponent) => {
              if (node.id === newNode.id) {
                node.color = '#f05060';
                node.locked = false;
                this.foundedNode = true;
              }
            });
          }
        } else {
          //Si es categoria o tipo de expediente se ha de comprobar cual es el nodo padre para
          // insertarselo en la primera posicion
          data.forEach((node: FileTypesTreeComponent) => {
            this.checkCompleteArray(node, newNode);
          });
        }

        this.sharedService.updateCategoryAftFoundedNode(this.foundedNode);

        this.dataSource.data = data;
        this.dataChange.next(data);

        this.treeControl.collapseAll();
        this.expand(this.dataSource.data, newNode.id as number);
      });
  }

  /**
   * Carga el arbol y remarca el expediente/categoria a mostrar en el detalle
   * @param entityId
   * @param node
   */
  loadFullTreeDetailNodeView(
    entityId: number,
    node: ICategoryAdministrativeFileTypeDetailData
  ): void {
    const detailNode = this.transformNewNode(node);
    this.loadFullTree(entityId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: IFileTypesTreeComponent[]) => {
        //Hace falta reordenar los elementos del arbol, porque los hijos van ordeandoso por id
        data = this.reorderTreeDataNodes(data);

        //Si es una categoría padre
        if (detailNode.parentId === null) {
          data.forEach((element: IFileTypesTreeComponent) => {
            //Pintamos de rojo el nodo que se visualiza en la vista de detalle
            if (element.id === detailNode.id) {
              element.color = '#f05060';
            }
          });
        } else {
          //Si es categoria o tipo de expediente se ha de comprobar cual es el nodo
          // que se quiere visualizar para pintarlo
          data.forEach((node: FileTypesTreeComponent) => {
            this.checkAndPaintDetailNode(node, detailNode);
          });
        }

        this.dataSource.data = data;
        this.dataChange.next(data);

        this.treeControl.collapseAll();
        this.expand(this.dataSource.data, detailNode.id as number);
      });
  }

  /**
   * Transforma el nodo del tipo ICategoryAdministrativeFileTypeDetailData
   * en el tipo que necesita el árbol IFileTypesTreeComponent
   * @param newNode
   * @returns
   */
  transformNewNode(
    newNode: ICategoryAdministrativeFileTypeDetailData
  ): IFileTypesTreeComponent {
    const transformedNode = new FileTypesTreeComponent(
      newNode.id as number,
      '', //name
      null,
      false,
      newNode.treeId,
      false
    );
    transformedNode.name = this.chooseNodeNameLanguage(newNode);
    //Si en el idioma local no tiene nada seleccionamos el por defecto que es el ES
    if (
      transformedNode.name === null ||
      transformedNode.name === undefined ||
      transformedNode.name === ''
    ) {
      transformedNode.name = newNode.nameEs as string;
    }

    transformedNode.description = this.chooseNodeDescriptionLanguage(newNode);
    if (
      transformedNode.description === null ||
      transformedNode.description === undefined ||
      transformedNode.description === ''
    ) {
      transformedNode.description = newNode.descriptionEs as string;
    }

    transformedNode.color = '#f05060';
    transformedNode.locked = false;

    if (newNode.isFileType) {
      transformedNode.parentId = newNode.parentId as number;
      transformedNode.isSelectable = true;
      transformedNode.children = null;
    } else {
      if (newNode.parentId !== null && newNode.parentId !== undefined) {
        transformedNode.parentId = newNode.parentId as number;
      }
      transformedNode.expandable = true;
      transformedNode.children = [];
    }

    return transformedNode;
  }

  /**
   * Funcion para expandir el arbol hasta el padre del elemento que estamos gestionando
   * @param data
   * @param uniqueId
   */
  expand(data: IFileTypesTreeComponent[], uniqueId: number): any {
    data.forEach((node: IFileTypesTreeComponent) => {
      if (
        node.children &&
        node.children.find((c: IFileTypesTreeComponent) => c.id === uniqueId)
      ) {
        const index = this.treeControl.dataNodes.findIndex(
          (nodeAux: IFileTypesTreeComponent) => node.id === nodeAux.id
        );
        //expande los nodos
        this.treeControl.expand(this.treeControl.dataNodes[index]);
        //Selecciona los ids de los nodos para que si se mueve cualquier item no se cierre el arbol
        this.expansionModel.select(this.treeControl.dataNodes[index].id);
        this.expand(this.dataSource.data, node.id);
      } else if (
        node.children &&
        node.children.find((c: IFileTypesTreeComponent) => c.children)
      ) {
        this.expand(node.children, uniqueId);
      }
    });
  }

  /**
   * Busca el nodo padre para insertar el nuevo nodo en la posicion inicial
   * @param node
   * @param newNode
   */
  checkCompleteArray(
    node: IFileTypesTreeComponent,
    newNode: IFileTypesTreeComponent
  ): void {
    //Si estamos en el menu de craer buscamos al padre para insertarlo como primer hijo al nuevo nodo
    if (!this.comeFromEntityMInMTableButton) {
      if (node.id === newNode.parentId) {
        node.children?.unshift(newNode);
        this.foundedNode = true;
      } else {
        this.iterateChildNodes(
          node.children as IFileTypesTreeComponent[],
          newNode
        );
      }
    } else {
      //Estamos en gestionar en modelo, por tanto buscamos el mismo nodo para pintarlo y permitir moverlo
      if (node.id === newNode.id) {
        node.color = '#f05060';
        node.locked = false;
        this.foundedNode = true;
      } else {
        this.iterateChildNodes(
          node.children as IFileTypesTreeComponent[],
          newNode
        );
      }
    }
  }

  /**
   * Recorre los nodos hijos para ver  cual coincide con el padre en el caso de
   * venir del panel de crear o el mismo en el caso de venir desde el panel de detalle
   * @param children
   * @param newNode
   */
  iterateChildNodes(
    children: IFileTypesTreeComponent[],
    newNode: IFileTypesTreeComponent
  ): void {
    if (children && children.length > 0) {
      children.forEach((child: IFileTypesTreeComponent) =>
        this.checkCompleteArray(child, newNode)
      );
    }
  }

  /**
   * Busca el nodo que se muestra en la vista de detalle y lo pinta
   * @param node
   * @param detailNode
   */
  checkAndPaintDetailNode(
    node: IFileTypesTreeComponent,
    detailNode: IFileTypesTreeComponent
  ): void {
    if (node.id === detailNode.id) {
      node.color = '#f05060';
    } else {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child: IFileTypesTreeComponent) =>
          this.checkAndPaintDetailNode(child, detailNode)
        );
      }
    }
  }

  loadFullTree(entityId: number): Observable<IFileTypesTreeComponent[]> {
    this.loadingSubject.next(true);
    return this.treeService.getFileTypesAllNodes(entityId).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)),
      map((inputTreesSource: IFileTypesTree[]) => {
        return convertToTree(inputTreesSource);
      })
    );
  }

  visibleNodes(): IFileTypesTreeComponent[] {
    const result: IFileTypesTreeComponent[] = [];

    function addExpandedChildren(
      node: FileTypesTreeComponent,
      expanded: number[]
    ): void {
      result.push(node);
      if (expanded.includes(node.id) && node.children) {
        node.children.map((child: IFileTypesTreeComponent) =>
          addExpandedChildren(child, expanded)
        );
      }
    }

    this.dataSource.data.forEach((node: FileTypesTreeComponent) => {
      addExpandedChildren(node, this.expansionModel.selected as number[]);
    });
    return result;
  }

  drop(event: CdkDragDrop<string[]>): void {
    const visibleNodes = this.visibleNodes();
    const node = event.item.data as IFileTypesTreeComponent;

    // Función recursiva para encontrar los hermanos de un nodo
    function findNodeSiblings(
      arr: IFileTypesTreeComponent[],
      id: number
    ): IFileTypesTreeComponent[] | undefined {
      let result: IFileTypesTreeComponent[] | undefined;
      let subResult;
      arr.forEach((item: IFileTypesTreeComponent) => {
        if (item.id === id) {
          result = arr;
        } else if (item.children) {
          subResult = findNodeSiblings(item.children, id);
          if (subResult) {
            result = subResult;
          }
        }
      });
      return result;
    }

    // Determina donde insertar un nodo
    const nodeAtDest = visibleNodes[event.currentIndex];

    // Se asegura que el drop es válido, debe encontrarse en el mismo nivel del árbol
    // En una siguiente iteración podríamos incluir la posibilidad de mover nodos entre niveles
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find(
      (n: IFileTypesTreeComponent) => nodeAtDest.id === n.id
    );
    if (nodeAtDestFlatNode?.level !== node.level) {
      // Comentamos la alerta, aunque a lo mejor sería interesante indicarle al usuario
      // el motivo por el que ese movimiento no se permite.
      // alert('Los elementos solo pueden moverse en el mismo nivel del árbol.');
      this.rebuildTreeForData(this.dataSource.data);
      return;
    }

    // Si el nodo es el mismo que el nodo de destino, no se hace nada
    if (node.id === nodeAtDest.id) {
      return;
    }

    // Encuentra los hermanos del nodo de destino
    const newSiblings = findNodeSiblings(this.dataSource.data, nodeAtDest?.id);
    // Si no se encuentran hermanos, no se hace nada
    if (!newSiblings) {
      return;
    }
    // Encuentra el índice donde insertar el nodo
    const insertIndex = newSiblings.findIndex(
      (s: IFileTypesTreeComponent) => s.id === nodeAtDest.id
    );

    // Encuentra los hermanos de donde se encontraba el nodo antes de moverlo
    const siblings = findNodeSiblings(this.dataSource.data, node.id);
    // Si no se encuentran hermanos, no se hace nada
    if (!siblings) {
      return;
    }

    // Encuentra el índice donde se encontraba el nodo
    const siblingIndex = siblings.findIndex(
      (n: IFileTypesTreeComponent) => n.id === node.id
    );

    // Elimina el nodo de su posición original
    const nodeToInsert: IFileTypesTreeComponent | undefined = siblings.splice(
      siblingIndex,
      1
    )[0];
    // Si el nodoDestino, el nodo a insertar y el nodo de destino son el mismo,
    // no se hace nada
    if (nodeAtDest && nodeToInsert && nodeAtDest.id === nodeToInsert.id) {
      return;
    }

    // Inserta el nodo en su nueva posición
    if (nodeAtDest.id !== nodeToInsert.id) {
      newSiblings.splice(insertIndex, 0, nodeToInsert);
    }

    // Reconstruye el árbol con los datos modificados
    this.rebuildTreeForData(this.dataSource.data);
  }

  rebuildTreeForData(data: IFileTypesTreeComponent[]): void {
    this.dataSource.data = data;
    this.expansionModel.selected.forEach((id: number) => {
      const node = this.treeControl.dataNodes.find(
        (n: IFileTypesTreeComponent) => n.id === id
      ) as IFileTypesTreeComponent;
      this.treeControl.expand(node);
    });
  }

  /**
   * Reordena los nodos del arbol pro el customOrder
   * @param data
   * @returns
   */
  reorderTreeDataNodes(
    data: IFileTypesTreeComponent[]
  ): IFileTypesTreeComponent[] {
    //Clonamos el array original para evitar modificaciones no deseadas
    const newData = [...data];

    //Ordenamos por customOrder
    newData.sort(
      (a: IFileTypesTreeComponent, b: IFileTypesTreeComponent) =>
        (a.customOrder || 0) - (b.customOrder || 0)
    );

    // Recorremos los elementos y ordenamos los hijos recursivamente
    newData.forEach((node: IFileTypesTreeComponent) => {
      if (node.children && node.children.length > 0) {
        node.children = this.reorderTreeDataNodes(node.children);
      }
    });

    return newData;
  }

  /**
   * Selecciona el idioma para el nombre por defecto del nodo a mostrar
   * @param node
   * @returns
   */
  chooseNodeNameLanguage(
    node: ICategoryAdministrativeFileTypeDetailData
  ): string {
    const language = this.appConfig.lang;
    switch (language) {
      case 'ca':
        return node.nameCa as string;
      case 'gl':
        return node.nameGa as string;
      case 'eu':
        return node.nameEu as string;
      default:
        return node.nameEs as string;
    }
  }

  /**
   * Selecciona el idioma para la descripcion por defecto del nodo a mostrar
   * @param node
   * @returns
   */
  chooseNodeDescriptionLanguage(
    node: ICategoryAdministrativeFileTypeDetailData
  ): string {
    const language = this.appConfig.lang;
    switch (language) {
      case 'ca':
        return node.descriptionCa as string;
      case 'gl':
        return node.descriptionGa as string;
      case 'eu':
        return node.descriptionEu as string;
      default:
        return node.descriptionEs as string;
    }
  }

  /**
   * Carga el arbol y remarca el/los expediente/s/categoria/s eliminado/s
   * @param entityId
   * @param deletedCategoryAftTree
   */
  loadDeletedFullTreeDetailNodeView(
    entityId: number,
    deletedCategoryAftTree: ICategoryAdministrativeFileTypeDetailData[]
  ): void {
    this.loadFullTree(entityId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: IFileTypesTreeComponent[]) => {
        //Transformamos los nodos eliminados en el tipo de objeto que necesitamos para volver a mostrarlos
        deletedCategoryAftTree.forEach(
          (node: ICategoryAdministrativeFileTypeDetailData) => {
            const detailNode = this.transformDeletedNode(node);
            this.insertDeletedNodeIntoTree(data, detailNode);
          }
        );
        //Hace falta reordenar los elementos del arbol, porque los hijos van ordeandoso por id
        data = this.reorderTreeDataNodes(data);

        //Pintaremos los nodos eliminados
        deletedCategoryAftTree.forEach(
          (node: ICategoryAdministrativeFileTypeDetailData) => {
            const detailNode = this.transformNewNode(node);
            //Si es una categoría padre
            if (detailNode.parentId === null) {
              data.forEach((element: IFileTypesTreeComponent) => {
                //Pintamos de rojo el nodo que se visualiza en la vista de detalle
                if (element.id === detailNode.id) {
                  element.color = '#f05060';
                }
              });
            } else {
              //Si es categoria o tipo de expediente se ha de comprobar cual es el nodo
              // que se quiere visualizar para pintarlo
              data.forEach((node: FileTypesTreeComponent) => {
                this.paintEliminatedDetailNode(node, detailNode);
              });
            }
          }
        );

        this.dataSource.data = data;
        this.dataChange.next(data);

        this.treeControl.collapseAll();
        this.expand(
          this.dataSource.data,
          deletedCategoryAftTree[0].id as number
        );
      });
  }

  /**
   * Transforma el nodo eliminado del tipo ICategoryAdministrativeFileTypeDetailData
   * en el tipo que necesita el árbol IFileTypesTreeComponent
   * @param deletedNode
   * @returns
   */
  transformDeletedNode(
    deletedNode: ICategoryAdministrativeFileTypeDetailData
  ): IFileTypesTreeComponent {
    const transformedNode = new FileTypesTreeComponent(
      deletedNode.id as number,
      '', //name
      null,
      false,
      deletedNode.treeId,
      false
    );

    transformedNode.name = this.chooseNodeNameLanguage(deletedNode);
    //Si en el idioma local no tiene nada seleccionamos el por defecto que es el ES
    if (
      transformedNode.name === null ||
      transformedNode.name === undefined ||
      transformedNode.name === ''
    ) {
      transformedNode.name = deletedNode.nameEs as string;
    }

    transformedNode.description =
      this.chooseNodeDescriptionLanguage(deletedNode);
    //Si en el idioma local no tiene nada seleccionamos el por defecto que es el ES
    if (
      transformedNode.description === null ||
      transformedNode.description === undefined ||
      transformedNode.description === ''
    ) {
      transformedNode.description = deletedNode.descriptionEs as string;
    }

    transformedNode.color = '#f05060';
    transformedNode.locked = false;
    transformedNode.customOrder = deletedNode.customOrder;
    transformedNode.isDeleted = true;

    if (deletedNode.isFileType) {
      transformedNode.parentId = deletedNode.parentId as number;
      transformedNode.isSelectable = true;
      transformedNode.children = null;
    } else {
      if (deletedNode.parentId !== null && deletedNode.parentId !== undefined) {
        transformedNode.parentId = deletedNode.parentId as number;
      }
      transformedNode.expandable = true;
      transformedNode.children = [];
    }

    return transformedNode;
  }

  /**
   * Busca el nodo eliminado y lo pinta
   * @param node
   * @param detailNode
   */
  paintEliminatedDetailNode(
    node: IFileTypesTreeComponent,
    detailNode: IFileTypesTreeComponent
  ): void {
    if (node.id === detailNode.id) {
      node.color = '#f05060';
    } else {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child: IFileTypesTreeComponent) =>
          this.paintEliminatedDetailNode(child, detailNode)
        );
      }
    }
  }

  /**
   * Inserta los nodos eliminados en el arbol
   * @param tree
   * @param newNode
   * @returns
   */
  insertDeletedNodeIntoTree(
    tree: IFileTypesTreeComponent[],
    newNode: IFileTypesTreeComponent
  ): IFileTypesTreeComponent[] {
    // Si el parentId es null, insertamos el nuevo nodo en el nivel raíz
    if (newNode.parentId === null) {
      return this.insertInCustomOrder(tree, newNode);
    }

    // Si no es el nivel raíz, recorremos el árbol para encontrar el nodo padre
    const parent = this.findNodeById(tree, newNode.parentId);

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      // Insertamos el nuevo nodo en la lista de hijos del padre, respetando customOrder
      parent.children = this.insertInCustomOrder(parent.children, newNode);
    }

    return tree;
  }

  /**
   * Función para insertar el nodo en el lugar correcto según su customOrder
   * @param nodes
   * @param newNode
   * @returns
   */
  insertInCustomOrder(
    nodes: IFileTypesTreeComponent[],
    newNode: IFileTypesTreeComponent
  ): IFileTypesTreeComponent[] {
    // Si el array está vacío, simplemente añadimos el nodo
    if (nodes.length === 0) {
      return [newNode];
    }

    // Si ya hay nodos, buscamos dónde insertar el nuevo nodo
    for (let i = 0; i < nodes.length; i++) {
      if (newNode.customOrder! < nodes[i].customOrder!) {
        // Insertamos el nodo en la posición encontrada
        nodes.splice(i, 0, newNode);
        return nodes;
      }
    }

    // Si no se encuentra una posición antes de los nodos existentes, lo agregamos al final
    nodes.push(newNode);
    return nodes;
  }

  /**
   * Función auxiliar para encontrar el nodo padre en el árbol
   * @param nodes
   * @param parentId
   * @returns
   */
  findNodeById(
    nodes: IFileTypesTreeComponent[],
    parentId: number
  ): IFileTypesTreeComponent | null {
    for (let node of nodes) {
      if (node.id === parentId) {
        return node;
      }

      if (node.children) {
        const foundNode = this.findNodeById(node.children, parentId);
        if (foundNode) {
          return foundNode;
        }
      }
    }

    return null;
  }
}
