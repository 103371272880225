import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'ic-input-textarea',
  templateUrl: './input-textarea.component.html'
})
export class InputTextareaComponent implements OnInit {
  @Input() textAreaLabel?: string;
  @Input() textAreaType?: string;
  @Input() placeHolderText?: string;
  @Input() textAreaControl?: FormControl;
  @Input() inputHint?: string;
  @Input() textAreaClass?: string;
  @Input() textAreaInputClass?: string;
  @Input() disabledInput?: boolean | null = false;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() required?: boolean;
  @Input() nullValidator?: boolean;

  appearance: MatFormFieldAppearance = 'fill';

  ngOnInit(): void {
    if (this.disabledInput) {
      this.textAreaControl?.disable({
        onlySelf: true,
        emitEvent: false
      });
    }

    const validators: ValidatorFn | ValidatorFn[] | null = [];
    if (this.maxLength) {
      validators.push(Validators.maxLength(this.maxLength as number));
    }
    if (this.minLength) {
      validators.push(Validators.minLength(this.minLength as number));
    }
    if (
      this.required === true &&
      this.textAreaControl?.hasValidator(Validators.required) === false
    ) {
      validators.push(Validators.required);
    }
    if (
      this.nullValidator === true &&
      this.textAreaControl?.hasValidator(Validators.nullValidator) === false
    ) {
      validators.push(Validators.nullValidator);
    }
    if (validators && validators.length >= 1) {
      this.textAreaControl?.addValidators(validators);
    }
  }

  onInput(event: Event): void {
    if (
      this.textAreaControl &&
      this.textAreaControl.errors &&
      this.textAreaControl.errors?.maxlength &&
      (this.textAreaControl.value as string).length >=
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.textAreaControl.errors?.maxlength.actualLength
    ) {
      event.preventDefault();
      this.textAreaControl.markAllAsTouched();
    }
  }
}
