import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
  visibility: BehaviorSubject<boolean>;
  visibilityExt: BehaviorSubject<boolean>;

  constructor() {
    this.visibility = new BehaviorSubject(false);
    this.visibilityExt = new BehaviorSubject(false);
  }

  show(): void {
    this.visibility.next(true);
  }

  hide(): void {
    this.visibility.next(false);
  }

  showExt(): void {
    this.visibilityExt.next(true);
  }

  hideExt(): void {
    this.visibilityExt.next(false);
  }
}
