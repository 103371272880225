import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import { IEntityTenantNameId } from '@ic-app/models/settings/prior-inspection/items/settings-prior-inspection-items.model';
import { environment } from 'environments/environment';
import { Observable, map, catchError, throwError } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  getEntityTenantNameId(entityId: number): Observable<IEntityTenantNameId> {
    const req = {
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IEntityTenantNameId>(
        `${baseUrl}/configuration/getEntityTenantNameId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IEntityTenantNameId>) =>
            resp.body as IEntityTenantNameId
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar la entidad:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
