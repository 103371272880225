<nav mat-tab-nav-bar class="ic-mat-tab-nav-bar" [tabPanel]="tabPanel">
  <a
    mat-tab-link
    class="ic-tab"
    *ngFor="let link of navLinks"
    [routerLink]="link.link"
    routerLinkActive
    #rla="routerLinkActive"
    [active]="rla.isActive"
    [disabled]="disableMatFlags"
  >
    <span>{{ link.label }}</span>
  </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
