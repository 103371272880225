/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StateStorageService } from '@ic-services/state-storage.service';

@Injectable()
export class NavigationService {
  constructor(
    private router: Router,
    private stateStorageService: StateStorageService,
    private location: Location
  ) {}

  goBack(): void {
    const urlData = this.stateStorageService.getUrlWithData();
    if (urlData && urlData['url']) {
      const url = urlData['url'].replace(
        /([&?])refresh=\d*(&)?/,
        (match: string, p1: string, p2: string | undefined) =>
          p1 === '?' && p2 === '&' ? '?' : ''
      );
      this.router.navigate([url]);
    } else {
      this.location.back();
    }
  }
}
