import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MyFontAwesomeModule } from '@ic-app/myfontawesome.module';
import {
  DialogComponent,
  InputAutocompleteComponent,
  MenuOptionsComponent,
  IconButtonComponent,
  DialogPDFComponent,
  DinamicSearchComponent,
  TopNavPickerComponent,
  TreeActionButtonComponent,
  InspectionTreeComponent,
  PpcTreeComponent,
  PpcTreeDetailComponent,
  BasicButtonComponent,
  BetweenInputsComponent,
  CheckboxComponent,
  DatePickerComponent,
  DetailTableComponent,
  DialogInputComponent,
  DragndropComponent,
  ErrorSumaryComponent,
  FabButtonComponent,
  FlatButtonComponent,
  InputComponent,
  InputCurrencyComponent,
  InputMultipleSelectComponent,
  InputSelectComponent,
  InputTextareaComponent,
  InputTreeComponent,
  InputTreeDetailComponent,
  ListFilterComponent,
  MinifabButtonComponent,
  ProgressComponent,
  RaisedButtonComponent,
  SearchboxFilterComponent,
  SlidetoggleComponent,
  StrokedButtonComponent,
  TableFilterComponent,
  FullDataTableComponent,
  PartialDataTableComponent,
  ToolbarListComponent,
  ToolbarSearchboxComponent,
  InputChipComponent,
  InputTreeParentSelectableComponent,
  TreeIconButtonComponent,
  InputTreeDragndropComponent,
  SettingsInspectionTreeComponent,
  TableDragndropComponent,
  TopNavAutocompleteComponent
} from '@ic-components/components.index';
import { DirectivesModule } from '@ic-core/directives/directives.module';
import { MaterialModule } from '@ic-material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFilesizeModule } from 'ngx-filesize';

const COMPONENTS_MODULES = [
  BasicButtonComponent,
  FabButtonComponent,
  FlatButtonComponent,
  IconButtonComponent,
  MinifabButtonComponent,
  RaisedButtonComponent,
  StrokedButtonComponent,
  DetailTableComponent,
  BetweenInputsComponent,
  CheckboxComponent,
  DatePickerComponent,
  DialogComponent,
  DialogInputComponent,
  DialogPDFComponent,
  DinamicSearchComponent,
  DragndropComponent,
  ErrorSumaryComponent,
  InputComponent,
  InputChipComponent,
  InputAutocompleteComponent,
  InputCurrencyComponent,
  InputMultipleSelectComponent,
  InputSelectComponent,
  InputTextareaComponent,
  InputTreeComponent,
  InputTreeParentSelectableComponent,
  InputTreeDetailComponent,
  ListFilterComponent,
  SearchboxFilterComponent,
  SlidetoggleComponent,
  TableFilterComponent,
  ToolbarListComponent,
  ToolbarSearchboxComponent,
  MenuOptionsComponent,
  ProgressComponent,
  TopNavPickerComponent,
  FullDataTableComponent,
  PartialDataTableComponent,
  TreeActionButtonComponent,
  InspectionTreeComponent,
  PpcTreeComponent,
  PpcTreeDetailComponent,
  TreeIconButtonComponent,
  InputTreeDragndropComponent,
  SettingsInspectionTreeComponent,
  TableDragndropComponent,
  TopNavAutocompleteComponent
];

@NgModule({
  declarations: [...COMPONENTS_MODULES],
  exports: [...COMPONENTS_MODULES],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    MyFontAwesomeModule,
    DirectivesModule,
    NgxFilesizeModule,
    MatMenuModule,
    CdkDropList,
    CdkDrag
  ],
  providers: [CurrencyPipe]
})
export class ComponentsModule {}
