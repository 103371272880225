<button
  type="button"
  mat-stroked-button
  [color]="color"
  [disabled]="disabled"
  [class]="cssClasses"
  (click)="onClick($event)"
>
  {{ textInput }}
</button>
