import { Routes } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-core/guards/auth.guard';

import { OperationalModule } from './operational/operational.module';
import { PriorInspectionComponent } from './prior-inspection.component';
import { ReportsModule } from './reports/reports.module';
import { TrackingModule } from './tracking/tracking.module';

export const PRIOR_INSPECTION_ROUTE: Routes = [
  {
    path: '',
    component: PriorInspectionComponent,
    data: {
      role: [Authority.INSPECTOR, Authority.READER],
      title: 'sidebar.menu.label.prior-inspection.operational'
    },
    canActivate: [CustomAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'priorinspection/operational',
        pathMatch: 'full'
      },
      {
        path: 'operational',
        loadChildren: async (): Promise<typeof OperationalModule> =>
          (await import('./operational/operational.module')).OperationalModule
      },
      {
        path: 'tracking',
        loadChildren: async (): Promise<typeof TrackingModule> =>
          (await import('./tracking/tracking.module')).TrackingModule
      },
      {
        path: 'reports',
        loadChildren: async (): Promise<typeof ReportsModule> =>
          (await import('./reports/reports.module')).ReportsModule
      }
    ]
  }
];
