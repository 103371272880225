import { Injectable } from '@angular/core';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';
import { INavItem } from '@ic-models/nav-item.model';
import { TranslateService } from '@ngx-translate/core';

import { Authority } from './authority.constants';

@Injectable()
export class NavItems {
  static ITEMS: INavItem[];

  static initialize(translate: TranslateService): void {
    this.ITEMS = [
      {
        displayName: translate.instant(
          'sidebar.menu.label.prior-inspection.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.prior-inspection.title'
        ) as string,
        iconName: 'tasks' as IconName,
        route: 'priorinspection',
        roles: [Authority.INSPECTOR, Authority.READER],
        children: [
          {
            displayName: translate.instant(
              'sidebar.menu.label.prior-inspection.operational'
            ) as string,
            iconName: 'laptop' as IconName,
            roles: [Authority.INSPECTOR, Authority.READER],
            route: 'priorinspection/operational'
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.prior-inspection.tracking'
            ) as string,
            iconName: 'watchman-monitoring' as IconName,
            iconType: 'fab' as IconPrefix,
            roles: [Authority.INSPECTOR, Authority.READER],
            route: 'priorinspection/tracking'
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.prior-inspection.reports'
            ) as string,
            iconName: 'chart-pie' as IconName,
            roles: [Authority.INSPECTOR, Authority.READER],
            route: 'priorinspection/reports'
          }
        ]
      },
      {
        displayName: translate.instant(
          'sidebar.menu.label.omission-inspection.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.omission-inspection.title'
        ) as string,
        iconName: 'tasks' as IconName,
        slash: true,
        route: 'omissioninspection',
        splitLine: true,
        roles: [Authority.OMISSION, Authority.OMISSION_READER],
        children: [
          {
            displayName: translate.instant(
              'sidebar.menu.label.omission-inspection.operational'
            ) as string,
            iconName: 'laptop' as IconName,
            roles: [Authority.OMISSION, Authority.OMISSION_READER],
            route: 'omissioninspection/operational'
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.omission-inspection.tracking'
            ) as string,
            iconName: 'watchman-monitoring' as IconName,
            iconType: 'fab' as IconPrefix,
            roles: [Authority.OMISSION, Authority.OMISSION_READER],
            route: 'omissioninspection/tracking'
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.omission-inspection.reports'
            ) as string,
            iconName: 'chart-pie',
            roles: [Authority.OMISSION, Authority.OMISSION_READER],
            route: 'omissioninspection/reports'
          }
        ]
      },
      {
        displayName: translate.instant(
          'sidebar.menu.label.prior-control.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.prior-control.title'
        ) as string,
        iconName: 'check-double' as IconName,
        route: 'priorcontrol',
        splitLine: true,
        roles: [Authority.PPC, Authority.PPC_READER],
        children: [
          {
            displayName: translate.instant(
              'sidebar.menu.label.prior-control.operational'
            ) as string,
            iconName: 'laptop' as IconName,
            route: 'priorcontrol/operational',
            roles: [Authority.PPC, Authority.PPC_READER]
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.prior-control.reports'
            ) as string,
            iconName: 'chart-pie' as IconName,
            route: 'priorcontrol/reports',
            roles: [Authority.PPC, Authority.PPC_READER]
          }
        ]
      },
      {
        displayName: translate.instant(
          'sidebar.menu.label.permanent-control.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.permanent-control.title'
        ) as string,
        iconName: 'glasses' as IconName,
        route: 'permanentcontrol',
        roles: [],
        children: [
          {
            displayName: translate.instant(
              'sidebar.menu.label.permanent-control.operational'
            ) as string,
            iconName: 'laptop' as IconName,
            route: 'permanentcontrol/operational',
            roles: []
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.permanent-control.reports'
            ) as string,
            iconName: 'chart-pie' as IconName,
            route: 'permanentcontrol/reports',
            roles: []
          }
        ]
      },
      {
        displayName: translate.instant(
          'sidebar.menu.label.concurrent-control.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.concurrent-control.title'
        ) as string,
        iconName: 'search-plus' as IconName,
        route: 'concurrentcontrol',
        roles: [],
        children: [
          {
            displayName: translate.instant(
              'sidebar.menu.label.concurrent-control.operational'
            ) as string,
            iconName: 'laptop' as IconName,
            route: 'concurrentcontrol/operational',
            roles: []
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.concurrent-control.reports'
            ) as string,
            iconName: 'chart-pie' as IconName,
            route: 'concurrentcontrol/reports',
            roles: []
          }
        ]
      },
      {
        displayName: translate.instant(
          'sidebar.menu.label.annualReport.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.annualReport.title'
        ) as string,
        iconName: 'clipboard-list' as IconName,
        route: 'annualreport',
        splitLine: true,
        roles: []
      },
      {
        displayName: translate.instant(
          'sidebar.menu.label.actionPlan.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.actionPlan.title'
        ) as string,
        iconName: 'stream' as IconName,
        route: 'actionplan',
        roles: []
      },
      {
        displayName: translate.instant(
          'sidebar.menu.label.configuration.label'
        ) as string,
        title: translate.instant(
          'sidebar.menu.label.configuration.title'
        ) as string,
        iconName: 'cog' as IconName,
        route: 'configuration',
        splitLine: true,
        roles: [
          Authority.SCIPION_ADMIN,
          Authority.TENANT_ADMIN,
          Authority.FRB_ADVANCED_ADMIN,
          Authority.FRB_BASIC_ADMIN,
          Authority.CPP_ADVANCED_ADMIN,
          Authority.CPP_BASIC_ADMIN
        ],
        children: [
          {
            displayName: translate.instant(
              'sidebar.menu.label.configuration.settings'
            ) as string,
            iconName: 'wrench' as IconName,
            roles: [Authority.SCIPION_ADMIN, Authority.TENANT_ADMIN],
            route: 'configuration/settings'
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.prior-inspection.label'
            ) as string,
            iconName: 'tasks' as IconName,
            roles: [
              Authority.SCIPION_ADMIN,
              Authority.FRB_ADVANCED_ADMIN,
              Authority.FRB_BASIC_ADMIN
            ],
            route: 'configuration/prior-inspection'
          },
          {
            displayName: translate.instant(
              'sidebar.menu.label.permanent-control.label'
            ) as string,
            iconName: 'check-double' as IconName,
            roles: [
              Authority.SCIPION_ADMIN,
              Authority.CPP_ADVANCED_ADMIN,
              Authority.CPP_BASIC_ADMIN
            ],
            route: 'configuration/prior-control'
          }
        ]
      }
    ];
  }
}
