<div class="example-container ic-table-dragndrop">
  <h2>{{ firstTableTitle | translate }}</h2>

  <div
    cdkDropList
    #firstList="cdkDropList"
    [cdkDropListData]="dataSourceFirst"
    [cdkDropListConnectedTo]="[secondList]"
    class="drop-list"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="box-table"
      *ngFor="let item of dataSourceFirst; let i = index"
      cdkDrag
      [ngClass]="{
        'row-color': item.rowColor === false && !item.rowColorReused,
        'row-color-reused': item.rowColorReused === true
      }"
    >
      <!-- ICONO DE ITEM LEGAL SOURCE -->
      <mat-icon
        [color]="item.itemLegalSourceIconColor"
        [svgIcon]="item.itemLegalSourceIcon"
        class="text-bold legal-source-icon"
      >
      </mat-icon>

      <ic-input-select
        [isDisabled]="false"
        [formFieldClass]="'filter-class'"
        [inputFormControl]="getFormControlFirst(i)"
        (selectedOption)="changeItemLegalSourceIcon(item, 1)"
        [options]="acmElOptions"
        class="select-acm-el"
      >
      </ic-input-select>

      <!-- ICONO Y WORDING-->
      <div class="iconWithWording">
        <mat-icon
          [ngStyle]="{
            color: item.itemDragnDropIconColor,
            'margin-left': item.depth + 'px',
            'padding-right': '20px'
          }"
        >
          {{ item.itemDragnDropIcon }}
        </mat-icon>
        <span [ngClass]="{ 'line-through': item.itemLineThrough }">
          {{ item.wording | translate }}
        </span>
      </div>
      <div class="icon-button-container">
        <ic-icon-button
          *ngIf="item.parentId === null"
          [ariaLabel]="item.addItemUpTooltip | translate"
          [cssClasses]="'actionIcons'"
          [matIcon]="item.addItemUpIcon"
          [inline]="true"
          (icClick)="goToAddItemPanel(item, false)"
        >
        </ic-icon-button>
        <ic-icon-button
          *ngIf="item.parentId === null"
          [ariaLabel]="item.addItemDownTooltip | translate"
          [cssClasses]="'actionIcons'"
          [matIcon]="item.addItemDownIcon"
          [inline]="true"
          (icClick)="goToAddItemPanel(item, true)"
        >
        </ic-icon-button>
        <ic-icon-button
          *ngIf="item.parentId === null"
          [ariaLabel]="item.deleteTooltip | translate"
          [cssClasses]="'actionIcons'"
          [matIcon]="item.deletedIcon"
          [inline]="true"
          (icClick)="deleteOrUndoItem(item)"
        >
        </ic-icon-button>
      </div>
    </div>
  </div>
</div>

<div class="example-container ic-table-dragndrop">
  <h2>{{ secondTableTitle | translate }}</h2>

  <div
    cdkDropList
    #secondList="cdkDropList"
    [cdkDropListData]="dataSourceSecond"
    [cdkDropListConnectedTo]="[firstList]"
    class="drop-list"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="box-table"
      *ngFor="let item of dataSourceSecond; let i = index"
      cdkDrag
      [ngClass]="{
        'row-color': item.rowColor === false && !item.rowColorReused,
        'row-color-reused': item.rowColorReused === true
      }"
    >
      <!-- ICONO DE ITEM LEGAL SOURCE -->
      <mat-icon
        [color]="item.itemLegalSourceIconColor"
        [svgIcon]="item.itemLegalSourceIcon"
        class="text-bold legal-source-icon"
      >
      </mat-icon>

      <ic-input-select
        [isDisabled]="false"
        [formFieldClass]="'filter-class'"
        [inputFormControl]="getFormControlSecond(i)"
        (selectedOption)="changeItemLegalSourceIcon(item, 2)"
        [options]="acmElOptions"
        class="select-acm-el"
      >
      </ic-input-select>

      <!-- ICONO Y WORDING-->
      <div class="iconWithWording">
        <mat-icon
          [ngStyle]="{
            color: item.itemDragnDropIconColor,
            'margin-left': item.depth + 'px',
            'padding-right': '20px'
          }"
        >
          {{ item.itemDragnDropIcon }}
        </mat-icon>
        <span [ngClass]="{ 'line-through': item.itemLineThrough }">
          {{ item.wording | translate }}
        </span>
      </div>
      <div class="icon-button-container">
        <ic-icon-button
          *ngIf="item.parentId === null"
          [ariaLabel]="item.addItemUpTooltip | translate"
          [cssClasses]="'actionIcons'"
          [matIcon]="item.addItemUpIcon"
          [inline]="true"
          (icClick)="goToAddItemPanel(item, false)"
        >
        </ic-icon-button>
        <ic-icon-button
          *ngIf="item.parentId === null"
          [ariaLabel]="item.addItemDownTooltip | translate"
          [cssClasses]="'actionIcons'"
          [matIcon]="item.addItemDownIcon"
          [inline]="true"
          (icClick)="goToAddItemPanel(item, true)"
        >
        </ic-icon-button>
        <ic-icon-button
          *ngIf="item.parentId === null"
          [ariaLabel]="item.deleteTooltip | translate"
          [cssClasses]="'actionIcons'"
          [matIcon]="item.deletedIcon"
          [inline]="true"
          (icClick)="deleteOrUndoItem(item)"
        >
        </ic-icon-button>
      </div>
    </div>
  </div>
</div>
