import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { IAuthorityEntitiesTreeComponent } from '@ic-app/models/trees/configuration/authorities/authority-entities-tree.model';
import { ITenantEntitiesTreeComponent } from '@ic-app/models/trees/configuration/authorities/tenant-entities-tree.model';
import { ICategoriesTreeComponent } from '@ic-app/models/trees/configuration/categories-tree.model';
import { IEntitiesTreeComponent } from '@ic-app/models/trees/configuration/entities-tree.model';
import { createRequestOption } from '@ic-core/util/request-util';
import {
  IFileTypeItemsTree,
  IFileTypesTree,
  IOrganizationalUnitsTree
} from '@ic-models/tree.model';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;
@Injectable()
export class TreeService {
  constructor(private http: HttpClient) {}

  getFileTypesRootNodes(): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de tipos de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypesAllNodes(entityId: number): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE',
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/all-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de tipos de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcFileTypesRootNodes(): Observable<IFileTypesTree[]> {
    const req = {
      type: 'PPC_ADMINISTRATIVE_FILE_TYPE'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de tipos de expediente' +
              'de control permanenete previo del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOmissionFileTypesRootNodes(): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE_PHASE_O'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de tipos de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypeItemsRootNodes(
    fileTypeId: number
  ): Observable<IFileTypeItemsTree[]> {
    const req = {
      type: 'FILE_TYPE_ITEMS',
      fileTypeId: fileTypeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypeItemsTree[]>(`${baseUrl}/tree/aftItems/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypeItemsTree[]>) =>
            resp.body as IFileTypeItemsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de items de fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsRootNodes(): Observable<IOrganizationalUnitsTree[]> {
    const req = {
      type: 'ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>(`${baseUrl}/tree/ou/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsResponsibleRootNodes(): Observable<
    IOrganizationalUnitsTree[]
  > {
    const req = {
      type: 'RESPONSIBLE_ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>(`${baseUrl}/tree/ou/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypesRootNodes(): Observable<number> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE'
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/root-nodes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del arbol de tipos de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypeItemsRootNodes(fileTypeId: number): Observable<number> {
    const req = {
      type: 'FILE_TYPE_ITEMS',
      fileTypeId: fileTypeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/items/root-nodes', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del arbol de items de fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countOrganizationalUnitsRootNodes(): Observable<number> {
    const req = {
      type: 'ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/root-nodes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del árbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countOrganizationalUnitsResponsibleRootNodes(): Observable<number> {
    const req = {
      type: 'RESPONSIBLE_ORGANIZATIONAL_UNIT'
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/root-nodes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los nodos raíz del árbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypesTreeData(rootId: number): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de tipos de expedientes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypesOmissionTreeData(rootId: number): Observable<IFileTypesTree[]> {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE_PHASE_O',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de tipos de expedientes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcFileTypesTreeData(rootId: number): Observable<IFileTypesTree[]> {
    const req = {
      type: 'PPC_ADMINISTRATIVE_FILE_TYPE',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypesTree[]>(`${baseUrl}/tree/aft/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypesTree[]>) =>
            resp.body as IFileTypesTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de tipos de expedientes del servidor:' +
              'de control permanenete previo del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getFileTypeItemsTreeData(rootId: number): Observable<IFileTypeItemsTree[]> {
    const req = {
      type: 'FILE_TYPE_ITEMS',
      rootId: rootId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IFileTypeItemsTree[]>(`${baseUrl}/tree/aftItems/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IFileTypeItemsTree[]>) =>
            resp.body as IFileTypeItemsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de items de fiscalización del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsTreeData(
    organizationalUnitTreeId: number
  ): Observable<IOrganizationalUnitsTree[]> {
    const req = {
      type: 'ORGANIZATIONAL_UNIT',
      organizationalUnitTreeId: organizationalUnitTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOrganizationalUnitsResponsibleTreeData(
    organizationalUnitTreeId: number
  ): Observable<IOrganizationalUnitsTree[]> {
    const req = {
      type: 'RESPONSIBLE_ORGANIZATIONAL_UNIT',
      organizationalUnitTreeId: organizationalUnitTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IOrganizationalUnitsTree[]>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IOrganizationalUnitsTree[]>) =>
            resp.body as IOrganizationalUnitsTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de departamentos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypesTreeData(treeId: number): Observable<number> {
    const req = {
      treeId: treeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/fileTypes/count', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los tipos de expedientes en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countFileTypeItemsTreeData(fileTypeTreeId: number): Observable<number> {
    const req = {
      fileTypeTreeId: fileTypeTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los items de fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countOrganizationalUnitsTreeData(
    organizationalUnitTreeId: number
  ): Observable<number> {
    const req = {
      organizationalUnitTreeId: organizationalUnitTreeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>('http://localhost:4200/items', {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los departamentos en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getEntitiesRootNodes(): Observable<IEntitiesTreeComponent[]> {
    const req = {
      type: 'ENTITIES'
    };
    const options = createRequestOption(req);
    return this.http
      .get<IEntitiesTreeComponent[]>(`${baseUrl}/tree/entities-root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IEntitiesTreeComponent[]>) =>
            resp.body as IEntitiesTreeComponent[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de entidades del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCategoriesRootNodes(
    entityId: number
  ): Observable<ICategoriesTreeComponent[]> {
    const req = {
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ICategoriesTreeComponent[]>(`${baseUrl}/tree/category/root-nodes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ICategoriesTreeComponent[]>) =>
            resp.body as ICategoriesTreeComponent[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de categorias del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCategoriesTreeData(
    rootId: number,
    entityId: number
  ): Observable<ICategoriesTreeComponent[]> {
    const req = {
      rootId: rootId,
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ICategoriesTreeComponent[]>(`${baseUrl}/tree/category/tree`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ICategoriesTreeComponent[]>) =>
            resp.body as ICategoriesTreeComponent[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el arbol de categorias del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getEntitiesNotSelectedRootNodes(
    entitiesIds: number[]
  ): Observable<IEntitiesTreeComponent[]> {
    const req = {
      entitiesIds: entitiesIds
    };
    const options = createRequestOption(req);
    return this.http
      .get<IEntitiesTreeComponent[]>(
        `${baseUrl}/tree/entities-not-selected-root-nodes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IEntitiesTreeComponent[]>) =>
            resp.body as IEntitiesTreeComponent[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de entidades no seleccionadas del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene el arbol de entidades de la tabla ic_tenant
   * @returns
   */
  getTenantEntitiesRootNodes(): Observable<ITenantEntitiesTreeComponent[]> {
    return this.http
      .get<ITenantEntitiesTreeComponent[]>(
        `${baseUrl}/tree/authorities/tenant-entities-root-nodes`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ITenantEntitiesTreeComponent[]>) =>
            resp.body as ITenantEntitiesTreeComponent[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de entidades principales del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene el arbol de entidades hijas del tenantId de la tabla ic_entity
   * @param tenantId
   * @returns
   */
  getEntitiesRootNodesByTenantId(
    tenantId: number
  ): Observable<IAuthorityEntitiesTreeComponent[]> {
    const req = {
      tenantId: tenantId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAuthorityEntitiesTreeComponent[]>(
        `${baseUrl}/tree/authorities/entities-root-nodes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAuthorityEntitiesTreeComponent[]>) =>
            resp.body as IAuthorityEntitiesTreeComponent[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los nodos raíz del arbol de entidades ' +
              'filtradas por tenant del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
