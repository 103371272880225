import { Component } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SpinnerService } from '@ic-services/spinner.service';

@Component({
  selector: 'ic-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent {
  color = 'primary';
  mode = 'indeterminate' as ProgressSpinnerMode;
  value = 50;

  constructor(public spinnerService: SpinnerService) {}
}
