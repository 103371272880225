/* eslint-disable max-len */
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import {
  IPpcItemCommentReportList,
  IPpcItemCommentModelList,
  PpcItemCommentToDeleteDTO,
  PpcItemCommentToSave
} from '@ic-models/prior-operational/ppc-report/ppc-item-comment/ppc-item-comment';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class PpcItemCommentsService {
  constructor(private http: HttpClient) {}

  getPpcItemCommentsReport(
    ppcReportProcedureId: number,
    ppcSupportedVerificationItemId: number
  ): Observable<IPpcItemCommentReportList[]> {
    const req = {
      ppcReportProcedureId: ppcReportProcedureId,
      ppcSupportedVerificationItemId: ppcSupportedVerificationItemId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcItemCommentReportList[]>(
        `${baseUrl}/ppcItemComments/listPpcItemCommentReport`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcItemCommentReportList[]>) =>
            resp.body as IPpcItemCommentReportList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los comentarios de los items de ppc del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcItemCommentsReportFromParent(
    currentPpcProcedureId: number,
    ppcSupportedVerificationItemId: number
  ): Observable<IPpcItemCommentReportList[]> {
    const req = {
      currentPpcProcedureId: currentPpcProcedureId,
      ppcSupportedVerificationItemId: ppcSupportedVerificationItemId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcItemCommentReportList[]>(
        `${baseUrl}/ppcItemComments/listPpcItemCommentReportFromParent`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcItemCommentReportList[]>) =>
            resp.body as IPpcItemCommentReportList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los comentarios de los items de ppc del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getAllPpcItemCommentsReport(
    ppcReportProcedureId: number,
    currentPpcProcedureId: number,
    isReformulated: boolean,
    savedPronouncements: boolean
  ): Observable<IPpcItemCommentReportList[]> {
    const req = {
      ppcReportProcedureId: ppcReportProcedureId,
      currentPpcProcedureId: currentPpcProcedureId,
      isReformulated: isReformulated,
      savedPronouncements: savedPronouncements
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcItemCommentReportList[]>(
        `${baseUrl}/ppcItemComments/listAllPpcItemCommentReport`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcItemCommentReportList[]>) =>
            resp.body as IPpcItemCommentReportList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los comentarios de los items de ppc del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcItemCommentsModelDefinition(
    ppcAdministrativeFileId: number,
    currentPpcProcedureId: number,
    ppcReportProcedureId: number,
    ppcSupportedVerificationItemId: number,
    isReformulated: boolean,
    savedPronouncements: boolean
  ): Observable<IPpcItemCommentModelList[]> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      currentPpcProcedureId: currentPpcProcedureId,
      ppcReportProcedureId: ppcReportProcedureId,
      ppcSupportedVerificationItemId: ppcSupportedVerificationItemId,
      isReformulated: isReformulated,
      savedPronouncements: savedPronouncements
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcItemCommentModelList[]>(
        `${baseUrl}/ppcItemComments/listPpcModelDefinition`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcItemCommentModelList[]>) =>
            resp.body as IPpcItemCommentModelList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los comentarios de los items de ppc del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countPpcItemCommentsModelDefinition(
    filter: string,
    ppcAdministrativeFileId: number,
    ppcReportProcedureId: number,
    ppcSupportedVerificationItemId: number
  ): Observable<number> {
    const req = {
      searchBox: filter,
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      ppcReportProcedureId: ppcReportProcedureId,
      ppcSupportedVerificationItemId: ppcSupportedVerificationItemId
    };

    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/ppcItemComments/countPpcModelDefinition`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al contar los comentarios de los items del informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deletePpcItemComments(
    ppcItemCommentDelete: PpcItemCommentToDeleteDTO
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/deletePpcItemComments`, ppcItemCommentDelete)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al borrar los comentarios de los items de ppc del informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcItemComments(
    ppcItemCommentSave: PpcItemCommentToSave
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/savePpcItemComment`, ppcItemCommentSave)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar los comentarios de los items de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  checkIfNodeHasComments(
    nodeId: number,
    ppcReportProcedureId: number
  ): Observable<boolean> {
    const req = {
      ppcSupportedVerificationItemId: nodeId,
      ppcReportProcedureId: ppcReportProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/checkIfNodeHasComments`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al recuperar los comentarios del item de ppc del informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
