import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from '@ic-services/nav.service';
import { SharedService } from '@ic-services/shared.service';
import { TranslateService } from '@ngx-translate/core';

interface INavLink {
  label: string;
  link: string;
  index: number;
}

@Component({
  selector: 'ic-operational',
  templateUrl: './operational.component.html'
})
export class OperationalComponent implements OnInit {
  navLinks: INavLink[] = [];
  activeLinkIndex = -1;
  disableMatFlags = false;

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {
    this.navLinks = [
      {
        label: this.translate.instant('solfrb.title') as string,
        link: 'solfrb',
        index: 0
      },
      {
        label: this.translate.instant('administrative-file.title') as string,
        link: 'administrative-file',
        index: 1
      }
    ];
  }

  ngOnInit(): void {
    this.sharedService.flagDisableTabs$.subscribe(
      (value: boolean) => (this.disableMatFlags = value)
    );
    const mainTitle = this.translate.instant(
      'sidebar.menu.label.prior-inspection.title'
    ) as string;
    const title = (mainTitle + ' - ').concat(
      this.translate.instant(
        this.route.snapshot.data['title'] as string
      ) as string
    );
    this.navService.updateTitle(title);
    this.router.events.subscribe(() => {
      this.activeLinkIndex = this.navLinks.findIndex(
        (tab: INavLink) => tab.link === '.' + this.router.url
      );
    });
  }
}
