import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IStyleValues {
  maxWidth?: string;
  color?: string;
  fontSize?: string;
}

@Component({
  selector: 'ic-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  titleStyleString: string | null = null;
  contentStyleString: string | null = null;
  content2StyleString: string | null = null;
  content3StyleString: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      titleStyle?: IStyleValues;
      content: string;
      contentStyle?: IStyleValues;
      content2?: string;
      content2Style?: IStyleValues;
      content3?: string;
      content3Style?: IStyleValues;
      buttonCancel: string;
      buttonAccept: string;
      buttonAcceptAndAction: string;
    }
  ) {
    if (data.titleStyle) {
      this.titleStyleString = '';
      this.titleStyleString += data.titleStyle.color
        ? `color: ${data.titleStyle.color};`
        : '';
      this.titleStyleString += data.titleStyle.maxWidth
        ? `max-width: ${data.titleStyle.maxWidth};`
        : '';
      this.titleStyleString += data.titleStyle.fontSize
        ? `font-size: ${data.titleStyle.fontSize};`
        : '';
    }
    if (data.contentStyle) {
      this.contentStyleString = '';
      this.contentStyleString += data.contentStyle.color
        ? `color: ${data.contentStyle.color};`
        : '';
      this.contentStyleString += data.contentStyle.maxWidth
        ? `max-width: ${data.contentStyle.maxWidth};`
        : '';
      this.contentStyleString += data.contentStyle.fontSize
        ? `font-size: ${data.contentStyle.fontSize};`
        : '';
    }
    if (data.content2Style) {
      this.content2StyleString = '';
      this.content2StyleString += data.content2Style.color
        ? `color: ${data.content2Style.color};`
        : '';
      this.content2StyleString += data.content2Style.maxWidth
        ? `max-width: ${data.content2Style.maxWidth};`
        : '';
      this.content2StyleString += data.content2Style.fontSize
        ? `font-size: ${data.content2Style.fontSize};`
        : '';
    }
    if (data.content3Style) {
      this.content3StyleString = '';
      this.content3StyleString += data.content3Style.color
        ? `color: ${data.content3Style.color};`
        : '';
      this.content3StyleString += data.content3Style.maxWidth
        ? `max-width: ${data.content3Style.maxWidth};`
        : '';
      this.content3StyleString += data.content3Style.fontSize
        ? `font-size: ${data.content3Style.fontSize};`
        : '';
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cancel($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: false });
  }

  accept($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: true, action: false });
  }

  acceptAndAction($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: true, action: true });
  }
}
