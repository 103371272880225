import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IActionButton } from '@ic-models/action-button.model';

@Component({
  selector: 'ic-menu-options',
  templateUrl: './menu-options.component.html'
})
export class MenuOptionsComponent {
  @Input() optionsButtons: IActionButton[] = [];
  @Input() color = '';
  @Input() iconName = '';
  @Input() iconAriaLabel = '';
  @Input() cssClasses = '';
  @Output() actionClicked: EventEmitter<number> = new EventEmitter<number>();

  buttonClicked = false;

  executeAction(event: MouseEvent, action: number): void {
    if (!this.buttonClicked) {
      this.buttonClicked = true;
      this.actionClicked.emit(action);
      setTimeout(() => {
        this.buttonClicked = false;
      }, 1000);
    } else {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return;
    }
  }
}
