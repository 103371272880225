import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Error401Component } from './error401/error401.component';
import { Error403Component } from './error403/error403.component';
import { Error500Component } from './error500/error500.component';
import { Error503Component } from './error503/error503.component';

@NgModule({
  declarations: [
    Error401Component,
    Error403Component,
    Error500Component,
    Error503Component
  ],
  exports: [
    Error401Component,
    Error403Component,
    Error500Component,
    Error503Component
  ],
  imports: [CommonModule]
})
export class ErrorModule {}
