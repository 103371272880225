import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ic-input-currency',
  templateUrl: './input-currency.component.html'
})
export class InputCurrencyComponent implements OnInit, AfterViewInit {
  @ViewChild('inputCurrency', { static: false, read: ElementRef })
  myInputElement!: ElementRef;
  @Input() inputCurrencyLabel?: string;
  @Input() inputClass?: string;
  @Input() placeHolderText?: string;
  @Input() currencyFormControl: FormControl = new FormControl();
  @Input() autoCompleteConfig?: string;
  @Input() inputHint?: string;
  @Input() disabledInput?: boolean;
  @Output() changeInput: EventEmitter<number> = new EventEmitter<number>();

  maxDigits = 9;

  private invalidRegex?: RegExp;

  /**
   * The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code,
   * such as `USD` for the US dollar and `EUR` for the euro. The default currency code can be
   * configured using the `DEFAULT_CURRENCY_CODE` injection token.
   */
  @Input() currencyCode = 'EUR';

  /**
   * Decimal representation options, specified by a string in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   * Default is `1`.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   * Default is `2`.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * Default is `2`.
   * If not provided, the number will be formatted with the proper amount of digits,
   * depending on what the [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) specifies.
   * For example, the Canadian dollar has 2 digits, whereas the Chilean peso has none.
   */
  @Input() currencyDigitsInfo = '1.2';

  /**
   *  A locale code for the locale format rules to use.
   * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by default.
   * See [Setting your app locale](guide/i18n#setting-up-the-locale-of-your-app).
   */
  @Input() currencyLocale = 'es-ES';

  /**
   * The format for the currency indicator. One of the following:
   *   - `code`: Show the code (such as `USD`).
   *   - `symbol`(default): Show the symbol (such as `$`).
   *   - `symbol-narrow`: Use the narrow symbol for locales that have two symbols for their
   * currency.
   */
  @Input() currencySymbol = 'symbol';

  /**
   * Separador de fracciones para la moneda seleccionada. Por defecto establecemos la ',' que es el
   * separador de fracciones decimales en el locale de España. Si cambiamos el locale hay que actualizar
   * el separador de fracciones por el carácter correspondiente para que funcione correctamente el componente
   * y la directiva asociada.
   */
  @Input() fractionSeparator = ',';

  constructor() {
    this.setInvalidCharactersExpression();
  }

  ngOnInit(): void {
    if (this.disabledInput) {
      this.currencyFormControl?.disable({
        onlySelf: true,
        emitEvent: false
      });
    }
  }

  ngAfterViewInit(): void {
    this.changeInput.emit(this.currencyFormControl?.value as number);
  }

  onChange(): void {
    this.updateCurrencyFormControlToElementValue();
    // console.log('EMIT VALUE', this.currencyFormControl?.value);
    this.changeInput.emit(this.currencyFormControl?.value as number);
  }

  onPaste(): void {
    this.updateCurrencyFormControlToElementValue();
    this.changeInput.emit(this.currencyFormControl?.value as number);
  }

  private updateCurrencyFormControlToElementValue(): void {
    if (this.currencyFormControl) {
      this.currencyFormControl.setValue(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.myInputElement.nativeElement.value.replace(
          this.invalidRegex as RegExp,
          ''
        )
      );
    }
  }

  private setInvalidCharactersExpression(): void {
    this.invalidRegex = new RegExp(
      '[^0-9' + this.fractionSeparator + ']+',
      'g'
    );
  }
}
