/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '@ic-app/components/components.index';
import { PpcConstants } from '@ic-app/constants/ppc.constants';
import { IFiscalYear } from '@ic-models/fiscal-year.model';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable } from 'rxjs';

export function getPropertyName(obj: any, expression: any): any {
  const res: { [key: string]: any } = {};
  Object.keys(obj).map((k: any) => {
    res[k] = () => k;
  });
  return expression(res)();
}

export const deepCopyFunction = (inObject: any): any => {
  let value, key;

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject: { [key: string]: any } = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
};

export const isEmptyNullOrUndefinedObject = (value: any): boolean => {
  if (value === undefined || value === null) {
    return true;
  }
  return Object.keys(value).length === 0 && value.constructor === Object;
};

export const isEmptyNullOrUndefinedArray = (value: any[]): boolean => {
  if (value === undefined || value === null) {
    return true;
  }
  return value.length === 0 && value.constructor === Array;
};

export const isEmptyNullOrUndefinedString = (
  value: string | null | undefined
): boolean => {
  if (value === undefined || value === null) {
    return true;
  }
  return value.length === 0;
};

export const isNullOrUndefinedNumber = (
  value: number | null | undefined
): boolean => {
  return value === undefined || value === null;
};

export const isZeroNullOrUndefinedNumber = (
  value: number | null | undefined
): boolean => {
  return value === undefined || value === null || value === 0;
};

export const isNullOrUndefined = (value: any): boolean => {
  return value === undefined || value === null;
};

/**
 * Filtra en un array de objetos por los valores de las keys pasadas por el filtro
 * Ejemplo:
 *   filterArrayOfObjects(this.contacts, {
 *         selected: true,
 *         isInContactsAlready: true,
 *       })
 * Devolvería un array con los contactos que tuvieran selected y isInContactsAlready a true
 * @param array
 * @param filter
 */
export const filterArrayOfObjects = (
  array: any[] | undefined,
  filter: { [key: string]: any } | undefined
): any[] => {
  const filterKeys = filter ? Object.keys(filter) : undefined;
  return array
    ? array.filter((item: any) => {
        return filterKeys?.every((key: any) => {
          return filter ? filter[key] === item[key] : false;
        });
      })
    : [];
};

/**
 * Filtro para devolver los elementos de un array de objetos que cumplan con las
 * funciones del filtro
 * Ejemplo:
 *  filterArrayOfObjectsWithFunction(this.contacts, {
 *         email: email => email === 'miemail@localhost.com' || email === 'miemail2@localhost.com',
 *         selected: select => selected === true,
 *  })
 * Devolvería un array con los contactos que tuvieran uno de esos emails y selected true
 * @param array
 * @param filter
 */
export const filterArrayOfObjectsWithFunction = (
  array: any[],
  filter: { [key: string]: any }
): any[] => {
  const filterKeys = Object.keys(filter);
  return array.filter((item: any) => {
    return filterKeys.every((key: any) => {
      // ignoramos las partes del filtro que no sean una función
      if (typeof filter[key] !== 'function') {
        return true;
      }
      return filter[key](item[key]);
    });
  });
};

/**
 * Filtra un array de objetos por un término de búsqueda
 * @param array
 * @param term
 * @returns array de objetos que coinciden con el términmo de búsqueda
 */
export const filterArrayOfObjectsByTerm = <T>(
  array: T[],
  term: string
): T[] => {
  return array.filter((object: T) => {
    for (const key in object) {
      const val = object[key];
      if (
        typeof val === 'string' &&
        val.toLowerCase().includes(term.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });
};

/**
 * Obtiene un identificador único basado en la fecha y hora actual en segundos
 * @returns string con el identificador único
 */
export const getTimestampBasedId = (): string => {
  const timestamp = Math.floor(Date.now() / 1000);
  const randomNumber = Math.floor(Math.random() * 100);

  return `${timestamp}-${randomNumber}`;
};

export const getActualFiscalYearFromTime = (
  data: IFiscalYear[] | undefined
): IFiscalYear[] => {
  // TODO: Sustituimos Moment
  // const actualMoment = moment();
  // const year = actualMoment.get('year');
  const now = new Date();
  const year = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(now);
  return filterArrayOfObjects(data, {
    description: Number(year)
  }) as IFiscalYear[];
};

export const getUserTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateFromTimeStampInLocaleFormat = (
  timeStamp: number,
  localeId: string
): string => {
  const date = new Date(timeStamp);
  // Utilizamos el locale definido en la aplicación y un fallback locale que sea el español
  const formattedDate = new Intl.DateTimeFormat([localeId, 'es']).format(date);

  // Extraer day, month, y year por separado para reformatear la fecha con guiones
  const [day, month, year] = formattedDate.split('/');
  const twoDigitMonth = month.padStart(2, '0');
  const twoDigitDay = day.padStart(2, '0');
  return `${twoDigitDay}/${twoDigitMonth}/${year}`; // "24-03-22"
};

export const getDateFromTimeStampInLocaleFormatWithSeparator = (
  timeStamp: number,
  separator: string,
  localeId: string
): string => {
  const formattedDate = getDateFromTimeStampInLocaleFormat(timeStamp, localeId);
  // Extraer day, month, y year por separado para reformatear la fecha con guiones
  const [day, month, year] = formattedDate.split('/');
  const twoDigitMonth = month.padStart(2, '0');
  const twoDigitDay = day.padStart(2, '0');
  return `${twoDigitDay}${separator}${twoDigitMonth}${separator}${year}`;
};

interface IReplacementMap {
  yyyy: string;
  yy: string;
  MMMM: string;
  MMM: string;
  MM: string;
  M: string;
  dd: string;
  d: string;
  HH: string;
  H: string;
  hh: string;
  h: string;
  mm: string;
  m: string;
  ss: string;
  s: string;
  SSS: string;
  Z: string;
  ZZZ: string;
}

export const formatDate = (
  date: Date,
  format: string,
  locale: string
): string => {
  const timeZoneOffset = date.getTimezoneOffset() * -1;
  const timeZoneOffsetHours = Math.floor(timeZoneOffset / 60);
  const timeZoneOffsetMinutes = timeZoneOffset % 60;
  const timeZoneOffsetString =
    (timeZoneOffset > 0 ? '-' : '+') +
    timeZoneOffsetHours.toString().padStart(2, '0') +
    timeZoneOffsetMinutes.toString().padStart(2, '0');
  const replacements: IReplacementMap = {
    yyyy: date.getFullYear().toString(),
    yy: date.getFullYear().toString().slice(-2),
    MMMM: date.toLocaleDateString(locale, { month: 'long' }),
    MMM: date.toLocaleDateString(locale, { month: 'short' }),
    MM: (date.getMonth() + 1).toString().padStart(2, '0'),
    M: (date.getMonth() + 1).toString(),
    dd: date.getDate().toString().padStart(2, '0'),
    d: date.getDate().toString(),
    HH: date.getHours().toString().padStart(2, '0'),
    H: date.getHours().toString(),
    hh: (date.getHours() % 12).toString().padStart(2, '0'),
    h: (date.getHours() % 12).toString(),
    mm: date.getMinutes().toString().padStart(2, '0'),
    m: date.getMinutes().toString(),
    ss: date.getSeconds().toString().padStart(2, '0'),
    s: date.getSeconds().toString(),
    SSS: date.getMilliseconds().toString().padStart(3, '0'),
    Z: timeZoneOffsetString,
    ZZZ: 'GMT' + timeZoneOffsetString
  };
  let formattedDate = format;
  for (const key in replacements) {
    if (Object.prototype.hasOwnProperty.call(replacements, key)) {
      const regExp = new RegExp(key, 'g');
      formattedDate = formattedDate.replace(
        regExp,
        replacements[key as keyof IReplacementMap]
      );
    }
  }
  return formattedDate;
};

export interface IDialogOptions {
  title?: string;
  content?: string;
  content2?: string;
  content3?: string;
  buttonAccept?: string;
  buttonCancel?: string;
  buttonAcceptAndAction?: string;
}

/**
 * Funcion flexible para abrir un dialogo con las opciones pasadas
 * @param translate
 * @param dialog
 * @param options
 * @returns
 */
export const openDialog = (
  translate: TranslateService,
  dialog: MatDialog,
  options: IDialogOptions
): MatDialogRef<DialogComponent> => {
  const data = {
    title: options.title ? translate.instant(options.title) : null,
    content: options.content ? translate.instant(options.content) : null,
    content2: options.content2 ? translate.instant(options.content2) : null,
    content3: options.content3 ? translate.instant(options.content3) : null,
    buttonAccept: options.buttonAccept
      ? translate.instant(options.buttonAccept)
      : null,
    buttonCancel: options.buttonCancel
      ? translate.instant(options.buttonCancel)
      : null,
    buttonAcceptAndAction: options.buttonAcceptAndAction
      ? translate.instant(options.buttonAcceptAndAction)
      : null
  };

  return dialog.open(DialogComponent, { data });
};

export const openDialogUnSavedChanges = (
  translate: TranslateService,
  dialog: MatDialog
): MatDialogRef<DialogComponent> => {
  const title = 'common.message.unsaved-changes-title';
  const content = 'common.message.unsaved-changes-content';
  return dialog.open(DialogComponent, {
    data: {
      title: translate.instant(title) as string,
      content: translate.instant(content) as string,
      buttonAccept: translate.instant('common.button.save') as string,
      buttonAcceptAndAction: translate.instant(
        'common.button.discard'
      ) as string,
      buttonCancel: translate.instant('common.button.cancel') as string
    }
  });
};

export const openDialogUnsavedChanges = (
  translate: TranslateService,
  dialog: MatDialog,
  title: string,
  content: string
): MatDialogRef<DialogComponent> => {
  return dialog.open(DialogComponent, {
    data: {
      title: translate.instant(title) as string,
      content: translate.instant(content) as string,
      buttonAccept: translate.instant('common.button.save') as string,
      buttonAcceptAndAction: translate.instant(
        'common.button.discard'
      ) as string,
      buttonCancel: translate.instant('common.button.cancel') as string
    }
  });
};

//TODO borrar despues del merge
export const openDialogWithTitleContentAndButtons = (
  translate: TranslateService,
  dialog: MatDialog,
  title: string,
  content: string,
  buttonAccept: string,
  buttonCancel: string
): MatDialogRef<DialogComponent> => {
  return dialog.open(DialogComponent, {
    data: {
      title: translate.instant(title) as string,
      content: translate.instant(content) as string,
      buttonAccept: translate.instant(buttonAccept) as string,
      buttonCancel: translate.instant(buttonCancel) as string
    }
  });
};
//TODO borrar despues del merge
export const openDialogWithTitleContentAndOnlyAcceptButton = (
  translate: TranslateService,
  dialog: MatDialog,
  title: string,
  content: string,
  buttonAccept: string
): MatDialogRef<DialogComponent> => {
  return dialog.open(DialogComponent, {
    data: {
      title: translate.instant(title) as string,
      content: translate.instant(content) as string,
      buttonAccept: translate.instant(buttonAccept) as string
    }
  });
};

export const moveElementToFirstPosition = (arr: any[], elemento: any): void => {
  const index = arr.indexOf(elemento);

  if (index !== -1) {
    // Si el elemento existe en el array, lo movemos al principio.
    arr.unshift(arr.splice(index, 1)[0]);
  }
};

export const showSubscribeGetterError = (
  message: string,
  translate: TranslateService,
  snackBar: MatSnackBar
): Observable<never> => {
  showMessage(
    translate.instant(message) as string,
    translate.instant('common.button.accept') as string,
    snackBar
  );
  return EMPTY;
};

export const showMessage = (
  text: string,
  button: string,
  snackBar: MatSnackBar
): void => {
  snackBar.open(text, button, {
    duration: 5000
  });
};

export const getResultCodeFromConclusionId = (
  conclusionId: number,
  hasPpcReportSubjectiveImprovements: boolean
): number => {
  switch (conclusionId) {
    case 1:
      return hasPpcReportSubjectiveImprovements
        ? PpcConstants.PPC_REPORT_RESULT_FAVORABLE_WITH_RECOMMENDATIONS_CODE
        : PpcConstants.PPC_REPORT_RESULT_FAVORABLE_WITHOUT_RECOMMENDATIONS_CODE;
    case 2:
      return hasPpcReportSubjectiveImprovements
        ? PpcConstants.PPC_REPORT_RESULT_FAVORABLE_WITH_RECOMMENDATIONS_BUT_INFRACTIONS_CODE
        : PpcConstants.PPC_REPORT_RESULT_FAVORABLE_WITHOUT_RECOMMENDATIONS_BUT_INFRACTIONS_CODE;
    default:
      return hasPpcReportSubjectiveImprovements
        ? PpcConstants.PPC_REPORT_RESULT_UNFAVORABLE_WITH_RECOMMENDATIONS_CODE
        : PpcConstants.PPC_REPORT_RESULT_UNFAVORABLE_WITHOUT_RECOMMENDATIONS_CODE;
  }
};

export const getDateFormatted = (date: string): string => {
  // Dividimos la fecha y la hora (si existiera)
  const [datePart] = date.split(' ');

  // Dividimos la fecha en año, mes y día
  const [year, month, day] = datePart.split('-').map(Number);

  // Retornamos el formato deseado
  return `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;
};
