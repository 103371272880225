import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'ic-input',
  templateUrl: './input.component.html'
})
export class InputComponent implements OnInit {
  @Input() inputType?: string;
  @Input() placeHolderText?: string;
  @Input() inputFormControl: FormControl = new FormControl();
  @Input() inputHint?: string;
  @Input() autoCompleteConfig?: string;
  @Input() min?: number;
  @Input() max?: number;
  @Input() inputLabel?: string;
  @Input() inputClass?: string;
  @Input() disabledInput?: boolean | null = false;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() required?: boolean;
  @Input() nullValidator?: boolean;

  appearance: MatFormFieldAppearance = 'fill';

  ngOnInit(): void {
    if (this.disabledInput) {
      this.inputFormControl.disable({
        onlySelf: true,
        emitEvent: false
      });
    }
    const validators: ValidatorFn | ValidatorFn[] | null = [];
    if (this.maxLength) {
      validators.push(Validators.maxLength(this.maxLength as number));
    }
    if (this.minLength) {
      validators.push(Validators.minLength(this.minLength as number));
    }
    if (
      this.required === true &&
      this.inputFormControl?.hasValidator(Validators.required) === false
    ) {
      validators.push(Validators.required);
    }
    if (
      this.nullValidator === true &&
      this.inputFormControl?.hasValidator(Validators.nullValidator) === false
    ) {
      validators.push(Validators.nullValidator);
    }
    if (validators && validators.length >= 1) {
      this.inputFormControl?.addValidators(validators);
    }
  }

  onInput(event: Event): void {
    if (
      this.inputFormControl &&
      this.inputFormControl.errors &&
      this.inputFormControl.errors?.maxlength &&
      (this.inputFormControl.value as string).length >=
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.inputFormControl.errors?.maxlength.actualLength
    ) {
      event.preventDefault();
      this.inputFormControl.markAllAsTouched();
    }
  }
}
