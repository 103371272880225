import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import { ITenantDetailData } from '@ic-app/models/settings/tenants/detail/tenant-detail.model';
import { Tenant } from '@ic-app/models/settings/tenants/panel/tenant.model';
import { ITenantsFilter } from '@ic-app/models/settings/tenants/tenants-list-filter.model';
import { ITenantsList } from '@ic-app/models/settings/tenants/tenants-list.model';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

import { AppConfigService } from '../app-config.service';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsTenantsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Lista todos los tenants y/o los filtra
   * @param filter
   * @param pageNumber
   * @param pageSize
   * @param filteredEntityId
   * @returns
   */
  findTenants(
    filter: ITenantsFilter,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<ITenantsList[]> {
    const req = {
      searchBox: filter.searchBox,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ITenantsList[]>(`${baseUrl}/settings/findTenants`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ITenantsList[]>) => resp.body as ITenantsList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tenants de configuración:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Cuenta los tenants totales teniendo en cuenta los filtros
   * @param filter
   * @param mainTable
   * @param filteredEntityId
   * @returns
   */
  countTenants(
    filter: ITenantsFilter,
    mainTable: boolean,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      mainTable: mainTable,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/settings/countTenants`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los tenants de configuracion:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Guarda un tenant y las entidades correpsondientes a este
   * @param tenantData
   * @returns
   */
  saveTenant(tenantData: Tenant): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/settings/saveTenant`, tenantData)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al guardar el tenant en el servidor:',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al guardar el tenant en el servidor:',
              err.message
            );
            return new Error('settings.error-messages.save-tenant');
          });
        })
      );
  }

  /**
   * Devuelve los datos pertenecientes al tenant que se pasa
   * @param tenantId
   * @returns
   */
  findTenantDetailById(tenantId: number): Observable<ITenantDetailData> {
    const req = {
      tenantId: tenantId
    };

    const options = createRequestOption(req);

    return this.http
      .get<ITenantDetailData>(`${baseUrl}/settings/findTenantDetailById`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ITenantDetailData>) =>
            resp.body as ITenantDetailData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el tenant de configuración del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Modifica un tenant y las entidades correpsondientes a este
   * @param tenantEditData
   * @returns
   */
  editTenant(tenantEditData: Tenant): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/settings/editTenant`, tenantEditData)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al modificar el tenant en el servidor:',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al modificar el tenant en el servidor:',
              err.message
            );
            return new Error('settings.error-messages.edit-tenant');
          });
        })
      );
  }
}
