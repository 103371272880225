import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ic-between-inputs',
  templateUrl: './between-inputs.component.html'
})
export class BetweenInputsComponent {
  @Input() placeHolderText1 = '';
  @Input() placeHolderText2 = '';
  @Input() inputFormControl1: FormControl = new FormControl();
  @Input() inputFormControl2: FormControl = new FormControl();
  @Input() inputHint = '';
  @Input() inputClass = '';
}
