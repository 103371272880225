import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ic-dinamic-search',
  templateUrl: './dinamic-search.component.html',
  styleUrls: ['./dinamic-search.component.scss'],
  animations: [
    trigger('searchExpand', [
      state('expanded', style({ width: '100%' })),
      state('collapsed', style({ width: '0' })),
      transition('expanded <=> collapsed', animate('300ms ease-in-out'))
    ])
  ]
})
export class DinamicSearchComponent {
  isSearchOpen = false;
  searchQuery = '';

  @Output() search = new EventEmitter<string>();

  toggleSearch(): void {
    this.isSearchOpen = !this.isSearchOpen; // Toggle search input visibility
    if (!this.isSearchOpen) {
      this.searchQuery = '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch(event: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.searchQuery = event.target.value as string;
    this.search.emit(this.searchQuery);
  }
}
