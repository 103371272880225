import { Injectable } from '@angular/core';

@Injectable()
export class StorageOptions {
  static readonly ZIP_FORMAT = 'ZIP';
  static readonly RAR_FORMAT = 'RAR';
  static readonly SEVEN_ZIP_FORMAT = '7ZIP';

  static readonly FILE_MULTIPART_LIMIT_SIZE = 10_000_000;
  static readonly FILE_MULTIPART_PART_SIZE = 10_000_000;
}

export enum ModuleType {
  INSPECTION_BASIC_REQUIREMENTS = 'FRB',
  PREVIOUS_PERMANENT_CONTROL = 'PPC'
}
