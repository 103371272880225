/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatIconRegistry } from '@angular/material/icon';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Sort, MatSort, SortDirection } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { IColumn } from '@ic-models/column.model';
import { Subject, takeUntil } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface RowAction {
  row: any;
  action: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface RowPosition {
  row: any;
  position: EventTarget | null;
}

export interface IActionRowPosition {
  row: any;
  action: number;
  position: EventTarget | null;
}

@Component({
  selector: 'ic-detail-table',
  templateUrl: './detail-table.component.html'
})
export class DetailTableComponent<T>
  implements OnInit, AfterViewInit, OnDestroy
{
  private unsubscribe$ = new Subject<void>();

  @Input() customColumns: IColumn[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() optionButtons = false;
  @Input() observationsSelected = false;
  @Input() addActionsTable?: boolean;
  @Input() expandedDetail = false;

  @Input() dataSource: any;
  @Input() checks = false;
  @Input() pageIndex?: number;
  @Input() pageSize?: number;
  @Input() length?: number;
  @Input() pageSizeOptions: number[] = [];
  @Input() defaultSortDirection = 'asc' as SortDirection;
  @Input() defaultSortColumn = '';
  @Input() rowClass?: string;
  @Input() paginatorActivated = false;
  @Input() emptyMessage = '';

  @Input() selection = new SelectionModel<any>(true, []);

  // @Output() callDataSource: EventEmitter<ITableFilter> = new EventEmitter();
  @Output() paginationChange: EventEmitter<PageEvent> =
    new EventEmitter<PageEvent>();
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() actionClicked: EventEmitter<RowAction> =
    new EventEmitter<RowAction>();
  @Output() deletedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() changedOrderUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() changedOrderDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() editedRow: EventEmitter<RowPosition> =
    new EventEmitter<RowPosition>();
  @Output() executedActionIcons: EventEmitter<IActionRowPosition> =
    new EventEmitter<IActionRowPosition>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  controls: any;
  detailTableForm?: FormGroup;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      't_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/t_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'p_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/p_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'g_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/g_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'u_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/u_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'a_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/a_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'e_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/e_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'l_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/l_letter.svg'
      )
    );
  }

  ngOnInit(): void {
    this.getData(this.dataSource).forEach((element: any) => {
      element.expandedElement = false;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    if (this.paginatorActivated) {
      this.dataSource.paginator = this.paginator;
      this.paginator?.page
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((x: PageEvent) => {
          this.paginationChange.emit(x);
        });
    }
    this.sort?.sortChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((s: Sort) => {
        this.sortChange.emit(s);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.getData(this.dataSource).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.getData(this.dataSource).forEach((row: T) =>
        this.selection.select(row)
      );
    }
  }

  // Diferencia el acceso a datos desde tablas estáticas o dinámicas
  getData(dataSource: any): T[] {
    if (dataSource.data) {
      return dataSource.data as T[];
    } else {
      return dataSource.getData() as T[];
    }
  }

  onRowCheckboxChange(
    $event: MatCheckboxChange,
    row: T
  ): boolean | void | null {
    return $event ? this.selection.toggle(row) : null;
  }

  onHeaderCheckboxChange($event: MatCheckboxChange): void | null {
    return $event ? this.masterToggle() : null;
  }

  refreshItemsSelected(): void {
    this.selection.clear();
  }

  clickedOption(action: number, row: T): void {
    const rowAction: RowAction = {
      action: action,
      row: row
    };

    this.actionClicked.emit(rowAction);
  }

  deleteRow(row: T): void {
    this.deletedRow.emit(row);
  }

  changeOrderUp(row: T): void {
    this.changedOrderUp.emit(row);
  }

  changeOrderDown(row: T): void {
    this.changedOrderDown.emit(row);
  }

  expandDetail(row: any): void {
    if (row.note) {
      row.expandedElement = !row.expandedElement;
    }
  }

  editRow(row: T, evt: Event): void {
    const rowPosition: RowPosition = {
      row: row,
      position: evt.currentTarget
    };
    this.editedRow.emit(rowPosition);
  }

  executeActionIcons(row: T, action: number, evt: Event): void {
    const actionRowPosition: IActionRowPosition = {
      row: row,
      action: action,
      position: evt.currentTarget
    };
    this.executedActionIcons.emit(actionRowPosition);
  }
}
