import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  IAutoCompleteElement
} from '@ic-components/form/input-autocomplete/input-autocomplete.component';
import { AppConfigService } from '@ic-services/app-config.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class AutoCompleteFiscalYearService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteFiscalYearFilterData
        .filter((autocompleteFiscalYearData: IAutoCompleteElement) => {
          return autocompleteFiscalYearData.name
            .toLowerCase()
            .includes(filter.searchValue.toLowerCase());
        })
        .map((autocompleteFiscalYearData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteFiscalYearData.id,
            autocompleteFiscalYearData.name
          );
        })
    );
  }

  getDataById(id: number): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteFiscalYearFilterData
        .filter(
          (autocompleteFiscalYearData: IAutoCompleteElement) =>
            autocompleteFiscalYearData.id === id
        )
        .map((autocompleteFiscalYearData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteFiscalYearData.id,
            autocompleteFiscalYearData.name
          );
        })
    );
  }
}
