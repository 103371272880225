import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { ModuleType } from '@ic-app/constants/storage.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import { DocumentData } from '@ic-models/storage/document-data';
import { MultipartUploadId } from '@ic-models/storage/multipart-upload-id';
import { MultiPartUrlI } from '@ic-models/storage/multipart-url';
import { PartI } from '@ic-models/storage/part';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class FileStorageService {
  constructor(private http: HttpClient) {}

  getUploadUrl(
    fileName: string,
    administrativeFileId: number,
    moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS
  ): Observable<DocumentData> {
    const req = {
      fileName: fileName,
      administrativeFileId: administrativeFileId
    };
    const options = createRequestOption(req);
    const url =
      moduleType === ModuleType.INSPECTION_BASIC_REQUIREMENTS
        ? `${baseUrl}/storage/storageurl`
        : `${baseUrl}/ppc/storage/storageurl`;
    return this.http
      .get<DocumentData>(url, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<DocumentData>) => resp.body as DocumentData)
      );
  }

  setFrbCorrectionDocumentActiveFalse(
    oldTokenFileName: string,
    procedureId: number
  ): Observable<string> {
    const req = {
      oldTokenFileName: oldTokenFileName,
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<string>(`${baseUrl}/storage/setFrbCorrectionDocumentActiveFalse`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(map((resp: HttpResponse<string>) => resp.body as string));
  }

  setAdministrativeFileCorrectionDocumentActiveFalse(
    oldTokenFileName: string,
    fileId: number
  ): Observable<string> {
    const req = {
      oldTokenFileName: oldTokenFileName,
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<string>(
        `${baseUrl}/storage/setAdministrativeFileCorrectionDocumentActiveFalse`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(map((resp: HttpResponse<string>) => resp.body as string));
  }

  setAdministrativeFileReturnDocumentActiveFalse(
    oldTokenFileName: string,
    fileId: number
  ): Observable<string> {
    const req = {
      oldTokenFileName: oldTokenFileName,
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<string>(
        `${baseUrl}/storage/setAdministrativeFileReturnDocumentActiveFalse`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(map((resp: HttpResponse<string>) => resp.body as string));
  }

  getDownloadUrl(
    fileTokenName: string,
    originalName: string,
    moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS
  ): Observable<string> {
    const req = {
      fileTokenName: fileTokenName,
      originalName: originalName
    };
    const options = createRequestOption(req);
    const url =
      moduleType === ModuleType.INSPECTION_BASIC_REQUIREMENTS
        ? `${baseUrl}/storage/downloadurl`
        : `${baseUrl}/ppc/storage/downloadurl`;

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'text/plain, */*',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json' // We send JSON
      }),
      params: options,
      responseType: 'text' as 'json' // We accept plain text as response.
    };

    return this.http
      .get<string>(url, httpOptions)
      .pipe(map((resp: string) => resp));
  }

  getMultipartUploadId(
    fileName: string,
    administrativeFileId: number,
    moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS
  ): Observable<MultipartUploadId> {
    const req = {
      fileName: fileName,
      administrativeFileId: administrativeFileId
    };
    const options = createRequestOption(req);
    const url =
      moduleType === ModuleType.INSPECTION_BASIC_REQUIREMENTS
        ? `${baseUrl}/storage/multipartuploadid`
        : `${baseUrl}/ppc/storage/multipartuploadid`;
    return this.http
      .get<MultipartUploadId>(url, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<MultipartUploadId>) =>
            resp.body as MultipartUploadId
        )
      );
  }

  getUploadUrlMultiparts(
    fileTokenName: string,
    uploadId: string,
    partsNumber: number,
    moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS
  ): Observable<MultiPartUrlI[]> {
    const req = {
      fileTokenName: fileTokenName,
      uploadId: uploadId,
      partsNumber: partsNumber
    };
    const options = createRequestOption(req);
    const url =
      moduleType === ModuleType.INSPECTION_BASIC_REQUIREMENTS
        ? `${baseUrl}/storage/multipartuploadurls`
        : `${baseUrl}/ppc/storage/multipartuploadurls`;
    return this.http
      .get<MultiPartUrlI[]>(url, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<MultiPartUrlI[]>) => resp.body as MultiPartUrlI[]
        )
      );
  }

  closeMultipartUpload(
    partETags: PartI[],
    fileTokenName: string,
    uploadId: string,
    moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS
  ): Observable<void> {
    const req = {
      fileTokenName: fileTokenName,
      uploadId: uploadId
    };
    const options = createRequestOption(req);
    const url =
      moduleType === ModuleType.INSPECTION_BASIC_REQUIREMENTS
        ? `${baseUrl}/storage/closemultipartupload`
        : `${baseUrl}/ppc/storage/closemultipartupload`;
    return this.http.post<void>(url, partETags, {
      params: options
    });
  }

  deleteFile(
    tokenFileName: string,
    moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS
  ): Observable<void> {
    const req = {
      tokenFileName: tokenFileName
    };
    const options = createRequestOption(req);
    const url =
      moduleType === ModuleType.INSPECTION_BASIC_REQUIREMENTS
        ? `${baseUrl}/storage/deleteFile`
        : `${baseUrl}/ppc/storage/deleteFile`;
    return this.http
      .get<void>(url, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(map((resp: HttpResponse<void>) => resp.body as void));
  }

  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return,
  @typescript-eslint/typedef */
  downloadFile(
    tokenFileName: string,
    moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS
  ): Observable<any> {
    const req = {
      tokenFileName: tokenFileName
    };
    const options = createRequestOption(req);
    const url =
      moduleType === ModuleType.INSPECTION_BASIC_REQUIREMENTS
        ? `${baseUrl}/storage/downloadFile`
        : `${baseUrl}/ppc/storage/downloadFile`;

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      }),
      params: options,
      responseType: 'arraybuffer' as 'json' // We accept plain text as response.
    };
    return this.http.get<any>(url, httpOptions).pipe(
      map((resp) => resp),
      catchError((err: HttpErrorResponse) => {
        console.error(
          'Se ha producido un error al descargar el informe:',
          err.message
        );
        return throwError(() => new Error(err.message));
      })
    );
  }

  setPpcCorrectionDocumentActiveFalse(
    oldTokenFileName: string,
    ppcProcedureId: number
  ): Observable<string> {
    const req = {
      oldTokenFileName: oldTokenFileName,
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<string>(`${baseUrl}/storage/setPpcCorrectionDocumentActiveFalse`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(map((resp: HttpResponse<string>) => resp.body as string));
  }

  setPpcAdministrativeFileCorrectionDocumentActiveFalse(
    oldTokenFileName: string,
    ppcAdministrativeFileId: number
  ): Observable<string> {
    const req = {
      oldTokenFileName: oldTokenFileName,
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<string>(
        `${baseUrl}/storage/setPpcAdministrativeFileCorrectionDocumentActiveFalse`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(map((resp: HttpResponse<string>) => resp.body as string));
  }

  setPpcAdministrativeFileReturnDocumentActiveFalse(
    oldTokenFileName: string,
    ppcAdministrativeFileId: number
  ): Observable<string> {
    const req = {
      oldTokenFileName: oldTokenFileName,
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<string>(
        `${baseUrl}/storage/setPpcAdministrativeFileReturnDocumentActiveFalse`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(map((resp: HttpResponse<string>) => resp.body as string));
  }
}
