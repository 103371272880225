import { Injectable } from '@angular/core';
import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  UrlSegment
} from '@angular/router';

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  shouldDetach(_route: ActivatedRouteSnapshot): boolean {
    return false; // Do not detach any routes
  }

  store(_route: ActivatedRouteSnapshot, _handle: DetachedRouteHandle): void {
    // No-op
  }

  shouldAttach(_route: ActivatedRouteSnapshot): boolean {
    return false; // Do not attach any routes
  }

  retrieve(_route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null; // Return null to force route reinitialization
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // Customize condition to determine when to reuse the route
    // For example, you can check for certain route parameters or paths
    // const isRefreshed = future.queryParams['refresh'] !== undefined;

    // const futureUrl = this.getFullRouteUrl(future);

    const shouldReload = future.data.shouldReload as boolean;

    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      shouldReload
    ) {
      // Logic if 'refresh' query parameter is present
      return false; // Do not reuse the route
    }

    return future.routeConfig === curr.routeConfig;
  }

  private getFullRouteUrl(route: ActivatedRouteSnapshot): string {
    let url = '';
    let currentRoute = route;

    // Iterate through the route and its parents to construct the full URL
    while (currentRoute) {
      if (currentRoute.url.length) {
        url =
          currentRoute.url
            .map((segment: UrlSegment) => segment.path)
            .join('/') +
          '/' +
          url;
      }
      currentRoute = currentRoute.parent as ActivatedRouteSnapshot;
    }

    // Remove trailing slash if present
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    return `/${url}`;
  }

  // private isFiscalYearChange(
  //   _future: ActivatedRouteSnapshot,
  //   _curr: ActivatedRouteSnapshot
  // ): boolean {
  //   let fiscalYearChanged = false;
  //   this.appConfig.selectedFiscalYear$.subscribe(
  //     () => (fiscalYearChanged = true)
  //   );
  //   return fiscalYearChanged;
  // }
}
