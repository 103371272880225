<div class="reports">
  <div class="reports-content">
    <form
      autocomplete="off"
      *ngIf="reportsForm"
      [formGroup]="reportsForm"
      (ngSubmit)="submitDateRange()"
    >
      <mat-card>
        <mat-card-header>
          <mat-card-title> {{ 'reports.title' | translate }} </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="filter-panel-row">
            <div class="filter-panel-row-first-column">
              <ic-date-picker
                [placeHolderText]="'reports.filter.date.title' | translate"
                [range]="true"
                [disabledInput]="true"
                class="row-one-element"
                [inputFormControlStart]="controls?.dateStart"
                [inputFormControlEnd]="controls?.dateEnd"
              >
              </ic-date-picker>
            </div>
            <div class="filter-panel-row-second-column">
              <ic-raised-button
                [type]="'submit'"
                [color]="'primary'"
                [disabled]="isFormEmpty"
                [textInput]="
                  'reports.generate-statistics-report.title' | translate
                "
              ></ic-raised-button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
