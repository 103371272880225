import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MenuOptions,
  NavItems,
  FrbConstants,
  HttpConstants,
  StorageOptions,
  TreeOptions
} from '@ic-app/constants/constants.index';

@NgModule({
  providers: [
    MenuOptions,
    NavItems,
    FrbConstants,
    HttpConstants,
    StorageOptions,
    TreeOptions
  ],
  imports: [CommonModule]
})
export class ConstantsModule {}
