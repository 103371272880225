<div
  class="ic-datepicker"
  *ngIf="range && inputFormControlStart && inputFormControlEnd"
>
  <mat-form-field [appearance]="appearance" class="ic-datepicker-column">
    <mat-label>{{ placeHolderText }}</mat-label>
    <mat-date-range-input
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
    >
      <input
        matStartDate
        class="ic-input-container-form-input"
        placeholder="Fecha de inicio"
        #dateRangeStart
        [readOnly]="disabledInput"
        [formControl]="inputFormControlStart"
      />
      <input
        matEndDate
        class="ic-input-container-form-input"
        placeholder="Fecha de fin"
        #dateRangeEnd
        [readOnly]="disabledInput"
        [formControl]="inputFormControlEnd"
      />
    </mat-date-range-input>
    <!-- <mat-icon
      *ngIf="!hideClearButton"
      matDatepickerToggleIcon
      (click)="clearDate($event)"
      >clear</mat-icon
    > -->
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker
      #picker
      disabled="false"
      (closed)="onDateInput()"
    ></mat-date-range-picker>
  </mat-form-field>
  <div class="ic-datepicker-column">
    <ic-icon-button
      *ngIf="!hideClearButton && isNotEmpty"
      [fontAwesome]="true"
      [faIcon]="'calendar-times'"
      [disabled]="false"
      [iconColor]="'primary'"
      [faIconClass]="'ic-display-flex-fa-icon'"
      (icClick)="clearDate($event)"
    ></ic-icon-button>
  </div>
</div>
<div class="ic-datepicker" *ngIf="!range && inputFormControl">
  <mat-form-field [appearance]="appearance">
    <mat-label [ngStyle]="important ? { color: 'red' } : {}">
      {{ placeHolderText }}
    </mat-label>
    <input
      [ngStyle]="important ? { color: 'red' } : {}"
      matInput
      [min]="minDate"
      [max]="maxDate"
      [matDatepicker]="picker"
      [formControl]="inputFormControl"
    /><mat-error
      *ngIf="
        inputFormControl.hasError('required') ||
        inputFormControl.hasError('nullValidator')
      "
      >{{ 'components.input.errors.required' | translate }}</mat-error
    >
    <mat-icon
      *ngIf="!hideClearButton"
      matDatepickerToggleIcon
      (click)="clearDate($event)"
      >clear</mat-icon
    >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false"></mat-datepicker>
  </mat-form-field>
</div>
