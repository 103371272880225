<mat-form-field appearance="fill" class="top-nav-searchbox">
  <input
    type="text"
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    placeholder="Buscar Ayuntamiento"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option [value]="allOption.text"> Todos los Ayuntamientos </mat-option>
    <mat-option *ngFor="let data of filteredDataToPick" [value]="data.text">
      {{ data.text }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
