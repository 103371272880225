<mat-tree
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl"
  class="tree mat-tree-position"
>
  <mat-tree-node *matTreeNodeDef="let node">
    <div
      *ngIf="node.nodeVisible"
      [ngClass]="{
        'ppc-tree-parent-node': !node.parentId && node.treeId,
        'tree-node': node.parentId,
        'root-node': !node.parentId && !node.treeId
      }"
    >
      <li
        *ngIf="node.nodeVisible"
        class="mat-tree-node"
        [ngStyle]="{ color: node.color }"
      >
        <div
          *ngIf="node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
            [matTooltip]="node.nationalWording"
          ></mat-icon>
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
        </div>
        <div
          *ngIf="!node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon legal-source-icon-invisible"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
          ></mat-icon>
        </div>
        <mat-icon
          *ngIf="node.completed && node.result === 1"
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="node.completed && node.result === 0"
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        ><span
          [ngClass]="{
            'text-bold': !node.parentId,
            'tree-label-result':
              node.completed && (node.result === 0 || node.result === 1),
            'tree-label': !node.completed
          }"
        >
          {{ node.wording }}
        </span>
        <div class="tree-button">
          <ic-tree-action-button
            class="button"
            *ngFor="let action of node.actionButtons"
            (actionPressed)="nodeButtonPressed($event)"
            [onlySee]="onlySee"
            [labelButton]="action.btnLabel"
            [actionButton]="action.action"
            [currentNode]="node"
            [customColor]="action.color ? action.color : 'default'"
            [pushedButton]="action.pushed"
          ></ic-tree-action-button>
        </div>
      </li>
      <div *ngIf="node.nodeVisible">
        <mat-icon
          *ngIf="node.fileName !== null && !node.parentId"
          fontIcon="{{ node.fileIcon }}"
          color="{{ node.fileIconColor }}"
          [class]="'{{ node.fileIcon }}, tree-file-icons'"
          style="margin-left: -16px; padding-right: 5px"
        ></mat-icon>
        <label
          *ngIf="node.fileName !== null && !node.parentId"
          class="tree-file-name"
          (click)="downloadDocument(node.fileName, node.fullFileName)"
        >
          {{ node.fileName }}
        </label>
        <mat-icon
          *ngIf="!onlySee && node.id !== null && !node.parentId"
          disabled="false"
          [matTooltip]="'ppc-administrative-file.manage-comments' | translate"
          [class]="'actionIcons, tree-comments-buttons'"
          [fontIcon]="'description'"
          (click)="addCommentPressed(node)"
        >
        </mat-icon>
        <mat-icon
          *ngIf="onlySee && node.id !== null && !node.parentId"
          disabled="true"
          [matTooltip]="'ppc-administrative-file.manage-comments' | translate"
          [class]="'actionIcons, tree-comments-buttons-disable'"
          [fontIcon]="'description'"
        >
        </mat-icon>
        <mat-icon
          id="{{ node.id }}"
          (click)="expandComments(node)"
          *ngIf="node.showComments === false && !node.parentId"
          [matTooltip]="'ppc-administrative-file.view-comment' | translate"
          [class]="'actionIcons, tree-visibility-comment-icons'"
          [fontIcon]="'expand_more'"
        >
        </mat-icon>
        <mat-icon
          id="{{ node.id }}"
          (click)="unexpandComments(node)"
          *ngIf="node.showComments === true && !node.parentId"
          [matTooltip]="'ppc-administrative-file.unexpand-comment' | translate"
          [class]="'actionIcons, tree-visibility-comment-icons'"
          [fontIcon]="'expand_less'"
        >
        </mat-icon>

        <div
          id="{{ node.id }}"
          *ngIf="node.nodeVisible && node.showComments && !node.parentId"
        >
          <mat-card>
            <mat-card-content>
              <ng-container
                *ngFor="let ppcItemComment of ppcItemCommentReportList"
              >
                <mat-list
                  *ngIf="
                    ppcItemComment.ppcReportItemComment.commentExpanded ===
                      true &&
                    ppcItemComment.ppcReportItemComment
                      .ppcSupportedVerificationItemId === node.id
                  "
                  role="list"
                >
                  <mat-list-item role="listitem">
                    <div
                      [ngClass]="
                        currentUser?.email ===
                        ppcItemComment.ppcReportItemComment.commentEmail
                          ? 'comment-blue'
                          : 'comment'
                      "
                    >
                      <div class="comment-data">
                        <div class="comment-header">
                          <div class="comment-user">
                            <strong
                              [ngClass]="
                                currentUser?.email ===
                                ppcItemComment.ppcReportItemComment.commentEmail
                                  ? 'blue-text'
                                  : ''
                              "
                              >{{
                                ppcItemComment.ppcReportItemComment.firstName
                              }}
                              {{ ppcItemComment.ppcReportItemComment.lastName }}
                            </strong>
                          </div>
                          <div class="comment-date">
                            <span> - </span
                            ><span>{{
                              ppcItemComment.ppcReportItemComment.commentDate
                                | date : 'dd/MM/yyyy HH:mm'
                            }}</span>
                          </div>
                        </div>
                        <div class="comment-content">
                          {{
                            ppcItemComment.ppcSupportedReportItemComment.wording
                          }}
                        </div>
                        <div
                          *ngIf="
                            ppcItemComment.ppcReportItemComment.explanatoryNote
                          "
                          class="comment-content"
                        >
                          <strong>Nota: </strong>
                          {{
                            ppcItemComment.ppcReportItemComment.explanatoryNote
                          }}
                        </div>
                      </div>
                    </div>
                  </mat-list-item>
                </mat-list>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tree-node>
  <!-- [className]="
  node.completed && (node.result === 0 || node.result === 1)
    ? 'tree-label-result'
    : 'tree-label'
" -->
  <mat-nested-tree-node
    *matTreeNodeDef="let node; when: hasNestedChild"
    [ngStyle]="{ display: !node.parentId ? 'flex' : 'block' }"
  >
    <!-- En el ngStyle, el flex es para que en los nodos padre ponga los iconos por encima de la línea divisoria
       y que en los nodos hijos, con el block ocupen todo el ancho -->
    <div
      *ngIf="node.nodeVisible"
      [ngClass]="{
        'ppc-tree-parent-node': !node.parentId && node.treeId,
        '': node.parentId || !node.treeId,
        'root-node': !node.parentId && !node.treeId
      }"
    >
      <li
        *ngIf="node.nodeVisible"
        class="mat-tree-node"
        [ngStyle]="{ color: node.color }"
      >
        <div
          *ngIf="node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
            [matTooltip]="node.nationalWording"
          ></mat-icon>
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
        </div>
        <div
          *ngIf="!node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon legal-source-icon-invisible"
            [ngStyle]="{
              'margin-left': '-' + node.depth + 'px',
              'padding-right': node.depth + 'px'
            }"
          ></mat-icon>
        </div>
        <mat-icon
          *ngIf="node.completed && node.result === 1 && !node.parentIcon"
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="node.completed && node.result === 0 && !node.parentIcon"
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        >
        <mat-icon
          *ngIf="
            node.completed &&
            (node.result === 1 || node.result === 0) &&
            node.parentIcon
          "
          [ngStyle]="{ color: '#aaaaaa' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >subdirectory_arrow_right</mat-icon
        >
        <span
          [ngClass]="{
            'text-bold': !node.parentId,
            'tree-label-result':
              node.completed && (node.result === 0 || node.result === 1),
            'tree-label': !node.completed
          }"
        >
          {{ node.wording }}
        </span>
        <div class="tree-button">
          <ic-tree-action-button
            class="button"
            *ngFor="let action of node.actionButtons"
            (actionPressed)="nodeButtonPressed($event)"
            [onlySee]="onlySee"
            [labelButton]="action.btnLabel"
            [actionButton]="action.action"
            [currentNode]="node"
            [customColor]="action.color ? action.color : 'default'"
            [pushedButton]="action.pushed"
          ></ic-tree-action-button>
        </div>
        <div
          class="tree-all-comments"
          *ngIf="
            ppcItemCommentReportList?.length !== 0 &&
            !node.id &&
            node.itemsToShowHaveComments
          "
        >
          <span *ngIf="!expandedComments">{{
            'ppc-administrative-file.view-all-comments' | translate
          }}</span>
          <span *ngIf="expandedComments">{{
            'ppc-administrative-file.unexpand-all-comments' | translate
          }}</span>
          <mat-icon
            (click)="expandAllComments()"
            *ngIf="isTitle(node) && !expandedComments"
            [matTooltip]="
              'ppc-administrative-file.view-all-comments' | translate
            "
            [class]="'actionIcons, tree-visibility-all-comments-icon'"
            [fontIcon]="'expand_more'"
          >
          </mat-icon>
          <mat-icon
            (click)="unexpandAllComments()"
            *ngIf="isTitle(node) && expandedComments"
            [matTooltip]="
              'ppc-administrative-file.unexpand-all-comments' | translate
            "
            [class]="'actionIcons, tree-visibility-all-comments-icon'"
            [fontIcon]="'expand_less'"
          >
          </mat-icon>
        </div>
      </li>
      <!-- Botones/iconos si el item es Parent y se ha pulsado la opcion que no tiene hijos -->
      <div
        *ngIf="
          (!node.completed && !node.expanded) ||
          (node.completed && !node.expanded)
        "
      >
        <mat-icon
          *ngIf="node.fileName !== null && !node.parentId"
          fontIcon="{{ node.fileIcon }}"
          color="{{ node.fileIconColor }}"
          [class]="'{{ node.fileIcon }}, tree-file-icons'"
          style="margin-left: -16px; padding-right: 5px"
        ></mat-icon>
        <label
          *ngIf="node.fileName !== null && !node.parentId"
          class="tree-file-name"
          (click)="downloadDocument(node.fileName, node.fullFileName)"
        >
          {{ node.fileName }}
        </label>
        <mat-icon
          *ngIf="!onlySee && node.id !== null && !node.parentId"
          disabled="false"
          [matTooltip]="'ppc-administrative-file.manage-comments' | translate"
          [class]="'actionIcons, tree-comments-buttons'"
          [fontIcon]="'description'"
          (click)="addCommentPressed(node)"
        >
        </mat-icon>
        <mat-icon
          *ngIf="onlySee && node.id !== null && !node.parentId"
          disabled="true"
          [matTooltip]="'ppc-administrative-file.manage-comments' | translate"
          [class]="'actionIcons, tree-comments-buttons-disable'"
          [fontIcon]="'description'"
        >
        </mat-icon>
        <mat-icon
          id="{{ node.id }}"
          (click)="expandComments(node)"
          *ngIf="node.showComments === false && !node.parentId"
          [matTooltip]="'ppc-administrative-file.view-comment' | translate"
          [class]="'actionIcons, tree-visibility-comment-icons'"
          [fontIcon]="'expand_more'"
        >
        </mat-icon>
        <mat-icon
          id="{{ node.id }}"
          (click)="unexpandComments(node)"
          *ngIf="node.showComments === true && !node.parentId"
          [matTooltip]="'ppc-administrative-file.unexpand-comment' | translate"
          [class]="'actionIcons, tree-visibility-comment-icons'"
          [fontIcon]="'expand_less'"
        >
        </mat-icon>
        <div
          id="{{ node.id }}"
          *ngIf="node.nodeVisible && node.showComments && !node.parentId"
        >
          <mat-card>
            <mat-card-content>
              <ng-container
                *ngFor="let ppcItemComment of ppcItemCommentReportList"
              >
                <mat-list
                  *ngIf="
                    ppcItemComment.ppcReportItemComment.commentExpanded ===
                      true &&
                    ppcItemComment.ppcReportItemComment
                      .ppcSupportedVerificationItemId === node.id
                  "
                  role="list"
                >
                  <mat-list-item role="listitem">
                    <div
                      [ngClass]="
                        currentUser?.email ===
                        ppcItemComment.ppcReportItemComment.commentEmail
                          ? 'comment-blue'
                          : 'comment'
                      "
                    >
                      <div class="comment-data">
                        <div class="comment-header">
                          <div class="comment-user">
                            <strong
                              [ngClass]="
                                currentUser?.email ===
                                ppcItemComment.ppcReportItemComment.commentEmail
                                  ? 'blue-text'
                                  : ''
                              "
                              >{{
                                ppcItemComment.ppcReportItemComment.firstName
                              }}
                              {{ ppcItemComment.ppcReportItemComment.lastName }}
                            </strong>
                          </div>
                          <div class="comment-date">
                            <span> - </span
                            ><span>{{
                              ppcItemComment.ppcReportItemComment.commentDate
                                | date : 'dd/MM/yyyy HH:mm'
                            }}</span>
                          </div>
                        </div>
                        <div class="comment-content">
                          {{
                            ppcItemComment.ppcSupportedReportItemComment.wording
                          }}
                        </div>
                      </div>
                    </div>
                  </mat-list-item>
                </mat-list>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <ul [class.tree-invisible]="!nestedTreeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
        <!-- Botones/iconos si el item es Parent y se ha pulsado la opcion que tiene hijos -->
        <div>
          <mat-icon
            *ngIf="node.fileName !== null && !node.parentId"
            fontIcon="{{ node.fileIcon }}"
            color="{{ node.fileIconColor }}"
            [class]="'{{ node.fileIcon }}, tree-file-icons'"
            style="margin-left: -16px; padding-right: 5px"
          ></mat-icon>
          <label
            *ngIf="node.fileName !== null && !node.parentId"
            class="tree-file-name"
            (click)="downloadDocument(node.fileName, node.fullFileName)"
          >
            {{ node.fileName }}
          </label>
          <mat-icon
            *ngIf="!onlySee && node.id !== null && !node.parentId"
            disabled="false"
            [matTooltip]="'ppc-administrative-file.manage-comments' | translate"
            [class]="'actionIcons, tree-comments-buttons'"
            [fontIcon]="'description'"
            (click)="addCommentPressed(node)"
          >
          </mat-icon>
          <mat-icon
            *ngIf="onlySee && node.id !== null && !node.parentId"
            disabled="true"
            [matTooltip]="'ppc-administrative-file.manage-comments' | translate"
            [class]="'actionIcons, tree-comments-buttons-disable'"
            [fontIcon]="'description'"
          >
          </mat-icon>
          <mat-icon
            id="{{ node.id }}"
            (click)="expandComments(node)"
            *ngIf="node.showComments === false && !node.parentId"
            [matTooltip]="'ppc-administrative-file.view-comment' | translate"
            [class]="'actionIcons, tree-visibility-comment-icons'"
            [fontIcon]="'expand_more'"
          >
          </mat-icon>
          <mat-icon
            id="{{ node.id }}"
            (click)="unexpandComments(node)"
            *ngIf="node.showComments === true && !node.parentId"
            [matTooltip]="
              'ppc-administrative-file.unexpand-comment' | translate
            "
            [class]="'actionIcons, tree-visibility-comment-icons'"
            [fontIcon]="'expand_less'"
          >
          </mat-icon>
          <div
            id="{{ node.id }}"
            *ngIf="node.nodeVisible && node.showComments && !node.parentId"
          >
            <mat-card>
              <mat-card-content>
                <ng-container
                  *ngFor="let ppcItemComment of ppcItemCommentReportList"
                >
                  <mat-list
                    *ngIf="
                      ppcItemComment.ppcReportItemComment.commentExpanded ===
                        true &&
                      ppcItemComment.ppcReportItemComment
                        .ppcSupportedVerificationItemId === node.id
                    "
                    role="list"
                  >
                    <mat-list-item role="listitem">
                      <div
                        [ngClass]="
                          currentUser?.email ===
                          ppcItemComment.ppcReportItemComment.commentEmail
                            ? 'comment-blue'
                            : 'comment'
                        "
                      >
                        <div class="comment-data">
                          <div class="comment-header">
                            <div class="comment-user">
                              <strong
                                [ngClass]="
                                  currentUser?.email ===
                                  ppcItemComment.ppcReportItemComment
                                    .commentEmail
                                    ? 'blue-text'
                                    : ''
                                "
                                >{{
                                  ppcItemComment.ppcReportItemComment.firstName
                                }}
                                {{
                                  ppcItemComment.ppcReportItemComment.lastName
                                }}
                              </strong>
                            </div>
                            <div class="comment-date">
                              <span> - </span
                              ><span>{{
                                ppcItemComment.ppcReportItemComment.commentDate
                                  | date : 'dd/MM/yyyy HH:mm'
                              }}</span>
                            </div>
                          </div>
                          <div class="comment-content">
                            {{
                              ppcItemComment.ppcSupportedReportItemComment
                                .wording
                            }}
                          </div>
                        </div>
                      </div>
                    </mat-list-item>
                  </mat-list>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </ul>
    </div>
  </mat-nested-tree-node>
</mat-tree>
