import { Component, Input } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'ic-error-sumary',
  templateUrl: './error-sumary.component.html'
})
export class ErrorSumaryComponent {
  @Input() control?: string;
  @Input() errorRequired?: string;
  @Input() errorEmail?: string;
  @Input() errorMax?: string;
  @Input() errorMin?: string;

  @Input() visible?: boolean;

  constructor(private controlContainer: ControlContainer) {}

  get form(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get formControl(): AbstractControl {
    return this.form.get(this.control as string) as AbstractControl;
  }

  get isNotValid(): boolean {
    return (
      this.formControl.invalid &&
      (this.formControl.touched || this.formControl.dirty)
    );
  }
}
