/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IActionEmit {
  id: number;
  action: number;
  treeId: number;
}

@Component({
  selector: 'ic-tree-action-button',
  templateUrl: './tree-action-button.component.html'
})
export class TreeActionButtonComponent {
  @Input() onlySee?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() currentNode?: any;
  @Input() labelButton?: string;
  @Input() actionButton?: number;
  @Input() customColor?: string;
  @Input() pushedButton?: boolean;
  @Output() actionPressed = new EventEmitter<IActionEmit>();

  buttonPressed(): void {
    // Emitimos la acción de botón presionado y la gestionamos en el componente padre
    this.actionPressed.emit({
      id: this.currentNode.id,
      action: this.actionButton as number,
      treeId: this.currentNode.treeId
    });
  }
}
