export const environment = {
  production: false,
  develop: true,
  domain: 'testscipion.eu.auth0.com',
  clientId: 'y3ezspdwz1KRhBUmdTCsykKfoBmsKMYu',
  audience: 'https://devtower.scipionapp.com/',
  baseUrl: 'https://devtower.scipionapp.com/api',
  redirectUrl: 'https://www.scipionapp.com/',
  uriApiWildCard: 'https://devtower.scipionapp.com/api/*'
};
