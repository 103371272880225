/* eslint-disable max-len */

import { Icons } from '@ic-app/constants/app.icons';
import { IListActionButtons } from '@ic-app/models/list-action-buttons.model';
import {
  getTimestampBasedId,
  isEmptyNullOrUndefinedString,
  isNullOrUndefined
} from '@ic-core/util/global-util';
import {
  IImpactLevel,
  ImpactLevel
} from '@ic-models/prior-operational/ppc-report/ppc-observations/ppc-observation';

export const enum PpcSubjectiveImprovementTypes {
  REPORT_SUBJECTIVE_IMPROVEMENT_SCIPION_CATALOG = 100,
  REPORT_SUBJECTIVE_IMPROVEMENT_NEW_MODEL = 200,
  REPORT_SUBJECTIVE_IMPROVEMENT_NO_MODEL = 250,
  REPORT_SUBJECTIVE_IMPROVEMENT_EXISTING = 300
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPpcSubjectiveImprovementsListDTO {
  ppcSubjectiveImprovementReportList: IPpcSubjectiveImprovementReportList[];
  ppcSubjectiveImprovementModelDefinitionList:
    | IPpcSubjectiveImprovementModelList[]
    | null;
  ppcSubjectiveImprovementReportListToDelete?: number[];
  ppcSubjectiveImprovementModelListToDelete?: number[] | null;
}

export class PpcSubjectiveImprovementToSave
  implements IPpcSubjectiveImprovementsListDTO
{
  ppcSubjectiveImprovementReportList: IPpcSubjectiveImprovementReportList[];
  ppcSubjectiveImprovementModelDefinitionList:
    | IPpcSubjectiveImprovementModelList[]
    | null;
  ppcSubjectiveImprovementReportListToDelete: number[];
  ppcSubjectiveImprovementModelListToDelete: number[] | null;

  constructor(
    ppcSubjectiveImprovementReportList: IPpcSubjectiveImprovementReportList[],
    ppcSubjectiveImprovementModelDefinitionList:
      | IPpcSubjectiveImprovementModelList[]
      | null,
    ppcSubjectiveImprovementReportListToDelete: number[],
    ppcSubjectiveImprovementModelListToDelete: number[] | null
  ) {
    this.ppcSubjectiveImprovementReportList =
      ppcSubjectiveImprovementReportList;
    this.ppcSubjectiveImprovementModelDefinitionList =
      ppcSubjectiveImprovementModelDefinitionList;
    this.ppcSubjectiveImprovementReportListToDelete =
      ppcSubjectiveImprovementReportListToDelete;
    this.ppcSubjectiveImprovementModelListToDelete =
      ppcSubjectiveImprovementModelListToDelete;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPpcSubjectiveImprovementToDeleteDTO {
  ppcModelSubjectiveImprovementId: number | null;
  ppcReportSubjectiveImprovementId: number | null;
  isPpcSubjectiveImprovementFromReportPpc: boolean;
  isDeletedFromReportPpc?: boolean | null;
  ppcReportProcedureId: number;
}

export class PpcSubjectiveImprovementToDeleteDTO
  implements IPpcSubjectiveImprovementToDeleteDTO
{
  ppcModelSubjectiveImprovementId: number | null;
  ppcReportSubjectiveImprovementId: number | null;
  isPpcSubjectiveImprovementFromReportPpc: boolean;
  isDeletedFromReportPpc?: boolean | null;
  ppcReportProcedureId: number;
  ppcSubjectiveImprovementsListDTO: PpcSubjectiveImprovementToSave;

  constructor(
    ppcModelSubjectiveImprovementId: number | null,
    ppcReportSubjectiveImprovementId: number | null,
    isPpcSubjectiveImprovementFromReportPpc: boolean,
    isDeletedFromReportPpc: boolean | null,
    ppcReportProcedureId: number,
    ppcSubjectiveImprovementToSave: PpcSubjectiveImprovementToSave
  ) {
    this.ppcModelSubjectiveImprovementId = ppcModelSubjectiveImprovementId;
    this.ppcReportSubjectiveImprovementId = ppcReportSubjectiveImprovementId;
    this.isPpcSubjectiveImprovementFromReportPpc =
      isPpcSubjectiveImprovementFromReportPpc;
    this.isDeletedFromReportPpc = isDeletedFromReportPpc;
    this.ppcReportProcedureId = ppcReportProcedureId;
    this.ppcSubjectiveImprovementsListDTO = ppcSubjectiveImprovementToSave;
  }
}

// Interface para la primera tabla => Recomendaciones para el Informe
export interface IPpcSubjectiveImprovementReportList {
  code?: number;
  ppcSupportedReportSubjectiveImprovement: IPpcSupportedReportSubjectiveImprovement;
  ppcReportSubjectiveImprovement: IPpcReportSubjectiveImprovement;
  actions?: IListActionButtons[];
  ppcAdministrativeFileId: number;
  ppcCurrentProcedure: number;
  categoryLabel: string | undefined;
  categoryIcon: string | undefined;
  categoryIconColor: string | undefined;
  categoryCode?: number;
  impactLevelLabel: string | undefined;
  impactLevelCode?: number;
  impactLevelIconColor: string | undefined;
  changed: boolean;
  ppcModelSubjectiveImprovementId?: number;
}

export class PpcSubjectiveImprovementReportList
  implements IPpcSubjectiveImprovementReportList
{
  code?: number;
  ppcSupportedReportSubjectiveImprovement: IPpcSupportedReportSubjectiveImprovement;
  ppcReportSubjectiveImprovement: IPpcReportSubjectiveImprovement;
  actions?: IListActionButtons[];
  ppcAdministrativeFileId: number;
  ppcCurrentProcedure: number;
  categoryLabel: string | undefined;
  categoryIcon: string | undefined;
  categoryIconColor: string | undefined;
  impactLevelLabel: string | undefined;
  impactLevelIconColor: string | undefined;
  changed: boolean;
  ppcModelSubjectiveImprovementId?: number;

  constructor(
    wording: string | null,
    orderSubjectiveImprovement: number,
    ppcReportProcedureId: number,
    isPpcSubjectiveImprovementModel: boolean | null,
    ppcSubjectiveImprovementModel: IPpcSubjectiveImprovementModelList | null,
    ppcAdministrativeFileId: number,
    ppcCurrentProcedure: number,
    code: number | null,
    localId: string | null = null
  ) {
    if (isPpcSubjectiveImprovementModel === true) {
      this.code = 200;
      this.categoryIconColor = 'warning';
      this.categoryLabel = 'ppc-report.observation-panel.category-labels.model';
      this.actions = [
        Icons.ICON_EMPTY,
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
      this.categoryIcon = 'u_letter';
      this.impactLevelIconColor = 'stopped';
      this.impactLevelLabel =
        'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    }
    if (isPpcSubjectiveImprovementModel === false) {
      this.code = 250;
      this.categoryIconColor = 'no-model';
      this.categoryLabel =
        'ppc-report.observation-panel.category-labels.no-model';
      this.actions = [
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_EDIT_OPTION,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
      this.categoryIcon = 'u_letter';
      this.impactLevelIconColor = 'stopped';
      this.impactLevelLabel =
        'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    }
    if (isPpcSubjectiveImprovementModel === null && code !== null) {
      if (code === 300) {
        this.categoryIconColor = 'stopped';
        this.categoryLabel =
          'ppc-report.observation-panel.category-labels.existing';
        this.ppcSupportedReportSubjectiveImprovement =
          ppcSubjectiveImprovementModel?.ppcSupportedReportSubjectiveImprovement as IPpcSupportedReportSubjectiveImprovement;
        this.categoryIcon = 'u_letter';
        this.impactLevelIconColor = 'stopped';
        this.impactLevelLabel =
          'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
      } else if (code === 100) {
        this.ppcSupportedReportSubjectiveImprovement =
          new PpcSupportedReportSubjectiveImprovement(
            wording,
            isEmptyNullOrUndefinedString(localId)
              ? getTimestampBasedId()
              : localId
          );

        this.impactLevelIconColor =
          ppcSubjectiveImprovementModel?.impactLevelIconColor;
        this.impactLevelLabel = ppcSubjectiveImprovementModel?.impactLevelLabel;
        this.categoryIcon = ppcSubjectiveImprovementModel?.categoryIcon;
        this.categoryIconColor =
          ppcSubjectiveImprovementModel?.categoryIconColor;
        this.categoryLabel = ppcSubjectiveImprovementModel?.categoryLabel;
      } else {
        this.categoryIconColor = 'warning';
        this.categoryLabel =
          'ppc-report.observation-panel.category-labels.model';
        this.ppcSupportedReportSubjectiveImprovement =
          new PpcSupportedReportSubjectiveImprovement(
            wording,
            isEmptyNullOrUndefinedString(localId)
              ? getTimestampBasedId()
              : localId
          );
        this.categoryIcon = 'u_letter';
        this.impactLevelIconColor = 'stopped';
        this.impactLevelLabel =
          'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
      }
      this.code = code;
      this.actions = [
        Icons.ICON_EMPTY,
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
    } else {
      this.ppcSupportedReportSubjectiveImprovement =
        new PpcSupportedReportSubjectiveImprovement(
          wording,
          isEmptyNullOrUndefinedString(localId)
            ? getTimestampBasedId()
            : localId
        );
    }

    this.ppcAdministrativeFileId = ppcAdministrativeFileId;
    this.ppcCurrentProcedure = ppcCurrentProcedure;
    this.ppcReportSubjectiveImprovement = new PpcReportSubjectiveImprovement(
      orderSubjectiveImprovement,
      ppcReportProcedureId
    );

    this.changed = true;
  }
}

// Interface para la segunda tabla => Recomendaciones de modelo
export interface IPpcSubjectiveImprovementModelList {
  code: number;
  createdDate?: Date;
  insertMoment?: Date;
  ppcSupportedReportSubjectiveImprovement: IPpcSupportedReportSubjectiveImprovement;
  ppcReportSubjectiveImprovementModel: IPpcReportSubjectiveImprovementModelDefinition;
  actions?: IListActionButtons[];
  categoryLabel: string;
  categoryIcon: string;
  categoryIconColor: string;
  categoryCode?: number;
  impactLevelLabel: string;
  impactLevelCode?: number;
  impactLevelIconColor: string;
  changed: boolean;
  isPpcSubjectiveImprovementReport?: boolean;
  isNewPpcSubjectiveImprovement?: boolean;
  checked?: boolean;
  subjectiveImprovementGroup?: number;
}

export class PpcSubjectiveImprovementModelList
  implements IPpcSubjectiveImprovementModelList
{
  code: number;
  insertMoment?: Date;
  ppcSupportedReportSubjectiveImprovement: IPpcSupportedReportSubjectiveImprovement;
  ppcReportSubjectiveImprovementModel: IPpcReportSubjectiveImprovementModelDefinition;
  iconColor?: string;
  actions?: IListActionButtons[];
  categoryLabel = '';
  categoryIcon: string;
  categoryIconColor: string;
  impactLevelLabel: string;
  impactLevelIconColor: string;
  changed: boolean;
  isPpcSubjectiveImprovementReport?: boolean;
  isNewPpcSubjectiveImprovement?: boolean;

  constructor(
    wording: string,
    ppcAdministrativeFileId: number,
    ppcCurrentProcedureId: number,
    localId: string | null = null
  ) {
    this.code = 200;
    this.insertMoment = new Date();
    this.ppcSupportedReportSubjectiveImprovement =
      new PpcSupportedReportSubjectiveImprovement(
        wording,
        isNullOrUndefined(localId) ? getTimestampBasedId() : localId
      );
    this.ppcReportSubjectiveImprovementModel =
      new PpcReportSubjectiveImprovementModelDefinition(
        ppcAdministrativeFileId,
        ppcCurrentProcedureId
      );
    this.impactLevelIconColor = 'stopped';
    this.actions = [
      Icons.ICON_EMPTY,
      Icons.ICON_EDIT_OPTION,
      Icons.ICON_ARROW_CROSS
    ];
    this.categoryIcon = 'u_letter';
    this.categoryIconColor = 'warning';
    this.impactLevelLabel =
      'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    this.changed = true;
    this.isPpcSubjectiveImprovementReport = true;
    this.isNewPpcSubjectiveImprovement = true;
  }
}

export interface IPpcSupportedReportSubjectiveImprovement {
  id?: number;
  localId?: string | null;
  code?: number;
  impactLevel: IImpactLevel;
  idTranslation?: number;
  wording: string | null;
}

export class PpcSupportedReportSubjectiveImprovement
  implements IPpcSupportedReportSubjectiveImprovement
{
  impactLevel: IImpactLevel;
  wording: string | null;
  localId?: string | null;

  constructor(wording: string | null, localId: string | null = null) {
    this.impactLevel = new ImpactLevel();
    this.wording = wording;
    this.localId = localId;
  }
}

export interface IPpcReportSubjectiveImprovement {
  id?: number;
  localId?: string;
  deleted: boolean;
  orderSubjectiveImprovement: number;
  ppcReportProcedureId: number;
  ppcParentSupportedReportSubjectiveImprovementId?: number | null;
  ppcParentSupportedReportSubjectiveImprovementWording?: string | null;
  ppcParentSupportedReportSubjectiveImprovementLocalId?: string | null;
  explanatoryNote?: string;
}

export class PpcReportSubjectiveImprovement
  implements IPpcReportSubjectiveImprovement
{
  deleted: boolean;
  orderSubjectiveImprovement: number;
  ppcReportProcedureId: number;
  ppcParentSupportedReportSubjectiveImprovementId: number | null;

  constructor(
    orderSubjectiveImprovement: number,
    ppcReportProcedureId: number
  ) {
    this.deleted = false;
    this.orderSubjectiveImprovement = orderSubjectiveImprovement;
    this.ppcReportProcedureId = ppcReportProcedureId;
    this.ppcParentSupportedReportSubjectiveImprovementId = null;
  }
}

export interface IPpcReportSubjectiveImprovementModelDefinition {
  id?: number;
  localId?: string;
  subjectiveImprovementEdits?: number;
  subjectiveImprovementRelevance?: number;
  subjectiveImprovementUses?: number;
  areaId?: number;
  ppcAdministrativeFileId?: number;
  ppcCurrentProcedureId?: number;
  topicId?: number;
  deleted: boolean;
}

export class PpcReportSubjectiveImprovementModelDefinition
  implements IPpcReportSubjectiveImprovementModelDefinition
{
  ppcAdministrativeFileId: number;
  ppcCurrentProcedureId: number;
  deleted: boolean;

  constructor(ppcAdministrativeFileId: number, ppcCurrentProcedureId: number) {
    this.ppcAdministrativeFileId = ppcAdministrativeFileId;
    this.ppcCurrentProcedureId = ppcCurrentProcedureId;
    this.deleted = false;
  }
}
