/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { isEmptyNullOrUndefinedArray } from '@ic-core/util/global-util';
import { AppConfigService } from '@ic-services/app-config.service';

@Directive({
  selector: '[icAccessControl]'
})
export class AccessControlDirective implements OnInit {
  @Input() roles: string[] = [];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.checkAccessToLeaveOrRemoveElement();
  }

  checkAccessToLeaveOrRemoveElement(): void {
    // Los elemenos se renderizan por defecto
    if (!isEmptyNullOrUndefinedArray(this.roles)) {
      // cogemos el array de roles del usuario logueado (puede tener uno o varios)
      const userRoles = this.appConfig.authorities;

      // Comprobamos que alguno de los roles del usuario está incluido dentro
      // de los roles que pasamos a la directiva
      const rolesFound = userRoles.filter(
        (role: string) => this.roles.indexOf(role) !== -1
      );
      // En caso de no encontrar alguno de los roles de la directiva dentro de los roles
      // asignados al usuario eliminamos el elemento del html para que no se renderice.
      if (isEmptyNullOrUndefinedArray(rolesFound)) {
        this.renderer.removeChild(
          this.elementRef.nativeElement.parentElement,
          this.elementRef.nativeElement
        );
      }
    }
  }

  checkAccessToHideOrShowElement(): void {
    // Partimos del elemento oculto y solo lo mostramos si el elemento puede verlo.
    this.elementRef.nativeElement.style.display = 'none';
    if (isEmptyNullOrUndefinedArray(this.roles)) {
      this.elementRef.nativeElement.style.display = 'block';
    } else {
      // cogemos el array de roles del usuario logueado (puede tener uno o varios)
      const userRoles = this.appConfig.authorities;

      // Comprobamos que alguno de los roles del usuario está incluido dentro
      // de los roles que pasamos a la directiva
      const rolesFound = userRoles.filter(
        (role: string) => this.roles.indexOf(role) !== -1
      );
      // En caso de encontrar alguno de los roles de la directiva dentro de los roles
      // asignados al usuario mostramos el elemento. En caso contrario lo ocultamos
      this.elementRef.nativeElement.style.display = isEmptyNullOrUndefinedArray(
        rolesFound
      )
        ? 'none'
        : 'block';
    }
  }
}
