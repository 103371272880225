import { Routes } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-core/guards/auth.guard';
import { OmissionInspectionComponent } from '@ic-pages/omission-inspection/omission-inspection.component';

import { OmissionOperationalModule } from './operational/omission-operational.module';
import { OmissionReportsModule } from './reports/omission-reports.module';
import { OmissionTrackingModule } from './tracking/omission-tracking.module';

export const OMISSION_INSPECTION_ROUTE: Routes = [
  {
    path: '',
    component: OmissionInspectionComponent,
    data: {
      role: [Authority.OMISSION, Authority.OMISSION_READER],
      title: 'sidebar.menu.label.omission-inspection.operational'
    },
    canActivate: [CustomAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'omissioninspection/operational',
        pathMatch: 'full'
      },
      {
        path: 'operational',
        loadChildren: async (): Promise<typeof OmissionOperationalModule> =>
          (await import('./operational/omission-operational.module'))
            .OmissionOperationalModule
      },
      {
        path: 'tracking',
        loadChildren: async (): Promise<typeof OmissionTrackingModule> =>
          (await import('./tracking/omission-tracking.module'))
            .OmissionTrackingModule
      },
      {
        path: 'reports',
        loadChildren: async (): Promise<typeof OmissionReportsModule> =>
          (await import('./reports/omission-reports.module'))
            .OmissionReportsModule
      }
    ]
  }
];
