import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatCheckboxChange,
  _MatCheckboxBase
} from '@angular/material/checkbox';
import { ICheckBox } from '@ic-models/checkbox.model';

@Component({
  selector: 'ic-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent {
  @Input() checkboxObj: ICheckBox = { name: '' };
  @Input() color = '';
  @Input() hidden = false;
  @Input() labelPositionCheck =
    'before' as _MatCheckboxBase<MatCheckboxChange>['labelPosition'];
  @Input() checkboxFormControl: FormControl = new FormControl();

  @Output() checkboxChange = new EventEmitter<ICheckBox>();

  checkboxClicked(event: MatCheckboxChange): void {
    this.checkboxObj.checked = event.checked;
    this.checkboxChange.emit(this.checkboxObj);
  }
}
