import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyFontAwesomeModule } from '@ic-app/myfontawesome.module';
import { DirectivesModule } from '@ic-core/directives/directives.module';
import { MaterialModule } from '@ic-material/material.module';
import { OmissionOperationalComponent } from '@ic-pages/omission-inspection/operational/omission-operational.component';
import { OMISSION_OPERATIONAL_ROUTE } from '@ic-pages/omission-inspection/operational/omission-operational.routing';
import { SharedModule } from '@ic-shared/shared.module';

const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];
const PROJECT_MODULES = [
  MaterialModule,
  SharedModule,
  MyFontAwesomeModule,
  DirectivesModule
];

@NgModule({
  declarations: [OmissionOperationalComponent],
  exports: [OmissionOperationalComponent],
  imports: [
    ...ANGULAR_MODULES,
    RouterModule.forChild(OMISSION_OPERATIONAL_ROUTE),
    ...PROJECT_MODULES
  ]
})
export class OmissionOperationalModule {}
