<ic-icon-button
  [disabled]="false"
  [ariaLabel]="iconAriaLabel"
  [matIcon]="iconName"
  [matMenuTriggerFor]="menuOptions"
  [cssClasses]="cssClasses"
  (click)="$event.stopPropagation()"
>
</ic-icon-button>
<!-- <button
  mat-icon-button
  class="menu-options-button"
  [color]="color"
  [matMenuTriggerFor]="menuOptions"
  (click)="$event.stopPropagation()"
>
  <fa-icon size="lg" icon="{{ iconName }}"></fa-icon>
  <mat-icon>menu</mat-icon> -->
<!-- </button> -->
<mat-menu
  #menuOptions="matMenu"
  class="mat-menu-options-simple"
  [overlapTrigger]="false"
  xPosition="before"
>
  <div *ngFor="let optionsButton of optionsButtons; let i = index">
    <div
      icAccessControl
      [roles]="
        optionsButton && optionsButton.accessRoles
          ? optionsButton.accessRoles
          : []
      "
    >
      <button
        *ngIf="
          !optionsButton?.children &&
          !optionsButton?.children?.length &&
          optionsButton.action
        "
        type="button"
        mat-menu-item
        (click)="executeAction($event, optionsButton.action)"
      >
        <span>{{ optionsButton.btnLabel | translate }}</span>
      </button>
      <button
        *ngIf="
          optionsButton?.children &&
          optionsButton?.children?.length &&
          !optionsButton.action
        "
        type="button"
        mat-menu-item
        (click)="expandChild($event, optionsButton, i)"
      >
        <span [ngStyle]="{ display: 'inline-flex' }"
          >{{ optionsButton.btnLabel | translate }}
          <mat-icon
            [ngStyle]="{ color: 'black', 'font-size': '20px' }"
            [@indicatorRotate]="isExpanded(i) ? 'expanded' : 'collapsed'"
          >
            expand_more
          </mat-icon></span
        >
      </button>
    </div>
    <div *ngIf="isExpanded(i)">
      <button
        *ngFor="let child of optionsButton?.children"
        [ngStyle]="{ 'padding-left': 20 + 'px' }"
        type="button"
        mat-menu-item
        (click)="executeAction($event, child.action)"
      >
        <span>{{ child.btnLabel | translate }}</span>
      </button>
    </div>
  </div>
</mat-menu>
