import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { environment } from 'environments/environment';
import { Observable, map, catchError, throwError } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class QuicksightService {
  constructor(private http: HttpClient) {}

  getEmbedUrl(): Observable<string> {
    return this.http
      .get<string>(`${baseUrl}/quicksight/url`, {
        headers: HttpConstants.GET_HEADERS,
        observe: 'response',
        responseType: 'text' as 'json'
      })
      .pipe(
        map((resp: HttpResponse<string>) => {
          return resp.body as string;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al obtener la url para ir a quicksight:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
