import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  Self
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[decimalFormatter]'
})
export class DecimalFormatterDirective implements OnDestroy, AfterViewInit {
  private formatter: Intl.NumberFormat;
  private destroy$ = new Subject<void>();

  @Input() locale = 'es-ES';
  @Input() maximumFractionDigits = 2;

  constructor(@Self() private ngControl: NgControl) {
    this.formatter = new Intl.NumberFormat(this.locale, {
      maximumFractionDigits: this.maximumFractionDigits
    });
  }

  ngAfterViewInit(): void {
    this.setValue(this.formatPrice(this.ngControl.value as number));
    this.ngControl.control?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.updateValue.bind(this));
  }

  @HostListener('focus') onFocus(): void {
    this.setValue(this.unformatValue(this.ngControl.value as string));
  }

  @HostListener('blur') onBlur(): void {
    const value = this.ngControl.value as number;
    !!value && this.setValue(this.formatPrice(value));
  }

  updateValue(value: string): void {
    const inputVal = value;
    this.setValue(
      inputVal
        ? this.validateDecimalValue(inputVal.replace(/[^0-9.]/g, ''))
        : ''
    );
  }

  validateDecimalValue(v: string): string {
    // Comprobamos si el valor es un número válido o no
    if (Number.isNaN(Number(v))) {
      // quitamos el último carácter que es el que está invalidando el número
      const strippedValue = v.slice(0, v.length - 1);

      // si el valor sigue siendo inválido, entonces estamos en un escenario de copy/paste
      // y en ese caso simplemente seteamos el valor a vacío
      return Number.isNaN(Number(strippedValue)) ? '' : strippedValue;
    }
    return v;
  }

  formatPrice(v: number | bigint): string {
    return this.formatter.format(v);
  }

  unformatValue(v: string): string {
    return v.replace(/,/g, '');
  }

  setValue(v: number | string): void {
    this.ngControl.control?.setValue(v, { emitEvent: false });
  }

  ngOnDestroy(): void {
    this.setValue(this.unformatValue(this.ngControl.value as string));
    this.destroy$.next();
    this.destroy$.complete();
  }
}
