import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import {
  IComment,
  UserComment,
  UserCommentFilter
} from '@ic-models/prior-inspection/comment.model';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class CommentsService {
  constructor(private http: HttpClient) {}

  findCommentsByAdministrativeFileId(
    fileId: number,
    filter: UserCommentFilter,
    iteration: number
  ): Observable<IComment[]> {
    const req = {
      id: fileId,
      proceduresId: filter.proceduresId,
      userId: filter.userId,
      isPublic: filter.isPublic,
      isEmpty: filter.isEmpty,
      iteration: iteration
    };
    const options = createRequestOption(req);
    return this.http
      .get<IComment[]>(
        `${baseUrl}/comments/administrativeFileCommentsByFileId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IComment[]>) => resp.body as IComment[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los comentarios del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findCommentsByProcedureId(
    procedureId: number,
    filter: UserCommentFilter
  ): Observable<IComment[]> {
    const req = {
      id: procedureId,
      proceduresId: filter.proceduresId,
      userId: filter.userId,
      isPublic: filter.isPublic,
      isEmpty: filter.isEmpty
    };
    const options = createRequestOption(req);
    return this.http
      .get<IComment[]>(`${baseUrl}/comments/frbCommentsByProcedureId`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IComment[]>) => resp.body as IComment[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los comentarios del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/typedef */
  saveComment(userComment: UserComment): Observable<any> {
    const options = createRequestOption();

    return this.http
      .post(`${baseUrl}/comments/saveComment`, userComment, {
        params: options
      })
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar el comentario en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updateComment(userComment: UserComment, id: number): Observable<any> {
    const req = {
      id: id
    };
    const options = createRequestOption(req);

    return this.http
      .put(`${baseUrl}/comments/updateComment`, userComment, {
        params: options
      })
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el comentario en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deleteComment(id: number): Observable<any> {
    const req = {
      id: id
    };
    const options = createRequestOption(req);
    return this.http
      .get<any[]>(`${baseUrl}/comments/deleteComment`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar el comentario en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
  /* eslint-enable */
}
