import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import {
  IPpcComment,
  PpcUserCommentFilter
} from '@ic-models/prior-control/ppc-comment.model';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class PpcCommentsService {
  constructor(private http: HttpClient) {}

  findPpcCommentsByPpcAdministrativeFileId(
    ppcAdministrativeFileId: number,
    ppcFilter: PpcUserCommentFilter,
    iteration: number
  ): Observable<IPpcComment[]> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      ppcProceduresId: ppcFilter.ppcProceduresId,
      userId: ppcFilter.userId,
      isPublic: ppcFilter.isPublic,
      isEmpty: ppcFilter.isEmpty,
      iteration: iteration
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcComment[]>(
        `${baseUrl}/ppccomments/ppcAdministrativeFileCommentsByPpcAdministrativeFileId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IPpcComment[]>) => resp.body as IPpcComment[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los comentarios cpp del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findPpcCommentsByPpcProcedureId(
    ppcProcedureId: number,
    ppcFilter: PpcUserCommentFilter
  ): Observable<IPpcComment[]> {
    const req = {
      id: ppcProcedureId,
      ppcProceduresId: ppcFilter.ppcProceduresId,
      userId: ppcFilter.userId,
      isPublic: ppcFilter.isPublic,
      isEmpty: ppcFilter.isEmpty
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcComment[]>(`${baseUrl}/ppccomments/ppcCommentsByProcedureId`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IPpcComment[]>) => resp.body as IPpcComment[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los comentarios cpp del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
