import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  IAutoCompleteElement
} from '@ic-components/form/input-autocomplete/input-autocomplete.component';
import { AppConfigService } from '@ic-services/app-config.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class AutoCompleteResponsibleOUService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.responsibleAutocompleteOUData
        .filter((responsibleAutocompleteOUData: IAutoCompleteElement) => {
          //console.log(autocompleteOUData);
          return (
            responsibleAutocompleteOUData.name
              .toLowerCase()
              .includes(filter.searchValue.toLowerCase()) ||
            responsibleAutocompleteOUData.parentName
              ?.toLowerCase()
              .includes(filter.searchValue.toLowerCase()) ||
            responsibleAutocompleteOUData.code
              ?.toLowerCase()
              .includes(filter.searchValue.toLowerCase())
          );
        })
        .map((autocompleteOUData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteOUData.id,
            autocompleteOUData.name,
            autocompleteOUData.parentName,
            autocompleteOUData.code
          );
        })
    );
  }
}
