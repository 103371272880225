import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAutoCompleteElement,
  ICategoriesAutoCompleteElement
} from '@ic-app/components/form/input-autocomplete/input-autocomplete.component';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import { ISettingsCategoryAdministrativeFileTypeData } from '@ic-app/models/settings/prior-inspection/category-afts/category-aft.model';
import { ICategoryAdministrativeFileTypeLegalBases } from '@ic-app/models/settings/prior-inspection/category-afts/category-afts-legal-bases.model';
import { ICategoryAdministrativeFileTypesFilter } from '@ic-app/models/settings/prior-inspection/category-afts/category-afts-list-filter.model';
import { ICategoryAdministrativeFileTypesList } from '@ic-app/models/settings/prior-inspection/category-afts/category-afts-list.model';
import { ICategoryAdministrativeFileTypeDetailData } from '@ic-app/models/settings/prior-inspection/category-afts/detail/category-afts-detail.model';
import { ICategoryAftEntity } from '@ic-app/models/settings/prior-inspection/category-afts/detail/category-afts-entity.model';
import { IFileTypesTreeComponent } from '@ic-app/models/trees/file-types-tree.model';
import { AppConfigService } from '@ic-services/app-config.service';
import { environment } from 'environments/environment';
import { Observable, catchError, map, tap, throwError } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsPriorInspectionCategoryAFTsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Lista todas las categorías/tipos de expediente y/o las filtra
   * @param filter
   * @param pageNumber
   * @param pageSize
   * @param filteredEntityId
   * @returns
   */
  findCategoryAFTs(
    filter: ICategoryAdministrativeFileTypesFilter,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<ICategoryAdministrativeFileTypesList[]> {
    const req = {
      searchBox: filter.searchBox,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      categoryAFTType:
        !!filter.categoryAFTType && filter.categoryAFTType.length > 0
          ? JSON.stringify(filter.categoryAFTType)
          : undefined,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ICategoryAdministrativeFileTypesList[]>(
        `${baseUrl}/settingsPriorInspection/findCategoryAdministrativeFileTypes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ICategoryAdministrativeFileTypesList[]>) =>
            resp.body as ICategoryAdministrativeFileTypesList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las categorías/tipos de expediente FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Cuenta las categorías/tipos de expediente totales teniendo en cuenta los filtros
   * @param filter
   * @param mainTable
   * @param filteredEntityId
   * @returns
   */
  countCategoryAFTs(
    filter: ICategoryAdministrativeFileTypesFilter,
    mainTable: boolean,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      mainTable: mainTable,
      categoryAFTType:
        !!filter.categoryAFTType && filter.categoryAFTType.length > 0
          ? JSON.stringify(filter.categoryAFTType)
          : undefined,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(
        `${baseUrl}/settingsPriorInspection/countCategoryAdministrativeFileTypes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar las categorías/tipos de expediente de configuracion FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Funcionalidad para rellenar el autocomplete de categorias cuando se selecciona una entidad
   * @param entityId
   * @returns
   */
  loadCategoriesByEntityId(
    entityId: number
  ): Observable<ICategoriesAutoCompleteElement[] | undefined> {
    const req = {
      entityId: entityId
    };

    const options = createRequestOption(req);

    return this.http
      .get<ICategoriesAutoCompleteElement[]>(
        `${baseUrl}/autocompleteCategoriesByEntityId`,
        {
          params: options
        }
      )
      .pipe(
        tap((data: ICategoriesAutoCompleteElement[] | undefined) => {
          this.appConfig.autocompleteCategoriesByEntityIdData = [];
          Object.assign(
            this.appConfig.autocompleteCategoriesByEntityIdData,
            data
          );
        }),
        catchError((err: Error) => {
          console.error('ERROR AL OBTENER LAS CATEGORÍAS');
          throw err;
        })
      );
  }

  /**
   * Guarda/edita una categoria/tipo de expediente
   * @param categoryAFTData
   * @param isEdit
   * @param entityId
   * @returns
   */
  saveCategoryAFT(
    categoryAFTData: ISettingsCategoryAdministrativeFileTypeData,
    isEdit: boolean,
    entityId: number
  ): Observable<ISettingsCategoryAdministrativeFileTypeData> {
    const req = {
      isEdit: isEdit,
      entityId: entityId
    };

    const options = createRequestOption(req);

    return this.http
      .post<ISettingsCategoryAdministrativeFileTypeData>(
        `${baseUrl}/settingsPriorInspection/saveCategoryAFT`,
        categoryAFTData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: ISettingsCategoryAdministrativeFileTypeData) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al guardar la categoría/tipo de expediente de configuración FRB:',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al guardar la categoría/tipo de expediente de configuración FRB:',
              err.message
            );
            return new Error(
              'settings-prior-inspection.error-messages.save-category-aft'
            );
          });
        })
      );
  }

  /**
   * Devuelve los datos de la categoria/tipo de expediente a buscar para gestioar en el modelo
   * @param categoryAFTId
   * @returns
   */
  findCategoryAFTDetailById(
    categoryAFTId: number
  ): Observable<ICategoryAdministrativeFileTypeDetailData> {
    const req = {
      categoryAFTId: categoryAFTId
    };

    const options = createRequestOption(req);

    return this.http
      .get<ICategoryAdministrativeFileTypeDetailData>(
        `${baseUrl}/settingsPriorInspection/findCategoryAFTDetailById`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ICategoryAdministrativeFileTypeDetailData>) =>
            resp.body as ICategoryAdministrativeFileTypeDetailData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la categoria/tipo de expediente frb del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Devuelve los datos de la categoria/tipo de expediente a buscar para volver al panel de creacion
   * @param categoryAFTId
   * @returns
   */
  findCategoryAFTById(
    categoryAFTId: number
  ): Observable<ISettingsCategoryAdministrativeFileTypeData> {
    const req = {
      categoryAFTId: categoryAFTId
    };

    const options = createRequestOption(req);

    return this.http
      .get<ISettingsCategoryAdministrativeFileTypeData>(
        `${baseUrl}/settingsPriorInspection/findCategoryAFTById`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ISettingsCategoryAdministrativeFileTypeData>) =>
            resp.body as ISettingsCategoryAdministrativeFileTypeData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la categoria/tipo de expediente frb del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene las bases legales
   * @returns
   */
  getLegalBases(): Observable<ICategoryAdministrativeFileTypeLegalBases[]> {
    const options = createRequestOption();
    return this.http
      .get<ICategoryAdministrativeFileTypeLegalBases[]>(
        `${baseUrl}/settingsPriorInspection/getLegalBases`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ICategoryAdministrativeFileTypeLegalBases[]>) =>
            resp.body as ICategoryAdministrativeFileTypeLegalBases[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las bases legales:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Guarda en el modelo la categoría/tipo de expediente reorganizando la parte del arbol necesaria
   * @param treeData
   * @param aftId
   * @param legalSourceId
   * @returns
   */
  saveManageInModelAFT(
    aftTreeData: IFileTypesTreeComponent[],
    aftId: number,
    entityId: number,
    parentId: number,
    legalSourceId: number,
    customCode: string
  ): Observable<boolean> {
    const req = {
      aftId: aftId,
      entityId: entityId,
      parentId: parentId,
      legalSourceId: legalSourceId,
      customCode: customCode
    };

    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/settingsPriorInspection/saveManageInModelAFT`,
        aftTreeData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar la gestión en modelo de la categoría/tipo de expediente:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene las entidades en las que está la categoria/tipo de expediente por su id
   * @param categoryAftId
   * @param searchBox
   * @param pageNumber
   * @param pageSize
   * @param filteredEntityId
   * @returns
   */
  findEntitiesByCategoryAftId(
    categoryAftId: number,
    searchBox: string,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<ICategoryAftEntity[]> {
    const req = {
      categoryAftId: categoryAftId,
      searchBox: searchBox,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ICategoryAftEntity[]>(
        `${baseUrl}/settingsPriorInspection/findEntitiesByCategoryAftId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ICategoryAftEntity[]>) =>
            resp.body as ICategoryAftEntity[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las entidades de la categoría/tipo de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Cuenta las entidades en las que está presente la categoría/tipo de expediente
   * @param searchBox
   * @param categoryAftId
   * @param filteredEntityId
   * @returns
   */
  countEntitiesByCategoryAftId(
    searchBox: string,
    categoryAftId: number,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: searchBox,
      categoryAftId: categoryAftId,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(
        `${baseUrl}/settingsPriorInspection/countEntitiesByCategoryAftId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar las entidades de la categoría/tipo de expediente en configuracion FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Devuelve los ids de las entidades en las que está presente la categoría/tipo de expediente
   * @param categoryAftId
   * @returns
   */
  getEntitiesIdsByCategoryAftId(categoryAftId: number): Observable<number[]> {
    const req = {
      categoryAftId: categoryAftId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number[]>(
        `${baseUrl}/settingsPriorInspection/getEntitiesIdsByCategoryAftId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number[]>) => resp.body as number[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las entidades filtradas por el id de la categoria/tipo de expediente:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Funcionalidad para rellenar el autocomplete de categorias cuando se selecciona una entidad
   * @param entityId
   * @returns
   */
  loadAutocompleteEntitiesNotSelectedByEntitiesIds(
    entitiesIds: number[]
  ): Observable<IAutoCompleteElement[] | undefined> {
    const req = {
      entitiesIds: entitiesIds
    };

    const options = createRequestOption(req);

    return this.http
      .get<IAutoCompleteElement[]>(
        `${baseUrl}/autocompleteEntitiesNotSelectedByEntitiesIds`,
        {
          params: options
        }
      )
      .pipe(
        tap((data: IAutoCompleteElement[] | undefined) => {
          this.appConfig.autocompleteEntitiesNotSelectedByEntitiesIdsData = [];
          Object.assign(
            this.appConfig.autocompleteEntitiesNotSelectedByEntitiesIdsData,
            data
          );
        }),
        catchError((err: Error) => {
          console.error(
            'Error al obtener las entidades del autocomplete en categorias/tipo de expediente'
          );
          throw err;
        })
      );
  }

  /**
   * Guarda la copia de la categoría/tipo de expediente en el modelo para la entidad seleccionada y
   * reordena los tipos de expedientes/categorías que hagan falta
   * @param aftTreeData
   * @param aftId
   * @param entityId
   * @param parentId
   * @param legalSourceId
   * @param customCode
   * @returns
   */
  saveCopyCategoryAFT(
    aftTreeData: IFileTypesTreeComponent[],
    aftId: number,
    entityId: number,
    parentId: number,
    legalSourceId: number,
    customCode: string
  ): Observable<boolean> {
    const req = {
      aftId: aftId,
      entityId: entityId,
      parentId: parentId,
      legalSourceId: legalSourceId,
      customCode: customCode
    };

    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/settingsPriorInspection/saveCopyCategoryAFT`,
        aftTreeData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar copia de la categoría/tipo de expediente:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene la base legal perteneciente a la categoría/tipo de expediente de la entidad seleccionada
   * @param categoryAftId
   * @param entityId
   * @returns
   */
  getEntityLegalBase(
    categoryAftId: number,
    entityId: number
  ): Observable<number> {
    const req = {
      categoryAftId: categoryAftId,
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/settingsPriorInspection/getEntityLegalBase`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el id de la base legal de la entidad seleccionada:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los datos de la entidad filtrada
   * @param filteredEntityId
   * @returns
   */
  getFilteredEntityDataCategory(
    filteredEntityId: number
  ): Observable<ICategoryAftEntity> {
    const req = {
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ICategoryAftEntity>(
        `${baseUrl}/settingsPriorInspection/getFilteredEntityDataCategory`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ICategoryAftEntity>) =>
            resp.body as ICategoryAftEntity
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al cargar la entidad filtrada de la Categoría/Tipo de expediente:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Elimina el modelo de categoria/tipo de expediente de la categoria/tipo de expediente y entidad seleccionada
   * En el caso de ser categoria tambien elimina el modelo de sus hijos para dicha entidad
   * @param categoryAFTData
   * @param entityId
   * @returns
   */
  deleteCategoryAFTModelForCategoryAFTAndEntity(
    categoryAFTDetailData: ICategoryAdministrativeFileTypeDetailData,
    entityId: number
  ): Observable<ICategoryAdministrativeFileTypeDetailData[]> {
    const req = {
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .post<ICategoryAdministrativeFileTypeDetailData[]>(
        `${baseUrl}/settingsPriorInspection/deleteCategoryAFTModelForCategoryAFTAndEntity`,
        categoryAFTDetailData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ICategoryAdministrativeFileTypeDetailData[]>) =>
            resp.body as ICategoryAdministrativeFileTypeDetailData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al eliminar el modelo de la categoría/tipo de expediente para la entidad:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Carga la entidad que se ha eliminado el modelo de la categoría/tipo de expediente
   * @param categoryAftId
   * @param searchBox
   * @param pageNumber
   * @param pageSize
   * @param deletedEntityId
   * @returns
   */
  findDeletedEntityFromAftModel(
    categoryAftId: number,
    searchBox: string,
    pageNumber: number,
    pageSize: number,
    deletedEntityId: number
  ): Observable<ICategoryAftEntity[]> {
    const req = {
      categoryAftId: categoryAftId,
      searchBox: searchBox,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      deletedEntityId: deletedEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ICategoryAftEntity[]>(
        `${baseUrl}/settingsPriorInspection/findDeletedEntityFromAftModel`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ICategoryAftEntity[]>) =>
            resp.body as ICategoryAftEntity[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las entidades de la categoría/tipo de expediente del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Rehabilita el modelo de categoria/tipo de expediente de la categoria/tipo de expediente y entidad seleccionada
   * En el caso de ser categoria tambien rehabilita el modelo de sus hijos para dicha entidad
   * @param categoryAFTData
   * @param entityId
   * @returns
   */
  undoDeletedCategoryAFTModel(
    deletedCategoryAftTree: ICategoryAdministrativeFileTypeDetailData[],
    entityId: number
  ): Observable<boolean> {
    const req = {
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/settingsPriorInspection/undoDeletedCategoryAFTModel`,
        deletedCategoryAftTree,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al recuperar el modelo de la categoría/tipo de expediente para la entidad:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
