/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SettingsPriorInspectionItems } from '@ic-app/models/settings/prior-inspection/items/settings-prior-inspection-items.model';

export interface IIconActionEmit {
  id: number;
  action: number;
  currentNode: SettingsPriorInspectionItems;
}

@Component({
  selector: 'ic-tree-icon-button',
  templateUrl: './tree-icon-button.component.html'
})
export class TreeIconButtonComponent {
  @Input() currentNode?: any;
  @Input() actionButton?: number;
  @Input() iconName?: string;
  @Input() titleIcon?: string;
  @Input() matIconClass?: string;
  @Output() executedActionIcons = new EventEmitter<IIconActionEmit>();

  executeActionIcons(): void {
    this.executedActionIcons.emit({
      id: this.currentNode.id,
      action: this.actionButton as number,
      currentNode: this.currentNode
    });
  }
}
