import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PanelService } from '@ic-services/panel.service';

@Component({
  selector: 'ic-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @Input() mode = '';

  @ViewChild('panel') panelRef: MatSidenav | undefined;

  panelWidth = '';

  constructor(private panelService: PanelService) {}

  ngOnInit(): void {
    this.getPanelWidth();
    this.listenOnSidenavOpen();
    this.listenOnSidenavClose();
  }

  ngAfterViewInit(): void {
    this.listenOnMatSidenavCloseFinish();
  }

  getPanelWidth(): void {
    this.panelService.panelWidth$.subscribe((value: string) => {
      this.panelWidth = value;
    });
  }

  listenOnSidenavOpen(): void {
    this.panelService.open$.subscribe(() => this.panelRef?.open());
  }

  listenOnSidenavClose(): void {
    this.panelService.close$.subscribe(() => this.panelRef?.close());
  }

  listenOnMatSidenavCloseFinish(): void {
    this.panelRef?.openedChange.subscribe((status: boolean) => {
      if (!status) {
        this.panelService.detachComponent();
      }
    });
  }
}
