import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessControlDirective } from './access-control.directive';
import { AutoSelectValueDirective } from './auto-select-value.directive';
import { CurrencyInputDirective } from './currency-input.directive';
import { DecimalFormatterDirective } from './decimal-formatter.directive';
import { DragndropDirective } from './dragndrop.directive';

@NgModule({
  declarations: [
    AutoSelectValueDirective,
    DecimalFormatterDirective,
    CurrencyInputDirective,
    DragndropDirective,
    AccessControlDirective
  ],
  exports: [
    AutoSelectValueDirective,
    DecimalFormatterDirective,
    CurrencyInputDirective,
    AccessControlDirective
  ],
  imports: [CommonModule]
})
export class DirectivesModule {}
