import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyFontAwesomeModule } from '@ic-app/myfontawesome.module';
import { DirectivesModule } from '@ic-core/directives/directives.module';
import { MaterialModule } from '@ic-material/material.module';
import { SharedModule } from '@ic-shared/shared.module';

import { PriorOperationalComponent } from './prior-operational.component';
import { PPC_OPERATIONAL_ROUTE } from './prior-operational.routing';

const PROJECT_COMPONENTS = [PriorOperationalComponent];
const PROJECT_MODULES = [
  MaterialModule,
  SharedModule,
  MyFontAwesomeModule,
  DirectivesModule
];
const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [...PROJECT_COMPONENTS],
  exports: [...PROJECT_COMPONENTS],
  imports: [
    ...ANGULAR_MODULES,
    RouterModule.forChild(PPC_OPERATIONAL_ROUTE),
    ...PROJECT_MODULES
  ]
})
export class PriorOperationalModule {}
