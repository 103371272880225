import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  IAutoCompleteElement
} from '@ic-components/form/input-autocomplete/input-autocomplete.component';
import { AppConfigService } from '@ic-services/app-config.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class AutoCompleteBudgetaryItemService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteBudgetaryItemData
        .filter((autocompleteBudgetaryData: IAutoCompleteElement) => {
          //console.log(autocompleteOUData);
          return autocompleteBudgetaryData.name
            .toLowerCase()
            .includes(filter.searchValue.toLowerCase());
        })
        .map((autocompleteBudgetaryData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteBudgetaryData.id,
            autocompleteBudgetaryData.name,
            autocompleteBudgetaryData.parentName
          );
        })
    );
  }

  getDataById(id: number): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteBudgetaryItemData
        .filter(
          (autocompleteBudgetaryData: IAutoCompleteElement) =>
            //console.log(autocompleteOUData);
            // .filter((element) => element.id != node.id)
            autocompleteBudgetaryData.id === id
        )
        .map((autocompleteBudgetaryData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteBudgetaryData.id,
            autocompleteBudgetaryData.name
          );
        })
    );
  }
}
