<div class="searchbox-filter" #container>
  <div class="searchbox-filter-searchbox">
    <!-- <mat-icon></mat-icon> -->
    <fa-icon size="lg" icon="search" [classes]="['ic-mr-16']"></fa-icon>
    <!-- <ntx-input-no-label [placeholder]="'Buscar notificación'"></ntx-input-no-label> -->
    <input
      type="text"
      [placeholder]="placeHolderText"
      [formControl]="searchboxInputFormControl"
      (input)="getVal($event)"
      class="searchbox-filter-input ic-text-16 ic-text-grey-600"
    />
    <ic-icon-button
      #filterButton
      [disabled]="show"
      [fontAwesome]="false"
      [matIcon]="'filter_alt'"
      (icClick)="openPanel()"
    ></ic-icon-button>
  </div>
  <div *ngIf="show" class="searchbox-filter-panel ic-margin-top-4">
    <div class="searchbox-filter-panel-content ic-margin-top-4">
      <div #contentPanelRef>
        <ng-content></ng-content>
      </div>
      <div
        *ngIf="!contentPanelRef.innerHTML.trim()"
        class="placeholder ic-align-center-text ic-basic-text"
      >
        /** area para el filtro específico **/
      </div>
    </div>
  </div>
</div>
