import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appDrawer?: any;
  currentUrl = new BehaviorSubject<string>('');
  menuTitle = new BehaviorSubject<string>('');

  menuTitleChange$ = this.menuTitle.asObservable();

  constructor(private router: Router, private http: HttpClient) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  updateCurrentUrl(): void {
    this.currentUrl.next(this.currentUrl.getValue());
  }

  updateTitle(title: string): void {
    this.menuTitle.next(title);
  }

  closeNav(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.appDrawer?.close();
  }

  toggleNav(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.appDrawer?.toggle();
  }

  openNav(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.appDrawer?.open();
  }
}
