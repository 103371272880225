export interface ISelectOption {
  id: number;
  text: string;
  code?: number;
  tooltip?: string;
}

export class SelectOption implements ISelectOption {
  id: number;
  text: string;
  code?: number;
  tooltip?: string;
  constructor(value: number, display: string, code?: number, tooltip?: string) {
    this.id = value;
    this.text = display;
    this.code = code;
    this.tooltip = tooltip;
  }
}
