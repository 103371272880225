<mat-table
  [dataSource]="dataSource"
  class="ic-detail-table mat-elevation-z8"
  matSort
  [matSortActive]="defaultSortColumn"
  [matSortDirection]="defaultSortDirection"
  matSortDisableClear
  multiTemplateDataRows
>
  <ng-container *ngIf="checks" matColumnDef="selection">
    <mat-header-cell
      *matHeaderCellDef
      class="ic-detail-cell-3 ic-detail-center"
    >
      <mat-checkbox
        (change)="onHeaderCheckboxChange($event)"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [color]="'primary'"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-detail-cell-3 ic-detail-center">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="onRowCheckboxChange($event, row)"
        [checked]="selection.isSelected(row)"
        [color]="'primary'"
        [(ngModel)]="row.checked"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container
    *ngFor="let customColumn of customColumns"
    matColumnDef="{{ customColumn.columnDefinition }}"
  >
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [disabled]="!customColumn.sort"
      [ngClass]="customColumn.headerCellClass"
    >
      {{ customColumn.headerDefinition }}
    </mat-header-cell>

    <!-------------- Elementos a mostrar en función del tipo de dato de la columna -------------->

    <!-- Tipo genérico (textos y números simples) -->
    <div
      *ngIf="!customColumn.currency && !customColumn.byte && !customColumn.date"
    >
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.elementAuxTooltip ?? ''] &&
          !element[customColumn.elementTooltip ?? '']
            ? (element[customColumn.elementAuxTooltip ?? '']
              | translate
                : {
                    availableWorkingDays:
                      element[customColumn.availableWorkingDays ?? '']
                  })
            : !customColumn.multipleLevelTooltip
            ? (element[customColumn.elementTooltip ?? '']
              | translate
                : {
                    availableWorkingDays:
                      element[customColumn.availableWorkingDays ?? '']
                  })
            : element[customColumn.elementTooltip ?? ''][
                customColumn.multipleLevelTooltip
              ]
        }}"
      >
        <span *ngIf="!customColumn.secondLevelElement">
          {{ element[customColumn.element ?? ''] | translate }}</span
        >
        <span *ngIf="customColumn.secondLevelElement">
          {{
            element[customColumn.element ?? ''][customColumn.secondLevelElement]
          }}</span
        >

        <!-- Icono con colores fijos -->
        <mat-icon
          *ngIf="element[customColumn.icon ?? '']"
          [color]="customColumn.iconColor"
        >
          {{ customColumn.iconName }}
        </mat-icon>

        <!-- Icono fijo con colores variables -->
        <mat-icon
          *ngIf="customColumn.fixedIcon && !element[customColumn.hide ?? '']"
          [color]="element[customColumn.iconColor ?? '']"
        >
          {{ customColumn.iconName }}
        </mat-icon>

        <!-- <span md-colors="{color:'{{ element[customColumn.iconColor] }}'}"
        *ngIf="customColumn.fixedIcon && element[customColumn.hide]
        ">
          {{ element[customColumn.workingDays] }}
        </span> -->
        <span
          [class]="element[customColumn.iconColor ?? '']"
          *ngIf="customColumn.fixedIcon && element[customColumn.hide ?? '']"
          ><strong>
            {{ element[customColumn.workingDays ?? ''] }}
          </strong>
        </span>

        <!-- Icono variable con colores variables -->
        <mat-icon
          *ngIf="customColumn.multipleIcon"
          [color]="element[customColumn.iconColor ?? '']"
        >
          {{ element[customColumn.iconName ?? ''] }}
        </mat-icon>

        <!-- Icono personalizado con colores variables -->
        <mat-icon
          *ngIf="customColumn.personalIcon"
          [color]="element[customColumn.iconColor ?? '']"
          [svgIcon]="element[customColumn.iconName ?? '']"
        >
        </mat-icon>
      </mat-cell>
    </div>

    <!-- Fechas con hora -->
    <div *ngIf="customColumn.date && customColumn.dateHour">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.element ?? ''] | date : 'dd/MM/yyyy HH:mm'
        }}"
      >
        {{ element[customColumn.element ?? ''] | date : 'dd/MM/yyyy HH:mm' }}
      </mat-cell>
    </div>

    <!-- Fechas sin hora -->
    <div *ngIf="customColumn.date && !customColumn.dateHour">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.element ?? ''] | date : 'dd/MM/yyyy'
        }}"
      >
        {{ element[customColumn.element ?? ''] | date : 'dd/MM/yyyy' }}
      </mat-cell>
    </div>

    <!-- Cifras monetarias en euros -->
    <div *ngIf="customColumn.currency">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{
          element[customColumn.element ?? ''] | currency : 'EUR'
        }}"
      >
        {{ element[customColumn.element ?? ''] | currency : 'EUR' }}
      </mat-cell>
    </div>

    <!-- Tamaño en bytes -->
    <div *ngIf="customColumn.byte">
      <mat-cell
        *matCellDef="let element"
        [class]="customColumn.cellClass ?? ''"
        [ngClass]="{ 'text-bold': element[customColumn.bold ?? ''] }"
        matTooltip="{{ element[customColumn.element ?? ''] | filesize }}"
      >
        {{ element[customColumn.element ?? ''] | filesize }}
      </mat-cell>
    </div>
  </ng-container>

  <!-------------- Fin de formato de elementos -------------->

  <!-- Columna con opciones -->
  <ng-container *ngIf="optionButtons" matColumnDef="actions" stickyEnd>
    <mat-header-cell *matHeaderCellDef class="ic-detail-cell-5">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-detail-cell-5">
      <ic-menu-options
        [optionsButtons]="row.actions"
        [color]="'accent'"
        [iconName]="'list'"
        (actionClicked)="clickedOption($event, row)"
      ></ic-menu-options>
    </mat-cell>
  </ng-container>

  <!-- Columna con opcion de cambiar el orden especifica para observaciones -->
  <ng-container
    *ngIf="observationsSelected"
    matColumnDef="observationsSelected"
    stickyEnd
  >
    <mat-header-cell *matHeaderCellDef class="ic-detail-cell-15">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-detail-cell-15">
      <label (click)="$event.stopPropagation()">
        <mat-icon (click)="changeOrderUp(row)"> arrow_circle_up </mat-icon>
      </label>
      <label (click)="$event.stopPropagation()">
        <mat-icon (click)="changeOrderDown(row)"> arrow_circle_down </mat-icon>
      </label>
      <label *ngIf="row.note" (click)="$event.stopPropagation()">
        <mat-icon
          [title]="'components.table.observations.edit-note' | translate"
          (click)="editRow(row, $event)"
        >
          description
        </mat-icon>
      </label>
      <label *ngIf="!row.note" (click)="$event.stopPropagation()">
        <mat-icon
          class="material-icons-outlined"
          [title]="'components.table.observations.add-note' | translate"
          (click)="editRow(row, $event)"
        >
          description
        </mat-icon>
      </label>
      <label (click)="$event.stopPropagation()">
        <mat-icon
          [title]="
            'components.table.observations.remove-observation' | translate
          "
          (click)="deleteRow(row)"
        >
          close
        </mat-icon>
      </label>
    </mat-cell>
  </ng-container>

  <!-- Columna observaciones de ppc guardadas en el informe cpp -->
  <ng-container
    *ngIf="addActionsTable"
    matColumnDef="addActionsTable"
    stickyEnd
  >
    <mat-header-cell *matHeaderCellDef class="ic-detail-cell-15">
      {{ 'components.table.actions' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="ic-detail-cell-15">
      <label style="cursor: pointer" *ngFor="let action of row.actions">
        <mat-icon
          [ngClass]="action.class"
          [title]="action.title | translate"
          (click)="executeActionIcons(row, action.action, $event)"
        >
          {{ action.iconName }}
        </mat-icon>
      </label>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div
        class="element-detail"
        *ngIf="element.expandedElement && element.note"
      >
        <mat-icon [title]="'components.table.observations.note' | translate">
          description
        </mat-icon>
        <label>
          {{ element.note }}
        </label>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
    [ngClass]="rowClass"
  ></mat-header-row>
  <mat-row
    (click)="expandDetail(element)"
    *matRowDef="let element; columns: displayedColumns"
    [ngClass]="rowClass"
  >
  </mat-row>

  <mat-row
    *matRowDef="let element; columns: ['expandedDetail']"
    class="note-expanded note-color"
    [class]="rowClass ?? ''"
    [ngClass]="{ 'non-detail-row': !element.note || !element.expandedElement }"
  ></mat-row>
</mat-table>
<div
  class="empty-row"
  *ngIf="
    ((dataSource.loading$ | async) === false ||
      (dataSource.loading$ | async) === null ||
      (dataSource.loading$ | async) === undefined) &&
    getData(dataSource).length === 0 &&
    emptyMessage
  "
>
  {{ emptyMessage }}
</div>

<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<mat-paginator
  *ngIf="paginatorActivated"
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  showFirstLastButtons
></mat-paginator>
