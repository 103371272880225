/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig
} from '@angular/material/dialog';
import { InputMaxSizes } from '@ic-app/constants/common.constants';

@Component({
  selector: 'ic-dialog-input',
  templateUrl: './dialog-input.component.html'
})
export class DialogInputComponent implements OnInit {
  dialogInputForm?: FormGroup;
  controls: any;
  posTop = 0;
  posLeft = 0;
  readonly inputMaxSizes = InputMaxSizes;

  private readonly triggerElementRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogInputComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      subtitle?: string;
      buttonCancel: string;
      buttonAccept: string;
      buttonAcceptAndAction: string;
      trigger?: ElementRef;
      input?: any;
    },
    private fb: FormBuilder
  ) {
    this.triggerElementRef = data.trigger as ElementRef;
  }

  ngOnInit(): void {
    const rect =
      this.triggerElementRef.nativeElement.getBoundingClientRect() as DOMRect;
    if (!this.data.subtitle) {
      this.data.subtitle = this.data.title;
    }
    this.crearFormulario();
    this.posTop = rect.top;
    this.posLeft = rect.left;
    this.positionDialog();
  }

  positionDialog(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    // const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    const pos = this.posDialog();
    matDialogConfig.position = {
      left: `${pos.left}px`,
      top: `${pos.top}px`
    };
    this.dialogRef.updatePosition(matDialogConfig.position);
    this.dialogRef.updateSize('700px', '550px');
  }

  posDialog(): { left: number; top: number } {
    this.posLeft -= 700;
    const ret = { left: this.posLeft, top: this.posTop };
    const posBottom = this.posTop + 550;
    if (window.innerHeight < posBottom)
      ret.top -= posBottom - window.innerHeight;
    return ret;
  }

  crearFormulario(): void {
    this.dialogInputForm = this.fb.group({
      input: '',
      hasSigns: [false],
      signs: this.fb.array([])
    });
    this.initControlsObject();
    if (this.data.input) {
      this.controls.input.value = this.data.input;
    }
  }

  initControlsObject(): void {
    this.controls = {
      input: this.dialogInputForm?.get('input')
    };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cancel($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: false });
  }

  accept($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: true, input: this.controls.input.value });
  }
}
