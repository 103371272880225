import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { INavItem } from '@ic-models/nav-item.model';
import { NavService } from '@ic-services/nav.service';

@Component({
  selector: 'ic-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      )
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded = true;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item?: INavItem;
  @Input() depth = 0;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  faCog = 'fa-cog' as IconName;

  constructor(public navService: NavService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit(): void {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item && this.item.route && this.item.route && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  /**
   * Se ejecuta al seleccionar un elemento del menú
   * @param item
   */
  onItemSelected(item: INavItem | undefined): void {
    // Si el elemento del menú no tiene hijos y no tiene que expandirse,
    // navegamos a la ruta indicada en el NavItem y cerramos el menú
    if (item && (!item.children || !item.children.length)) {
      this.router.navigate([item.route]);
      // this.navService.updateTitle(item.displayName);
      // this.navService.closeNav();
    }
    // Si el elemento del menú seleccionado tiene hijos, expandimos
    // el submenú asociado
    if (item && item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
