import { Routes } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-core/guards/auth.guard';

import { PriorControlComponent } from './prior-control.component';
import { PriorOperationalModule } from './prior-operational/prior-operational.module';
import { PriorReportsModule } from './prior-reports/prior-reports.module';

export const PRIOR_CONTROL_ROUTE: Routes = [
  {
    path: '',
    component: PriorControlComponent,
    data: {
      role: [Authority.INSPECTOR, Authority.PPC, Authority.PPC_READER],
      title: 'sidebar.menu.label.prior-control.operational'
    },
    canActivate: [CustomAuthGuard],
    children: [
      {
        path: 'operational',
        loadChildren: async (): Promise<typeof PriorOperationalModule> =>
          (await import('./prior-operational/prior-operational.module'))
            .PriorOperationalModule
      },
      {
        path: 'reports',
        loadChildren: async (): Promise<typeof PriorReportsModule> =>
          (await import('./prior-reports/prior-reports.module'))
            .PriorReportsModule
      }
    ]
  }
];
