export interface ITenantEntitiesTreeComponent {
  id: number;
  name: string;
  isSelectable: boolean;
  children?: ITenantEntitiesTreeComponent[] | null;
}

export class TenantEntitiesTreeComponent
  implements ITenantEntitiesTreeComponent
{
  constructor(
    public id: number,
    public name: string,
    public isSelectable: boolean = true,
    public children?: ITenantEntitiesTreeComponent[] | null
  ) {}
}
