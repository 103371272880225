import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'ic-date-picker',
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements OnInit {
  @Input() placeHolderText = '';
  @Input() range?: boolean;
  @Input() disabledInput = false;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() inputFormControl?: FormControl;
  @Input() inputFormControlStart?: FormControl;
  @Input() inputFormControlEnd?: FormControl;
  @Input() hideClearButton = false;
  @Input() important = false;

  appearance: MatFormFieldAppearance = 'fill';
  isNotEmpty?: boolean;
  // showButton = false;

  constructor(private dateAdapter: DateAdapter<Date>) {}

  ngOnInit(): void {
    // Ojo al rawValue cuando se acceda al valor del formulario
    if (this.range && this.disabledInput) {
      this.initIsNotEmpty();
      this.inputFormControlStart?.disable();
      this.inputFormControlEnd?.disable();
      this.inputFormControlStart?.valueChanges.subscribe((value: string) =>
        this.setIsNotEmpty(value)
      );
      this.inputFormControlEnd?.valueChanges.subscribe((value: string) =>
        this.setIsNotEmpty(value)
      );
    } else if (!this.range && this.disabledInput) {
      this.inputFormControl?.disable();
    }

    // Configuramos el calendario para que la semana empiece en lunes y no en domingo
    this.dateAdapter.getFirstDayOfWeek = (): number => {
      return 1;
    };
  }

  clearDate(event: Event): void {
    event.stopPropagation();
    if (this.range) {
      this.inputFormControlStart?.setValue('');
      this.inputFormControlEnd?.setValue('');
      // this.showButton = false;
    } else {
      this.inputFormControl?.setValue('');
    }
  }

  onDateInput(): void {
    // this.inputFormControlStart?.markAllAsTouched();
    // this.inputFormControlEnd?.markAllAsTouched();
    // this.inputFormControlStart?.enable();
    // this.inputFormControlEnd?.enable();
    // setTimeout(() => {
    //   this.inputFormControlStart?.disable({ onlySelf: true, emitEvent: false });
    //   this.inputFormControlEnd?.disable({ onlySelf: true, emitEvent: false });
    // }, 500);
  }

  private initIsNotEmpty(): void {
    const formControlStartValue = this.inputFormControlStart?.value as string;
    const formControlEndValue = this.inputFormControlEnd?.value as string;
    if (
      (formControlStartValue !== undefined &&
        formControlStartValue !== null &&
        formControlStartValue !== '') ||
      (formControlEndValue !== undefined &&
        formControlEndValue !== null &&
        formControlEndValue !== '')
    ) {
      this.isNotEmpty = true;
    } else {
      this.isNotEmpty = false;
    }
  }

  private setIsNotEmpty(value: string): void {
    if (value !== undefined && value !== null && value !== '') {
      this.isNotEmpty = true;
    } else {
      this.isNotEmpty = false;
    }
  }
}
