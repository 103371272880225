import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ic-dialog-pdf',
  templateUrl: './dialog-pdf.component.html'
})
export class DialogPDFComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogPDFComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string;
      file: string;
      buttonCancel: string;
      buttonAccept: string;
      buttonAcceptAndAction: string;
    }
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('50%', '80%');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  cancel($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: false });
  }

  accept($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: true, action: false });
  }

  acceptAndAction($event: Event): void {
    $event.preventDefault();
    this.dialogRef.close({ result: true, action: true });
  }
}
