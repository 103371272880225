<div icAccessControl [roles]="item ? item.roles : []">
  <div *ngIf="item && item.splitLine" class="horizontal-line"></div>
  <a
    mat-list-item
    [ngStyle]="{ 'padding-left': depth * 0.5 + 'em' }"
    (click)="onItemSelected(item)"
    [ngClass]="{
      active: item && item.route ? router.isActive(item.route, true) : false,
      expanded: expanded
    }"
    class="menu-list-item"
  >
    <div class="list-component-row" title="{{ item?.title || '' }}">
      <div class="list-component-block">
        <fa-layers class="menu-icon">
          <fa-icon
            *ngIf="!item?.iconType"
            [icon]="item?.iconName || faCog"
          ></fa-icon>
          <!-- Si la librería de iconos no es la sólida por defecto se elige la librería específica -->
          <fa-icon
            *ngIf="item?.iconType"
            [icon]="[item?.iconType || 'fab', item?.iconName || faCog]"
          ></fa-icon>
          <fa-icon *ngIf="item?.slash" size="1x" icon="slash"></fa-icon>
        </fa-layers>
      </div>
      <div class="list-component-block">
        {{ item?.displayName || '' }}
      </div>
    </div>
    <span *ngIf="item?.children && item?.children?.length">
      <!-- <span fxFlex></span> -->
      <mat-icon
        [ngStyle]="{ color: 'white' }"
        [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"
      >
        expand_more
      </mat-icon>
    </span>
  </a>
  <div *ngIf="expanded">
    <ic-menu-list-item
      *ngFor="let child of item?.children"
      [item]="child"
      [depth]="depth + 1"
    >
    </ic-menu-list-item>
  </div>
</div>
