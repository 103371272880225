import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class StateStorageService {
  private previousUrlKey = 'previousUrl';

  constructor(private $sessionStorage: SessionStorageService) {}

  storeUrl(url: string): void {
    this.$sessionStorage.store(this.previousUrlKey, url);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storeUrlWithData(urlData: any): void {
    this.$sessionStorage.store(this.previousUrlKey, urlData);
  }

  getUrl(): string | null | undefined {
    return this.$sessionStorage.retrieve(this.previousUrlKey) as
      | string
      | null
      | undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUrlWithData(): any | null | undefined {
    return this.$sessionStorage.retrieve(this.previousUrlKey);
  }

  clearUrl(): void {
    this.$sessionStorage.clear(this.previousUrlKey);
  }
}
