import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import { IObservationList, IObservationsToEdit } from '@ic-models/observation';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class ObservationsService {
  constructor(private http: HttpClient) {}

  getObservationsByAdministrativeFile(
    administrativeFileId: number
  ): Observable<IObservationList[]> {
    const req = {
      administrativeFileId: administrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationList[]>(`${baseUrl}/observations`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IObservationList[]>) =>
            resp.body as IObservationList[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las observaciones del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOmissionObservationsByAdministrativeFile(
    administrativeFileId: number,
    inspectionOmissionProcedureId: number
  ): Observable<IObservationList[]> {
    const req = {
      administrativeFileId: administrativeFileId,
      inspectionOmissionProcedureId: inspectionOmissionProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationList[]>(`${baseUrl}/observations/omission`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IObservationList[]>) =>
            resp.body as IObservationList[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las observaciones del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getObservationsToEditByAdministrativeFile(
    administrativeFileId: number,
    inspectionProcedureId: number
  ): Observable<IObservationsToEdit> {
    const req = {
      administrativeFileId: administrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationsToEdit>(
        `${baseUrl}/observations/${inspectionProcedureId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationsToEdit>) =>
            resp.body as IObservationsToEdit
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las observaciones del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOmissionObservationsToEditByAdministrativeFile(
    administrativeFileId: number,
    inspectionOmissionProcedureId: number
  ): Observable<IObservationsToEdit> {
    const req = {
      administrativeFileId: administrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationsToEdit>(
        `${baseUrl}/observations/omission/${inspectionOmissionProcedureId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationsToEdit>) =>
            resp.body as IObservationsToEdit
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las observaciones servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getObservationsFromParentByAdministrativeFile(
    administrativeFileId: number
  ): Observable<IObservationsToEdit> {
    return this.http
      .get<IObservationsToEdit>(
        `${baseUrl}/observations/parent/${administrativeFileId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationsToEdit>) =>
            resp.body as IObservationsToEdit
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las observaciones del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
