import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import {
  EntityDetailData,
  IEntityDetailData
} from '@ic-app/models/settings/entities/detail/entity-detail.model';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

import { AppConfigService } from '../app-config.service';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsEntitiesService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Devuelve los datos pertenecientes a la entidad que se pasa
   * @param entityId
   * @returns
   */
  findEntityDetailById(entityId: number): Observable<IEntityDetailData> {
    const req = {
      entityId: entityId
    };

    const options = createRequestOption(req);

    return this.http
      .get<IEntityDetailData>(`${baseUrl}/settings/findEntityDetailById`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IEntityDetailData>) =>
            resp.body as IEntityDetailData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al cargar la entidad de configuración:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Modifica la entidad
   * @param entityData
   * @returns
   */
  editEntity(entityData: EntityDetailData): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/settings/editEntity`, entityData)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al modificar la entidad en el servidor:',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al modificar la entidad en el servidor:',
              err.message
            );
            return new Error('settings.error-messages.edit-entity');
          });
        })
      );
  }
}
