import { CollectionViewer } from '@angular/cdk/collections';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import {
  convertChildrenFileTypeItemsTreeToFileTypeItemsTreeComponent,
  convertFileTypeItemsRootNodesToFileTypeItemsTreeComponent
} from '@ic-core/util/tree-util';
import { IFileTypeItemsTree } from '@ic-models/tree.model';
import { AppConfigService } from '@ic-services/app-config.service';
import { TreeService } from '@ic-services/tree.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { IFileTypeItemsTreeComponent } from './file-type-items-tree.model';

export class FileTypeItemsTreeDataSource extends MatTreeNestedDataSource<IFileTypeItemsTreeComponent> {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();

  private _treeData = new BehaviorSubject<IFileTypeItemsTreeComponent[]>([]);

  override get data(): IFileTypeItemsTreeComponent[] {
    return this._treeData.value;
  }

  override set data(value: IFileTypeItemsTreeComponent[]) {
    this._treeData.next(value);
  }

  // _data = new BehaviorSubject<IFileTypeItemsTreeComponent[]>([]);

  // get data(): IFileTypeItemsTreeComponent[] {
  //   return this._data.value;
  // }
  // set data(value: IFileTypeItemsTreeComponent[]) {
  //   this._data.next(value);
  // }

  constructor(
    private treeService: TreeService,
    private appConfig: AppConfigService,
    private translateService: TranslateService
  ) {
    super();
  }

  loadRootNodes(fileTypeNumber: number): void {
    this.treeService
      .getFileTypeItemsRootNodes(fileTypeNumber)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((inputTreesSource: IFileTypeItemsTree[]) => {
        this.data = convertFileTypeItemsRootNodesToFileTypeItemsTreeComponent(
          inputTreesSource,
          this.translateService
        );
      });
  }

  loadTree(node: IFileTypeItemsTreeComponent): void {
    this.loadingSubject.next(true);
    this.treeService
      .getFileTypeItemsTreeData(node.id as number)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((inputTreesSource: IFileTypeItemsTree[]) => {
        this.data =
          convertChildrenFileTypeItemsTreeToFileTypeItemsTreeComponent(
            this._treeData.value,
            inputTreesSource
          );
        // TODO: No entiendo estas tres líneas inferiores, checkear que funciona correctamente
        const dataTemp = this.data;
        this.data = [];
        this.data = dataTemp;
      });
  }

  override connect(
    collectionViewer: CollectionViewer
  ): Observable<IFileTypeItemsTreeComponent[]> {
    super.connect(collectionViewer);
    return this._treeData.asObservable();
  }

  override disconnect(): void {
    super.disconnect();
    this._treeData.complete();
    this.loadingSubject.complete();
  }
}
