import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { isNullOrUndefined } from '@ic-core/util/global-util';
import { createRequestOption } from '@ic-core/util/request-util';
import { IPpcApplicableRegulationsReportList } from '@ic-models/ppc-applicable-regulations/ppc-applicable-regulations';
import { IPpcLegalProvisionsReportList } from '@ic-models/ppc-legal-provisions/ppc-legal-provisions';
import { PpcItemCommentToSave } from '@ic-models/prior-operational/ppc-report/ppc-item-comment/ppc-item-comment';
import { PpcObservationToSave } from '@ic-models/prior-operational/ppc-report/ppc-observations/ppc-observation';
import { PpcReportToSave } from '@ic-models/prior-operational/ppc-report/ppc-report';
import { PpcReportToSaveDTO } from '@ic-models/prior-operational/ppc-report/ppc-report.model';
import { PpcSubjectiveImprovementToSave } from '@ic-models/prior-operational/ppc-report/ppc-subjective-improvement/ppc-subjective-improvement';
import { IPpcSupportedReportConclusion } from '@ic-models/prior-operational/ppc-supported-report-conclusion.model';
import { IRadioOption } from '@ic-models/radio-button-option.model';
import { DocumentData } from '@ic-models/storage/document-data';
import {
  IIPTreePpcData,
  PpcReportPronouncementsDTO
} from '@ic-models/trees/ppc-report-pronouncements-tree.model';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

const baseUrl = environment.baseUrl;
@Injectable()
export class PpcReportService {
  constructor(private http: HttpClient) {}

  savePpcReport(
    ppcReportToSave: PpcReportToSave,
    ppcAdministrativeFileId: number,
    resultCode?: number
  ): Observable<boolean> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      resultCode: resultCode
    };

    const options = createRequestOption(req);

    return this.http
      .post<boolean>(`${baseUrl}/savePpcReport`, ppcReportToSave, {
        params: options
      })
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error el informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReformulatedPpcReportObservationsAndSubjectiveImprovements(
    ppcReportToSave: PpcReportToSave,
    ppcReportProcedureId: number
  ): Observable<boolean> {
    const req = {
      ppcReportProcedureId: ppcReportProcedureId
    };

    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/ppcreport/saveReformulatedPpcReportObservationsAndSubjectiveImprovements`,
        ppcReportToSave,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar las observaciones y recomendaciones' +
              ' en el informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReformulatedPpcReportObservations(
    ppcObservationToSave: PpcObservationToSave,
    ppcReportProcedureId: number
  ): Observable<boolean> {
    const ppcObservationToSaveBody = isNullOrUndefined(ppcObservationToSave)
      ? new PpcObservationToSave([], null, [], null)
      : ppcObservationToSave;
    const req = {
      ppcReportProcedureId: ppcReportProcedureId
    };

    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/ppcreport/saveReformulatedPpcReportObservations`,
        ppcObservationToSaveBody,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar las observaciones de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReformulatedPpcReportSubjectiveImprovements(
    ppcSubjectiveImprovementSave: PpcSubjectiveImprovementToSave,
    ppcReportProcedureId: number
  ): Observable<boolean> {
    const ppcSubjectiveImprovementToSaveBody = isNullOrUndefined(
      ppcSubjectiveImprovementSave
    )
      ? new PpcSubjectiveImprovementToSave([], null, [], null)
      : ppcSubjectiveImprovementSave;
    const req = {
      ppcReportProcedureId: ppcReportProcedureId
    };

    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/ppcreport/saveReformulatedPpcReportSubjectiveImprovements`,
        ppcSubjectiveImprovementToSaveBody,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar las recomendaciones de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReformulatedPpcReportPronouncements(
    pronouncements: PpcReportPronouncementsDTO[],
    ppcReportProcedureId: number,
    resultCode: number,
    conclusionId: number,
    hasSubjectiveImprovements: boolean
  ): Observable<void> {
    const ppcReportPronouncements: PpcReportToSaveDTO = {
      pronouncements: pronouncements
    };
    const req = {
      ppcReportProcedureId: ppcReportProcedureId,
      resultCode: resultCode,
      conclusionId: conclusionId,
      hasSubjectiveImprovements
    };

    const options = createRequestOption(req);
    return this.http
      .post<void>(
        `${baseUrl}/ppcreport/saveReformulatedPpcReportPronouncements`,
        ppcReportPronouncements,
        {
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<void>) => resp.body as void),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar los pronunciamientos en el informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReformulatedPpcItemComments(
    ppcItemCommentSave: PpcItemCommentToSave,
    ppcReportProcedureId: number
  ): Observable<boolean> {
    const ppcItemCommentSaveBody = isNullOrUndefined(ppcItemCommentSave)
      ? new PpcItemCommentToSave([], null, [], null)
      : ppcItemCommentSave;
    const req = {
      ppcReportProcedureId: ppcReportProcedureId
    };

    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/ppcreport/saveReformulatedPpcItemComments`,
        ppcItemCommentSaveBody,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar los comentarios de los items de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReformulatedPpcReportApplicableRegulationAndLegalProvision(
    ppcReportProcedureId: number
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${baseUrl}/ppcreport/saveReformulatedPpcReportApplicableRegulationAndLegalProvision`,
        ppcReportProcedureId
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar las normativas aplicables y las disposiciones' +
              'legales del expediente de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportTreeData(
    ppcAdministrativeFileTypeId: number,
    ppcVerificationModelId: number
  ): Observable<IIPTreePpcData[]> {
    const req = {
      ppcVerificationModelId: ppcVerificationModelId
    };

    const options = createRequestOption(req);
    return this.http
      .get<IIPTreePpcData[]>(
        `${baseUrl}/ppcreport/tree/${ppcAdministrativeFileTypeId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreePpcData[]>) =>
            resp.body as IIPTreePpcData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportParentTreeData(
    ppcAdministrativeFileId: number
  ): Observable<IIPTreePpcData[]> {
    return this.http
      .get<IIPTreePpcData[]>(
        `${baseUrl}/ppcreport/treeparent/${ppcAdministrativeFileId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreePpcData[]>) =>
            resp.body as IIPTreePpcData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcReportPronouncements(
    pronouncements: PpcReportPronouncementsDTO[],
    ppcAdministrativeFileId: number,
    ppcReportProcedureId: number,
    fullInspected: boolean,
    resultCode: number,
    conclusionId: number
  ): Observable<void> {
    const req = {
      fullInspected: fullInspected,
      resultCode: resultCode,
      conclusionId: conclusionId
    };

    const ppcReport: PpcReportToSaveDTO = {
      pronouncements: pronouncements
    };

    const options = createRequestOption(req);
    return this.http
      .post<void>(
        `${baseUrl}/ppcreport/pronouncements/${ppcAdministrativeFileId}/${ppcReportProcedureId}`,
        ppcReport,
        { params: options, observe: 'response' }
      )
      .pipe(
        map((resp: HttpResponse<void>) => resp.body as void),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al guardar los pronuncimamientos para el expediente CPP ${ppcAdministrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }

  getPpcReportTreeDataForEdition(
    ppcAdministrativeFileId: number
  ): Observable<IIPTreePpcData[]> {
    return this.http
      .get<IIPTreePpcData[]>(
        `${baseUrl}/ppcreport/tree/${ppcAdministrativeFileId}/edit`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreePpcData[]>) =>
            resp.body as IIPTreePpcData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos para editar el árbol de pronunciamientos CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updatePpcReportPronouncements(
    pronouncements: PpcReportPronouncementsDTO[],
    ppcAdministrativeFileId: number,
    ppcReportProcedureId: number,
    fullInspected: boolean,
    resultCode: number,
    conclusionId: number
  ): Observable<void> {
    const req = {
      fullInspected: fullInspected,
      resultCode: resultCode,
      conclusionId: conclusionId
    };

    const ppcReport: PpcReportToSaveDTO = {
      pronouncements: pronouncements
    };

    const options = createRequestOption(req);
    return this.http
      .put<void>(
        `${baseUrl}/ppcreport/pronouncements/${ppcAdministrativeFileId}/${ppcReportProcedureId}`,
        ppcReport,
        { params: options, observe: 'response' }
      )
      .pipe(
        map((resp: HttpResponse<void>) => resp.body as void),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al guardar los pronuncimamientos para el expediente CPP ${ppcAdministrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }

  getPpcReportConclusion(): Observable<IRadioOption[]> {
    return this.http
      .get<IRadioOption[]>(`${baseUrl}/ppcreport/conclusions`, {
        headers: HttpConstants.GET_HEADERS,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IRadioOption[]>) => resp.body as IRadioOption[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las conclusiones CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportConclusionDescriptionAndCode(
    ppcSupportedReportConclusionId: number
  ): Observable<IPpcSupportedReportConclusion> {
    const req = {
      ppcSupportedReportConclusionId: ppcSupportedReportConclusionId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcSupportedReportConclusion>(
        `${baseUrl}/ppcreport/conclusionDescriptionAndCode`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IPpcSupportedReportConclusion>) => {
          return resp.body as IPpcSupportedReportConclusion;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el texto de la conclusion CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportTreeDataForOnlySee(
    ppcProcedureId: number
  ): Observable<IIPTreePpcData[]> {
    return this.http
      .get<IIPTreePpcData[]>(
        `${baseUrl}/ppcreport/tree/${ppcProcedureId}/see`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreePpcData[]>) =>
            resp.body as IIPTreePpcData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportErrorParentTreeForOnlySee(
    ppcParentProcedureId: number
  ): Observable<IIPTreePpcData[]> {
    return this.http
      .get<IIPTreePpcData[]>(
        `${baseUrl}/ppcreport/errorparent/tree/${ppcParentProcedureId}/see`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreePpcData[]>) =>
            resp.body as IIPTreePpcData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportErrorParentTree(
    ppcAdministrativeFileTypeId: number,
    ppcAdministrativeFileId: number
  ): Observable<IIPTreePpcData[]> {
    return this.http
      .get<IIPTreePpcData[]>(
        `${baseUrl}/ppcreport/errorparent/tree/${ppcAdministrativeFileTypeId}/${ppcAdministrativeFileId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreePpcData[]>) =>
            resp.body as IIPTreePpcData[]
        ),
        catchError((err: Error) => {
          console.error(
            `Se ha producido un error al obtener /ppcreport/errorparent/tree/
             para el expediente CPP ${ppcAdministrativeFileTypeId}`,
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcReportErrorTree(
    ppcAdministrativeFileId: number
  ): Observable<IIPTreePpcData[]> {
    return this.http
      .get<IIPTreePpcData[]>(
        `${baseUrl}/ppcreport/error/tree/${ppcAdministrativeFileId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreePpcData[]>) =>
            resp.body as IIPTreePpcData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  acceptPpcReport(
    ppcAdministrativeFileId: number,
    ppcReportProcedureId: number,
    ppcAreaCode: number,
    ppcAdministrativeFileTypeId: number,
    document: DocumentData,
    ppcProcedureId: number
  ): Observable<boolean> {
    return this.http
      .put<boolean>(
        `${baseUrl}/ppcreport/accept/${ppcAdministrativeFileId}/${ppcReportProcedureId}/${ppcAreaCode}/
        ${ppcAdministrativeFileTypeId}/${ppcProcedureId}`,
        document
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al aceptar el informe CPP para el expediente ${ppcAdministrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }

  deletePpcReport(ppcAdministrativeFileId: number): Observable<boolean> {
    const options = createRequestOption();

    return this.http
      .get<boolean>(
        `${baseUrl}/ppcreport/pronouncements/delete/report/${ppcAdministrativeFileId}`,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar el informe CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcApplicableRegulation(
    ppcAreaCode: number
  ): Observable<IPpcApplicableRegulationsReportList[]> {
    const req = {
      ppcAreaCode: ppcAreaCode
    };

    const options = createRequestOption(req);
    return this.http
      .get<IPpcApplicableRegulationsReportList[]>(
        `${baseUrl}/ppcreport/getPpcApplicableRegulation`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcApplicableRegulationsReportList[]>) =>
            resp.body as IPpcApplicableRegulationsReportList[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las normativas aplicables CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcApplicableRegulationFromPpcReportProcedureId(
    ppcAreaCode: number,
    ppcReportProcedureId: number
  ): Observable<IPpcApplicableRegulationsReportList[]> {
    const req = {
      ppcAreaCode: ppcAreaCode,
      ppcReportProcedureId: ppcReportProcedureId
    };

    const options = createRequestOption(req);
    return this.http
      .get<IPpcApplicableRegulationsReportList[]>(
        `${baseUrl}/ppcreport/getPpcApplicableRegulationFromPpcReportProcedureId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcApplicableRegulationsReportList[]>) =>
            resp.body as IPpcApplicableRegulationsReportList[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las normativas aplicables CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcLegalProvisionsFromPpcReportProcedureId(
    ppcAdministrativeFileTypeId: number,
    ppcReportProcedureId: number
  ): Observable<IPpcLegalProvisionsReportList[]> {
    const req = {
      ppcAdministrativeFileTypeId: ppcAdministrativeFileTypeId,
      ppcReportProcedureId: ppcReportProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcLegalProvisionsReportList[]>(
        `${baseUrl}/ppcreport/getPpcLegalProvisionsFromPpcReportProcedureId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcLegalProvisionsReportList[]>) =>
            resp.body as IPpcLegalProvisionsReportList[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las disposiciones legales CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcLegalProvisionsFromModel(
    ppcAdministrativeFileTypeId: number
  ): Observable<IPpcLegalProvisionsReportList[]> {
    const req = {
      ppcAdministrativeFileTypeId: ppcAdministrativeFileTypeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcLegalProvisionsReportList[]>(
        `${baseUrl}/ppcreport/getPpcLegalProvisionsFromModel`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcLegalProvisionsReportList[]>) =>
            resp.body as IPpcLegalProvisionsReportList[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las disposiciones legales CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
