<!-- <mat-form-field appearance="fill">
  <mat-label>{{ inputLabel }}</mat-label>
  <mat-select [(value)]="selected" [formControl]="inputFormControl">
    <mat-option *ngIf="hasDefaultOption">{{ defaultOptionText }}</mat-option>
    <mat-option *ngFor="let option of options" [value]="option.id">
      {{ option.text }}</mat-option
    >
  </mat-select>
</mat-form-field> -->
<mat-form-field
  class="ic-input-select"
  [appearance]="appearance"
  [ngClass]="formFieldClass"
>
  <mat-label>{{ inputLabel }}</mat-label>
  <mat-select
    [multiple]="multiple"
    [placeholder]="inputLabel"
    [attr.disabled]="isDisabled"
    [formControl]="inputFormControl"
    (selectionChange)="select($event.value)"
  >
    <mat-option *ngIf="hasDefaultOption">{{ defaultOptionText }}</mat-option>
    <mat-option
      [matTooltip]="option.tooltip"
      *ngFor="let option of options"
      [value]="option.id"
    >
      {{ option.text }}
    </mat-option>
  </mat-select>
  <mat-error
    *ngIf="
      inputFormControl.hasError('required') ||
      inputFormControl.hasError('nullValidator')
    "
    >{{ 'components.input-select.errors.required' | translate }}</mat-error
  >
</mat-form-field>
