/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

type OnChangeType = () => any;
type OnTouchedType = () => any;

@Component({
  selector: 'ic-input-select',
  templateUrl: './input-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true
    }
  ]
})
export class InputSelectComponent implements OnInit, ControlValueAccessor {
  @HostBinding('attr.id')
  externalId = '';
  @Input() showHint = false;
  @Input() multiple = false;
  @Input() isDisabled = false;
  @Input() inputLabel = '';
  @Input() inputFormControl: FormControl = new FormControl();
  @Input() hasDefaultOption = false;
  @Input() defaultOptionText = '';
  @Input() options: any;
  @Input() formFieldClass = '';
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value') _value = '';

  @Output() selectedOption: EventEmitter<string | number | number[]> =
    new EventEmitter<string | number | number[]>();

  appearance: MatFormFieldAppearance = 'fill';
  disableSelect = new FormControl(false);
  selected: any;

  onChange: any = () => {};
  onTouched: any = () => {};

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  ngOnInit(): void {
    if (this.isDisabled) {
      this.inputFormControl.disable({
        onlySelf: true,
        emitEvent: false
      });
    }
    // if (this.value) {
    //   this.selected = this.value.id;
    // }
  }

  registerOnChange(fn: OnChangeType): void {
    this.onChange = fn;
  }

  writeValue(value: string): void {
    if (value) {
      this.value = value;
      return;
    }
    this.value = '';
  }

  registerOnTouched(fn: OnTouchedType): void {
    this.onTouched = fn;
  }

  select(optionValue: string | number | number[]): void {
    this.selectedOption.emit(optionValue);
  }
}
