/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IFileTypeItemsTree,
  IFileTypesTree,
  IOrganizationalUnitsTree,
  IPpcFileTypesTree
} from '@ic-models/tree.model';
import {
  FileTypeItemsTreeComponent,
  IFileTypeItemsTreeComponent
} from '@ic-models/trees/file-type-items-tree.model';
import {
  FileTypesTreeComponent,
  IFileTypesTreeComponent
} from '@ic-models/trees/file-types-tree.model';
import { IInputTreeComponent } from '@ic-models/trees/input-tree.model';
import { IPTreeOmissionData } from '@ic-models/trees/inspection-omission-pronouncements-tree.model';
import { IPTreeData } from '@ic-models/trees/inspection-pronouncements-tree.model';
import { OrganizationalUnitTreeComponent } from '@ic-models/trees/organizational-unit-tree.model';
import {
  IPpcFileTypesTreeComponent,
  PpcFileTypesTreeComponent
} from '@ic-models/trees/ppc-file-types-tree.model';
import { IPTreePpcData } from '@ic-models/trees/ppc-report-pronouncements-tree.model';
import { TranslateService } from '@ngx-translate/core';

export const convertRootNodesToTreeComponent = (
  trees: IFileTypesTree[]
): IInputTreeComponent[] => {
  const arrayOfIInputTreeComponent: IInputTreeComponent[] = [];
  const mappedArray: {
    [key: string]: IInputTreeComponent | any;
  } = {};

  //Convertimos todos los Tree a InputTreeComponent y
  //los metemos en un array en el cual mapeamos por el id del elemento.
  for (let i = 0; i < trees.length; i++) {
    const treeComponent = new FileTypesTreeComponent(
      trees[i].id,
      trees[i].name,
      null,
      trees[i].isSelectable,
      trees[i].treeId,
      true,
      null,
      null,
      trees[i].customOrder
    );
    mappedArray[treeComponent.id] = treeComponent;
    (mappedArray[treeComponent.id] as IInputTreeComponent).children = [];

    arrayOfIInputTreeComponent.push(
      mappedArray[treeComponent.id] as IInputTreeComponent
    );
  }

  return arrayOfIInputTreeComponent;
};

export const convertRootNodesToTreeComponentPpc = (
  trees: IPpcFileTypesTree[]
): IInputTreeComponent[] => {
  const arrayOfIInputTreeComponent: IInputTreeComponent[] = [];
  const mappedArray: {
    [key: string]: IInputTreeComponent | any;
  } = {};

  //Convertimos todos los Tree a InputTreeComponent y
  //los metemos en un array en el cual mapeamos por el id del elemento.
  for (let i = 0; i < trees.length; i++) {
    const treeComponent = new PpcFileTypesTreeComponent(
      trees[i].id,
      trees[i].name,
      null,
      trees[i].isSelectable,
      trees[i].treeId,
      true,
      null,
      null,
      trees[i].customOrder
    );
    mappedArray[treeComponent.id] = treeComponent;
    (mappedArray[treeComponent.id] as IInputTreeComponent).children = [];

    arrayOfIInputTreeComponent.push(
      mappedArray[treeComponent.id] as IInputTreeComponent
    );
  }

  return arrayOfIInputTreeComponent;
};

export const convertRootNodesToTreeComponentOU = (
  trees: IOrganizationalUnitsTree[]
): IInputTreeComponent[] => {
  const arrayOfIInputTreeComponent: IInputTreeComponent[] = [];
  const mappedArray: {
    [key: string]: IInputTreeComponent | any;
  } = {};

  //Convertimos todos los Tree a InputTreeComponent y
  //los metemos en un array en el cual mapeamos por el id del elemento.
  for (let i = 0; i < trees.length; i++) {
    const treeComponent = new OrganizationalUnitTreeComponent(
      trees[i].id,
      trees[i].name,
      null,
      trees[i].parentName,
      true,
      trees[i].treeId,
      false,
      null
    );
    mappedArray[treeComponent.id] = treeComponent;
    (mappedArray[treeComponent.id] as IInputTreeComponent).children = [];

    arrayOfIInputTreeComponent.push(
      mappedArray[treeComponent.id] as IInputTreeComponent
    );
  }

  return arrayOfIInputTreeComponent;
};

export const convertFileTypeItemsRootNodesToFileTypeItemsTreeComponent = (
  trees: IFileTypeItemsTree[],
  translate: TranslateService
): IFileTypeItemsTreeComponent[] => {
  const arrayOfIInputTreeComponent: IFileTypeItemsTreeComponent[] = [];
  const mappedArray: {
    [key: string | number]: IFileTypeItemsTreeComponent | any;
  } = {};

  const generalTreeComponent = new FileTypeItemsTreeComponent(
    null,
    translate.instant('detail.tree.general-requirements') as string,
    null,
    null,
    true,
    true,
    null
  );
  const particularTreeComponent = new FileTypeItemsTreeComponent(
    null,
    translate.instant('detail.tree.additional-requirements') as string,
    null,
    null,
    true,
    false,
    null
  );

  mappedArray['essential'] = generalTreeComponent;
  mappedArray['particular'] = particularTreeComponent;

  //Convertimos todos los Tree a InputTreeComponent y
  //los metemos en un array en el cual mapeamos por el id del elemento.
  for (let i = 0; i < trees.length; i++) {
    const treeComponent = new FileTypeItemsTreeComponent(
      trees[i].id,
      trees[i].name,
      null,
      trees[i].treeId,
      true,
      trees[i].essential,
      null
    );

    mappedArray[treeComponent.id as number] = treeComponent;
    (
      mappedArray[treeComponent.id as number] as IFileTypeItemsTreeComponent
    ).children = [];

    //Los agrupamos por essential o particular para poder mostrar los items agrupados
    if (treeComponent.essential) {
      if (!(mappedArray['essential'] as FileTypeItemsTreeComponent).children) {
        (mappedArray['essential'] as FileTypeItemsTreeComponent).children = [];
      }
      if (
        (mappedArray[treeComponent.id as number] as FileTypeItemsTreeComponent)
          .children?.length ??
        0 > 0
      ) {
        (
          mappedArray[treeComponent.id as number] as IFileTypeItemsTreeComponent
        ).expandable = true;
      }
      (mappedArray['essential'] as FileTypeItemsTreeComponent).children?.push(
        mappedArray[treeComponent.id as number] as IFileTypeItemsTreeComponent
      );
    } else {
      if (!(mappedArray['particular'] as FileTypeItemsTreeComponent).children) {
        (mappedArray['particular'] as FileTypeItemsTreeComponent).children = [];
      }
      if (
        (mappedArray[treeComponent.id as number] as FileTypeItemsTreeComponent)
          .children?.length ??
        0 > 0
      ) {
        (
          mappedArray[treeComponent.id as number] as FileTypeItemsTreeComponent
        ).expandable = true;
      }
      (mappedArray['particular'] as FileTypeItemsTreeComponent).children?.push(
        mappedArray[treeComponent.id as number] as IFileTypeItemsTreeComponent
      );
    }
  }
  arrayOfIInputTreeComponent.push(
    mappedArray['essential'] as FileTypeItemsTreeComponent
  );
  arrayOfIInputTreeComponent.push(
    mappedArray['particular'] as FileTypeItemsTreeComponent
  );

  return arrayOfIInputTreeComponent;
};

export const convertChildrenFileTypesTreeToFileTypesTreeComponent = (
  initialTree: IFileTypesTreeComponent[],
  trees: IFileTypesTree[]
): IFileTypesTreeComponent[] => {
  const arrayOfIInputTreeComponent: IFileTypesTreeComponent[] = [];
  const mappedArray: {
    [key: string | number]: IFileTypesTreeComponent | any;
  } = {};
  let mappedElem: IFileTypesTreeComponent;

  //Convertimos todos los Tree a InputTreeComponent y
  //los metemos en un array en el cual mapeamos por el id del elemento.
  for (let i = 0; i < trees.length; i++) {
    const treeComponent = new FileTypesTreeComponent(
      trees[i].id,
      trees[i].name,
      trees[i].parentId,
      trees[i].isSelectable,
      null,
      false,
      null,
      trees[i].description,
      trees[i].customOrder
    );
    mappedArray[treeComponent.id] = treeComponent;
    (mappedArray[treeComponent.id] as IFileTypesTreeComponent).children = [];
  }
  //Recorremos el array de elementos mapeados y cogemos cada elemento.
  //Si el elemento tiene padre, añadimos el nodo al array de sus hijos
  // y sino pues lo añadimos como un nodo raiz
  for (const id in mappedArray) {
    if (Object.prototype.hasOwnProperty.call(mappedArray, id)) {
      mappedElem = mappedArray[id] as IFileTypesTreeComponent;

      if (mappedElem.parentId) {
        mappedElem.level =
          ((mappedArray[mappedElem.parentId] as IFileTypesTreeComponent)
            .level as number) + 1;
        (
          mappedArray[mappedElem.parentId] as IFileTypesTreeComponent
        ).children?.push(mappedElem);
        (
          mappedArray[mappedElem.parentId] as IFileTypesTreeComponent
        ).expandable = true;
        (
          mappedArray[mappedElem.parentId] as IFileTypesTreeComponent
        ).children?.sort(
          (a: IFileTypesTreeComponent, b: IFileTypesTreeComponent) =>
            (a.customOrder as number) > (b.customOrder as number) ? 1 : -1
        );
      } else {
        arrayOfIInputTreeComponent.push(mappedElem);
        arrayOfIInputTreeComponent.sort(
          (a: IFileTypesTreeComponent, b: IFileTypesTreeComponent) =>
            (a.customOrder as number) > (b.customOrder as number) ? 1 : -1
        );
      }
    }
  }

  // TODO: comprobar que este for que sustituimos funciona correctamente
  // for (const id in arrayOfIInputTreeComponent) {
  //   if (arrayOfIInputTreeComponent.hasOwnProperty(id)) {
  //     mappedElem = arrayOfIInputTreeComponent[id];
  //     initialTree.forEach((initialTreeElement, index) => {
  //       if (!mappedElem.parentId && mappedElem.id == initialTreeElement.id) {
  //         initialTree[index].children = mappedElem.children;
  //         initialTree[index].children?.sort((a, b) =>
  //           (a.customOrder as number) > (b.customOrder as number) ? 1 : -1
  //         );
  //       }
  //     });
  //   }
  // }
  for (const mappedElem of arrayOfIInputTreeComponent) {
    initialTree.forEach(
      (initialTreeElement: IFileTypesTreeComponent, index: number) => {
        if (!mappedElem.parentId && mappedElem.id === initialTreeElement.id) {
          initialTree[index].children = mappedElem.children;
          initialTree[index].children?.sort(
            (a: IFileTypesTreeComponent, b: IFileTypesTreeComponent) =>
              (a.customOrder as number) > (b.customOrder as number) ? 1 : -1
          );
        }
      }
    );
  }

  return initialTree;
};

export const convertChildrenFileTypesTreeToFileTypesTreeComponentPpc = (
  initialTree: IPpcFileTypesTreeComponent[],
  trees: IFileTypesTree[]
): IPpcFileTypesTreeComponent[] => {
  const arrayOfIInputTreeComponent: IPpcFileTypesTreeComponent[] = [];
  const mappedArray: {
    [key: string | number]: IPpcFileTypesTreeComponent | any;
  } = {};
  let mappedElem: IPpcFileTypesTreeComponent;

  //Convertimos todos los Tree a InputTreeComponent y
  //los metemos en un array en el cual mapeamos por el id del elemento.
  for (let i = 0; i < trees.length; i++) {
    const treeComponent = new PpcFileTypesTreeComponent(
      trees[i].id,
      trees[i].name,
      trees[i].parentId,
      trees[i].isSelectable,
      null,
      false,
      null,
      trees[i].description,
      trees[i].customOrder
    );
    mappedArray[treeComponent.id] = treeComponent;
    (mappedArray[treeComponent.id] as IPpcFileTypesTreeComponent).children = [];
  }
  //Recorremos el array de elementos mapeados y cogemos cada elemento.
  //Si el elemento tiene padre, añadimos el nodo al array de sus hijos
  // y sino pues lo añadimos como un nodo raiz
  for (const id in mappedArray) {
    if (Object.prototype.hasOwnProperty.call(mappedArray, id)) {
      mappedElem = mappedArray[id] as IPpcFileTypesTreeComponent;

      if (mappedElem.parentId) {
        mappedElem.level =
          ((mappedArray[mappedElem.parentId] as IPpcFileTypesTreeComponent)
            .level as number) + 1;
        (
          mappedArray[mappedElem.parentId] as IPpcFileTypesTreeComponent
        ).children?.push(mappedElem);
        (
          mappedArray[mappedElem.parentId] as IPpcFileTypesTreeComponent
        ).expandable = true;
        (
          mappedArray[mappedElem.parentId] as IPpcFileTypesTreeComponent
        ).children?.sort(
          (a: IPpcFileTypesTreeComponent, b: IPpcFileTypesTreeComponent) =>
            (a.customOrder as number) > (b.customOrder as number) ? 1 : -1
        );
      } else {
        arrayOfIInputTreeComponent.push(mappedElem);
        arrayOfIInputTreeComponent.sort(
          (a: IPpcFileTypesTreeComponent, b: IPpcFileTypesTreeComponent) =>
            (a.customOrder as number) > (b.customOrder as number) ? 1 : -1
        );
      }
    }
  }

  // for (const id in arrayOfIInputTreeComponent) {
  //   if (arrayOfIInputTreeComponent.hasOwnProperty(id)) {
  //     mappedElem = arrayOfIInputTreeComponent[id];
  //     initialTree.forEach((initialTreeElement, index) => {
  //       if (!mappedElem.parentId && mappedElem.id == initialTreeElement.id) {
  //         initialTree[index].children = mappedElem.children;
  //         initialTree[index].children.sort((a, b) =>
  //           a.customOrder > b.customOrder ? 1 : -1
  //         );
  //       }
  //     });
  //   }
  // }
  for (const mappedElem of arrayOfIInputTreeComponent) {
    initialTree.forEach(
      (initialTreeElement: IPpcFileTypesTreeComponent, index: number) => {
        if (!mappedElem.parentId && mappedElem.id === initialTreeElement.id) {
          initialTree[index].children = mappedElem.children;
          initialTree[index].children?.sort(
            (a: IPpcFileTypesTreeComponent, b: IPpcFileTypesTreeComponent) =>
              (a.customOrder as number) > (b.customOrder as number) ? 1 : -1
          );
        }
      }
    );
  }
  console.log(initialTree);
  return initialTree;
};

export const convertChildrenFileTypeItemsTreeToFileTypeItemsTreeComponent = (
  initialTree: IFileTypeItemsTreeComponent[],
  trees: IFileTypeItemsTree[]
): IFileTypeItemsTreeComponent[] => {
  const arrayOfIFileTypeItemsTreeComponent: IFileTypeItemsTreeComponent[] = [];
  const mappedArray: {
    [key: string | number]: IFileTypeItemsTreeComponent | any;
  } = {};
  let mappedElem: IFileTypeItemsTreeComponent;

  //Convertimos todos los Tree a InputTreeComponent y
  //los metemos en un array en el cual mapeamos por el id del elemento.
  for (let i = 0; i < trees.length; i++) {
    const treeComponent = new FileTypeItemsTreeComponent(
      trees[i].id,
      trees[i].name,
      trees[i].parentId,
      trees[i].treeId,
      false,
      trees[i].essential,
      null
    );
    mappedArray[treeComponent.id as number] = treeComponent;
    (
      mappedArray[treeComponent.id as number] as IFileTypeItemsTreeComponent
    ).children = [];
  }
  //Recorremos el array de elementos mapeados y cogemos cada elemento.
  //Si el elemento tiene padre, añadimos el nodo al array de sus hijos
  // y sino pues lo añadimos como un nodo raiz
  for (const id in mappedArray) {
    if (Object.prototype.hasOwnProperty.call(mappedArray, id)) {
      mappedElem = mappedArray[id] as IFileTypeItemsTreeComponent;

      if (mappedElem.parentId) {
        mappedElem.level =
          ((mappedArray[mappedElem.parentId] as IFileTypeItemsTreeComponent)
            .level as number) + 1;
        (
          mappedArray[mappedElem.parentId] as IFileTypeItemsTreeComponent
        ).children?.push(mappedElem);
        (
          mappedArray[mappedElem.parentId] as IFileTypeItemsTreeComponent
        ).expandable = true;
      } else {
        arrayOfIFileTypeItemsTreeComponent.push(mappedElem);
      }
    }
  }

  // TODO: comprobar que este for que sustituimos funciona correctamente
  // for (const id in arrayOfIFileTypeItemsTreeComponent) {
  //   if (arrayOfIFileTypeItemsTreeComponent.hasOwnProperty(id)) {
  //     mappedElem = arrayOfIFileTypeItemsTreeComponent[id];
  //     //La primera posicion del array van a ser los generales(essential), la segunda los particulares
  //     initialTree[0] &&
  //       initialTree[0].children &&
  //       initialTree[0].children.forEach((initialTreeElement, index) => {
  //         if (
  //           !mappedElem.parentId &&
  //           mappedElem.id == initialTreeElement.id &&
  //           initialTree[0].children
  //         ) {
  //           initialTree[0].children[index].children = mappedElem.children;
  //           if (mappedElem.children && mappedElem.children.length > 0) {
  //             initialTree[0].children[index].expandable = true;
  //           } else {
  //             initialTree[0].children[index].expandable = false;
  //           }
  //         }
  //       });
  //     initialTree[1] &&
  //       initialTree[1].children &&
  //       initialTree[1].children.forEach((initialTreeElement, index) => {
  //         if (
  //           !mappedElem.parentId &&
  //           mappedElem.id == initialTreeElement.id &&
  //           initialTree[1].children
  //         ) {
  //           initialTree[1].children[index].children = mappedElem.children;
  //           if (mappedElem.children && mappedElem.children.length > 0) {
  //             initialTree[1].children[index].expandable = true;
  //           } else {
  //             initialTree[1].children[index].expandable = false;
  //           }
  //         }
  //       });
  //   }
  // }
  for (const mappedElem of arrayOfIFileTypeItemsTreeComponent) {
    initialTree[0]?.children?.forEach(
      (initialTreeElement: IFileTypeItemsTreeComponent, index: number) => {
        if (
          !mappedElem.parentId &&
          mappedElem.id === initialTreeElement.id &&
          initialTree[0].children
        ) {
          initialTree[0].children[index].children = mappedElem.children;
          if (mappedElem.children && mappedElem.children.length > 0) {
            initialTree[0].children[index].expandable = true;
          } else {
            initialTree[0].children[index].expandable = false;
          }
        }
      }
    );
    initialTree[1]?.children?.forEach(
      (initialTreeElement: IFileTypeItemsTreeComponent, index: number) => {
        if (
          !mappedElem.parentId &&
          mappedElem.id === initialTreeElement.id &&
          initialTree[1].children
        ) {
          initialTree[1].children[index].children = mappedElem.children;
          if (mappedElem.children && mappedElem.children.length > 0) {
            initialTree[1].children[index].expandable = true;
          } else {
            initialTree[1].children[index].expandable = false;
          }
        }
      }
    );
  }

  return initialTree;
};

export const treeSearchRecursive = (
  tree:
    | IPTreeData
    | IPTreeOmissionData
    | IFileTypeItemsTreeComponent
    | IPTreePpcData,
  id: number
):
  | IPTreeData
  | IPTreeOmissionData
  | IFileTypeItemsTreeComponent
  | IPTreePpcData
  | void => {
  if (tree.id === id) {
    return tree;
  }

  for (const child of tree.children as
    | IPTreeData[]
    | IPTreeOmissionData[]
    | IFileTypeItemsTreeComponent[]) {
    const res = treeSearchRecursive(child, id);

    if (res) {
      return res;
    }
  }
};

export const treeSearchIterative = (
  tree: IPTreeData | IPTreeOmissionData | IFileTypeItemsTreeComponent,
  id: number
): IPTreeData | IPTreeOmissionData | IFileTypeItemsTreeComponent | void => {
  for (const stack = [tree]; stack.length; ) {
    const curr = stack.pop();

    if (curr?.id === id) {
      return curr;
    }

    // TODO: OJO, Antes la instrucción era stack.push(...curr.child);
    stack.push(
      ...(curr?.children as
        | IPTreeData[]
        | IPTreeOmissionData[]
        | IFileTypeItemsTreeComponent[])
    );
  }
};

export const findNodeInTreeArrayRecursive = (
  treeArray:
    | IPTreeData[]
    | IPTreeOmissionData[]
    | IFileTypeItemsTreeComponent[]
    | IPTreePpcData[],
  id: number
):
  | IPTreeData
  | IPTreeOmissionData
  | IFileTypeItemsTreeComponent
  | IPTreePpcData
  | void => {
  let nodeFound:
    | IPTreeData
    | IPTreeOmissionData
    | IFileTypeItemsTreeComponent
    | IPTreePpcData
    | undefined;
  [...treeArray].find(
    (
      element:
        | IPTreeData
        | IPTreeOmissionData
        | IFileTypeItemsTreeComponent
        | IPTreePpcData
    ) => {
      const node = treeSearchRecursive(element, id);
      if (node !== undefined) {
        nodeFound = node;
        return;
      }
    }
  );
  return nodeFound;
};

export const findNodeInTreeArrayIterative = (
  treeArray:
    | IPTreeData[]
    | IPTreeOmissionData[]
    | IFileTypeItemsTreeComponent[],
  id: number
): IPTreeData | IPTreeOmissionData | IFileTypeItemsTreeComponent | void => {
  let nodeFound:
    | IPTreeData
    | IPTreeOmissionData
    | IFileTypeItemsTreeComponent
    | undefined;
  [...treeArray].find(
    (
      element: IPTreeData | IPTreeOmissionData | IFileTypeItemsTreeComponent
    ) => {
      const node = treeSearchIterative(element, id);
      if (node !== undefined) {
        nodeFound = node;
        return;
      }
    }
  );
  return nodeFound;
};
