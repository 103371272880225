<mat-chip-grid #chipGrid>
  <ng-container *ngIf="chips && chips.length > 0">
    <mat-chip-row
      *ngFor="let chip of chips"
      selected
      [removable]="isRemovable"
      (removed)="removeChip(chip)"
    >
      <mat-icon [ngClass]="iconClass">{{ chipIcon }}</mat-icon>
      <div class="text">
        <span [ngClass]="firstLineClass">{{
          getPropertyValue(chip, firstLineKey ?? '')
        }}</span>
        <span
          *ngIf="getPropertyValue(chip, secondLineKey ?? '')"
          [ngClass]="secondLineClass"
          >{{ getPropertyValue(chip, secondLineKey ?? '') }}</span
        >
        <span
          [ngClass]="secondLineClass"
          *ngIf="!getPropertyValue(chip, secondLineKey ?? '')"
          >{{ unknownName }}</span
        >
      </div>
      <mat-icon matChipRemove>{{ removeIcon }}</mat-icon>
    </mat-chip-row>
  </ng-container>
  <mat-form-field>
    <input
      title="input"
      [placeholder]="placeHolderText ?? ''"
      #chipInput
      matInput
      [formControl]="inputFormControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="inputSeparatorKeyCodes ?? []"
      (matChipInputTokenEnd)="addToChips($event)"
    />
  </mat-form-field>
</mat-chip-grid>
<mat-error class="mat-error-email" *ngIf="inputFormControl.hasError('email')">{{
  'components.input-chip.errors.email' | translate
}}</mat-error>
<mat-error
  class="mat-error-email"
  *ngIf="inputFormControl.hasError('maxEmailsLengthError')"
  >{{ 'components.input-chip.errors.email' | translate }}</mat-error
>
<mat-error
  *ngIf="
    inputFormControl.hasError('maxlength') &&
    (inputFormControl.dirty || inputFormControl.touched)
  "
>
  {{
    'components.input-chip.errors.maxLength'
      | translate : { maxLengthNumber: maxLength }
  }}
</mat-error>
<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="selectedChip($event)"
>
  <mat-option
    *ngFor="let chip of chipsFiltered | async"
    [value]="chip"
    matTooltip="{{ chip[secondLineKey ?? ''] }}"
  >
    {{ chip[firstLineKey ?? ''] }}
  </mat-option>
</mat-autocomplete>
