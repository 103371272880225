import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmptyNullOrUndefinedObject } from '@ic-core/util/global-util';
import { IEntityUser } from '@ic-models/entity.model';
import { IFiscalYear } from '@ic-models/fiscal-year.model';
import { AppConfigService } from '@ic-services/app-config.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class ParamsInterceptor implements HttpInterceptor {
  constructor(private appConfig: AppConfigService) {}
  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !request ||
      !request.url ||
      (request.url.startsWith('http') &&
        !(baseUrl && request.url.startsWith(baseUrl)))
    ) {
      return next.handle(request);
    }

    const selectedEntity: IEntityUser | null =
      this.appConfig.getSelectedEntity();

    if (
      selectedEntity !== null &&
      !isEmptyNullOrUndefinedObject(selectedEntity)
    ) {
      let cloneRequest = request.clone({
        params: request.params.append(
          'entity',
          selectedEntity.entityId.toString()
        )
      });

      const fiscalYear: IFiscalYear | null =
        this.appConfig.getSelectedFiscalYear();
      if (
        fiscalYear !== null &&
        !isEmptyNullOrUndefinedObject(this.appConfig.getSelectedFiscalYear())
      ) {
        cloneRequest = cloneRequest.clone({
          params: cloneRequest.params.append(
            'fiscal-year',
            fiscalYear.id.toString()
          )
        });
      }
      return next.handle(cloneRequest);
    }
    return next.handle(request);
  }
}
