import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUserTimeZone } from '@ic-core/util/global-util';
import { createRequestOption } from '@ic-core/util/request-util';
import { SignedReport } from '@ic-models/sign-reports/signedReport';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;
@Injectable()
export class SignaturesService {
  constructor(private http: HttpClient) {}

  saveSignedReport(
    signedReport: SignedReport,
    administrativeFileId: number
  ): Observable<boolean> {
    // let offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      zoneId: zoneId
      // offset: offset
    };

    const options = createRequestOption(req);

    return this.http
      .put<boolean>(
        `${baseUrl}/inspection/signedreport/${administrativeFileId}/`,
        signedReport,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al aceptar el informe para el expediente ${administrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }

  saveOmissionSignedReport(
    signedReport: SignedReport,
    administrativeFileId: number
  ): Observable<boolean> {
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      // offset: offset
      zoneId: zoneId
    };

    const options = createRequestOption(req);

    return this.http
      .put<boolean>(
        `${baseUrl}/inspection/omission/signedreport/${administrativeFileId}/`,
        signedReport,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al aceptar el informe para el expediente ${administrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }

  saveSignedPpcReport(
    signedReport: SignedReport,
    ppcAdministrativeFileId: number
  ): Observable<boolean> {
    // let offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      zoneId: zoneId
      // offset: offset
    };

    const options = createRequestOption(req);

    return this.http
      .put<boolean>(
        `${baseUrl}/ppcreport/signedreport/${ppcAdministrativeFileId}/`,
        signedReport,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al aceptar el informe para el expediente CPP ${ppcAdministrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }
}
