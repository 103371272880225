import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NavItems } from '@ic-app/constants/app.nav-items';
import { INavItem } from '@ic-models/nav-item.model';
import { NavService } from '@ic-services/nav.service';

@Component({
  selector: 'ic-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements AfterViewInit {
  @ViewChild('appDrawer') appDrawer?: ElementRef;
  navItems: INavItem[] = NavItems.ITEMS;

  titleSelected = '';

  constructor(private navService: NavService) {}

  ngAfterViewInit(): void {
    this.navService.appDrawer = this.appDrawer;
  }
}
