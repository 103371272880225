import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import { IReportSignerList } from '@ic-models/sign-reports/reportSignerList';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class SignsService {
  constructor(private http: HttpClient) {}

  getReportSigners(procedureTypeCode: number): Observable<IReportSignerList> {
    const req = {
      procedureTypeCode: procedureTypeCode
    };
    const options = createRequestOption(req);
    return this.http
      .get<IReportSignerList>(`${baseUrl}/signers/reportsigners`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IReportSignerList>) =>
            resp.body as IReportSignerList
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles firmantes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getReportSignersIdsToModify(procedureId: number): Observable<number[]> {
    const req = {
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number[]>(`${baseUrl}/signers/reportsigners/${procedureId}`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number[]>) => resp.body as number[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los firmantes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
