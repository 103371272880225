/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { IActionButton } from '@ic-models/action-button.model';

import { Authority } from './authority.constants';

@Injectable()
export class MenuOptions {
  static readonly TOP_NAV_LOGOUT_OPTION = 1;

  static readonly FRB_TABLE_ACCEPT_OPTION = 1;
  static readonly FRB_TABLE_CORRECTION_OPTION = 2;
  static readonly FRB_TABLE_EDIT_OPTION = 3;
  static readonly FRB_TABLE_DETAIL_OPTION = 4;
  static readonly FRB_TABLE_SEND_OPTION = 5;
  static readonly FRB_TABLE_DELETE_OS_OPTION = 6;
  static readonly FRB_TABLE_CORRECT_OS_OPTION = 7;
  static readonly FRB_TABLE_DELETE_FRB_OPTION = 8;

  static readonly FRB_TABLE_CORRECTION_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_CORRECTION_OPTION,
    btnLabel: 'solfrb.table.actions.create-os',
    accessRoles: [Authority.INSPECTOR]
  };

  static readonly FRB_TABLE_EDIT_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_EDIT_OPTION,
    btnLabel: 'solfrb.table.actions.edit',
    accessRoles: [Authority.INSPECTOR]
  };

  static readonly FRB_TABLE_DETAIL_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_DETAIL_OPTION,
    btnLabel: 'solfrb.table.actions.detail',
    accessRoles: [Authority.INSPECTOR, Authority.READER]
  };

  static readonly FRB_TABLE_ACCEPT_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_ACCEPT_OPTION,
    btnLabel: 'solfrb.table.actions.accept-request',
    accessRoles: [Authority.INSPECTOR]
  };

  static readonly FRB_TABLE_SEND_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_SEND_OPTION,
    btnLabel: 'solfrb.table.actions.send-os',
    accessRoles: [Authority.INSPECTOR]
  };

  static readonly FRB_TABLE_DELETE_OS_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_DELETE_OS_OPTION,
    btnLabel: 'solfrb.table.actions.delete-os',
    accessRoles: [Authority.INSPECTOR]
  };

  static readonly FRB_TABLE_CORRECT_OS_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_CORRECT_OS_OPTION,
    btnLabel: 'solfrb.table.actions.correct-os',
    accessRoles: [Authority.INSPECTOR]
  };

  static readonly FRB_TABLE_DELETE_FRB_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.FRB_TABLE_DELETE_FRB_OPTION,
    btnLabel: 'solfrb.table.actions.delete-frb',
    accessRoles: [Authority.INSPECTOR]
  };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_WITH_PRONOUNCEMENTS_OPTION = 1;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_IN_FAVOR_OPTION = 2;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_TO_UNFAVOURABLE_OPTION = 3;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DETAIL_OPTION = 4;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_EDIT_OPTION = 5;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_EDIT_PRONOUNCEMENTS_OPTION = 6;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_ADD_SIGNED_REPORT_OPTION = 7;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_REPORT_PROPOSITION_OPTION = 8;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AMEDMENT_OPTION = 9;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_CORRECTION_SUPPLEMENTARY_OPTION = 10;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_CORRECTION_SUPPLEMENTARY_OPTION = 11;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_SEND_CORRECTION_SUPPLEMENTARY_OPTION = 12;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_REQUEST_RESPONSE_OPTION = 13;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_LOAD_ALL_PRONOUNCEMENTS_FROM_PARENT_OPTION = 14;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_LOAD_UNFAVOURABLE_PRONOUNCEMENTS_FROM_PARENT_OPTION = 15;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_OMISSION_OPTION = 16;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_RETURN_OPTION = 17;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_SEND_RETURN_OPTION = 18;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_RETURN_OPTION = 19;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_REOPEN_FILE_OPTION = 20;
  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_SEE_REPORT_PROPOSITION_OPTION = 21;
  static readonly PPC_ADMINISTRATIVE_FILES_TABLE_INSPECTION_OPTION = 22;

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_WITH_PRONOUNCEMENTS_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_WITH_PRONOUNCEMENTS_OPTION,
      btnLabel: 'administrative-file.table.actions.audit-pronouncement',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_IN_FAVOR_OPTION_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_IN_FAVOR_OPTION,
      btnLabel: 'administrative-file.table.actions.audit-favorable',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_TO_UNFAVOURABLE_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_AUDIT_TO_UNFAVOURABLE_OPTION,
      btnLabel: 'administrative-file.table.actions.audit-desfavorable',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_CORRECTION_SUPPLEMENTARY_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_CORRECTION_SUPPLEMENTARY_OPTION,
      btnLabel: 'administrative-file.table.actions.correction-supplementary',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_CORRECTION_SUPPLEMENTARY_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_CORRECTION_SUPPLEMENTARY_OPTION,
      btnLabel:
        'administrative-file.table.actions.delete-correction-supplementary',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_SEND_CORRECTION_SUPPLEMENTARY_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_SEND_CORRECTION_SUPPLEMENTARY_OPTION,
      btnLabel:
        'administrative-file.table.actions.send-correction-supplementary',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DETAIL_OPTION_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_DETAIL_OPTION,
      btnLabel: 'administrative-file.table.actions.detail',
      accessRoles: [
        Authority.INSPECTOR,
        Authority.OMISSION,
        Authority.READER,
        Authority.OMISSION_READER
      ]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_REQUEST_RESPONSE_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_REQUEST_RESPONSE_OPTION,
      btnLabel: 'administrative-file.table.actions.request-response',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_EDIT_OPTION_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_EDIT_OPTION,
      btnLabel: 'administrative-file.table.actions.edit',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_EDIT_PRONOUNCEMENTS_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_EDIT_PRONOUNCEMENTS_OPTION,
      btnLabel: 'administrative-file.table.actions.edit-pronouncement',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_ADD_SIGNED_REPORT_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_ADD_SIGNED_REPORT_OPTION,
      btnLabel: 'administrative-file.table.actions.add-signed',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_SEE_REPORT_PROPOSITION_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_SEE_REPORT_PROPOSITION_OPTION,
      btnLabel: 'administrative-file.table.actions.see-inspection',
      accessRoles: [
        Authority.INSPECTOR,
        Authority.OMISSION,
        Authority.READER,
        Authority.OMISSION_READER
      ]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_REPORT_PROPOSITION_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_REPORT_PROPOSITION_OPTION,
      btnLabel: 'administrative-file.table.actions.delete-proposition',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_AMEDMENT_OPTION_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_AMEDMENT_OPTION,
      btnLabel: 'administrative-file.table.actions.amedment',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_LOAD_ALL_PRONOUNCEMENTS_FROM_PARENT_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_LOAD_ALL_PRONOUNCEMENTS_FROM_PARENT_OPTION,
      btnLabel: 'administrative-file.table.actions.load-all',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_LOAD_UNFAVOURABLE_PRONOUNCEMENTS_FROM_PARENT_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_LOAD_UNFAVOURABLE_PRONOUNCEMENTS_FROM_PARENT_OPTION,
      btnLabel: 'administrative-file.table.actions.load-unfavorable',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_OMISSION_OPTION_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_OMISSION_OPTION,
      btnLabel: 'administrative-file.table.actions.delete-omission',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_RETURN_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_RETURN_OPTION,
      btnLabel: 'administrative-file.table.actions.return-expedient',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_SEND_RETURN_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_SEND_RETURN_OPTION,
      btnLabel: 'administrative-file.table.actions.edit-return-expedient',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_RETURN_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_DELETE_RETURN_OPTION,
      btnLabel: 'administrative-file.table.actions.delete-return-expedient',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TABLE_REOPEN_FILE_BUTTON: IActionButton =
    {
      action: MenuOptions.PIO_ADMINISTRATIVE_FILES_TABLE_REOPEN_FILE_OPTION,
      btnLabel: 'administrative-file.table.actions.reopen-file',
      accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
    };

  static readonly PIO_ADMINISTRATIVE_FILES_TREE_YES_BUTTON = 0;
  static readonly PIO_ADMINISTRATIVE_FILES_TREE_NO_BUTTON = 1;

  // PPC REQUEST TABLE
  static readonly PPC_REQUEST_ACCEPT_OPTION = 1;
  static readonly PPC_RETURN_REQUEST_OPTION = 2;
  static readonly PPC_REQUEST_EDIT_OPTION = 3;
  static readonly PPC_REQUEST_DETAIL_OPTION = 4;
  static readonly PPC_RETURN_REQUEST_SEND_OPTION = 5;
  static readonly PPC_RETURN_REQUEST_DELETE_OPTION = 6;
  static readonly PPC_CORRECT_REQUEST_NOT_ACCEPTED_OPTION = 7;
  static readonly PPC_DELETE_REQUEST_OPTION = 8;

  static readonly PPC_REQUEST_ACCEPT_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.PPC_REQUEST_ACCEPT_OPTION,
    btnLabel: 'solppc.table.actions.accept-request',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_RETURN_REQUEST_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.PPC_RETURN_REQUEST_OPTION,
    btnLabel: 'solppc.table.actions.create-os',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_RETURN_REQUEST_SEND_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.PPC_RETURN_REQUEST_SEND_OPTION,
    btnLabel: 'solppc.table.actions.send-os',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_RETURN_REQUEST_DELETE_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.PPC_RETURN_REQUEST_DELETE_OPTION,
    btnLabel: 'solppc.table.actions.delete-os',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_REQUEST_EDIT_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.PPC_REQUEST_EDIT_OPTION,
    btnLabel: 'solppc.table.actions.edit',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_REQUEST_DETAIL_BUTTON: IActionButton = {
    action: MenuOptions.PPC_REQUEST_DETAIL_OPTION,
    btnLabel: 'solppc.table.actions.detail',
    accessRoles: [Authority.PPC, Authority.PPC_READER]
  };

  static readonly PPC_CORRECT_REQUEST_NOT_ACCEPTED_BUTTON: IActionButton = {
    action: MenuOptions.PPC_CORRECT_REQUEST_NOT_ACCEPTED_OPTION,
    btnLabel: 'solppc.table.actions.correct-os',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_DELETE_REQUEST_OPTION_BUTTON: IActionButton = {
    action: MenuOptions.PPC_DELETE_REQUEST_OPTION,
    btnLabel: 'solppc.table.actions.delete-ppc',
    accessRoles: [Authority.PPC]
  };

  // PPC ADMINISTRATIVE FILE
  static readonly PPC_ADMINISTRATIVE_FILES_REPORT_PPC_OPTION = 1;
  static readonly PPC_ADMINISTRATIVE_FILES_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_OPTION = 2;
  static readonly PPC_ADMINISTRATIVE_FILES_RETURN_FILE_OPTION = 3;
  static readonly PPC_ADMINISTRATIVE_FILES_EDIT_FILE_OPTION = 4;
  static readonly PPC_ADMINISTRATIVE_FILES_SEE_FILE_DETAIL_OPTION = 5;
  static readonly PPC_ADMINISTRATIVE_FILES_SEND_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_OPTION = 6;
  static readonly PPC_ADMINISTRATIVE_FILES_DELETE_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_OPTION = 7;
  static readonly PPC_ADMINISTRATIVE_FILES_REQUEST_RESPONSE_LETTER_OPTION = 8;
  static readonly PPC_ADMINISTRATIVE_FILES_SEND_RETURN_OPTION = 9;
  static readonly PPC_ADMINISTRATIVE_FILES_DELETE_RETURN_OPTION = 10;
  static readonly PPC_ADMINISTRATIVE_FILES_REOPEN_FILE_OPTION = 11;
  static readonly PPC_ADMINISTRATIVE_FILES_ADD_SIGNED_REPORT_OPTION = 12;
  static readonly PPC_ADMINISTRATIVE_FILES_DELETE_REPORT_PROPOSITION_OPTION = 13;
  static readonly PPC_ADMINISTRATIVE_FILES_REFORMULATE_OPTION = 14;

  static readonly PPC_ADMINISTRATIVE_FILES_REPORT_PPC_BUTTON: IActionButton = {
    action: MenuOptions.PPC_ADMINISTRATIVE_FILES_REPORT_PPC_OPTION,
    btnLabel: 'ppc-administrative-file.table.actions.ppc-report',
    accessRoles: [Authority.PPC, Authority.PPC_READER]
  };

  static readonly PPC_ADMINISTRATIVE_FILES_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PPC_ADMINISTRATIVE_FILES_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_OPTION,
      btnLabel:
        'ppc-administrative-file.table.actions.request-complementary-information',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_RETURN_FILE_BUTTON: IActionButton = {
    action: MenuOptions.PPC_ADMINISTRATIVE_FILES_RETURN_FILE_OPTION,
    btnLabel: 'ppc-administrative-file.table.actions.return-file',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_ADMINISTRATIVE_FILES_EDIT_FILE_BUTTON: IActionButton = {
    action: MenuOptions.PPC_ADMINISTRATIVE_FILES_EDIT_FILE_OPTION,
    btnLabel: 'ppc-administrative-file.table.actions.edit-file',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_ADMINISTRATIVE_FILES_SEE_FILE_DETAIL_BUTTON: IActionButton =
    {
      action: MenuOptions.PPC_ADMINISTRATIVE_FILES_SEE_FILE_DETAIL_OPTION,
      btnLabel: 'ppc-administrative-file.table.actions.see-file-detail',
      accessRoles: [Authority.PPC, Authority.PPC_READER]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_SEND_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PPC_ADMINISTRATIVE_FILES_SEND_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_OPTION,
      btnLabel:
        'ppc-administrative-file.table.actions.send-correction-supplementary',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_DELETE_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PPC_ADMINISTRATIVE_FILES_DELETE_COMPLEMENTARY_INFORMATION_REQUEST_LETTER_OPTION,
      btnLabel:
        'ppc-administrative-file.table.actions.delete-correction-supplementary',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_REQUEST_RESPONSE_LETTER_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PPC_ADMINISTRATIVE_FILES_REQUEST_RESPONSE_LETTER_OPTION,
      btnLabel: 'ppc-administrative-file.table.actions.request-response',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_SEND_RETURN_BUTTON: IActionButton = {
    action: MenuOptions.PPC_ADMINISTRATIVE_FILES_SEND_RETURN_OPTION,
    btnLabel: 'ppc-administrative-file.table.actions.edit-return-expedient',
    accessRoles: [Authority.PPC]
  };

  static readonly PPC_ADMINISTRATIVE_FILES_DELETE_RETURN_BUTTON: IActionButton =
    {
      action: MenuOptions.PPC_ADMINISTRATIVE_FILES_DELETE_RETURN_OPTION,
      btnLabel: 'ppc-administrative-file.table.actions.delete-return-expedient',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_REOPEN_FILE_BUTTON: IActionButton = {
    action: MenuOptions.PPC_ADMINISTRATIVE_FILES_REOPEN_FILE_OPTION,
    btnLabel: 'ppc-administrative-file.table.actions.reopen-file',
    accessRoles: [Authority.INSPECTOR, Authority.OMISSION]
  };

  static readonly PPC_ADMINISTRATIVE_FILES_ADD_SIGNED_REPORT_OPTION_BUTTON: IActionButton =
    {
      action: MenuOptions.PPC_ADMINISTRATIVE_FILES_ADD_SIGNED_REPORT_OPTION,
      btnLabel: 'ppc-administrative-file.table.actions.add-signed',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_DELETE_REPORT_PROPOSITION_OPTION_BUTTON: IActionButton =
    {
      action:
        MenuOptions.PPC_ADMINISTRATIVE_FILES_DELETE_REPORT_PROPOSITION_OPTION,
      btnLabel: 'ppc-administrative-file.table.actions.delete-proposition',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_REFORMULATE_OPTION_BUTTON: IActionButton =
    {
      action: MenuOptions.PPC_ADMINISTRATIVE_FILES_REFORMULATE_OPTION,
      btnLabel: 'ppc-administrative-file.table.actions.reformulate',
      accessRoles: [Authority.PPC]
    };

  static readonly PPC_ADMINISTRATIVE_FILES_TREE_YES_BUTTON = 0;
  static readonly PPC_ADMINISTRATIVE_FILES_TREE_NO_BUTTON = 1;
}
