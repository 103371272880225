export interface IFileTypeItemsTreeComponent {
  id: number | null;
  name: string;
  parentId: number | null;
  treeId: number | null;
  expandable: boolean;
  essential: boolean;
  children?: IFileTypeItemsTreeComponent[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  level?: number | any;
}

export class FileTypeItemsTreeComponent implements IFileTypeItemsTreeComponent {
  constructor(
    public id: number | null,
    public name: string,
    public parentId: number | null,
    public treeId: number | null = null,
    public expandable: boolean,
    public essential: boolean,
    public children?: IFileTypeItemsTreeComponent[] | null
  ) {}
}
