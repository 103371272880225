import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@ic-material/material.module';
import { OmissionInspectionComponent } from '@ic-pages/omission-inspection/omission-inspection.component';
import { OMISSION_INSPECTION_ROUTE } from '@ic-pages/omission-inspection/omission-inspection.routing';
import { OmissionOperationalModule } from '@ic-pages/omission-inspection/operational/omission-operational.module';
import { OmissionReportsModule } from '@ic-pages/omission-inspection/reports/omission-reports.module';
import { OmissionTrackingModule } from '@ic-pages/omission-inspection/tracking/omission-tracking.module';
import { SharedModule } from '@ic-shared/shared.module';

const PROJECT_MODULES = [
  SharedModule,
  MaterialModule,
  OmissionOperationalModule,
  OmissionReportsModule,
  OmissionTrackingModule
];
const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [OmissionInspectionComponent],
  exports: [OmissionInspectionComponent],
  imports: [
    ...ANGULAR_MODULES,
    RouterModule.forChild(OMISSION_INSPECTION_ROUTE),
    ...PROJECT_MODULES
  ]
})
export class OmissionInspectionModule {}
