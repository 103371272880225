import { Injectable } from '@angular/core';

@Injectable()
export class FrbConstants {
  static readonly INSPECTION_REQUEST_PROCEDURE_CODE = 1000;
  static readonly INSPECTION_REQUEST_CORRECTION_PROCEDURE_CODE = 1005;
  static readonly INSPECTION_PROCEDURE_CODE = 3000;
  static readonly INSPECTION_OMISSION_PROCEDURE_CODE = 4000;

  //PHASE CODES
  static readonly INSPECTION_PHASE_CODE = 3000;
  static readonly REPORT_PROPOSITION_PHASE_CODE = 3002;
  static readonly STOPPED_PHASE_CODE = 3005;
  static readonly FINISHED_PHASE_CODE = 6000;

  // LOAD PRONOUNCEMENTS CODE
  static readonly PRONOUNCEMENTS_NEW_INSPECTION_CODE = 0;
  static readonly PRONOUNCEMENTS_LOAD_ALL_CODE = 1;
  static readonly PRONOUNCEMENTS_LOAD_UNFAVORABLE_CODE = 2;

  // INTERNAL_ADMINISTRATIVE_FILE_CODES
  static readonly INTERNAL_ADMINISTRATIVE_FILE_INSPECTION_CODE = 1000;
  static readonly INTERNAL_ADMINISTRATIVE_FILE_INSPECTION_OMISSION_CODE = 2000;

  // DOCUMENTS_TYPE_CODE
  static readonly URGENT_DOCUMENT_TYPE_CODE = 2;
  static readonly INSPECTION_DOCUMENT_TYPE_CODE = 3;
  static readonly ADITIONAL_DOCUMENT_TYPE_CODE = 10;

  // CORRECTION_PROCEDURE_RESULT_CODES
  static readonly INSPECTION_REQUEST_CORRECTION_PROCEDURE_RESULT_NOT_SENT_CODE = 0;
  static readonly INSPECTION_REQUEST_CORRECTION_PROCEDURE_RESULT_SENT_CODE = 1;

  // INSPECTION_REQUEST_RESULT_CODES
  static readonly INSPECTION_REQUEST_RESULT_CODE_ACCEPTED = 1;
  static readonly INSPECTION_REQUEST_RESULT_CODE_NOT_ACCEPTED = 0;

  // INSPECTION_RESULT_CODES
  static readonly INSPECTION_RESULT_UNFAVORABLE_CODE = 0;
  static readonly INSPECTION_RESULT_FAVORABLE_CODE = 1;
  static readonly INSPECTION_RESULT_FAVORABLE_OBSERVATIONS_CODE = 2;

  // OMISSION CONCLUSION CODES
  static readonly CONCLUSION_FAVORABLE_CODE = 1000;
  static readonly CONCLUSION_UNFAVORABLE_1_CODE = 2000;
  static readonly CONCLUSION_UNFAVORABLE_2_CODE = 3000;
  static readonly CONCLUSION_UNFAVORABLE_3_CODE = 4000;

  //RETURN_PROCEDURE_CODE
  static readonly ADMINISTRATIVE_FILE_RESULT_RETURNED_CODE = 3;

  //PROCEDURE_TYPE_CODE
  static readonly INSPECTION_REQUEST_TYPE = 1000;
  static readonly INSPECTION_REQUEST_AMENDMENT_TYPE = 1010;
  static readonly REPORT_REQUEST_TYPE = 2000;
  static readonly REPORT_RESPONSE_TYPE = 2020;
  static readonly INSPECTION_TYPE = 3000;
  static readonly INSPECTION_AMENDMENT_TYPE = 3010;
  static readonly INSPECTION_OMISSION_TYPE = 4000;
  static readonly OCEX_NOTIFICATION_TYPE = 5000;
  static readonly RETURN_INSPECTION_REQUEST_TYPE = 1005;
  static readonly RETURN_INSPECTION_TYPE = 3005;

  // SPENDING_PHASE_CODES
  static readonly SPENDING_PHASE_O_CODE = 300;

  // OMISSION PROCEDURE_TYPE_CODE
  static readonly INSPECTION_OMISSION_REQUEST_CORRECTION_TYPE = 2500;
  static readonly INSPECTION_OMISSION_RESPONSE_CORRECTION_TYPE = 2520;

  // OMISSION_REASON_CODES
  static readonly OMISSION_REASON_SPEND_CODE = 1000;
  static readonly OMISSION_REASON_INCORRECT_CODE = 4000;
  static readonly OMISSION_REASON_NOT_JUSTIFY_CODE = 6000;

  static readonly SAVE_FRB = 0;
  static readonly SAVE_AND_VALIDATE_FRB = 1;
  static readonly INSPECTION_REQUEST_ACCEPTED = 2;

  static readonly CREATE_AMENDMENT_ADMINISTRATIVE_FILE = 0;
  static readonly CREATE_AMENDMENT_PROCEDURE = 1;
  static readonly SAVE_VALIDATE_PROCEDURE = 2;
  static readonly SAVE_VALIDATE_ADMINISTRATIVE_FILE = 3;
  static readonly CREATE_AND_VALIDATE = 4;
  static readonly CREATE_AND_VALIDATE_ADMINISTRATIVE_FILE = 5;

  //EXCEL_REPORT_FILES_CODES
  static readonly INSPECTION_PROCEDURE_ITERATION_CODE = 3600;
  static readonly INSPECTION_ADMINISTRATIVE_FILE_CODE = 3500;
  static readonly INSPECTION_OBSERVATION_CODE = 3700;
}
