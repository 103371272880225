<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  (cdkDropListDropped)="drop($event)"
  cdkDropList
  class="example-tree"
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodePadding=""
    cdkDrag
    [cdkDragData]="node"
    [cdkDragDisabled]="node.locked"
  >
    <div
      [ngClass]="{
        'dragable-div-child-node': true,
        'node-locked': node.locked,
        'dragable-cursor': !node.locked
      }"
    >
      <button mat-icon-button disabled title="Draggable Button"></button>
      <mat-icon class="mat-icon-rtl-mirror" cdkDragHandle>menu</mat-icon>
      <span
        [ngClass]="{ strikethrough: node.isDeleted }"
        [style]="'color:' + node.color"
        [matTooltip]="node.description"
        cdkDragHandle
        >{{ node.name }}</span
      >
    </div>

    <!-- {{ node.name }}: {{ node.desc }} -->
  </mat-tree-node>
  <mat-tree-node
    *matTreeNodeDef="let node; when: hasChild"
    matTreeNodePadding=""
    cdkDrag
    [cdkDragData]="node"
    [cdkDragDisabled]="node.locked"
  >
    <div
      [ngClass]="{
        'dragable-div-parent-node': true,
        'node-locked': node.locked,
        'dragable-cursor': !node.locked
      }"
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        (click)="expansionModel.toggle(node.id)"
        [attr.aria-label]="'toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <span
        [ngClass]="{ strikethrough: node.isDeleted }"
        [style]="'color:' + node.color"
        [matTooltip]="node.description"
        cdkDragHanlded
        >{{ node.name }}</span
      >
    </div>
  </mat-tree-node>
</mat-tree>
