<ng-container *ngIf="isNotValid">
  <ng-container *ngIf="formControl.hasError('required')">
    {{ errorRequired }}
  </ng-container>
  <ng-container *ngIf="formControl.hasError('email')">{{
    errorEmail
  }}</ng-container>
  <ng-container *ngIf="formControl.hasError('max')">{{
    errorMax
  }}</ng-container>
  <ng-container *ngIf="formControl.hasError('min')">{{
    errorMin
  }}</ng-container>
</ng-container>
