<mat-sidenav-container class="layout">
  <mat-sidenav
    #panelTop
    mode="over"
    position="start"
    [style.width]="panelTopWidth + '%'"
  >
    <div id="top-panel-outlet" cdkPortalOutlet></div>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
