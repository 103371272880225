/* eslint-disable max-len */
import { Icons } from '@ic-app/constants/app.icons';
import {
  getTimestampBasedId,
  isEmptyNullOrUndefinedString,
  isNullOrUndefined
} from '@ic-app/core/util/global-util';
import { IListActionButtons } from '@ic-app/models/list-action-buttons.model';
import {
  IImpactLevel,
  ImpactLevel
} from '@ic-models/prior-operational/ppc-report/ppc-observations/ppc-observation';

export const enum PpcItemCommentTypes {
  REPORT_ITEM_COMMENT_SCIPION_CATALOG = 100,
  REPORT_ITEM_COMMENT_NEW_MODEL = 200,
  REPORT_ITEM_COMMENT_NO_MODEL = 250,
  REPORT_ITEM_COMMENT_EXISTING = 300
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPpcItemCommentsListDTO {
  ppcItemCommentReportList: IPpcItemCommentReportList[];
  ppcItemCommentModelDefinitionList: IPpcItemCommentModelList[] | null;
  ppcItemCommentReportListToDelete?: number[];
  ppcItemCommentModelListToDelete?: number[] | null;
}

export class PpcItemCommentToSave implements IPpcItemCommentsListDTO {
  ppcItemCommentReportList: IPpcItemCommentReportList[];
  ppcItemCommentModelDefinitionList: IPpcItemCommentModelList[] | null;
  ppcItemCommentReportListToDelete: number[];
  ppcItemCommentModelListToDelete: number[] | null;

  constructor(
    ppcItemCommentReportList: IPpcItemCommentReportList[],
    ppcItemCommentModelDefinitionList: IPpcItemCommentModelList[] | null,
    ppcItemCommentReportListToDelete: number[],
    ppcItemCommentModelListToDelete: number[] | null
  ) {
    this.ppcItemCommentReportList = ppcItemCommentReportList;
    this.ppcItemCommentModelDefinitionList = ppcItemCommentModelDefinitionList;
    this.ppcItemCommentReportListToDelete = ppcItemCommentReportListToDelete;
    this.ppcItemCommentModelListToDelete = ppcItemCommentModelListToDelete;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPpcItemCommentToDeleteDTO {
  ppcModelItemCommentId: number | null;
  ppcReportItemCommentId: number | null;
  isPpcItemCommentFromReportPpc: boolean;
  isDeletedFromReportPpc?: boolean | null;
  ppcReportProcedureId: number;
}

export class PpcItemCommentToDeleteDTO implements IPpcItemCommentToDeleteDTO {
  ppcModelItemCommentId: number | null;
  ppcReportItemCommentId: number | null;
  isPpcItemCommentFromReportPpc: boolean;
  isDeletedFromReportPpc?: boolean | null;
  ppcReportProcedureId: number;
  ppcItemCommentsListDTO: PpcItemCommentToSave;

  constructor(
    ppcModelItemCommentId: number | null,
    ppcReportItemCommentId: number | null,
    isPpcItemCommentFromReportPpc: boolean,
    isDeletedFromReportPpc: boolean | null,
    ppcReportProcedureId: number,
    ppcItemCommentToSave: PpcItemCommentToSave
  ) {
    this.ppcModelItemCommentId = ppcModelItemCommentId;
    this.ppcReportItemCommentId = ppcReportItemCommentId;
    this.isPpcItemCommentFromReportPpc = isPpcItemCommentFromReportPpc;
    this.isDeletedFromReportPpc = isDeletedFromReportPpc;
    this.ppcReportProcedureId = ppcReportProcedureId;
    this.ppcItemCommentsListDTO = ppcItemCommentToSave;
  }
}

// Interface para la primera tabla => Recomendaciones para el Informe
export interface IPpcItemCommentReportList {
  code?: number;
  ppcSupportedReportItemComment: IPpcSupportedReportItemComment;
  ppcReportItemComment: IPpcReportItemComment;
  actions?: IListActionButtons[];
  ppcAdministrativeFileId: number;
  ppcCurrentProcedure: number;
  categoryLabel: string | undefined;
  categoryIcon: string | undefined;
  categoryIconColor: string | undefined;
  categoryCode?: number;
  impactLevelLabel: string | undefined;
  impactLevelCode?: number;
  impactLevelIconColor: string | undefined;
  changed: boolean;
  ppcModelItemCommentId?: number;
  ppcSupportedVerificationItemId?: number;
  firstName?: string;
  lastName?: string;
  commentEmail?: string;
  commentDate?: string;
}

export class PpcItemCommentReportList implements IPpcItemCommentReportList {
  code?: number;
  ppcSupportedReportItemComment: IPpcSupportedReportItemComment;
  ppcReportItemComment: IPpcReportItemComment;
  actions?: IListActionButtons[];
  ppcAdministrativeFileId: number;
  ppcCurrentProcedure: number;
  categoryLabel: string | undefined;
  categoryIcon: string | undefined;
  categoryIconColor: string | undefined;
  impactLevelLabel: string | undefined;
  impactLevelIconColor: string | undefined;
  changed: boolean;
  ppcModelItemCommentId?: number;

  constructor(
    wording: string | null,
    orderItemComment: number,
    ppcReportProcedureId: number,
    isPpcItemCommentModel: boolean | null,
    ppcItemCommentModel: IPpcItemCommentModelList | null,
    ppcAdministrativeFileId: number,
    ppcCurrentProcedure: number,
    code: number | null,
    ppcSupportedVerificationItemId: number,
    firstName: string,
    lastName: string,
    commentEmail: string,
    commentDate: string,
    localId: string | null = null
  ) {
    if (isPpcItemCommentModel === true) {
      this.code = 200;
      this.categoryIconColor = 'warning';
      this.categoryLabel = 'ppc-report.observation-panel.category-labels.model';
      this.actions = [
        Icons.ICON_EMPTY,
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
      this.categoryIcon = 'u_letter';
      this.impactLevelIconColor = 'stopped';
      this.impactLevelLabel =
        'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    }
    if (isPpcItemCommentModel === false) {
      this.code = 250;
      this.categoryIconColor = 'no-model';
      this.categoryLabel =
        'ppc-report.observation-panel.category-labels.no-model';
      this.actions = [
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_EDIT_OPTION,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
      this.categoryIcon = 'u_letter';
      this.impactLevelIconColor = 'stopped';
      this.impactLevelLabel =
        'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    }
    if (isPpcItemCommentModel === null && code !== null) {
      if (code === 300) {
        this.categoryIconColor = 'stopped';
        this.categoryLabel =
          'ppc-report.observation-panel.category-labels.existing';
        this.ppcSupportedReportItemComment =
          ppcItemCommentModel?.ppcSupportedReportItemComment as IPpcSupportedReportItemComment;
        this.categoryIcon = 'u_letter';
        this.impactLevelIconColor = 'stopped';
        this.impactLevelLabel =
          'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
      } else if (code === 100) {
        this.ppcSupportedReportItemComment = new PpcSupportedReportItemComment(
          wording,
          isEmptyNullOrUndefinedString(localId)
            ? getTimestampBasedId()
            : localId
        );

        this.impactLevelIconColor = ppcItemCommentModel?.impactLevelIconColor;
        this.impactLevelLabel = ppcItemCommentModel?.impactLevelLabel;
        this.categoryIcon = ppcItemCommentModel?.categoryIcon;
        this.categoryIconColor = ppcItemCommentModel?.categoryIconColor;
        this.categoryLabel = ppcItemCommentModel?.categoryLabel;
      } else {
        this.categoryIconColor = 'warning';
        this.categoryLabel =
          'ppc-report.observation-panel.category-labels.model';
        this.ppcSupportedReportItemComment = new PpcSupportedReportItemComment(
          wording,
          isEmptyNullOrUndefinedString(localId)
            ? getTimestampBasedId()
            : localId
        );
        this.categoryIcon = 'u_letter';
        this.impactLevelIconColor = 'stopped';
        this.impactLevelLabel =
          'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
      }
      this.code = code;
      this.actions = [
        Icons.ICON_EMPTY,
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
    } else {
      this.ppcSupportedReportItemComment = new PpcSupportedReportItemComment(
        wording,
        isEmptyNullOrUndefinedString(localId) ? getTimestampBasedId() : localId
      );
    }

    this.ppcAdministrativeFileId = ppcAdministrativeFileId;
    this.ppcCurrentProcedure = ppcCurrentProcedure;
    this.ppcReportItemComment = new PpcReportItemComment(
      orderItemComment,
      ppcReportProcedureId,
      ppcSupportedVerificationItemId,
      firstName,
      lastName,
      commentEmail,
      commentDate
    );

    this.changed = true;
  }
}

// Interface para la segunda tabla => Recomendaciones de modelo
export interface IPpcItemCommentModelList {
  code: number;
  createdDate?: Date;
  insertMoment?: Date;
  ppcSupportedReportItemComment: IPpcSupportedReportItemComment;
  ppcReportItemCommentModel: IPpcReportItemCommentModelDefinition;
  iconColor?: string;
  actions?: IListActionButtons[];
  categoryLabel: string;
  categoryIcon: string;
  categoryIconColor: string;
  categoryCode?: number;
  impactLevelLabel: string;
  impactLevelCode?: number;
  impactLevelIconColor: string;
  changed: boolean;
  isNewPpcItemComment?: boolean;
  isPpcItemCommentReport?: boolean;
  checked?: boolean;
  itemCommentGroup?: number;
}

export class PpcItemCommentModelList implements IPpcItemCommentModelList {
  code: number;
  insertMoment?: Date;
  ppcSupportedReportItemComment: IPpcSupportedReportItemComment;
  ppcReportItemCommentModel: IPpcReportItemCommentModelDefinition;
  iconColor?: string;
  actions?: IListActionButtons[];
  categoryLabel = '';
  categoryIcon: string;
  categoryIconColor: string;
  impactLevelLabel: string;
  impactLevelIconColor: string;
  changed: boolean;
  isPpcItemCommentReport?: boolean;
  isNewPpcItemComment?: boolean;

  constructor(
    wording: string,
    ppcAdministrativeFileId: number,
    ppcCurrentProcedureId: number,
    ppcSupportedVerificationItemId: number,
    localId: string | null = null
  ) {
    this.code = 200;
    this.insertMoment = new Date();
    this.ppcSupportedReportItemComment = new PpcSupportedReportItemComment(
      wording,
      isNullOrUndefined(localId) ? getTimestampBasedId() : localId
    );
    this.ppcReportItemCommentModel = new PpcReportItemCommentModelDefinition(
      ppcAdministrativeFileId,
      ppcCurrentProcedureId,
      ppcSupportedVerificationItemId
    );
    this.impactLevelIconColor = 'stopped';
    this.actions = [
      Icons.ICON_EMPTY,
      Icons.ICON_EDIT_OPTION,
      Icons.ICON_ARROW_CROSS
    ];
    this.categoryIcon = 'u_letter';
    this.categoryIconColor = 'warning';
    this.impactLevelLabel =
      'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    this.changed = true;
    this.isPpcItemCommentReport = true;
    this.isNewPpcItemComment = true;
  }
}

export interface IPpcSupportedReportItemComment {
  id?: number;
  localId?: string | null;
  code?: number;
  impactLevel: IImpactLevel;
  idTranslation?: number;
  wording: string | null;
}

export class PpcSupportedReportItemComment
  implements IPpcSupportedReportItemComment
{
  impactLevel: IImpactLevel;
  wording: string | null;
  localId?: string | null;

  constructor(wording: string | null, localId: string | null = null) {
    this.impactLevel = new ImpactLevel();
    this.wording = wording;
    this.localId = localId;
  }
}

export interface IPpcReportItemComment {
  id?: number;
  localId?: string;
  deleted: boolean;
  orderItemComment: number;
  ppcReportProcedureId: number;
  ppcParentSupportedReportItemCommentId?: number | null;
  ppcParentSupportedReportItemCommentWording?: string | null;
  ppcParentSupportedReportItemCommentLocalId?: string | null;
  ppcSupportedVerificationItemId: number | null;
  firstName: string;
  lastName: string;
  commentEmail: string;
  commentDate: string;
  commentExpanded: boolean;
  explanatoryNote?: string;
}

export class PpcReportItemComment implements IPpcReportItemComment {
  deleted: boolean;
  orderItemComment: number;
  ppcReportProcedureId: number;
  ppcParentSupportedReportItemCommentId: number | null;
  ppcSupportedVerificationItemId: number | null;
  commentExpanded = false;
  firstName: string;
  lastName: string;
  commentEmail: string;
  commentDate: string;

  constructor(
    orderItemComment: number,
    ppcReportProcedureId: number,
    ppcSupportedVerificationItemId: number,
    firstName: string,
    lastName: string,
    commentEmail: string,
    commentDate: string
  ) {
    this.deleted = false;
    this.orderItemComment = orderItemComment;
    this.ppcReportProcedureId = ppcReportProcedureId;
    this.ppcParentSupportedReportItemCommentId = null;
    this.ppcSupportedVerificationItemId = ppcSupportedVerificationItemId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.commentEmail = commentEmail;
    this.commentDate = commentDate;
  }
}

export interface IPpcReportItemCommentModelDefinition {
  id?: number;
  localId?: string;
  itemCommentEdits?: number;
  itemCommentRelevance?: number;
  itemCommentUses?: number;
  areaId?: number;
  ppcAdministrativeFileId?: number;
  ppcCurrentProcedureId?: number;
  topicId?: number;
  deleted: boolean;
  ppcSupportedVerificationItemId: number | null;
}

export class PpcReportItemCommentModelDefinition
  implements IPpcReportItemCommentModelDefinition
{
  ppcAdministrativeFileId: number;
  ppcCurrentProcedureId: number;
  deleted: boolean;
  ppcSupportedVerificationItemId: number | null;

  constructor(
    ppcAdministrativeFileId: number,
    ppcCurrentProcedureId: number,
    ppcSupportedVerificationItemId: number
  ) {
    this.ppcAdministrativeFileId = ppcAdministrativeFileId;
    this.ppcCurrentProcedureId = ppcCurrentProcedureId;
    this.deleted = false;
    this.ppcSupportedVerificationItemId = ppcSupportedVerificationItemId;
  }
}
