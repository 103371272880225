import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ic-slidetoggle',
  templateUrl: './slidetoggle.component.html'
})
export class SlidetoggleComponent {
  //implements OnInit {
  @Input() color = '';
  @Input() checked?: boolean;
  @Input() slideLabel = '';
  @Input() slideControl?: FormControl | null;
  @Output() valueChange = new EventEmitter<boolean>();

  toggleChange(): void {
    this.valueChange.emit(!this.slideControl?.value);
  }
}
