import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAutoCompleteElement } from '@ic-app/components/form/input-autocomplete/input-autocomplete.component';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import {
  IAdministrativeFileTypeDetailList,
  IAdministrativeFileTypeList
} from '@ic-app/models/settings/prior-inspection/observations/administrative-file-type';
import { IAdministrativeFileTypeCategory } from '@ic-app/models/settings/prior-inspection/observations/administrative-file-type-category.model';
import { IObservationDetailData } from '@ic-app/models/settings/prior-inspection/observations/detail-observation/observation-detail.model';
import { IManageInModelObservationData } from '@ic-app/models/settings/prior-inspection/observations/manage-in-model-observation/observation-manage-in-model.model';
import { IObservationCategory } from '@ic-app/models/settings/prior-inspection/observations/observation-category.model';
import { IObservationImpactLevel } from '@ic-app/models/settings/prior-inspection/observations/observation-impact-level.model';
import { IObservationScipionUser } from '@ic-app/models/settings/prior-inspection/observations/observation-scipion-user.model';
import { IObservationVisibility } from '@ic-app/models/settings/prior-inspection/observations/observation-visibility.model';
import { ISettingsPriorInsepctionObservationsFilter } from '@ic-app/models/settings/prior-inspection/observations/settings-prior-inspection-observations-filter.model';
import {
  IObservationEntity,
  ISettingsObservationData,
  ISettingsPriorInsepctionObservations
} from '@ic-app/models/settings/prior-inspection/observations/settings-prior-inspection-observations.model';
import { ITableAdministrativeFileTypesList } from '@ic-app/models/settings/prior-inspection/observations/table-administrative-file-type';
import { environment } from 'environments/environment';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { AppConfigService } from '../app-config.service';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsPriorInspectionObservationsService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  /**
   * Lista todas las observaciones y/o las filtra
   * @param filter
   * @param pageNumber
   * @param pageSize
   * @param filteredEntityId
   * @returns
   */
  findObservations(
    filter: ISettingsPriorInsepctionObservationsFilter,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<ISettingsPriorInsepctionObservations[]> {
    const req = {
      searchBox: filter.searchBox,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      observationCategoryCode:
        !!filter.observationCategoryCode &&
        filter.observationCategoryCode.length > 0
          ? JSON.stringify(filter.observationCategoryCode)
          : undefined,
      observationImpactLevelCode:
        !!filter.observationImpactLevelCode &&
        filter.observationImpactLevelCode.length > 0
          ? JSON.stringify(filter.observationImpactLevelCode)
          : undefined,
      observationScipionUser:
        !!filter.observationScipionUser &&
        filter.observationScipionUser.length > 0
          ? JSON.stringify(filter.observationScipionUser)
          : undefined,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ISettingsPriorInsepctionObservations[]>(
        `${baseUrl}/settingsPriorInspection/findObservations`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ISettingsPriorInsepctionObservations[]>) =>
            resp.body as ISettingsPriorInsepctionObservations[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las observaciones FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Cuenta las observaciones totales teniendo en cuenta los filtros
   * @param filter
   * @param mainTable
   * @returns
   */
  countObservations(
    filter: ISettingsPriorInsepctionObservationsFilter,
    mainTable: boolean,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      mainTable: mainTable,
      observationCategoryCode:
        !!filter.observationCategoryCode &&
        filter.observationCategoryCode.length > 0
          ? JSON.stringify(filter.observationCategoryCode)
          : undefined,
      observationImpactLevelCode:
        !!filter.observationImpactLevelCode &&
        filter.observationImpactLevelCode.length > 0
          ? JSON.stringify(filter.observationImpactLevelCode)
          : undefined,
      observationScipionUser:
        !!filter.observationScipionUser &&
        filter.observationScipionUser.length > 0
          ? JSON.stringify(filter.observationScipionUser)
          : undefined,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/settingsPriorInspection/countObservations`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar las observaciones de configuracion FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los categoryCodes (Tipo) para el filtro
   * @returns
   */
  getCategoryCodes(): Observable<IObservationCategory[]> {
    const options = createRequestOption();
    return this.http
      .get<IObservationCategory[]>(
        `${baseUrl}/settingsPriorInspection/getCategoryCodes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationCategory[]>) =>
            resp.body as IObservationCategory[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles tipos de observaciones:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene las transcendencias (ImpactLevelCodes) para el filtro
   * @returns
   */
  getImpactLevelCodes(): Observable<IObservationImpactLevel[]> {
    const options = createRequestOption();
    return this.http
      .get<IObservationImpactLevel[]>(
        `${baseUrl}/settingsPriorInspection/getImpactLevelCodes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationImpactLevel[]>) =>
            resp.body as IObservationImpactLevel[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles tipos de transcendencia:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los usuarios para el filtro
   * @returns
   */
  getScipionUsers(): Observable<IObservationScipionUser[]> {
    const options = createRequestOption();
    return this.http
      .get<IObservationScipionUser[]>(
        `${baseUrl}/settingsPriorInspection/getScipionUsers`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationScipionUser[]>) =>
            resp.body as IObservationScipionUser[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles usuarios:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los distintos tipos de visibilidad de las observaciones
   * @returns
   */
  getVisibilityTypes(): Observable<IObservationVisibility[]> {
    const options = createRequestOption();
    return this.http
      .get<IObservationVisibility[]>(
        `${baseUrl}/settingsPriorInspection/getVisibilityTypes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationVisibility[]>) =>
            resp.body as IObservationVisibility[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles tipos de visibilidad de las observaciones:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los tipos de expediente para la tabla
   * @param entitiesSelected
   * @param categoryCodeSelected
   * @returns
   */
  getAdministrativeFileTypes(
    entitiesSelected: number[],
    categoryCodeSelected: number
  ): Observable<IAdministrativeFileTypeList[]> {
    const req = {
      entityIds: entitiesSelected,
      categoryCodeSelected: categoryCodeSelected
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileTypeList[]>(
        `${baseUrl}/settingsPriorInspection/listAdministrativeFileTypes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileTypeList[]>) =>
            resp.body as IAdministrativeFileTypeList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente en las observaciones de ' +
              'configuración FRB del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene las distintas categorias para los tipos de expediente
   * @returns
   */
  getAdministrativeFileTypeCategories(): Observable<
    IAdministrativeFileTypeCategory[]
  > {
    const options = createRequestOption();
    return this.http
      .get<IAdministrativeFileTypeCategory[]>(
        `${baseUrl}/settingsPriorInspection/getAdministrativeFileTypeCategories`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileTypeCategory[]>) =>
            resp.body as IAdministrativeFileTypeCategory[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las categorias de los tipos de expediente:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Guarda una observación
   * @param observationData
   * @returns
   */
  saveObservation(
    observationData: ISettingsObservationData
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${baseUrl}/settingsPriorInspection/saveObservation`,
        observationData,
        {
          headers: HttpConstants.GET_HEADERS
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al guardar las observaciones de configuración FRB:',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al guardar las observaciones de configuración FRB:',
              err.message
            );
            return new Error(
              'settings-prior-inspection.error-messages.save-observation'
            );
          });
        })
      );
  }

  /**
   * Obtiene los datos de la card de inromacion general de la observación buscando por su id
   * @param observationId
   * @returns
   */
  findObservationDetail(
    observationId: number
  ): Observable<IObservationDetailData> {
    const req = {
      observationId: observationId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationDetailData>(
        `${baseUrl}/settingsPriorInspection/findObservationDetail`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationDetailData>) =>
            resp.body as IObservationDetailData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar el detalle de la observación del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los tipos de expediente que tiene la observación
   * @param observationId
   * @returns
   */
  getAdministrativeFileTypesByObservationId(
    observationId: number,
    entitiesIdChecked: number[]
  ): Observable<IAdministrativeFileTypeDetailList[]> {
    const req = {
      observationId: observationId,
      entitiesIdChecked: entitiesIdChecked
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileTypeDetailList[]>(
        `${baseUrl}/settingsPriorInspection/listAdministrativeFileTypesByObservationId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileTypeDetailList[]>) =>
            resp.body as IAdministrativeFileTypeDetailList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente de la observación de ' +
              'configuración FRB del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Lista los tipos de expedientes de la entidad a copiar
   * @param observationId
   * @param observationEntityId
   * @returns
   */
  getObservationEntityAFTs(
    observationId: number,
    observationEntityId: number
  ): Observable<ITableAdministrativeFileTypesList[]> {
    const req = {
      observationId: observationId,
      observationEntityId: observationEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ITableAdministrativeFileTypesList[]>(
        `${baseUrl}/settingsPriorInspection/listObservationEntityAFTs`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ITableAdministrativeFileTypesList[]>) =>
            resp.body as ITableAdministrativeFileTypesList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente de la observación a copiar del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Lista los tipos de expedientes en comun entre las entities seleccionadas y
   * la entidad de la observación a copiar
   * @param entitiesSelectedIds
   * @param observationAFTs
   * @returns
   */
  getCommonAFTBetweenObservationEntityAndEntitiesSelected(
    entitiesSelectedIds: number[],
    administrativeFileTypeIds: number[]
  ): Observable<ITableAdministrativeFileTypesList[]> {
    const req = {
      entitiesSelectedIds: entitiesSelectedIds,
      administrativeFileTypeIds: administrativeFileTypeIds
    };
    const options = createRequestOption(req);
    return this.http
      .get<ITableAdministrativeFileTypesList[]>(
        `${baseUrl}/settingsPriorInspection/listCommonAFTsByEntitiesSelected`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ITableAdministrativeFileTypesList[]>) =>
            resp.body as ITableAdministrativeFileTypesList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente en común' +
              'de la observación a copiar del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los tipos de expediente comunes a las entidades seleccionadas
   * @param entitiesSelected
   * @param categoryCodeDescription
   * @returns
   */
  getCopyAdministrativeFileTypes(
    entitiesSelected: number[],
    categoryCodeDescription: string
  ): Observable<ITableAdministrativeFileTypesList[]> {
    const req = {
      entityIds: entitiesSelected,
      categoryCodeDescription: categoryCodeDescription
    };
    const options = createRequestOption(req);
    return this.http
      .get<ITableAdministrativeFileTypesList[]>(
        `${baseUrl}/settingsPriorInspection/listCopyAdministrativeFileTypes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ITableAdministrativeFileTypesList[]>) =>
            resp.body as ITableAdministrativeFileTypesList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente a copiar en las observaciones de ' +
              'configuración FRB del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveCopyObservation(
    observationId: number,
    categoryCode: number,
    entitiesSelected: number[],
    administrativeFileTypesSelected: ITableAdministrativeFileTypesList[]
  ): Observable<boolean> {
    const req = {
      observationId: observationId,
      categoryCode: categoryCode,
      entitiesSelected: entitiesSelected
    };
    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/settingsPriorInspection/saveCopyObservation`,
        administrativeFileTypesSelected,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            `Se ha producido un error al guardar la copia de la observación de configuración FRB:`,
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene las entidades en las que está la observación por su id
   * @param observationId
   * @param searchBox
   * @param pageNumber
   * @param pageSize
   * @param filteredEntityId
   * @returns
   */
  findEntitiesByObservationId(
    observationId: number,
    searchBox: string,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<IObservationEntity[]> {
    const req = {
      observationId: observationId,
      searchBox: searchBox,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationEntity[]>(
        `${baseUrl}/settingsPriorInspection/findEntitiesByObservationId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationEntity[]>) =>
            resp.body as IObservationEntity[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las entidades de la observación del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Cuenta las entidades pertenecientees a la observacion
   * @param searchBox
   * @param observationId
   * @param filteredEntityId
   * @returns
   */
  countEntitiesByObservationId(
    searchBox: string,
    observationId: number,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: searchBox,
      observationId: observationId,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(
        `${baseUrl}/settingsPriorInspection/countEntitiesByObservationId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar las entidades de una observacion en configuracion FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Busca la entidad seleccionada para la observación
   * @param observationId
   * @param entityId
   * @returns
   */
  findEntityByObservationIdAndEntityId(
    observationId: number,
    entityId: number
  ): Observable<IObservationEntity[]> {
    const req = {
      observationId: observationId,
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationEntity[]>(
        `${baseUrl}/settingsPriorInspection/findEntityByObservationIdAndEntityId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationEntity[]>) =>
            resp.body as IObservationEntity[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la entidad de la observación del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los tipos de expediente de la entidad a gestionar en el modelo
   * @param observationEntityId
   * @param categoryCodeDescription
   * @returns
   */
  getAdministrativeFileTypesByEntityId(
    observationEntityId: number,
    categoryCodeSelected: number
  ): Observable<ITableAdministrativeFileTypesList[]> {
    const req = {
      entityId: observationEntityId,
      categoryCodeSelected: categoryCodeSelected
    };
    const options = createRequestOption(req);
    return this.http
      .get<ITableAdministrativeFileTypesList[]>(
        `${baseUrl}/settingsPriorInspection/listAdministrativeFileTypesByEntityId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ITableAdministrativeFileTypesList[]>) =>
            resp.body as ITableAdministrativeFileTypesList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente a gestionar en modelo ' +
              'en las observaciones de configuración FRB del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Comprueba si una observación está utilizada
   * @param observationId
   * @returns
   */
  checkIfObservationIsUsed(observationId: number): Observable<boolean> {
    const req = {
      observationId: observationId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(
        `${baseUrl}/settingsPriorInspection/checkIfObservationIsUsed`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al comprobar si la observación ya está siendo utilizada:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Guardado de una obsrvación desde el panel de gestionar en modelo
   * @param observationId
   * @param observationEntityId
   * @param observationToSaveData
   * @returns
   */
  saveManageInModelObservation(
    observationId: number,
    observationEntityId: number,
    observationToSaveData: IManageInModelObservationData
  ): Observable<boolean> {
    const req = {
      observationId: observationId,
      entityId: observationEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/settingsPriorInspection/saveManageInModelObservation`,
        observationToSaveData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al guardar la observación gestionada en el modelo de configuración FRB:',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al guardar la observación gestionada en el modelo de configuración FRB:',
              err.message
            );
            return new Error(
              'settings-prior-inspection.error-messages.save-manage-in-model-observation'
            );
          });
        })
      );
  }

  /**
   * Elimina los modelos de observación de la observación y entidad seleccionada
   * @param observationId
   * @param entityId
   * @returns
   */
  deleteObservationModelForObservationAndEntity(
    observationId: number,
    entityId: number
  ): Observable<boolean> {
    const req = {
      observationId: observationId,
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(
        `${baseUrl}/settingsPriorInspection/deleteObservationModelForObservationAndEntity`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al eliminar el modelo de la observación para la entidad:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los datos de la entidad filtrada
   * @param filteredEntityId
   * @returns
   */
  getFilteredEntityDataObservation(
    filteredEntityId: number
  ): Observable<IObservationEntity> {
    const req = {
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IObservationEntity>(
        `${baseUrl}/settingsPriorInspection/getFilteredEntityDataObservation`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IObservationEntity>) =>
            resp.body as IObservationEntity
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al cargar la entidad filtrada de la observación:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Devuelve los ids de las entidades en las que está presente la observacion
   * @param observationId
   * @returns
   */
  getEntitiesIdsByObservationId(observationId: number): Observable<number[]> {
    const req = {
      observationId: observationId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number[]>(
        `${baseUrl}/settingsPriorInspection/getEntitiesIdsByObservationId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number[]>) => resp.body as number[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las entidades filtradas por el id de la observación:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Funcionalidad para rellenar el autocomplete de entidades en el panel de copiar Observacion
   * @param entityId
   * @returns
   */
  loadAutocompleteEntitiesNotSelectedByEntitiesIds(
    entitiesIds: number[]
  ): Observable<IAutoCompleteElement[] | undefined> {
    const req = {
      entitiesIds: entitiesIds
    };

    const options = createRequestOption(req);

    return this.http
      .get<IAutoCompleteElement[]>(
        `${baseUrl}/autocompleteEntitiesNotSelectedByEntitiesIds`,
        {
          params: options
        }
      )
      .pipe(
        tap((data: IAutoCompleteElement[] | undefined) => {
          this.appConfig.autocompleteEntitiesNotSelectedByEntitiesIdsData = [];
          Object.assign(
            this.appConfig.autocompleteEntitiesNotSelectedByEntitiesIdsData,
            data
          );
        }),
        catchError((err: Error) => {
          console.error(
            'Error al obtener las entidades del autocomplete en categorias/tipo de expediente'
          );
          throw err;
        })
      );
  }
}
