import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ic-raised-button',
  templateUrl: './raised-button.component.html'
})
export class RaisedButtonComponent {
  @Input() color?: string;
  @Input() disabled: boolean | null = false;
  @Input() textInput = '';
  @Input() type = 'button';

  @Output() icClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  buttonClicked = false;

  onClick(event: MouseEvent): void {
    if (!this.disabled && !this.buttonClicked) {
      this.buttonClicked = true;
      this.icClick.emit(event);
      setTimeout(() => {
        this.buttonClicked = false;
      }, 1000);
    } else {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      return;
    }
  }
}
