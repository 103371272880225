import { Injectable } from '@angular/core';
import { IOmissionAdded } from '@ic-models/omission/omission-request.model';
import { IAdministrativeFileFilter } from '@ic-models/prior-inspection/administrative-file-filter.model';
import { IFrbAdded } from '@ic-models/prior-inspection/frb-request.model';
import { IPpcAdministrativeFileFilter } from '@ic-models/prior-operational/ppc-administrative-file-filter.model';
import { IPpcAdministrativeFileAdded } from '@ic-models/prior-operational/ppc-administrative-file.model';
import {
  IPpcReportManualItemCommentFilter,
  IPpcReportManualObservationFilter,
  IPpcReportManualSubjectiveImprovementFilter
} from '@ic-models/prior-operational/ppc-report/ppc-report.model';
import { IPpcAdded } from '@ic-models/prior-operational/ppc-request.model';
import { IPTreePpcData } from '@ic-models/trees/ppc-report-pronouncements-tree.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
  // private isLoggedUserSource = new BehaviorSubject<boolean>(false);
  private treeFileTypeIdSource = new BehaviorSubject<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private AFFilterSource =
    new BehaviorSubject<IAdministrativeFileFilter | null>(null);
  private afPpcFilterSource =
    new BehaviorSubject<IPpcAdministrativeFileFilter | null>(null);
  private afPpcReportManualObservationFilterSource =
    new BehaviorSubject<IPpcReportManualObservationFilter | null>(null);
  private afPpcReportManualSubjectiveImprovementFilterSource =
    new BehaviorSubject<IPpcReportManualSubjectiveImprovementFilter | null>(
      null
    );
  private afPpcReportManualItemCommentFilterSource =
    new BehaviorSubject<IPpcReportManualItemCommentFilter | null>(null);
  private detailCommentsSource = new BehaviorSubject(true);
  private addedCommentSource = new BehaviorSubject(null);
  private flagDisableTabs = new BehaviorSubject<boolean>(false);
  private addedFrbData = new BehaviorSubject<IFrbAdded | null>(null);
  private addedPpcData = new BehaviorSubject<IPpcAdded | null>(null);
  private addedPpcAdministrativeFileData =
    new BehaviorSubject<IPpcAdministrativeFileAdded | null>(null);
  private addedOmissionData = new BehaviorSubject<IOmissionAdded | null>(null);
  private addedAmendmentData = new BehaviorSubject<IFrbAdded | null>(null);
  private detailPpcCommentsSource = new BehaviorSubject(true);
  private ppcAddedCommentSource = new BehaviorSubject(null);
  private ppcAddedAmendmentData = new BehaviorSubject<IPpcAdded | null>(null);
  private ppcAdministrativeFileAddedAmendmentData =
    new BehaviorSubject<IPpcAdministrativeFileAdded | null>(null);
  private ppcSupportedReportConclusionIdSource = new BehaviorSubject<
    number | null
  >(null);
  private resultCodePpcProcedureData = new BehaviorSubject<number | null>(null);
  private isPpcReportGenerated = new BehaviorSubject<boolean | null>(null);
  private ppcAdministrativeFileAddedReformulatedData =
    new BehaviorSubject<IPpcAdministrativeFileAdded | null>(null);
  private adPpcModelObservationFilterSource = new BehaviorSubject<string>('');
  private adPpcModelSubjectiveImprovementFilterSource =
    new BehaviorSubject<string>('');
  private lastIterationPpcAdminFileData = new BehaviorSubject<number | null>(
    null
  );
  private goToReportPpcViewFromPpcDetailData = new BehaviorSubject<boolean>(
    false
  );
  private adPpcModelItemCommentFilterSource = new BehaviorSubject<string>('');
  private changePpcObservations = new BehaviorSubject<boolean | null>(false);
  private changePpcSubjectiveImprovements = new BehaviorSubject<boolean | null>(
    false
  );
  private ppcReportNodeCommentAdded = new BehaviorSubject<IPTreePpcData | null>(
    null
  );
  private reloadPpcVerificationData = new BehaviorSubject<boolean>(false);

  // isLoggedUserSourceChanged$ = this.isLoggedUserSource.asObservable();
  treeFileTypeIdSourceChanged$ = this.treeFileTypeIdSource.asObservable();

  afFilterSourceChanged$ = this.AFFilterSource.asObservable();

  afPpcFilterSourceChanged$ = this.afPpcFilterSource.asObservable();

  detailCommentsData$ = this.detailCommentsSource.asObservable();

  addedCommentChanged$ = this.addedCommentSource.asObservable();

  flagDisableTabs$ = this.flagDisableTabs.asObservable();

  addedFrbData$ = this.addedFrbData.asObservable();

  addedOmissionData$ = this.addedOmissionData.asObservable();

  addedAmendmentData$ = this.addedAmendmentData.asObservable();

  addedPpcData$ = this.addedPpcData.asObservable();

  detailPpcCommentsData$ = this.detailPpcCommentsSource.asObservable();

  ppcAddedCommentChanged$ = this.ppcAddedCommentSource.asObservable();

  ppcAddedAmendmentData$ = this.ppcAddedAmendmentData.asObservable();

  addedPpcAdministrativeFileData$ =
    this.addedPpcAdministrativeFileData.asObservable();

  ppcAdministrativeFileAddedAmendmentData$ =
    this.ppcAdministrativeFileAddedAmendmentData.asObservable();

  ppcSupportedReportConclusionIdSourceChanged$ =
    this.ppcSupportedReportConclusionIdSource.asObservable();

  resultCodePpcProcedureDataChanged$ =
    this.resultCodePpcProcedureData.asObservable();

  isPpcReportGenerated$ = this.isPpcReportGenerated.asObservable();

  ppcAdministrativeFileAddedReformulatedData$ =
    this.ppcAdministrativeFileAddedReformulatedData.asObservable();

  adPpcModelObservationFilterSourceChanged$ =
    this.adPpcModelObservationFilterSource.asObservable();

  adPpcModelSubjectiveImprovementFilterSourceChanged$ =
    this.adPpcModelSubjectiveImprovementFilterSource.asObservable();

  lastIterationPpcAdminFileDataChanged$ =
    this.lastIterationPpcAdminFileData.asObservable();

  goToReportPpcViewFromPpcDetailDataChanged$ =
    this.goToReportPpcViewFromPpcDetailData.asObservable();

  adPpcModelItemCommentFilterSourceChanged$ =
    this.adPpcModelItemCommentFilterSource.asObservable();

  ppcObservationsChanged$ = this.changePpcObservations.asObservable();

  ppcSubjectiveImprovementsChanged$ =
    this.changePpcSubjectiveImprovements.asObservable();

  ppcReportNodeCommentAddedSourceChanged$ =
    this.ppcReportNodeCommentAdded.asObservable();

  reloadPpcVerificationDataChanged$ =
    this.reloadPpcVerificationData.asObservable();

  // updateLoggedUser(isLogged: boolean) {
  //   this.isLoggedUserSource.next(isLogged);
  // }

  updateTreeFileTypeId(id: number): void {
    this.treeFileTypeIdSource.next(id);
  }

  updateAdministrativeFileFilter(AFFilter: IAdministrativeFileFilter): void {
    this.AFFilterSource.next(AFFilter);
  }

  updateDetailCommentsSource(updateComments: boolean): void {
    this.detailCommentsSource.next(updateComments);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateAddedCommentSource(comment: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.addedCommentSource.next(comment);
  }

  updateFlagDisableTabs(disabled: boolean): void {
    this.flagDisableTabs.next(disabled);
  }

  updateAddedFrbData(addedFrb: IFrbAdded | null): void {
    this.addedFrbData.next(addedFrb);
  }

  updateAddedOmissionData(addedOmission: IOmissionAdded): void {
    this.addedOmissionData.next(addedOmission);
  }

  updateAddedAmendmentData(addedAmendment: IFrbAdded | null): void {
    this.addedAmendmentData.next(addedAmendment);
  }

  updateAddedPpcData(addedPpc: IPpcAdded | null): void {
    this.addedPpcData.next(addedPpc);
  }

  updatePpcAdministrativeFileFilter(
    AFFilter: IPpcAdministrativeFileFilter
  ): void {
    this.afPpcFilterSource.next(AFFilter);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatePpcAddedCommentSource(ppcComment: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.ppcAddedCommentSource.next(ppcComment);
  }

  updatePpcDetailCommentsSource(updatePpcComments: boolean): void {
    this.detailPpcCommentsSource.next(updatePpcComments);
  }

  updatePpcAddedAmendmentData(ppcAddedAmendment: IPpcAdded | null): void {
    this.ppcAddedAmendmentData.next(ppcAddedAmendment);
  }

  updatePpcReportManualSubjectiveImprovement(
    AFFilter: IPpcReportManualSubjectiveImprovementFilter
  ): void {
    this.afPpcReportManualSubjectiveImprovementFilterSource.next(AFFilter);
  }

  updatePpcReportManualItemComment(
    AFFilter: IPpcReportManualItemCommentFilter
  ): void {
    this.afPpcReportManualItemCommentFilterSource.next(AFFilter);
  }

  updatePpcReportManualObservation(
    AFFilter: IPpcReportManualObservationFilter
  ): void {
    this.afPpcReportManualObservationFilterSource.next(AFFilter);
  }

  updatePpcModelObservationFilter(filter: string): void {
    this.adPpcModelObservationFilterSource.next(filter);
  }

  updatePpcModelSubjectiveImprovementFilter(filter: string): void {
    this.adPpcModelSubjectiveImprovementFilterSource.next(filter);
  }

  updatePpcModelItemCommentFilter(filter: string): void {
    this.adPpcModelItemCommentFilterSource.next(filter);
  }

  updateAddedPpcAdministrativeFileData(
    addedPpcAdministrativeFile: IPpcAdministrativeFileAdded | null
  ): void {
    this.addedPpcAdministrativeFileData.next(addedPpcAdministrativeFile);
  }

  updatePpcAdministrativeFileAddedAmendmentData(
    ppcAdministrativeFileAddedAmendment: IPpcAdministrativeFileAdded | null
  ): void {
    this.ppcAdministrativeFileAddedAmendmentData.next(
      ppcAdministrativeFileAddedAmendment
    );
  }

  updatePpcSupportedReportConclusionId(id: number | null): void {
    this.ppcSupportedReportConclusionIdSource.next(id);
  }

  updateResultCodePpcProcedureData(id: number | null): void {
    this.resultCodePpcProcedureData.next(id);
  }

  updateIsPpcReportGenerated(isPpcReportGenerated: boolean | null): void {
    this.isPpcReportGenerated.next(isPpcReportGenerated);
  }

  updatePpcAdministrativeFileAddedReformulatedData(
    ppcAdministrativeFileAddedReformulated: IPpcAdministrativeFileAdded | null
  ): void {
    this.ppcAdministrativeFileAddedReformulatedData.next(
      ppcAdministrativeFileAddedReformulated
    );
  }

  updateLastIterationPpcAdminFileData(
    lastIterationPpcAdminFile: number | null
  ): void {
    this.lastIterationPpcAdminFileData.next(lastIterationPpcAdminFile);
  }

  updateGoToReportPpcViewFromPpcDetailData(
    goToReportPpcViewFromPpcDetail: boolean
  ): void {
    this.goToReportPpcViewFromPpcDetailData.next(
      goToReportPpcViewFromPpcDetail
    );
  }

  updatePpcObservationsReport(changePpcObservations: boolean | null): void {
    this.changePpcObservations.next(changePpcObservations);
  }

  updatePpcSubjectiveImprovementsReport(
    changePpcSubjectiveImprovements: boolean | null
  ): void {
    this.changePpcSubjectiveImprovements.next(changePpcSubjectiveImprovements);
  }

  updatePpcReportNodeCommentAdded(
    ppcReportNodeCommentAdded: IPTreePpcData | null
  ): void {
    this.ppcReportNodeCommentAdded.next(ppcReportNodeCommentAdded);
  }

  updateReloadPpcVerificationData(reloadPpcVerificationData: boolean): void {
    this.reloadPpcVerificationData.next(reloadPpcVerificationData);
  }
}
