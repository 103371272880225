<ic-icon-button
  [disabled]="false"
  [ariaLabel]="iconAriaLabel"
  [matIcon]="iconName"
  [matMenuTriggerFor]="menuOptions"
  [cssClasses]="cssClasses"
  (click)="$event.stopPropagation()"
>
</ic-icon-button>
<!-- <button
  mat-icon-button
  class="menu-options-button"
  [color]="color"
  [matMenuTriggerFor]="menuOptions"
  (click)="$event.stopPropagation()"
>
  <fa-icon size="lg" icon="{{ iconName }}"></fa-icon>
  <mat-icon>menu</mat-icon> -->
<!-- </button> -->
<mat-menu
  #menuOptions="matMenu"
  class="mat-menu-options-simple"
  [overlapTrigger]="false"
  xPosition="before"
>
  <div *ngFor="let optionsButton of optionsButtons">
    <div
      icAccessControl
      [roles]="
        optionsButton && optionsButton.accessRoles
          ? optionsButton.accessRoles
          : []
      "
    >
      <button
        type="button"
        mat-menu-item
        (click)="executeAction($event, optionsButton.action)"
      >
        <span>{{ optionsButton.btnLabel | translate }}</span>
      </button>
    </div>
  </div>
</mat-menu>
