import { Component } from '@angular/core';

@Component({
  selector: 'ic-list-filter',
  templateUrl: './list-filter.component.html'
})
export class ListFilterComponent {
  show = false;

  togglePanel(): void {
    this.show = !this.show;
  }
}
