import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import { IBudgetary } from '@ic-models/prior-inspection/budgetary.model';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class BudgetariesService {
  constructor(private http: HttpClient) {}

  getBudgetariesByProcedure(procedureId: number): Observable<IBudgetary[]> {
    const req = {
      procedureId: procedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IBudgetary[]>(`${baseUrl}/budgetaries`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IBudgetary[]>) => resp.body as IBudgetary[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles firmantes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
