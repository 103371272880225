export class DocumentData {
  constructor(
    public url: string | null,
    public tokenFileName: string,
    public documentUuid: string,
    public path: string,
    public originalName: string,
    public size: number,
    public replacement: boolean | null
  ) {}
}

export interface IDocumentsForm {
  attachedFiles: DocumentData[];
  emergencyAccreditationFiles: DocumentData[];
  documentsToDelete: number[];
}

export class DocumentsForm implements IDocumentsForm {
  attachedFiles: DocumentData[];
  emergencyAccreditationFiles: DocumentData[];
  documentsToDelete: number[];

  constructor(public documentsForm: IDocumentsForm) {
    this.attachedFiles = this.documentsForm.attachedFiles;
    this.emergencyAccreditationFiles =
      this.documentsForm.emergencyAccreditationFiles;
    this.documentsToDelete = this.documentsForm.documentsToDelete;
  }
}
