/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatIconRegistry } from '@angular/material/icon';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Sort, MatSort, SortDirection } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { isNullOrUndefined } from '@ic-app/core/util/global-util';
import { ITableButton } from '@ic-models/action-button.model';
import { IColumn } from '@ic-models/column.model';
import { IPpcObservationModelList } from '@ic-models/prior-operational/ppc-report/ppc-observations/ppc-observation';
import { IPpcSubjectiveImprovementModelList } from '@ic-models/prior-operational/ppc-report/ppc-subjective-improvement/ppc-subjective-improvement';
import { IReplaceRowFile } from '@ic-models/storage/replace-row-file';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

export interface RowAction {
  row: any;
  action: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface RowPosition {
  row: any;
  position: EventTarget | null;
}

@Component({
  selector: 'ic-partial-data-table',
  templateUrl: './partial-data-table.component.html'
})
export class PartialDataTableComponent<T> implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() customColumns?: IColumn[];
  @Input() displayedColumns?: string[];
  @Input() displayedColumnsHeader?: string[];
  @Input() optionButtons = false;
  @Input() editAndDelete = false;
  @Input() uploadAndDelete = false;
  @Input() addOption = false;
  @Input() addObservation = false;
  @Input() addActionsTable?: boolean;

  @Input() addButton?: ITableButton;
  @Input() editButton?: ITableButton;
  @Input() deleteButton?: ITableButton;
  @Input() dataSource: any;
  @Input() checks = false;
  @Input() hideMultipleChecks = false;
  @Input() addChecks = false;
  @Input() pageIndex?: number;
  @Input() pageSize?: number;
  @Input() length?: number;
  @Input() pageSizeOptions: number[] = [];
  @Input() defaultSortDirection = 'asc' as SortDirection;
  @Input() defaultSortColumn = '';
  @Input() tableClass = '';
  @Input() rowClass = '';
  @Input() rowClassHeader = '';
  @Input() paginatorActivated = false;
  @Input() emptyMessage = '';
  @Input() descriptionCheckBoxRow = false;
  @Input() disabled?: boolean | null = false;

  @Input() selection = new SelectionModel<any>(true, []);

  // @Output() callDataSource: EventEmitter<ITableFilter> = new EventEmitter();
  @Output() paginationChange: EventEmitter<PageEvent> =
    new EventEmitter<PageEvent>();
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() actionClicked: EventEmitter<RowAction> =
    new EventEmitter<RowAction>();
  @Output() selectedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() editedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadedRow: EventEmitter<IReplaceRowFile> =
    new EventEmitter<IReplaceRowFile>();
  @Output() addedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() addedRow2: EventEmitter<RowPosition> =
    new EventEmitter<RowPosition>();
  @Output() changedOrderUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() changedOrderDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedRows: EventEmitter<SelectionModel<any>> = new EventEmitter<
    SelectionModel<any>
  >();
  @Output() checkedRows: EventEmitter<SelectionModel<any>> = new EventEmitter<
    SelectionModel<any>
  >();
  @Output() executeActionPpcReportTypeOutput: EventEmitter<any> =
    new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.matIconRegistry.addSvgIcon(
      't_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/t_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'p_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/p_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'g_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/g_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'u_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/u_letter.svg'
      )
    );
  }

  ngAfterViewInit(): void {
    // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    // fromEvent(this.input.nativeElement, 'keyup')
    //   .pipe(
    //     debounceTime(150),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadLessonsPage();
    //     })
    //   )
    //   .subscribe();
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(() => this.callDataSourceFn());
    // .pipe(tap(() => this.callDataSourceFn()));
    this.dataSource.sort = this.sort;
    if (this.paginatorActivated) {
      this.updatePaginatorLabels();
      this.dataSource.paginator = this.paginator;
      this.paginator?.page
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((x: PageEvent) => {
          this.paginationChange.emit(x);
        });
    }
    this.sort?.sortChange
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((s: Sort) => {
        this.sortChange.emit(s);
      });
  }

  updatePaginatorLabels(): void {
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = this.translate.instant(
        'components.table.paginator.elements'
      ) as string;
      this.paginator._intl.nextPageLabel = this.translate.instant(
        'components.table.paginator.next'
      ) as string;
      this.paginator._intl.firstPageLabel = this.translate.instant(
        'components.table.paginator.first'
      ) as string;
      this.paginator._intl.lastPageLabel = this.translate.instant(
        'components.table.paginator.last'
      ) as string;
      this.paginator._intl.previousPageLabel = this.translate.instant(
        'components.table.paginator.previous'
      ) as string;
      this.paginator._intl.getRangeLabel = (
        page: number,
        pageSize: number,
        length: number
      ): string => {
        const of = this.translate.instant(
          'components.table.paginator.of'
        ) as string;
        if (length === 0 || pageSize === 0) {
          return `0 ${of} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex =
          startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
      };
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // callDataSourceFn() {
  //   this.callDataSource.emit({
  //     sortDirection: this.sort.direction,
  //     pageIndex: this.paginator.pageIndex,
  //     pageSize: this.paginator.pageSize,
  //   });
  // }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.getPageFromDataSource().length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      // Devuelve las filas de la página actual si la tabla está paginada
      // y devuelve todas las filas si la tabla no está paginada.
      this.getPageFromDataSource().forEach((row: T) =>
        this.selection.select(row)
      );
    }
    this.checkedRows.emit(this.selection);
  }

  // Emite el listado de filas seleccionadas
  addSelected(): void {
    this.selectedRows.emit(this.selection);
  }

  // Diferencia el acceso a datos desde tablas estáticas o dinámicas
  getData(dataSource: any): T[] {
    if (dataSource.data) {
      return dataSource.data as T[];
    } else {
      return dataSource.getData() as T[];
    }
  }

  getFilteredDataDataLength(dataSource: any): number {
    if (dataSource.data) {
      return dataSource.filteredData.length as number;
    } else {
      return dataSource.getData().length as number;
    }
  }

  getLoaded(dataSource: any): boolean {
    // Controlamos la variable loaded para en los listados grandes controlar que no aparezca el emptyMessage
    // mientras no ha terminado de gestionar los listados
    if (dataSource.loaded !== undefined) {
      return dataSource.loaded as boolean;
    } else {
      // En los listados que no se gestionan desde datasource no hay loaded
      // (se gestionan de forma inmediata y no hay problema)
      return true;
    }
  }

  onRowCheckboxChange(
    $event: MatCheckboxChange,
    row: T
  ): boolean | void | null {
    this.checkRow.emit(row);
    return $event.checked ? this.selection.select(row) : null;
  }

  onHeaderCheckboxChange($event: MatCheckboxChange): void | null {
    return $event ? this.masterToggle() : null;
  }

  refreshItemsSelected(): void {
    this.selection.clear();
  }

  clickedOption(action: number, row: T): void {
    const rowAction: RowAction = {
      action: action,
      row: row
    };

    this.actionClicked.emit(rowAction);
  }

  selectRow(row: T): void {
    this.selectedRow.emit(row);
  }

  deleteRow(row: T): void {
    this.deletedRow.emit(row);
  }

  addRow(row: T): void {
    this.addedRow.emit(row);
  }

  addRow2(row: T, evt: MouseEvent): void {
    const rowPosition: RowPosition = {
      row: row,
      position: evt.currentTarget
    };
    this.addedRow2.emit(rowPosition);
  }

  editRow(row: T): void {
    this.editedRow.emit(row);
  }

  changeOrderUp(row: T): void {
    this.changedOrderUp.emit(row);
  }

  changeOrderDown(row: T): void {
    this.changedOrderDown.emit(row);
  }

  getValAndUploadFile(row: T, event: Event): void {
    if ((event.target as HTMLInputElement)?.value) {
      this.uploadFile(row, (event.target as HTMLInputElement)?.files);
    }
  }

  uploadFile(row: T, files: FileList | null): void {
    const replace: IReplaceRowFile = {
      row: row,
      file: files !== null ? files[0] : null
    };
    this.uploadedRow.emit(replace);
  }

  executeActionPpcReportType(
    row: IPpcObservationModelList | IPpcSubjectiveImprovementModelList,
    action: number,
    event: MouseEvent
  ): void {
    if (!this.disabled) {
      console.log('Clicked!');
      this.executeActionPpcReportTypeOutput.emit({ row, action });
    } else {
      console.log('Disabled!');
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      console.log('Did it work?');
      return;
    }
  }

  filterTableByQuery($event: string): void {
    console.log('LLEGAMOS');
    this.dataSource.filter = $event.trim().toLowerCase();
  }

  private getPageFromDataSource(): any[] {
    if (
      !isNullOrUndefined(this.dataSource.filteredData) &&
      this.dataSource.filteredData.length > 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.dataSource.filteredData;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.getData(this.dataSource);
  }
}
