<button
  type="button"
  class="ic-tree-action-button-{{ customColor }}"
  [ngClass]="{
    'ic-tree-action-button-pushed': pushedButton === true
  }"
  [disabled]="onlySee"
  mat-raised-button
  (click)="buttonPressed()"
>
  {{ labelButton }}
</button>
