import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  IAutoCompleteElement
} from '@ic-app/components/form/input-autocomplete/input-autocomplete.component';
import { Observable, of } from 'rxjs';

import { AppConfigService } from '../app-config.service';

@Injectable()
export class AutoCompleteTenantEntitiesFilterService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    return of(
      this.appConfig.autocompleteTenantEntitiesFilterData
        .filter(
          (autocompleteTenantEntitiesFilterData: IAutoCompleteElement) => {
            //console.log(autocompleteOUData);
            return autocompleteTenantEntitiesFilterData.name
              .toLowerCase()
              .includes(filter.searchValue.toLowerCase());
          }
        )
        .map((autocompleteTenantEntitiesFilterData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteTenantEntitiesFilterData.id,
            autocompleteTenantEntitiesFilterData.name
          );
        })
    );
  }
}
