import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavService } from '@ic-services/nav.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ic-omission-tracking',
  templateUrl: './omission-tracking.component.html'
})
export class OmissionTrackingComponent implements OnInit {
  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const mainTitle = this.translate.instant(
      'sidebar.menu.label.omission-inspection.title'
    ) as string;
    const title = (mainTitle + ' - ').concat(
      this.translate.instant(
        this.route.snapshot.data['title'] as string
      ) as string
    );
    this.navService.updateTitle(title);
  }
}
