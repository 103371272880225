/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@auth0/auth0-angular';
import { IAutoCompleteElement } from '@ic-components/form/input-autocomplete/input-autocomplete.component';
import {
  filterArrayOfObjects,
  isEmptyNullOrUndefinedObject,
  getActualFiscalYearFromTime,
  getUserTimeZone
} from '@ic-core/util/global-util';
import { createRequestOption } from '@ic-core/util/request-util';
import { IEntityUser } from '@ic-models/entity.model';
import { IFiscalYear } from '@ic-models/fiscal-year.model';
import { ISpendingPhase } from '@ic-models/spendingPhase.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
// import { Observable, map, catchError, throwError } from 'rxjs';
import { Observable, catchError, map, throwError } from 'rxjs';

import { AppConfigService } from './app-config.service';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsService {
  private noDataBudgetaryItem = 'NO_DATA';
  private noDataFiscalYear = 0;
  allSettingsLoaded = false;
  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private auth: AuthService,
    private appConfig: AppConfigService,
    private snackBar: MatSnackBar
  ) {}

  getTimeUserTimeZone(): void {
    this.appConfig.timeZone = getUserTimeZone();
  }

  getLanguage(): string {
    const locale = this.appConfig.lang ? this.appConfig.lang : 'es';
    return locale;
  }

  getUsersLocale(defaultValue: string): string {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return defaultValue;
    }
    const wn = window.navigator;
    // buscamos el primer idioma con cultura (el primero que tenga un guión) si no hay ponemos el default
    const firstLangCulture = wn.languages.find(
      (lng: string | string[]) => lng.indexOf('-') !== -1
    );
    let lang: string = firstLangCulture ? firstLangCulture : defaultValue;
    lang = lang || wn.language;
    return lang;
  }

  loadAdministrativeFileTypes(): Promise<
    void | IAutoCompleteElement[] | undefined
  > {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        this.appConfig.autocompleteAFTData = [];
        Object.assign(this.appConfig.autocompleteAFTData, data);
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LOS TIPOS DE EXPEDIENTE');
      });
    return promise;
  }

  loadAdministrativeFileTypesPhaseO(): Promise<
    void | IAutoCompleteElement[] | undefined
  > {
    const req = {
      type: 'ADMINISTRATIVE_FILE_TYPE_PHASE_O'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        this.appConfig.autocompleteAFTPhaseOData = [];
        Object.assign(this.appConfig.autocompleteAFTPhaseOData, data);
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LOS TIPOS DE EXPEDIENTE');
      });
    return promise;
  }

  loadOrganizationalUnits(): Promise<
    void | IAutoCompleteElement[] | undefined
  > {
    const req = {
      type: 'ORGANIZATIONAL_UNIT'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        this.appConfig.autocompleteOUData = [];
        Object.assign(this.appConfig.autocompleteOUData, data);
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LOS ORGANIZATIONAL UNIT');
      });
    return promise;
  }

  loadResponsibleOrganizationalUnits(): Promise<
    void | IAutoCompleteElement[] | undefined
  > {
    const req = {
      type: 'RESPONSIBLE_ORGANIZATIONAL_UNIT'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        this.appConfig.responsibleAutocompleteOUData = [];
        Object.assign(this.appConfig.responsibleAutocompleteOUData, data);
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LOS LOS ORGANIZATIONAL UNIT');
      });
    return promise;
  }

  loadBudgetaryDescriptions(): Promise<
    void | IAutoCompleteElement[] | undefined
  > {
    const req = {
      type: 'BUDGETARY_APPLICATION_DESCRIPTION'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        // Actualizamos el elemento vacío para adaptarlo al idioma
        data?.find((element: IAutoCompleteElement) => {
          if (element.name === this.noDataBudgetaryItem) {
            element.name = this.translate.instant(
              'solfrb.panel.budgetaries.empty'
            ) as string;
            element.parentName = this.translate.instant(
              'solfrb.panel.budgetaries.empty'
            ) as string;
            return;
          }
        });
        this.appConfig.autocompleteBudgetaryDescriptionData = [];
        Object.assign(
          this.appConfig.autocompleteBudgetaryDescriptionData,
          data
        );
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LOS LOS BUDGETARIES');
      });
    return promise;
  }

  loadBudgetaryItems(): Promise<void | IAutoCompleteElement[] | undefined> {
    const req = {
      type: 'BUDGETARY_APPLICATION_ITEM'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        // Actualizamos el elemento vacío para adaptarlo al idioma
        data?.find((element: IAutoCompleteElement) => {
          if (element.name === this.noDataBudgetaryItem) {
            element.name = this.translate.instant(
              'solfrb.panel.budgetaries.empty'
            ) as string;
            element.parentName = this.translate.instant(
              'solfrb.panel.budgetaries.empty'
            ) as string;
            return;
          }
        });
        this.appConfig.autocompleteBudgetaryItemData = [];
        Object.assign(this.appConfig.autocompleteBudgetaryItemData, data);
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LOS LOS BUDGETARIES');
      });
    return promise;
  }

  loadBudgetaryItemsFilter(): Promise<
    void | IAutoCompleteElement[] | undefined
  > {
    const req = {
      type: 'BUDGETARY_APPLICATION_ITEM_FILTER'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        // Actualizamos el elemento vacío para adaptarlo al idioma
        data?.find((element: IAutoCompleteElement) => {
          if (element.name === this.noDataBudgetaryItem) {
            element.name = this.translate.instant(
              'solfrb.panel.budgetaries.empty'
            ) as string;
            element.parentName = this.translate.instant(
              'solfrb.panel.budgetaries.empty'
            ) as string;
            return;
          }
        });
        this.appConfig.autocompleteBudgetaryItemFilterData = [];
        Object.assign(this.appConfig.autocompleteBudgetaryItemFilterData, data);
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LOS LOS BUDGETARIES');
      });
    return promise;
  }

  loadAdministrativeFileTypeSpendingPhases(): Promise<
    void | ISpendingPhase[] | undefined
  > {
    const promise = this.http
      .get<ISpendingPhase[]>(`${baseUrl}/admintypes/spendingphases`)
      .toPromise()
      .then((data: ISpendingPhase[] | undefined) => {
        this.appConfig.administrativeFileTypeSpendingPhases = [];
        Object.assign(
          this.appConfig.administrativeFileTypeSpendingPhases,
          data
        );
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LAS FASES DE GASTO');
      });
    return promise;
  }

  loadUserEntities(): Promise<void | IEntityUser[] | undefined> {
    const promise = this.http
      .get<IEntityUser[]>(`${baseUrl}/users/entity`)
      .toPromise()
      .then((data: IEntityUser[] | undefined) => {
        this.appConfig.updateEntityUserDataSource(data);
        // Una vez tenemos las entidades asociadas al usuario buscamos aquella que es
        // la primaria o principal para el usuario
        const entityActive = filterArrayOfObjects(data, {
          primary: true
        }) as IEntityUser[];
        if (
          entityActive &&
          entityActive.length > 0 &&
          isEmptyNullOrUndefinedObject(this.appConfig.getSelectedEntity())
        ) {
          // Seteamos la entidad activa a la variable global de settingsService
          this.appConfig.updateEntitySelection(entityActive[0].id);
        }
        return data;
      })
      .catch(() => {
        console.error('ERROR AL OBTENER LAS ENTIDADES ASOCIADAS AL USUARIO');
      });
    return promise;
  }

  loadFiscalYears(): Promise<void | IFiscalYear[] | undefined> {
    const promise = this.http
      .get<IFiscalYear[]>(`${baseUrl}/users/fiscal-year`)
      .toPromise()
      .then((data: IFiscalYear[] | undefined) => {
        this.appConfig.autocompleteFiscalYearFilterData = [];
        data?.forEach((fiscalYear: IFiscalYear, index: number) => {
          if (fiscalYear.description === this.noDataFiscalYear) {
            this.appConfig.autocompleteFiscalYearFilterData.push({
              id: fiscalYear.id,
              name: this.translate.instant(
                'solfrb.panel.budgetaries.empty-fiscal-year'
              ) as string
            });
            data.splice(index, 1);
          } else {
            this.appConfig.autocompleteFiscalYearFilterData.push({
              id: fiscalYear.id,
              name: fiscalYear.description.toString()
            });
          }
        });
        this.appConfig.updateFiscalYearDataSource(data);
        let actualYear = getActualFiscalYearFromTime(data);
        if (!actualYear || actualYear.length === 0) {
          actualYear = filterArrayOfObjects(data, {
            description: Math.max(
              ...(data ? data.map((item: IFiscalYear) => item.description) : [])
            )
          }) as IFiscalYear[];
        }

        if (
          actualYear &&
          actualYear.length > 0 &&
          isEmptyNullOrUndefinedObject(this.appConfig.getSelectedFiscalYear())
        ) {
          // Seteamos la entidad activa a la variable global de settingsService
          this.appConfig.updateFiscalYearSelection(actualYear[0].id);
        }
        return data;
      })
      .catch(() => {
        console.error(
          'ERROR AL OBTENER LOS AÑOS FISCALES ASOCIADOS AL TENANT Y LA ENTIDAD DEL USUARIO'
        );
      });
    return promise;
  }

  async loadUserAlias(): Promise<string> {
    try {
      const data = await this.http
        .get<string>(`${baseUrl}/users/alias`, {
          responseType: 'text' as 'json'
        })
        .toPromise();
      this.appConfig.updateCurrentUserAlias(data as string);
      return data as string;
    } catch (err) {
      console.error('ERROR AL OBTENER EL ALIAS DE USUSARIO LOGUEADO');
      return err instanceof Error ? err.message : 'ERROR LOADING USER ALIAS';
    }
  }

  // getIdTokenInfo(): Promise<void> {
  //   return new Promise((resolve: (value: void | PromiseLike<void>) => void) => {
  //     this.auth.idTokenClaims$.subscribe((user: any) => {
  //       this.appConfig.authorities = user
  //         ? (user[`${this.appConfig.auth0RolesProperty}`] as string[])
  //         : [];
  //       this.appConfig.lang = user
  //         ? user[`${this.appConfig.auth0LangProperty}`]
  //           ? (user[`${this.appConfig.auth0LangProperty}`] as string)
  //           : 'es'
  //         : 'es';
  //       console.log('lang = ', this.appConfig.lang);
  //       // this.appConfig.lang = 'ca';
  //       resolve();
  //     });
  //   });
  // }

  // getUsersInfo(): Promise<boolean> {
  //   return new Promise(
  //     (resolve: (value: boolean | PromiseLike<boolean>) => void) => {
  //       this.auth.isAuthenticated$.subscribe((authenticated: boolean) => {
  //         this.appConfig.isLoggedIn = authenticated;
  //         if (authenticated !== true) {
  //           // this.auth.logout({
  //           //   logoutParams: { returnTo: environment.redirectUrl }
  //           // });
  //           this.auth.loginWithRedirect();
  //         }
  //         resolve(true);
  //       });
  //     }
  //   );
  // }

  loadSettingsWhenLogged(): void {
    this.getTimeUserTimeZone();
    this.auth.isAuthenticated$.subscribe((authenticated: boolean) => {
      if (authenticated) {
        Promise.all([
          this.loadAdministrativeFileTypes(),
          this.loadPpcAdministrativeFileTypes(),
          this.loadAdministrativeFileTypesPhaseO(),
          this.loadOrganizationalUnits(),
          this.loadResponsibleOrganizationalUnits(),
          this.loadBudgetaryDescriptions(),
          this.loadBudgetaryItems(),
          this.loadBudgetaryItemsFilter(),
          this.loadUserEntities(),
          this.loadFiscalYears(),
          this.loadAdministrativeFileTypeSpendingPhases(),
          this.loadUserAlias()
        ]);
      }
    });
  }

  loadInitialData(): Promise<boolean> {
    return new Promise<boolean>(
      (resolve: (value: boolean | PromiseLike<boolean>) => void) => {
        // this.getUsersInfo()
        //   .then(() => {
        // this.getIdTokenInfo()
        // })
        // .then(() => {
        console.log('translate service');
        this.translate.addLangs(['es', 'ca']);
        this.translate.setDefaultLang('ca');

        this.translate
          .use(this.appConfig.lang ? this.appConfig.lang : 'es')
          .toPromise()
          .then(() => {
            this.loadSettingsWhenLogged();
            resolve(true);
          });
      }
    );
  }

  // METHODS PPC ADMINISTRATIVE FILES

  loadPpcAdministrativeFileTypes(): Promise<any> {
    const req = {
      type: 'PPC_ADMINISTRATIVE_FILE_TYPE'
    };

    const options = createRequestOption(req);

    const promise = this.http
      .get<IAutoCompleteElement[]>(`${baseUrl}/autocomplete`, {
        params: options
      })
      .toPromise()
      .then((data: IAutoCompleteElement[] | undefined) => {
        this.appConfig.autocompletePPCAFTData = [];
        Object.assign(this.appConfig.autocompletePPCAFTData, data);
        return data;
      })
      .catch(() => {
        console.error(
          'ERROR AL OBTENER LOS TIPOS DE EXPEDIENTE DE CONTROL PERMANENTE PREVIO'
        );
      });
    return promise;
  }

  // TODO: Deprecar esta llamada????
  updatePpcAdministrativeFileEndDateOffset(
    ppcAdministrativeFileId: number,
    endOffset: number
  ): Observable<boolean> {
    return this.http
      .put<boolean>(
        `${baseUrl}/ppcadministrativefile/endoffset/update/${ppcAdministrativeFileId}`,
        endOffset
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al actualizar el offset del ppc administrative file:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // TODO: Revisar estas llamadas
  // updateProcedureEndDateOffset(
  //   procedureId: number,
  //   endOffset: number
  // ): Observable<any> {
  //   return this.http
  //     .put(`${baseUrl}/procedure/endoffset/update/${procedureId}`, endOffset)
  //     .pipe(
  //       map((resp) => resp),
  //       catchError((err) => {
  //         console.error(
  //           'Se ha producido un error al actualizar el offset del procedure:',
  //           // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  //           err.message
  //         );
  //         return throwError(err);
  //       })
  //     );
  // }

  // // TODO: Revisar estas llamadas
  // updateAdministrativeFileEndDateOffset(
  //   administrativeFileId: number,
  //   endOffset: number
  // ): Observable<any> {
  //   return this.http
  //     .put(
  //       `${baseUrl}/administrativefile/endoffset/update/${administrativeFileId}`,
  //       endOffset
  //     )
  //     .pipe(
  //       map((resp) => resp),
  //       catchError((err) => {
  //         console.error(
  //           'Se ha producido un error al actualizar el offset del administrative file:',
  //           // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  //           err.message
  //         );
  //         return throwError(err);
  //       })
  //     );
  // }

  // // TODO: Revisar estas llamadas
  // updateAdministrativeFileAndProcedureEndDateOffset(
  //   administrativeFileId: number,
  //   procedureId: number,
  //   endOffset: number
  // ): Observable<any> {
  //   return this.http
  //     .put(
  //       `${baseUrl}/administrativefile/endoffset/update/${administrativeFileId}/${procedureId}`,
  //       endOffset
  //     )
  //     .pipe(
  //       map((resp) => resp),
  //       catchError((err) => {
  //         console.error(
  //           'Se ha producido un error al actualizar el offset del administrative file:',
  //           // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  //           err.message
  //         );
  //         return throwError(err);
  //       })
  //     );
  // }

  private showMessage(text: string, button: string): void {
    this.snackBar.open(text, button, {
      duration: 5000
    });
  }
}
