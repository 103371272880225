/* eslint-disable max-len */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import {
  IPpcSubjectiveImprovementModelList,
  IPpcSubjectiveImprovementReportList,
  PpcSubjectiveImprovementToDeleteDTO,
  PpcSubjectiveImprovementToSave
} from '@ic-models/prior-operational/ppc-report/ppc-subjective-improvement/ppc-subjective-improvement';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class PpcSubjectiveImprovementsService {
  constructor(private http: HttpClient) {}

  getPpcSubjectiveImprovementsReport(
    ppcReportProcedureId: number
  ): Observable<IPpcSubjectiveImprovementReportList[]> {
    const req = {
      ppcReportProcedureId: ppcReportProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcSubjectiveImprovementReportList[]>(
        `${baseUrl}/ppcSubjectiveImprovements/listPpcSubjectiveImprovementReport`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcSubjectiveImprovementReportList[]>) =>
            resp.body as IPpcSubjectiveImprovementReportList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las ppc recomendaciones del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcSubjectiveImprovementsReportFromParent(
    currentPpcProcedureId: number
  ): Observable<IPpcSubjectiveImprovementReportList[]> {
    const req = {
      currentPpcProcedureId: currentPpcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcSubjectiveImprovementReportList[]>(
        `${baseUrl}/ppcSubjectiveImprovements/listPpcSubjectiveImprovementReportFromParent`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcSubjectiveImprovementReportList[]>) =>
            resp.body as IPpcSubjectiveImprovementReportList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las recomendaciones cpp del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcSubjectiveImprovementsModelDefinition(
    ppcAdministrativeFileId: number,
    currentPpcProcedureId: number,
    ppcReportProcedureId: number,
    isReformulated: boolean,
    savedPronouncements: boolean
  ): Observable<IPpcSubjectiveImprovementModelList[]> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      currentPpcProcedureId: currentPpcProcedureId,
      ppcReportProcedureId: ppcReportProcedureId,
      isReformulated: isReformulated,
      savedPronouncements: savedPronouncements
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcSubjectiveImprovementModelList[]>(
        `${baseUrl}/ppcSubjectiveImprovements/listPpcModelDefinition`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcSubjectiveImprovementModelList[]>) =>
            resp.body as IPpcSubjectiveImprovementModelList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las ppc recomendaciones del modelo del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countPpcSubjectiveImprovementsModelDefinition(
    filter: string,
    ppcAdministrativeFileId: number,
    ppcReportProcedureId: number
  ): Observable<number> {
    const req = {
      searchBox: filter,
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      ppcReportProcedureId: ppcReportProcedureId
    };

    const options = createRequestOption(req);
    return this.http
      .get<number>(
        `${baseUrl}/ppcSubjectiveImprovements/countPpcModelDefinition`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al contar las recomendaciones de modelo del informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deletePpcSubjectiveImprovements(
    ppcSubjectiveImprovementDelete: PpcSubjectiveImprovementToDeleteDTO
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${baseUrl}/deletePpcSubjectiveImprovements`,
        ppcSubjectiveImprovementDelete
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al borrar las recomendaciones del informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcSubjectiveImprovements(
    ppcSubjectiveImprovementSave: PpcSubjectiveImprovementToSave,
    ppcAdministrativeFileId: number,
    resultCode?: number
  ): Observable<boolean> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      resultCode: resultCode
    };
    const options = createRequestOption(req);
    return this.http
      .post<boolean>(
        `${baseUrl}/savePpcSubjectiveImprovement`,
        ppcSubjectiveImprovementSave,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar las recomendaciones de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
