/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsConstants } from '@ic-app/constants/settings.constants';
import { isNullOrUndefined } from '@ic-app/core/util/global-util';
import { SelectOption } from '@ic-app/models/select-option.model';
import { ISettingsInspectionTree } from '@ic-app/models/settings/prior-inspection/items/settings-prior-inspection-items.model';
import { ItemsAddComponent } from '@ic-app/pages/configuration/settings-prior-inspection/settings-prior-inspection-items/settings-prior-inspection-items-detail/items-add/items-add.component';
import { AppConfigService } from '@ic-app/services/app-config.service';
import { PanelService } from '@ic-app/services/panel.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ic-table-dragndrop',
  templateUrl: './table-dragndrop.component.html'
})
export class TableDragndropComponent<T> implements OnDestroy {
  //#region variables, constructor, ngOnDestroy getters
  private unsubscribe$ = new Subject<void>();

  @Input() dataSourceFirst: any[] = [];
  @Input() dataSourceSecond: any[] = [];
  @Input() inputTableFormControlFirst: FormArray<FormControl> =
    new FormArray<FormControl>([]);
  @Input() inputTableFormControlSecond: FormArray<FormControl> =
    new FormArray<FormControl>([]);
  @Input() firstTableTitle = '';
  @Input() secondTableTitle = '';
  @Output() dropEmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addItemSelected = new EventEmitter<{
    item: ISettingsInspectionTree;
    isItemAfter: boolean;
  }>();
  acmElOptions: SelectOption[] = [];

  // Select options for the ACM/EL column.
  acmOption = new SelectOption(SettingsConstants.ACM_OPTION, 'ACM');
  elOption = new SelectOption(SettingsConstants.EL_OPTION, 'EL');

  panelComponent?: ComponentRef<ItemsAddComponent>;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private panelService: PanelService,
    private appConfig: AppConfigService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {
    this.matIconRegistry.addSvgIcon(
      'acm_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/acm_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'el_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/el_letter.svg'
      )
    );
    // Cargamos el select con las opciones.
    this.fillOptions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(dataSource: any): T[] {
    if (dataSource.data) {
      return dataSource.data as T[];
    }
    return [];
  }

  getFormControlFirst(index: number): FormControl {
    return this.inputTableFormControlFirst.at(index) as FormControl;
  }
  getFormControlSecond(index: number): FormControl {
    return this.inputTableFormControlSecond.at(index) as FormControl;
  }

  getLoaded(dataSource: any): boolean {
    if (dataSource.loaded !== undefined) {
      return dataSource.loaded as boolean;
    } else {
      return true;
    }
  }

  //#endregion

  /**
   * Permite cambiar el icono y el color del icono de la columna ACM/EL.
   * @param item
   * @param numberDatasource numero de la tabla
   * @returns
   */
  changeItemLegalSourceIcon(
    item: ISettingsInspectionTree,
    numberDatasource: number
  ): void {
    const dataSource =
      numberDatasource === 1 ? this.dataSourceFirst : this.dataSourceSecond;
    const getFormControl =
      numberDatasource === 1
        ? this.getFormControlFirst.bind(this)
        : this.getFormControlSecond.bind(this);
    const itemIndex = dataSource.findIndex(
      (element: ISettingsInspectionTree) => element.id === item.id
    );

    if (itemIndex === -1) return;

    const formControlValue = getFormControl(itemIndex).value;

    if (formControlValue === SettingsConstants.ACM_OPTION) {
      item.itemLegalSourceIcon = 'acm_letter';
      item.itemLegalSourceIconColor = 'cross';
      item.itemLegalSourceLabel = 'components.inspection-tree.acm';
      item.itemLegalSourceId = SettingsConstants.ACM_OPTION;
    } else {
      item.itemLegalSourceIcon = 'el_letter';
      item.itemLegalSourceIconColor = 'general';
      item.itemLegalSourceLabel = 'components.inspection-tree.el';
      item.itemLegalSourceId = SettingsConstants.EL_OPTION;
    }

    dataSource[itemIndex] = item;

    this.dropEmitted.emit(true);
  }

  /**
   * Permite el movimiento de elementos entre tablas y dentro de la misma tabla.
   * @param event
   * @returns
   */
  drop(event: CdkDragDrop<any>): void {
    // Guardamos el elemento que se va a mover
    const elementToMove = event.previousContainer.data[event.previousIndex];
    // Guardamos el elemento que se va a reemplazar
    const elementoToReplace = event.container.data[event.currentIndex];

    const sameTable = event.previousContainer === event.container;

    const movement = this.calculateMovement(
      event.previousIndex,
      event.currentIndex
    );
    // Miramos si el movimiento es para abajo o para arriba en la misma tabla
    if (sameTable === true) {
      // Si el movimiento es igual a -1, no se mueve
      if (movement === -1) {
        return;
      }
    }

    // Miramos si se va a mover un elemento al final de la tabla y lo guardamos en una variable
    const isLastElement =
      event.currentIndex === event.container.data.length - 1;

    // Si se puede mover el elemento a la nueva posición
    if (
      this.canMoveItemTable(
        elementToMove,
        elementoToReplace,
        isLastElement,
        movement
      )
    ) {
      // Encontramos los hijos del elemento que se va a mover
      const childrenToMove = this.findChildrenByIdUsingPath(
        elementToMove.id,
        event.previousContainer.data
      );

      // Cambiamos el essential de los elementos que se mueven de una tabla a otra
      this.changeEssential(childrenToMove, sameTable);

      // Buscamos en qué tabla está el elemento a mover
      const tableElement = this.findElementTable(elementToMove);

      // Creamos un array con los FormControl de los hijos
      const formControlChildren: FormControl[] = this.createFormControlChildren(
        childrenToMove,
        tableElement,
        event
      );

      // Determinamos el índice donde insertar
      const indexToInsert = this.calculateIndexToInsert(
        event,
        elementoToReplace,
        sameTable,
        movement,
        childrenToMove
      );

      // Insertamos los elementos movidos en la nueva posición
      event.container.data.splice(indexToInsert, 0, ...childrenToMove);

      // Insertamos los FormControl en la posición indicada
      this.insertFormControls(
        formControlChildren,
        tableElement,
        indexToInsert,
        sameTable
      );

      // Recalculamos los colores de los iconos
      this.recalculateColors();

      this.dropEmitted.emit(true);
    }
  }

  /**
   * Permite borrar un item o desahacerlo
   * @param item
   */
  deleteOrUndoItem(item: ISettingsInspectionTree): void {
    const itemsToDelete = this.findChildrenByIdUsingPath(
      item.id,
      this.getDataSourceItem(item)
    );
    const isDeleting = item.deletedIcon === 'delete_forever';
    itemsToDelete.forEach((element: ISettingsInspectionTree) => {
      element.deleted = isDeleting;
      element.itemLineThrough = isDeleting;
    });
    item.deletedIcon = isDeleting ? 'undo' : 'delete_forever';
    item.deleteTooltip = isDeleting
      ? 'settings-prior-inspection.items.manage-in-model-panel.undo-delete-tooltip'
      : 'settings-prior-inspection.items.manage-in-model-panel.delete-structure-tooltip';

    this.dropEmitted.emit(true);
  }

  /**
   * Permite ir al panel de añadir item
   * @param item
   */
  goToAddItemPanel(item: ISettingsInspectionTree, isItemAfter: boolean): void {
    this.addItemSelected.emit({ item, isItemAfter });
  }

  /**
   * Metodo que devuelve un numero segun el movimiento que se esta realizando.
   * 1 si es hacia abajo, 0 si es hacia arriba y -1 si no se esta moviendo.
   * @param previousIndex
   * @param currentIndex
   * @returns
   */
  private calculateMovement(
    previousIndex: number,
    currentIndex: number
  ): number {
    if (previousIndex < currentIndex) {
      return 1;
    } else if (previousIndex > currentIndex) {
      return 0;
    } else {
      return -1;
    }
  }

  /**
   * Calcula la posicion donde se va a insertar el elemento.
   * @param event
   * @param elementoToReplace
   * @returns
   */
  private calculateIndexToInsert(
    event: CdkDragDrop<any>,
    elementoToReplace: any,
    sameTable: boolean,
    movement: number,
    childrenToMove: ISettingsInspectionTree[]
  ): number {
    const lengthChildren = childrenToMove.length;
    if (sameTable) {
      if (movement === 1 && lengthChildren > 1) {
        const indexToReplace = event.container.data.findIndex(
          (element: ISettingsInspectionTree) =>
            element.id === elementoToReplace?.id
        );
        const childrenToReplace = this.findChildrenByIdUsingPath(
          elementoToReplace.id,
          event.container.data
        );
        return indexToReplace + childrenToReplace.length;
      }
      return event.currentIndex;
    }
    return event.currentIndex;
  }

  /**
   * Elimina el form control del elemento y lo elimina de la tabla.
   * @param childrenToMove
   * @param tableElement
   * @param event
   * @returns
   */
  private createFormControlChildren(
    childrenToMove: ISettingsInspectionTree[],
    tableElement: number,
    event: CdkDragDrop<any>
  ): FormControl[] {
    const formControlChildren: FormControl[] = [];
    childrenToMove.forEach((element: ISettingsInspectionTree) => {
      const index = event.previousContainer.data.findIndex(
        (el: ISettingsInspectionTree) => el.id === element.id
      );
      if (index !== -1) {
        if (tableElement === 0) {
          formControlChildren.push(this.getFormControlFirst(index));
          this.inputTableFormControlFirst.removeAt(event.previousIndex);
        } else if (tableElement === 1) {
          formControlChildren.push(this.getFormControlSecond(index));
          this.inputTableFormControlSecond.removeAt(event.previousIndex);
        }
        event.previousContainer.data.splice(index, 1);
      }
    });
    return formControlChildren;
  }

  /**
   * Metodo que reasigna los valores de los formControl de la tabla al mover elementos
   * entre tablas o entre la misma tabla
   * @param formControlChildren
   * @param tableElement
   * @param indexToInsert
   * @param sameTable
   */
  private insertFormControls(
    formControlChildren: FormControl[],
    tableElement: number,
    indexToInsert: number,
    sameTable: boolean
  ): void {
    formControlChildren.forEach((formControl: FormControl) => {
      const targetFormControl =
        (tableElement === 0 && !sameTable) || (tableElement === 1 && sameTable)
          ? this.inputTableFormControlSecond
          : this.inputTableFormControlFirst;
      targetFormControl.insert(indexToInsert++, formControl);
    });
  }

  /**
   * Recalcula los colores de las filas de las tablas, segun el treeId.
   */
  private recalculateColors(): void {
    this.recalculateColorsTable(this.dataSourceFirst);
    this.recalculateColorsTable(this.dataSourceSecond);
  }

  /**
   * Devuelve un 0 si el elemento esta en la primera tabla,
   * y un 1 si esta en la segunda tabla o -1 si no se encuentra en ninguna tabla.
   * @param elementToMove
   * @returns
   */
  private findElementTable(elementToMove: ISettingsInspectionTree): number {
    // Miramos si el elemento a mover esta en la primera tabla
    if (
      this.dataSourceFirst.some(
        (element: ISettingsInspectionTree) => element.id === elementToMove.id
      )
    ) {
      return 0;
    }
    // Miramos si el elemento a mover esta en la segunda tabla
    if (
      this.dataSourceSecond.some(
        (element: ISettingsInspectionTree) => element.id === elementToMove.id
      )
    ) {
      return 1;
    }
    // Si no se encuentra en ninguna tabla
    return -1;
  }

  /**
   * Llena las opciones del select.
   */
  private fillOptions(): void {
    this.acmElOptions.push(this.acmOption, this.elOption);
  }

  /**
   * Permite contar la cantidad de elementos en un path.
   * @param path
   * @returns
   */
  private countPathElements(path: string): number {
    // Eliminar los caracteres '{' y '}' y luego dividir el string por comas
    const elements = path.replace(/{|}/g, '').split(',');
    // Filtrar cualquier elemento vacío en caso de que existan
    return elements.filter((element: string) => element.trim() !== '').length;
  }

  /**
   *
   * @param id
   * @param data
   * @returns Devuelve un array con el item padre y sus hijos y si se mueven a tablas diferentes cambiamos
   * el essential
   */
  private findChildrenByIdUsingPath(
    id: number,
    data: ISettingsInspectionTree[]
  ): ISettingsInspectionTree[] {
    const items: ISettingsInspectionTree[] = [];

    data.forEach((item: ISettingsInspectionTree) => {
      const pathIds = item.path.replace(/[{}"]/g, '').split(',').map(Number);
      if (pathIds.includes(id)) {
        items.push(item);
      }
    });

    return items;
  }

  /**
   * Permite cambiar el essential de los elementos que se mueven de una tabla a otra.
   * @param data
   * @param sameTable
   */
  private changeEssential(
    data: ISettingsInspectionTree[],
    sameTable: boolean
  ): void {
    if (!sameTable) {
      data.forEach((element: ISettingsInspectionTree) => {
        element.essential = !element.essential;
      });
    }
  }

  /**
   * Devuelve un booleano que indica si se puede mover un item de una tabla a otra o entre elementos de la misma tabla.
   * @param elementToMove
   * @param elementoToReplace
   * @returns
   */
  private canMoveItemTable(
    elementToMove: any,
    elementoToReplace: any,
    isLastElement: boolean,
    movement: number
  ): boolean {
    // Si el elemento a mover no tiene parentId y el elemento a reemplazar es undefined, se puede mover
    if (
      elementToMove.parentId === null &&
      (isNullOrUndefined(elementoToReplace) || isLastElement)
    ) {
      return true;
    }

    // Si el elemento a mover tiene el parentId a null y el elemento a reemplazar
    // es el ultimo elemento de un treeId y el movimiento es para abajo, se puede mover
    if (
      elementToMove.parentId === null &&
      this.isLastElementTreeId(elementoToReplace) &&
      movement === 1
    ) {
      return true;
    }

    // Si no tienen el mismo parentId, no se pueden mover
    const isSameParentId =
      elementToMove.parentId === elementoToReplace.parentId;
    const isSameTreeId = elementToMove.treeId === elementoToReplace.treeId;

    // Si los paths tienen la misma longitud, se pueden mover
    const isSamePathLength =
      this.countPathElements(elementToMove.path) ===
      this.countPathElements(elementoToReplace.path);

    // Si tienen distinto treeId, no se pueden mover,
    // a menos que sus parentId sea null
    if (
      !isSameTreeId &&
      isSamePathLength &&
      elementToMove.parentId === null &&
      elementoToReplace.parentId === null
    ) {
      return true;
    }

    return isSameParentId && isSameTreeId && isSamePathLength;
  }

  /**
   * Devuelve un booleano que indica si es el ultimo elemento de su rama.
   * @param elementoToReplace
   * @returns
   */
  private isLastElementTreeId(
    elementoToReplace: ISettingsInspectionTree
  ): boolean {
    // Buscamos los elementos con el mismo treeId que el elemento a reemplazar
    // en la primera y en la segunda tabla
    const elementsFirstTable = this.dataSourceFirst.filter(
      (element: ISettingsInspectionTree) =>
        element.treeId === elementoToReplace.treeId
    );
    const elementsSecondTable = this.dataSourceSecond.filter(
      (element: ISettingsInspectionTree) =>
        element.treeId === elementoToReplace.treeId
    );

    // Si el elemento a reemplazar es el ultimo de su rama, se puede mover
    if (
      !isNullOrUndefined(elementsFirstTable) &&
      elementsFirstTable.length > 0
    ) {
      return (
        elementsFirstTable[elementsFirstTable.length - 1].id ===
        elementoToReplace.id
      );
    } else {
      return (
        elementsSecondTable[elementsSecondTable.length - 1].id ===
        elementoToReplace.id
      );
    }
  }

  /**
   * Cuando se mueve un elemento de una tabla a otra, se recalculan los colores de las filas.
   * @param dataSource
   * @returns
   */
  private recalculateColorsTable(dataSource: ISettingsInspectionTree[]): void {
    if (dataSource.length === 0 || isNullOrUndefined(dataSource)) {
      return;
    }
    let currentColor = false;
    let previousTreeId: number | undefined = undefined;

    // Recorremos el dataSource para recalcular los colores
    dataSource.forEach((element: ISettingsInspectionTree) => {
      // Si rowColorReused es true, saltamos este elemento
      if (element.rowColorReused === true) {
        return;
      }

      if (previousTreeId !== undefined && element.treeId !== previousTreeId) {
        currentColor = !currentColor;
      }
      element.rowColor = currentColor;
      previousTreeId = element.treeId;
    });
  }

  /**
   * Devuelve el dataSource donde se encuentra el item.
   * @param item
   * @returns
   */
  private getDataSourceItem(
    item: ISettingsInspectionTree
  ): ISettingsInspectionTree[] {
    // Buscamos el item en la primera tabla y devolvemos el dataSource
    if (
      this.dataSourceFirst.some(
        (element: ISettingsInspectionTree) => element.id === item.id
      )
    ) {
      return this.dataSourceFirst as ISettingsInspectionTree[];
    }
    // Buscamos el item en la segunda tabla y devolvemos el dataSource
    if (
      this.dataSourceSecond.some(
        (element: ISettingsInspectionTree) => element.id === item.id
      )
    ) {
      return this.dataSourceSecond as ISettingsInspectionTree[];
    }

    return [];
  }
}
