import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'ic-input-multiple-select',
  templateUrl: './input-multiple-select.component.html'
})
export class InputMultipleSelectComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() inputLabel = '';
  @Input() inputFormControl: FormControl = new FormControl();
  @Input() hasDefaultOption = false;
  @Input() defaultOptionText = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() options: any;

  appearance: MatFormFieldAppearance = 'fill';

  disableSelect = new FormControl(false);

  ngOnInit(): void {
    if (this.isDisabled) {
      this.inputFormControl.disable({
        onlySelf: true,
        emitEvent: false
      });
    }
  }
}
