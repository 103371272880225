import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'ic-pages',
  templateUrl: './pages.component.html'
})
export class PagesComponent {
  constructor(private auth: AuthService) {}
}
