import { HttpParams } from '@angular/common/http';

interface IParsedValues {
  [key: string]: number;
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */
export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach((key) => {
      if (
        key !== 'sort' &&
        req[key] !== null &&
        req[key] !== undefined &&
        req[key] !== ''
      ) {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach((val: string | number | boolean) => {
        options = options.append('sort', val);
      });
    }
  }
  return options;
};
/* eslint-enable */

export const toCurrencyString = (total: number): string => {
  // let totalNumberString:string = total.toFixed(2).toString().replace('.', ',') + ' €';

  let totalNumberString: string = total.toFixed(2).toString().replace('.', ',');

  const parts = totalNumberString.split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  totalNumberString = parts.join(',');

  return totalNumberString + ' €';
};

export const toCurrencyNumber = (total: string): number => {
  let totalNumberString: string = total.replace(/\./g, '');
  totalNumberString = totalNumberString.split(' ')[0];

  const totalNumber = Number(totalNumberString.replace(',', '.'));
  return totalNumber;
};

// export const getTimestampFromMoment = (
//   date: moment.Moment
// ): number | undefined => {
//   // const dateOffset = date.utcOffset();
//   // El date ya tiene la información del locale, por lo que solo tenemos que convertirlo a utc
//   if (date.isValid()) {
//     return date.utc().valueOf();
//   } else {
//     return undefined;
//   }
// };

/**
 * Obtiene el timestamp de una fecha determinada
 * @param date
 * @returns
 */
export const getTimestampFromDate = (date: Date): number => {
  return date.getTime();
};

/**
 * Obtiene el timestamp de una fecha determinada en formato string
 * Es importante pasarle correctamente el formato de la fecha pasada en string
 * @param dateStr
 * @param format
 * @returns
 */
export const getTimestampFromStringDate = (
  dateStr: string,
  format: string
): number | undefined => {
  const date = dateStr
    ? parseDateFromStringWithFormat(dateStr, format)
    : undefined;
  return date ? getTimestampFromDate(date) : undefined;
};

/**
 * Obtiene el timestamp al inicio del día del momento actual
 * @returns
 */
export const getTimestampFromNow = (): number => {
  const now = new Date();
  return getTimestampFromDate(now);
};

/**
 * Obtiene el timestamp al inicio del día del momento actual
 * @returns
 */
export const getTimestampAtStartOfDay = (): number => {
  const now = new Date();
  return now.setHours(0, 0, 0, 0);
};

/**
 * Obtiene el timestamp al principio del día en una fecha determinada
 * @param date
 * @returns
 */
export const getTimestampAtStartOfDayFromDate = (date: Date): number => {
  return date.setHours(0, 0, 0, 0);
};

/**
 * Obtiene el timestamp de una fecha determinada en formato string al principio del día
 * Es importante pasarle correctamente el formato de la fecha pasada en string
 * @param dateStr
 * @param format
 * @returns
 */
export const getTimestampAtStartOfDayFromStringDate = (
  dateStr: string,
  format: string
): number | undefined => {
  const date = dateStr
    ? parseDateFromStringWithFormat(dateStr, format)
    : undefined;
  return date ? getTimestampAtStartOfDayFromDate(date) : undefined;
};

export const getTimestampAtStartOfDayFromTimestamp = (
  timestamp: number
): number => {
  const date = new Date(timestamp);
  return getTimestampAtStartOfDayFromDate(date);
};

/**
 * Obtiene el timestamp al inicio del día del momento actual
 * @returns
 */
export const getTimestampAtEndOfDay = (): number => {
  const now = new Date();
  return now.setHours(23, 59, 59, 990);
};

/**
 * Obtiene el timestamp al principio del día en una fecha determinada
 * @param date
 * @returns
 */
export const getTimestampAtEndOfDayFromDate = (date: Date): number => {
  return date.setHours(23, 59, 59, 990);
};

/**
 * Obtiene el timestamp de una fecha determinada en formato string al principio del día
 * Es importante pasarle correctamente el formato de la fecha pasada en string
 * @param dateStr
 * @param format
 * @returns
 */
export const getTimestampAtEndOfDayFromStringDate = (
  dateStr: string,
  format: string
): number | undefined => {
  const date = dateStr
    ? parseDateFromStringWithFormat(dateStr, format)
    : undefined;
  return date ? getTimestampAtEndOfDayFromDate(date) : undefined;
};

export const getTimestampAtEndOfDayFromTimestamp = (
  timestamp: number
): number => {
  const date = new Date(timestamp);
  return getTimestampAtEndOfDayFromDate(date);
};

/**
 * Obtiene el offset de UTC de una fecha determiinada en formato timestamp
 * @param date
 * @returns
 */
export const getUtcOffsetFromTimestamp = (timestamp: number): number => {
  const date = new Date(timestamp);
  return getUtcOffsetFromDate(date);
};

/**
 * Obtiene el offset de UTC de una fecha determiinada
 * @param date
 * @returns
 */
export const getUtcOffsetFromDate = (date: Date): number => {
  return date.getTimezoneOffset() * -1;
};

export const getUtcOffsetFromStringDate = (
  dateStr: string,
  format: string
): number | undefined => {
  const date = dateStr
    ? parseDateFromStringWithFormat(dateStr, format)
    : undefined;
  return date ? getUtcOffsetFromDate(date) : undefined;
};

/**
 * Parsea una fecha en formato string con un formato determinado y devuelve un tipo Date
 * que respresenta esa fecha
 * @param dateString
 * @param format
 * @returns
 */
export const parseDateFromStringWithFormat = (
  dateString: string,
  format: string
): Date => {
  const formatParts = format.split(/[^A-Za-z]+/);
  const dateParts = dateString.split(/[^0-9]+/);
  const parsedValues: IParsedValues = {};
  for (let i = 0; i < formatParts.length; i++) {
    const formatPart = formatParts[i];
    const datePart = dateParts[i];
    if (formatPart && datePart) {
      parsedValues[formatPart] = parseInt(datePart, 10);
    }
  }
  const year = parsedValues['yyyy'] || parsedValues['yy'];
  const month = parsedValues['MM'] || parsedValues['M'];
  const day = parsedValues['dd'] || parsedValues['d'];
  return new Date(year, month - 1, day);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDateType = (value: any): boolean => {
  if (typeof value === 'object' && value instanceof Date) {
    return true;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStringType = (value: any): boolean => {
  if (typeof value === 'string') {
    return true;
  }
  return false;
};
