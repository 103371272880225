import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-core/util/request-util';
import {
  IPpcObservationModelList,
  IPpcObservationReportList,
  PpcObservationToDeleteDTO,
  PpcObservationToSave
} from '@ic-models/prior-operational/ppc-report/ppc-observations/ppc-observation';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class PpcObservationsService {
  constructor(private http: HttpClient) {}

  getPpcObservationsReport(
    ppcReportProcedureId: number
  ): Observable<IPpcObservationReportList[]> {
    const req = {
      ppcReportProcedureId: ppcReportProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcObservationReportList[]>(
        `${baseUrl}/ppcobservations/listPpcObservationReport`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcObservationReportList[]>) =>
            resp.body as IPpcObservationReportList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las ppc observaciones del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcObservationsReportFromParent(
    currentPpcProcedureId: number
  ): Observable<IPpcObservationReportList[]> {
    const req = {
      currentPpcProcedureId: currentPpcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcObservationReportList[]>(
        `${baseUrl}/ppcobservations/listPpcObservationReportFromParent`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcObservationReportList[]>) =>
            resp.body as IPpcObservationReportList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las observaciones cpp del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcObservationsModelDefinition(
    ppcAdministrativeFileId: number,
    currentPpcProcedureId: number,
    ppcReportProcedureId: number,
    isReformulated: boolean,
    savedPronouncements: boolean
  ): Observable<IPpcObservationModelList[]> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      currentPpcProcedureId: currentPpcProcedureId,
      ppcReportProcedureId: ppcReportProcedureId,
      isReformulated: isReformulated,
      savedPronouncements: savedPronouncements
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcObservationModelList[]>(
        `${baseUrl}/ppcobservations/listPpcModelDefinition`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcObservationModelList[]>) =>
            resp.body as IPpcObservationModelList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las ppc observaciones del modelo del servirdor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countPpcObservationsModelDefinition(
    filter: string,
    ppcAdministrativeFileId: number,
    ppcReportProcedureId: number
  ): Observable<number> {
    const req = {
      searchBox: filter,
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      ppcReportProcedureId: ppcReportProcedureId
    };

    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/ppcobservations/countPpcModelDefinition`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al contar las observaciones de modelo del informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deletePpcObservations(
    ppcObservationDelete: PpcObservationToDeleteDTO
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/deletePpcObservations`, ppcObservationDelete)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al borrar las observaciones del informe de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcObservations(
    ppcObservationSave: PpcObservationToSave
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/savePpcObservation`, ppcObservationSave)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar las observaciones de control permanente previo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
