/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { StateStorageService } from '@ic-services/state-storage.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import { LoginService } from '@ic-services/login.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private stateStorageService: StateStorageService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: HttpErrorResponse) => {
          if (
            err.status === 401 &&
            err.url &&
            !err.url.includes('api/account')
          ) {
            this.stateStorageService.storeUrl(
              this.router.routerState.snapshot.url
            );
            this.auth.logout({
              logoutParams: { returnTo: environment.redirectUrl }
            });
            this.router.navigate(['']);
          }
        },
        () => {}
      )
    );
  }
}
