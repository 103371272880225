import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import {
  ISettingsBudgetApps,
  ISettingsBudgetAppsFilter
} from '@ic-app/models/settings/budget-apps/settings-budget-apps.model';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsBudgetAppsService {
  constructor(private http: HttpClient) {}

  findBudgetApps(
    filter: ISettingsBudgetAppsFilter,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<ISettingsBudgetApps[]> {
    const req = {
      searchBox: filter.searchBox,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ISettingsBudgetApps[]>(`${baseUrl}/settings/findBudgetApps`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ISettingsBudgetApps[]>) =>
            resp.body as ISettingsBudgetApps[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las partidas presupuestarias:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countBudgetApps(
    filter: ISettingsBudgetAppsFilter,
    mainTable: boolean,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      mainTable: mainTable,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/settings/countBudgetApps`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar las partidas presupuestarias:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  listBudgetApps(entityId: number): Observable<ISettingsBudgetApps[]> {
    const req = {
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ISettingsBudgetApps[]>(`${baseUrl}/settings/getBudgetApps`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ISettingsBudgetApps[]>) =>
            resp.body as ISettingsBudgetApps[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las partidas presupuestarias:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveBudgetApps(budgetAppsToSave: ISettingsBudgetApps[]): Observable<boolean> {
    return this.http
      .post<boolean>(`${baseUrl}/saveBudgetApps`, budgetAppsToSave)
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar las aplicaciones presupuestarias:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
