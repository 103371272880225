/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from '@auth0/auth0-angular';
import { ModuleType } from '@ic-app/constants/storage.constants';
import {
  IPpcItemCommentReportList,
  IPpcReportItemComment
} from '@ic-models/prior-operational/ppc-report/ppc-item-comment/ppc-item-comment';
import { IPTreePpcData } from '@ic-models/trees/ppc-report-pronouncements-tree.model';
import { FileStorageService } from '@ic-services/filestorage.service';
import { Subject, takeUntil } from 'rxjs';

import { IActionEmit } from '../inspection-tree/tree-action-button/tree-action-button.component';

@Component({
  selector: 'ic-ppc-tree',
  templateUrl: './ppc-tree.component.html'
})
export class PpcTreeComponent implements OnChanges {
  @Input() onlySee?: boolean;
  @Input() nestedDataSource: any;
  @Input() nestedTreeControl: any;
  @Input() ppcItemCommentReportList?: IPpcItemCommentReportList[];
  @Input() currentUser?: User;
  @Input() ppcReportNodeCommentAdded?: IPTreePpcData;
  @Input() nodeHasComments?: boolean;
  @Input() conclusionCardButtonClicked?: boolean;
  @Output() nodeButtonSelected = new EventEmitter<IActionEmit>();
  @Output() addCommentSelected = new EventEmitter();

  customColorClassName: any;
  unsubscribe$ = new Subject<void>();
  expandedComments = false;
  ppcReportItemCommentList?: IPpcReportItemComment[];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fileStorageService: FileStorageService
  ) {
    this.matIconRegistry.addSvgIcon(
      'e_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/e_letter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'a_letter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/icons/a_letter.svg'
      )
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    /* console.log(changes); */
    //Detectamos el cambio de que se ha añadido uno o varios comentarios
    // de esta forma nos evitamos llamar a un servicio
    if (this.ppcReportNodeCommentAdded !== undefined) {
      if (this.nodeHasComments) {
        //Actualizamos que el nodo padre pasa a tener comentarios en items para que se nos muestre el boton
        //de Ocultar/Mostrar todos los comentarios
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.nestedDataSource.data[0].itemsToShowHaveComments = true;
        this.expandComments(this.ppcReportNodeCommentAdded as IPTreePpcData);
      } else {
        this.ppcReportNodeCommentAdded.showComments = undefined;
      }
      //Si hay otros comentarios expandidos los cerramos
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.nestedDataSource.data[0].children?.forEach((node: IPTreePpcData) => {
        if (
          this.ppcReportNodeCommentAdded?.id !== node.id &&
          node.showComments
        ) {
          node.showComments = false;
        }
      });
    }
  }

  hasNestedChild = (_: number, nodeData: IPTreePpcData): boolean | undefined =>
    nodeData && nodeData.children && nodeData.children.length > 0;

  nodeButtonPressed(action: IActionEmit): void {
    console.log('Botón árbol pulsado', JSON.stringify(action));
    this.nodeButtonSelected.emit(action);
  }

  addCommentPressed(node: IPTreePpcData): void {
    console.log('Boton comentario pulsado');
    this.addCommentSelected.emit(node);
  }

  downloadDocument(fileName: string, fullFileName: string): void {
    this.fileStorageService
      .getDownloadUrl(
        fullFileName as string,
        fileName as string,
        ModuleType.PREVIOUS_PERMANENT_CONTROL
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: string) => {
        if (data !== '') {
          window.open(data);
        }
      });
  }

  showCommentIcon(nodeId: number): boolean {
    const foundItem = this.ppcItemCommentReportList?.find(
      (itemComment: IPpcItemCommentReportList) =>
        itemComment.ppcReportItemComment.ppcSupportedVerificationItemId ===
        nodeId
    );

    return foundItem !== undefined;
  }

  expandComments(node: IPTreePpcData): void {
    let allComentsExpanded = true;
    this.ppcItemCommentReportList?.forEach(
      (itemComment: IPpcItemCommentReportList) => {
        if (
          itemComment.ppcReportItemComment.ppcSupportedVerificationItemId ===
          node.id
        ) {
          itemComment.ppcReportItemComment.commentExpanded = true;
          node.showComments = true;
        }
      }
    );
    //Recorremos los nodos para que si todos los comentarios estan expandidos, el boton de arriba se cambie a
    //Ocultar todos los comentarios
    if (!this.conclusionCardButtonClicked) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.nestedDataSource.data[0].children?.forEach(
        (nodeDataSource: IPTreePpcData) => {
          if (nodeDataSource.showComments === false) {
            allComentsExpanded = false;
          }
        }
      );
      if (allComentsExpanded) {
        this.expandedComments = true;
      }
    } else {
      //Venimos del boton del informe cpp, por lo que, para saber si están desplegados todos los comentarios hemos de
      //mostrar solo los que tienen el nodeVisible a true
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.nestedDataSource.data[0].children?.forEach(
        (nodeDataSource: IPTreePpcData) => {
          if (
            nodeDataSource.showComments === false &&
            nodeDataSource.nodeVisible === true
          ) {
            allComentsExpanded = false;
          }
        }
      );
      if (allComentsExpanded) {
        this.expandedComments = true;
      }
    }
  }

  unexpandComments(node: IPTreePpcData): void {
    this.expandedComments = false;
    this.ppcItemCommentReportList?.forEach(
      (itemComment: IPpcItemCommentReportList) => {
        if (
          itemComment.ppcReportItemComment.ppcSupportedVerificationItemId ===
          node.id
        ) {
          itemComment.ppcReportItemComment.commentExpanded = false;
          node.showComments = false;
        }
      }
    );
  }

  expandAllComments(): void {
    this.expandedComments = true;
    this.ppcItemCommentReportList?.forEach(
      (itemComment: IPpcItemCommentReportList) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.nestedDataSource.data[0].children?.forEach(
          (node: IPTreePpcData) => {
            if (
              itemComment.ppcReportItemComment
                .ppcSupportedVerificationItemId === node.id
            ) {
              itemComment.ppcReportItemComment.commentExpanded = true;
              node.showComments = true;
            }
          }
        );
      }
    );
  }

  unexpandAllComments(): void {
    this.expandedComments = false;
    this.ppcItemCommentReportList?.forEach(
      (itemComment: IPpcItemCommentReportList) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.nestedDataSource.data[0].children?.forEach(
          (node: IPTreePpcData) => {
            if (
              itemComment.ppcReportItemComment
                .ppcSupportedVerificationItemId === node.id
            ) {
              itemComment.ppcReportItemComment.commentExpanded = false;
              node.showComments = false;
            }
          }
        );
      }
    );
  }

  isTitle(node: IPTreePpcData): boolean {
    console.log(node);
    return !node.parentId && !node.treeId;
  }
}
