<div class="reports">
  <div class="reports-content">
    <form
      autocomplete="off"
      *ngIf="reportsForm"
      [formGroup]="reportsForm"
      (ngSubmit)="submitDateRange()"
    >
      <!-- OCULTAMOS LO DE QUICKSIGHT PARA ESTA RELEASE -->
      <!-- <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{ 'reports.quicksight.title' | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="downloads-files">
            <button
              type="button"
              [title]="'reports.quicksight.goto' | translate"
              [attr.aria-label]="'reports.quicksight.goto' | translate"
              mat-icon-button
              (click)="goToQuickSight()"
            >
              <mat-icon class="button-enable">query_stats</mat-icon>
            </button>
            <p class="blue-text">
              {{ 'reports.quicksight.goto' | translate }}
            </p>
          </div>
        </mat-card-content>
      </mat-card> -->

      <mat-card>
        <mat-card-header>
          <mat-card-title> {{ 'reports.title' | translate }} </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="filter-panel-row">
            <div class="filter-panel-row-first-column">
              <ic-date-picker
                [placeHolderText]="'reports.filter.date.title' | translate"
                [range]="true"
                [disabledInput]="true"
                class="row-one-element"
                [inputFormControlStart]="controls?.dateStart"
                [inputFormControlEnd]="controls?.dateEnd"
              >
              </ic-date-picker>
            </div>
            <div class="filter-panel-row-second-column">
              <ic-raised-button
                [type]="'submit'"
                [color]="'primary'"
                [disabled]="isFormEmpty"
                [textInput]="
                  'reports.generate-statistics-report.title' | translate
                "
              ></ic-raised-button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="userRoles.includes('TENANT_ADMIN')">
        <mat-card-header>
          <mat-card-title>
            {{ 'reports.downloads-title' | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="downloads-files">
            <button
              type="button"
              [title]="'reports.download-button' | translate"
              [attr.aria-label]="'reports.download-button' | translate"
              mat-icon-button
              (click)="downloadReportAdministrativeFiles($event)"
            >
              <mat-icon class="button-enable">save_alt</mat-icon>
            </button>
            <p class="blue-text">
              {{ 'reports.report-administrative-file-title' | translate }}
            </p>
          </div>
          <div class="downloads-files">
            <button
              type="button"
              [title]="'reports.download-button' | translate"
              [attr.aria-label]="'reports.download-button' | translate"
              mat-icon-button
              (click)="downloadReportIterationAdministrativeFiles($event)"
            >
              <mat-icon class="button-enable">save_alt</mat-icon>
            </button>
            <p class="blue-text">
              {{ 'reports.iteration-administrative-file-title' | translate }}
            </p>
          </div>
          <div class="downloads-files">
            <button
              type="button"
              [title]="'reports.download-button' | translate"
              [attr.aria-label]="'reports.download-button' | translate"
              mat-icon-button
              (click)="downloadReportAdministrativeFilesObservations($event)"
            >
              <mat-icon class="button-enable">save_alt</mat-icon>
            </button>
            <p class="blue-text">
              {{
                'reports.report-administrative-file-observations-title'
                  | translate
              }}
            </p>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
