import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MyFontAwesomeModule } from '@ic-app/myfontawesome.module';
import { ComponentsModule } from '@ic-components/components.module';
import { DirectivesModule } from '@ic-core/directives/directives.module';
import { MaterialModule } from '@ic-material/material.module';
import { SharedModule } from '@ic-shared/shared.module';

import { PriorControlComponent } from './prior-control.component';
import { PRIOR_CONTROL_ROUTE } from './prior-control.routing';
import { PriorOperationalModule } from './prior-operational/prior-operational.module';

const PROJECT_MODULES = [
  SharedModule,
  MaterialModule,
  ComponentsModule,
  MyFontAwesomeModule,
  PriorOperationalModule,
  DirectivesModule
];
const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];
@NgModule({
  declarations: [PriorControlComponent],
  exports: [PriorControlComponent],
  imports: [
    ...ANGULAR_MODULES,
    RouterModule.forChild(PRIOR_CONTROL_ROUTE),
    ...PROJECT_MODULES
  ]
})
export class PriorControlModule {}
