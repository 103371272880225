<mat-tree
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl"
  class="tree mat-tree-position"
>
  <mat-tree-node *matTreeNodeDef="let node">
    <div
      *ngIf="node.nodeVisible"
      [ngClass]="{
        'ppc-tree-parent-node': !node.parentId && node.treeId,
        'tree-node': node.parentId,
        'root-node': !node.parentId && !node.treeId
      }"
    >
      <li
        *ngIf="node.nodeVisible"
        class="mat-tree-node"
        [ngStyle]="{ color: node.color }"
      >
        <div
          *ngIf="node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
            [matTooltip]="node.nationalWording"
          ></mat-icon>
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
        </div>
        <div
          *ngIf="!node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon legal-source-icon-invisible"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
          ></mat-icon>
        </div>
        <mat-icon
          *ngIf="node.completed && node.result === 1"
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="node.completed && node.result === 0"
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        ><span
          [ngClass]="{
            'text-bold': !node.parentId,
            'tree-label-result':
              node.completed && (node.result === 0 || node.result === 1),
            'tree-label': !node.completed
          }"
        >
          {{ node.wording }}
        </span>
        <div class="tree-button">
          <ic-tree-action-button
            class="button"
            *ngFor="let action of node.actionButtons"
            (actionPressed)="nodeButtonPressed($event)"
            [labelButton]="action.btnLabel"
            [actionButton]="action.action"
            [currentNode]="node"
            [customColor]="action.color ? action.color : 'default'"
            [pushedButton]="action.pushed"
          ></ic-tree-action-button>
        </div>
      </li>
    </div>
  </mat-tree-node>
  <!-- [className]="
  node.completed && (node.result === 0 || node.result === 1)
    ? 'tree-label-result'
    : 'tree-label'
" -->
  <mat-nested-tree-node
    *matTreeNodeDef="let node; when: hasNestedChild"
    [ngStyle]="{ display: !node.parentId ? 'flex' : 'block' }"
  >
    <!-- En el ngStyle, el flex es para que en los nodos padre ponga los iconos por encima de la línea divisoria
       y que en los nodos hijos, con el block ocupen todo el ancho -->
    <div
      *ngIf="node.nodeVisible"
      [ngClass]="{
        'ppc-tree-parent-node': !node.parentId && node.treeId,
        '': node.parentId || !node.treeId,
        'root-node': !node.parentId && !node.treeId
      }"
    >
      <li
        *ngIf="node.nodeVisible"
        class="mat-tree-node"
        [ngStyle]="{ color: node.color }"
      >
        <div
          *ngIf="node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
            [matTooltip]="node.nationalWording"
          ></mat-icon>
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
        </div>
        <div
          *ngIf="!node.nationalWording && node.id !== null"
          class="legal-source-icons-div"
        >
          <mat-icon
            svgIcon="a_letter"
            color="specific"
            class="text-bold legal-source-icon"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
            [class.legal-source-icon-invisible]="!node.regionWording"
            [matTooltip]="node.regionWording"
          ></mat-icon>
          <mat-icon
            svgIcon="e_letter"
            color="cross"
            class="text-bold legal-source-icon legal-source-icon-invisible"
            style="margin-left: -{{ node.depth }}px; padding-right: {{
              node.depth
            }}px"
          ></mat-icon>
        </div>
        <mat-icon
          *ngIf="node.completed && node.result === 1 && !node.parentIcon"
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="node.completed && node.result === 0 && !node.parentIcon"
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        >
        <mat-icon
          *ngIf="
            node.completed &&
            (node.result === 1 || node.result === 0) &&
            node.parentIcon
          "
          [ngStyle]="{ color: '#aaaaaa' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >subdirectory_arrow_right</mat-icon
        >
        <span
          [ngClass]="{
            'text-bold': !node.parentId,
            'tree-label-result':
              node.completed && (node.result === 0 || node.result === 1),
            'tree-label': !node.completed
          }"
        >
          {{ node.wording }}
        </span>
        <div class="tree-button">
          <ic-tree-action-button
            class="button"
            *ngFor="let action of node.actionButtons"
            (actionPressed)="nodeButtonPressed($event)"
            [labelButton]="action.btnLabel"
            [actionButton]="action.action"
            [currentNode]="node"
            [customColor]="action.color ? action.color : 'default'"
            [pushedButton]="action.pushed"
          ></ic-tree-action-button>
        </div>
      </li>
      <!-- Botones/iconos si el item es Parent y se ha pulsado la opcion que no tiene hijos -->
      <ul [class.tree-invisible]="!nestedTreeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </div>
  </mat-nested-tree-node>
</mat-tree>
