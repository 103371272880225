<!-- <div class="ic-input-autocomplete-container"> -->
<mat-form-field
  class="ic-input-autocomplete-container"
  [ngClass]="autocompleteClass"
>
  <mat-label>{{ autocompleteLabel ? autocompleteLabel : null }}</mat-label>
  <mat-icon
    *ngIf="!hideShow && !isDisabled"
    matSuffix
    [color]="iconColor"
    (openCloseTree)="openCloseTree($event)"
    (click)="showTree($event)"
    [@indicatorRotate]="showTreeGroups ? 'expanded' : 'collapsed'"
    [ngStyle]="{ cursor: 'pointer' }"
    >{{ matIcon ? matIcon : '' }}</mat-icon
  >
  <input
    matInput
    class="ic-input-autocomplete-container-form-input"
    [ngClass]="inputClass"
    type="text"
    [placeholder]="placeHolderText"
    aria-label="Tag"
    [formControl]="autocompleteFormControl"
    [matAutocomplete]="auto"
    (keydown.enter)="onEnter(autocompleteFormControl.value)"
    #trigger="matAutocompleteTrigger"
    position="above"
  />
  <mat-error
    *ngIf="
      autocompleteFormControl.hasError('noOptionSelected') &&
      !(
        autocompleteFormControl.hasError('required') ||
        autocompleteFormControl.hasError('nullValidator')
      )
    "
    >{{
      'components.input-autocomplete.errors.noOptionSelected' | translate
    }}</mat-error
  >
  <mat-error
    *ngIf="
      autocompleteFormControl.hasError('required') ||
      autocompleteFormControl.hasError('nullValidator')
    "
    >{{
      'components.input-autocomplete.errors.required' | translate
    }}</mat-error
  >

  <mat-hint *ngIf="hint">{{
    autocompleteFormControl.value
      ? autocompleteFormControl.value.parentName
      : ''
  }}</mat-hint>

  <mat-autocomplete
    #auto="matAutocomplete"
    class="ic-input-autocomplete-container-autocomplete"
    [displayWith]="displayFn"
  >
    <mat-option *ngIf="isLoading" class="is-loading"
      ><mat-spinner diameter="50"></mat-spinner
    ></mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option
        *ngFor="let element of filterElements"
        (click)="selected(element)"
        [value]="element"
        matTooltip="{{
          element.parentName !== null &&
          element.parentName !== undefined &&
          element.parentName !== ''
            ? element.parentName
            : element.name
        }}"
        matTooltipClass="mat-tooltip-autocomplete"
        [ngClass]="inputClass"
      >
        {{ element.name }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
<!-- </div> -->
