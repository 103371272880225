import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpConstants {
  static readonly GET_HEADERS = new HttpHeaders()
    .append(
      'Access-Control-Allow-Headers',
      'Content-Type, Origin, Authorization, Accept'
    )
    .append('Access-Control-Allow-Methods', 'GET')
    .append('Access-Control-Allow-Credentials', 'true')
    .append('Access-Control-Allow-Origin', '*')
    .append('accept', '*/*');

  static readonly POST_HEADERS = new HttpHeaders()
    .append(
      'Access-Control-Allow-Headers',
      'Content-Type, Origin, Authorization, Accept'
    )
    .append('Access-Control-Allow-Methods', 'POST')
    .append('Access-Control-Allow-Credentials', 'true')
    .append('Access-Control-Allow-Origin', '*')
    // .append('Content-Type', 'application/json')
    .append('accept', '*/*');

  static readonly DELETE_HEADERS = new HttpHeaders()
    .append(
      'Access-Control-Allow-Headers',
      'Content-Type, Origin, Authorization, Accept'
    )
    .append('Access-Control-Allow-Methods', 'DELETE')
    .append('Access-Control-Allow-Credentials', 'true')
    .append('Access-Control-Allow-Origin', '*')
    .append('Content-Type', 'application/json')
    .append('accept', '*/*');

  static readonly PUT_HEADERS = new HttpHeaders()
    .append(
      'Access-Control-Allow-Headers',
      'Content-Type, Origin, Authorization, Accept'
    )
    .append('Access-Control-Allow-Methods', 'PUT')
    .append('Access-Control-Allow-Credentials', 'true')
    .append('Access-Control-Allow-Origin', '*')
    .append('Content-Type', 'application/json')
    .append('accept', '*/*');
}
