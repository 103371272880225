import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { ISettingsInspectionTree } from '@ic-app/models/settings/prior-inspection/items/settings-prior-inspection-items.model';
import { IIPTreeOmissionData } from '@ic-app/models/trees/inspection-omission-pronouncements-tree.model';
import { createRequestOption } from '@ic-core/util/request-util';
import { IObservation } from '@ic-models/observations/observation';
import {
  InspectionOmissionToSaveDTO,
  InspectionToSaveDTO
} from '@ic-models/prior-inspection/inspection-model';
import { IProcedureResult } from '@ic-models/prior-inspection/procedureResult.model';
import { DocumentData } from '@ic-models/storage/document-data';
import {
  IIPTreeData,
  InspectionPronouncementsDTO
} from '@ic-models/trees/inspection-pronouncements-tree.model';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;
@Injectable()
export class InspectionService {
  constructor(private http: HttpClient) {}

  getInspectionTreeData(fileNumber: number): Observable<IIPTreeData[]> {
    return this.http
      .get<IIPTreeData[]>(`${baseUrl}/inspection/tree/${fileNumber}`, {
        headers: HttpConstants.GET_HEADERS,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IIPTreeData[]>) => resp.body as IIPTreeData[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getInspectionOmissionTreeData(
    fileNumber: number,
    inspectionOmissionProcedureId: number
  ): Observable<IIPTreeData[]> {
    return this.http
      .get<IIPTreeData[]>(
        `${baseUrl}/inspection/omission/tree/${fileNumber}/${inspectionOmissionProcedureId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IIPTreeData[]>) => resp.body as IIPTreeData[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getInspectionTreeDataForEdition(
    fileNumber: number,
    fileId: number
  ): Observable<IIPTreeData[]> {
    return this.http
      .get<IIPTreeData[]>(
        `${baseUrl}/inspection/tree/${fileNumber}/${fileId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IIPTreeData[]>) => resp.body as IIPTreeData[])
      );
  }

  getInspectionTreeDataForOnlySee(
    fileNumber: number,
    procedureId: number
  ): Observable<IIPTreeData[]> {
    return this.http
      .get<IIPTreeData[]>(
        `${baseUrl}/inspection/tree/${fileNumber}/${procedureId}/see`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IIPTreeData[]>) => resp.body as IIPTreeData[])
      );
  }

  getInspectionOmissionTreeDataForEdition(
    fileNumber: number,
    fileId: number,
    inspectionOmissionProcedureId: number
  ): Observable<IIPTreeData[]> {
    const req = {
      inspectionOmissionProcedureId: inspectionOmissionProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IIPTreeData[]>(
        `${baseUrl}/inspection/omission/edit/tree/${fileNumber}/${fileId}`,
        {
          params: options,
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IIPTreeData[]>) => resp.body as IIPTreeData[])
      );
  }

  getInspectionAllParentTreeData(
    fileNumber: number,
    fileId: number
  ): Observable<IIPTreeData[]> {
    return this.http
      .get<IIPTreeData[]>(
        `${baseUrl}/inspection/allparent/tree/${fileNumber}/${fileId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IIPTreeData[]>) => resp.body as IIPTreeData[])
      );
  }

  getInspectionErrorParentTreeData(
    fileNumber: number,
    fileId: number
  ): Observable<IIPTreeData[]> {
    return this.http
      .get<IIPTreeData[]>(
        `${baseUrl}/inspection/errorparent/tree/${fileNumber}/${fileId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IIPTreeData[]>) => resp.body as IIPTreeData[])
      );
  }

  /**
   * Carga el arbol para mostrar, al seleccionar el Ver comprobaciones
   * en la creación de la solicitud de Omision
   * @param fileNumber
   * @returns
   */
  getInspectionOmissionTreeShowData(
    fileNumber: number
  ): Observable<IIPTreeOmissionData[]> {
    return this.http
      .get<IIPTreeOmissionData[]>(
        `${baseUrl}/inspection/omission/tree/show/${fileNumber}/`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IIPTreeOmissionData[]>) =>
            resp.body as IIPTreeOmissionData[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveInspectionPronouncements(
    pronouncements: InspectionPronouncementsDTO[],
    observations: IObservation[],
    administrativeFileId: number,
    inspectionProcedureId: number,
    signersId: number[],
    fullInspected: boolean,
    resultCode: number
  ): Observable<void> {
    const req = {
      fullInspected: fullInspected,
      signersId: signersId,
      resultCode: resultCode
    };

    const inspection: InspectionToSaveDTO = {
      observations: observations,
      pronouncements: pronouncements
    };

    const options = createRequestOption(req);
    return this.http
      .post<void>(
        `${baseUrl}/inspection/pronouncements/${administrativeFileId}/${inspectionProcedureId}`,
        inspection,
        { params: options, observe: 'response' }
      )
      .pipe(
        map((resp: HttpResponse<void>) => resp.body as void),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al guardar los pronuncimamientos para el expediente ${administrativeFileId}`,
            error
          );
          // this.snackBar.open(
          //   this.translate.instant('calendar.error.save-event'),
          //   '',
          //   {
          //     panelClass: 'ntx-snackbar-error',
          //     duration: this.appConfig.errorDuration,
          //   }
          // );
          return throwError(() => new Error(error.message));
        })
      );
  }

  saveInspectionOmissionPronouncements(
    pronouncements: InspectionPronouncementsDTO[],
    observations: IObservation[],
    administrativeFileId: number,
    inspectionOmissionProcedureId: number,
    signersId: number[],
    fullInspected: boolean,
    resultCode: number,
    conclusionId: number
  ): Observable<void> {
    const req = {
      fullInspected: fullInspected,
      signersId: signersId,
      resultCode: resultCode,
      conclusionId: conclusionId
    };

    const inspection: InspectionOmissionToSaveDTO = {
      observations: observations,
      pronouncements: pronouncements
    };

    const options = createRequestOption(req);
    return this.http
      .post<void>(
        `${baseUrl}/inspection/omission/pronouncements/${administrativeFileId}/${inspectionOmissionProcedureId}`,
        inspection,
        { params: options, observe: 'response' }
      )
      .pipe(
        map((resp: HttpResponse<void>) => resp.body as void),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al guardar los pronuncimamientos para el expediente ${administrativeFileId}`,
            error
          );
          // this.snackBar.open(
          //   this.translate.instant('calendar.error.save-event'),
          //   '',
          //   {
          //     panelClass: 'ntx-snackbar-error',
          //     duration: this.appConfig.errorDuration,
          //   }
          // );
          return throwError(() => new Error(error.message));
        })
      );
  }

  updateInspectionPronouncements(
    pronouncements: InspectionPronouncementsDTO[],
    observations: IObservation[],
    observationsToDelete: number[],
    administrativeFileId: number,
    inspectionProcedureId: number,
    signersId: number[],
    fullInspected: boolean,
    resultCode: number
  ): Observable<void> {
    const req = {
      observationsToDelete: observationsToDelete,
      signersId: signersId,
      fullInspected: fullInspected,
      resultCode: resultCode
    };

    const inspection: InspectionToSaveDTO = {
      observations: observations,
      pronouncements: pronouncements
    };

    const options = createRequestOption(req);
    return this.http
      .put<void>(
        `${baseUrl}/inspection/pronouncements/${administrativeFileId}/${inspectionProcedureId}`,
        inspection,
        { params: options, observe: 'response' }
      )
      .pipe(
        map((resp: HttpResponse<void>) => resp.body as void),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al guardar los pronuncimamientos para el expediente ${administrativeFileId}`,
            error
          );
          // this.snackBar.open(
          //   this.translate.instant('calendar.error.save-event'),
          //   '',
          //   {
          //     panelClass: 'ntx-snackbar-error',
          //     duration: this.appConfig.errorDuration,
          //   }
          // );
          return throwError(() => new Error(error.message));
        })
      );
  }

  updateInspectionOmissionPronouncements(
    pronouncements: InspectionPronouncementsDTO[],
    observations: IObservation[],
    observationsToDelete: number[],
    administrativeFileId: number,
    inspectionOmissionProcedureId: number,
    signersId: number[],
    fullInspected: boolean,
    resultCode: number,
    conclusionId: number
  ): Observable<void> {
    const req = {
      observationsToDelete: observationsToDelete,
      signersId: signersId,
      fullInspected: fullInspected,
      resultCode: resultCode,
      conclusionId: conclusionId
    };

    const inspection: InspectionOmissionToSaveDTO = {
      observations: observations,
      pronouncements: pronouncements
    };

    const options = createRequestOption(req);
    return this.http
      .put<void>(
        `${baseUrl}/inspection/omission/pronouncements/${administrativeFileId}/${inspectionOmissionProcedureId}`,
        inspection,
        { params: options, observe: 'response' }
      )
      .pipe(
        map((resp: HttpResponse<void>) => resp.body as void),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al guardar los pronuncimamientos para el expediente ${administrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }

  acceptInspectionPronouncements(
    administrativeFileId: number,
    inspectionProcedureId: number,
    document: DocumentData
  ): Observable<boolean> {
    return this.http
      .put<boolean>(
        `${baseUrl}/inspection/pronouncements/accept/${administrativeFileId}/${inspectionProcedureId}`,
        document
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al aceptar el informe para el expediente ${administrativeFileId}`,
            error
          );
          // this.snackBar.open(
          //   this.translate.instant('calendar.error.save-event'),
          //   '',
          //   {
          //     panelClass: 'ntx-snackbar-error',
          //     duration: this.appConfig.errorDuration,
          //   }
          // );
          return throwError(() => new Error(error.message));
        })
      );
  }

  acceptInspectionOmissionPronouncements(
    administrativeFileId: number,
    inspectionOmissionProcedureId: number,
    document: DocumentData
  ): Observable<boolean> {
    return this.http
      .put<boolean>(
        `${baseUrl}/inspection/omission/pronouncements/accept/${administrativeFileId}/${inspectionOmissionProcedureId}`,
        document
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((error: HttpErrorResponse) => {
          console.log(
            `Error al aceptar el informe para el expediente ${administrativeFileId}`,
            error
          );
          return throwError(() => new Error(error.message));
        })
      );
  }

  deleteInspectionReport(administrativeFileId: number): Observable<boolean> {
    const options = createRequestOption();

    return this.http
      .get<boolean>(
        `${baseUrl}/inspection/pronouncements/delete/report/${administrativeFileId}`,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar el informe de fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deleteOmissionReport(administrativeFileId: number): Observable<boolean> {
    const options = createRequestOption();

    return this.http
      .get<boolean>(
        `${baseUrl}/inspection/omission/pronouncements/delete/report/${administrativeFileId}`,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar el informe de fiscalización en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getInspectionProcedureResults(): Observable<IProcedureResult[]> {
    return this.http
      .get<IProcedureResult[]>(`${baseUrl}/inspection/procedure/results/`, {
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IProcedureResult[]>) =>
            resp.body as IProcedureResult[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al recuperar los posibles resultados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getInspectionTreeDataByAdminFileTypeAndEntityAndTenant(
    administrativeFileTypeId: number,
    entityId: number,
    tenantId: number,
    essential: boolean
  ): Observable<ISettingsInspectionTree[]> {
    const req = {
      essential: essential
    };

    const options = createRequestOption(req);
    return this.http
      .get<ISettingsInspectionTree[]>(
        `${baseUrl}/inspection/tree/${administrativeFileTypeId}/${entityId}/${tenantId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ISettingsInspectionTree[]>) =>
            resp.body as ISettingsInspectionTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Permite recuperar un arbol de items por el treeId
   * @param treeId
   * @returns
   */
  getInspectionTreeDataByTreeId(
    treeId: number
  ): Observable<ISettingsInspectionTree[]> {
    return this.http
      .get<ISettingsInspectionTree[]>(
        `${baseUrl}/inspection/tree/items/${treeId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ISettingsInspectionTree[]>) =>
            resp.body as ISettingsInspectionTree[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los datos del árbol de pronunciamientos servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
