/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ModuleType,
  StorageOptions
} from '@ic-app/constants/storage.constants';
import { DialogComponent } from '@ic-components/form/dialog/dialog.component';
import { DocumentData } from '@ic-models/storage/document-data';
import { MultipartUploadId } from '@ic-models/storage/multipart-upload-id';
import { MultiPartUrlI } from '@ic-models/storage/multipart-url';
import { PartI } from '@ic-models/storage/part';
import { FileStorageService } from '@ic-services/filestorage.service';
import { TranslateService } from '@ngx-translate/core';
import JSZip from 'jszip';

export interface IFileProgress {
  file: File;
  progress: number;
  size?: number;
  name?: string;
}

@Component({
  selector: 'ic-dragndrop',
  templateUrl: './dragndrop.component.html'
})
export class DragndropComponent implements OnInit {
  @ViewChild('fileDropRef') input: ElementRef | undefined;
  @Input() dragNDropFormControl: FormControl = new FormControl();
  @Input() documentsLoaded: DocumentData[] = [];
  @Input() dragId = '';
  @Input() administrativeFileId?: number;
  @Input() hideDragndrop = false;
  @Input() multiple = false;
  @Output() filesData = new EventEmitter<DocumentData>();
  @Output() documentDelete = new EventEmitter<number>();
  @Output() removeDocumentsLoaded = new EventEmitter<DocumentData[]>();
  @Output() blockSubmit = new EventEmitter<boolean>();

  files: any[] = [];
  documentsLoadedToRemove: DocumentData[] = [];
  filesUploading: IFileProgress[] = [];
  hide = false;
  moduleType: ModuleType = ModuleType.INSPECTION_BASIC_REQUIREMENTS;

  constructor(
    private fileStorageService: FileStorageService,
    private http: HttpClient,
    public dialog: MatDialog,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const currentUrl = this.router.url;
    console.log('URL actual:', currentUrl);
    if (currentUrl.includes('ppc')) {
      this.moduleType = ModuleType.PREVIOUS_PERMANENT_CONTROL;
    }
  }

  // #region Evenetos de dragndrop

  /**
   * on file drop handler
   */
  onFileDropped($event: any): void {
    this.checkFiles($event.target.files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event: any): void {
    this.checkFiles($event.target.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number): void {
    // Reinicia el input de ficheros
    // this.input.nativeElement.value = '';
    this.files.splice(index, 1);
    this.documentDelete.emit(index);
    if (this.hideDragndrop) {
      this.hide = false;
    }
  }

  // #endregion

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number): void {
    this.blockSubmit.emit(true);
    setTimeout(() => {
      if (index === this.files.length) {
        this.blockSubmit.emit(false);
        // this.filesUploading = [];
        return;
      } else {
        //const interval = this.calculateTime(fileUploading);
        const progressInterval = setInterval(() => {
          const fileUploading = this.files[index];
          if (fileUploading) {
            fileUploading.progress = this.getProgress(fileUploading);
            if (fileUploading.progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1);
              // Si existe la opción de ocultar, se oculta el componente cuando se añade
              // un fichero(se controla que solo se añada uno)
              if (this.hideDragndrop) {
                this.hide = true;
              }
            }
          } else {
            return;
          }
        }, 50);
      }
    }, 250);
  }

  // Obtiene el porcentaje de subida al servidor
  getProgress(file: File): number {
    let progress = 0;
    this.filesUploading.forEach((element: any) => {
      if (file.name === element.file.name && file.size === element.file.size) {
        progress = element.progress;
      }
    });
    return progress;
  }

  // #region Subida de ficheros

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: any[]): void {
    for (const item of files) {
      this.uploadEachFile(item);
    }
    this.uploadFilesSimulator(0);
  }

  uploadEachFile(item: any): void {
    if (this.isZipType(item)) {
      this.unzipItem(item);
    } else {
      item.progress = 0;
      this.files.push(item);
      this.uploadFile(item);
    }
  }

  uploadFile(item: File): void {
    //Subimos el fichero, si sobrepasa el tamaño límite lo haremos en multiparte
    if (StorageOptions.FILE_MULTIPART_LIMIT_SIZE < item.size) {
      //Subida multiparte
      this.multiPartUpload(item);
    } else {
      //Subida normal
      this.normalUpload(item);
    }
  }

  // Función para subir ficheros en una sola parte
  // async normalUpload(item: File): Promise<void> {
  //   const file = item;
  //   const fileProgress: IFileProgress = {
  //     file: file,
  //     progress: 0
  //   };
  //   this.filesUploading.push(fileProgress);
  //   this.fileStorageService
  //     .getUploadUrl(item.name, this.administrativeFileId as number)
  //     .subscribe((data: DocumentData) => {
  //       const urlData = data;
  //       this.http
  //         .put(urlData.url as string, file, {
  //           // eslint-disable-next-line @typescript-eslint/naming-convention
  //           headers: { 'Content-Type': file.type },
  //           reportProgress: true,
  //           observe: 'events'
  //         })
  //         .subscribe((resp: HttpEvent<any>) => {
  //           if (resp.type === HttpEventType.Response) {
  //             urlData.size = item.size;
  //             urlData.replacement = this.isReplacement(urlData);
  //             this.filesData.emit(urlData);
  //             console.log('Upload complete');
  //           }
  //           if (resp.type === HttpEventType.UploadProgress) {
  //             const percentDone = Math.round(
  //               (100 * resp.loaded) / (resp.total || 1)
  //             );
  //             fileProgress.progress = percentDone;
  //             //console.log('Progress ' + percentDone + '%');
  //           }
  //         });
  //     });
  // }
  // async normalUpload(item: File): Promise<void> {
  //   const file = item;
  //   const fileProgress: IFileProgress = {
  //     file: file,
  //     progress: 0
  //   };
  //   this.filesUploading.push(fileProgress);
  //   const data: DocumentData = (await this.fileStorageService
  //     .getUploadUrl(item.name, this.administrativeFileId as number)
  //     .toPromise()) as DocumentData;
  //   const urlData = data;
  //   this.http
  //     .put(urlData.url as string, file, {
  //       // eslint-disable-next-line @typescript-eslint/naming-convention
  //       headers: { 'Content-Type': file.type },
  //       reportProgress: true,
  //       observe: 'events'
  //     })
  //     .subscribe((resp: HttpEvent<any>) => {
  //       if (resp.type === HttpEventType.Response) {
  //         urlData.size = item.size;
  //         urlData.replacement = this.isReplacement(urlData);
  //         this.filesData.emit(urlData);
  //         console.log('Upload complete');
  //       }
  //       if (resp.type === HttpEventType.UploadProgress) {
  //         const percentDone = Math.round(
  //           (100 * resp.loaded) / (resp.total || 1)
  //         );
  //         fileProgress.progress = percentDone;
  //       }
  //     });
  // }

  // findParentRoute(route: ActivatedRouteSnapshot): string {
  //   if (route.parent) {
  //     const parentRoute = route.parent.url
  //       .map((segment) => segment.path)
  //       .join('/');
  //     return parentRoute;
  //   }
  //   return ''; // No se encontró una ruta padre
  // }

  normalUpload(item: File): void {
    const file = item;
    const fileProgress: IFileProgress = {
      file: file,
      progress: 0
    };
    this.filesUploading.push(fileProgress);
    this.fileStorageService
      .getUploadUrl(
        item.name,
        this.administrativeFileId as number,
        this.moduleType
      )
      .subscribe((data: DocumentData) => {
        this.http
          .put(data.url as string, file, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { 'Content-Type': file.type },
            reportProgress: true,
            observe: 'events'
          })
          .subscribe((resp: HttpEvent<any>) => {
            if (resp.type === HttpEventType.Response) {
              data.size = item.size;
              data.replacement = this.isReplacement(data);
              this.filesData.emit(data);
              console.log('Upload complete');
            }
            if (resp.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round(
                (100 * resp.loaded) / (resp.total || 1)
              );
              fileProgress.progress = percentDone;
            }
          });
      });
  }

  // #region Subida de ficheros multiparte

  // Función para subir ficheros pesados en varias partes
  multiPartUpload(item: File): void {
    const file = item;
    const fileProgress: IFileProgress = {
      file: file,
      progress: 0
    };
    this.filesUploading.push(fileProgress);
    this.fileStorageService
      .getMultipartUploadId(
        item.name,
        this.administrativeFileId as number,
        this.moduleType
      )
      .subscribe((data: MultipartUploadId) => {
        const tokenFileName = data.tokenFileName;
        const uploadId = data;
        const size = item.size;
        const numParts =
          Math.trunc(size / StorageOptions.FILE_MULTIPART_PART_SIZE) + 1;

        this.fileStorageService
          .getUploadUrlMultiparts(
            tokenFileName,
            uploadId.uploadId,
            numParts,
            this.moduleType
          )
          .subscribe((data: MultiPartUrlI[]) => {
            this.uploadParts(tokenFileName, file, data, fileProgress).then(
              (parts: PartI[]) => {
                this.fileStorageService
                  .closeMultipartUpload(
                    parts,
                    tokenFileName,
                    uploadId.uploadId,
                    this.moduleType
                  )
                  .subscribe(() => {
                    this.emitMultipartMetaData(uploadId, size);
                    console.log('Terminado');
                  });
              }
            );
          });
      });
  }

  // Sube las partes al servidor y devuelve los etag que identifican cada petición de subida
  async uploadParts(
    tokenFileName: string,
    file: File,
    urls: MultiPartUrlI[],
    fileProgress: IFileProgress
  ): Promise<PartI[]> {
    const promises = this.uploadEachPart(
      tokenFileName,
      file,
      urls,
      fileProgress
    );
    this.initProgressMultipart(fileProgress, urls.length);
    // Espera hasta que todas las partes hayan sido subidas
    const resParts = await Promise.all(promises);

    const parts = resParts.map(function (part: any, index: number) {
      fileProgress.progress = 100;
      console.log(part.headers.keys().toString());
      return {
        partNumber: index + 1,
        customeTag: part.headers.get('etag')
      };
    });

    return parts;
  }

  // Sube cada parte al servidor y devuelve la respuesta de las peticiones
  uploadEachPart(
    tokenFileName: string,
    file: File,
    urls: MultiPartUrlI[],
    fileProgress: IFileProgress
  ): any[] {
    const promises: any[] = [];
    urls.forEach((element: MultiPartUrlI) => {
      // Se divide el fichero en función del número de partes
      const index = element.indexPart - 1;
      const start = index * StorageOptions.FILE_MULTIPART_PART_SIZE;
      const end = (index + 1) * StorageOptions.FILE_MULTIPART_PART_SIZE;
      const blob =
        index < urls.length ? file.slice(start, end) : file.slice(start);
      const formDataPart = new FormData();
      formDataPart.append('name', tokenFileName + '_part' + index);
      formDataPart.append('file', blob);
      // Se crea una promeas por cada parte para lanzarlas de forma asíncrona
      const promise = this.http
        .put(urls[index].url, formDataPart, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          headers: { 'Content-Type': blob.type },
          observe: 'response'
        })
        .toPromise();
      promise.then(() => {
        // Cuando termine de subirse la parte se actualiza la barra de progreso
        if (fileProgress.progress < 100) {
          fileProgress.progress += Math.round((1 / urls.length) * 100);
        }
        console.log('Parte' + element.indexPart + ' subida');
      });
      promises.push(promise);
    });
    return promises;
  }

  // Inicia la barra d eprogreso cuando se realiza una subida multiparte
  initProgressMultipart(fileProgress: IFileProgress, parts: number): void {
    // Multiplicamos por 50 para que de inicio se muestre cargado el equivalente a media parte
    fileProgress.progress = Math.round((1 / parts) * 50);
  }

  // Mapea la información del objeto pesado para conservar sus metadatos
  emitMultipartMetaData(uploadId: MultipartUploadId, size: number): void {
    const metaData = new DocumentData(
      null,
      uploadId.tokenFileName,
      uploadId.documentUuid,
      uploadId.path,
      uploadId.originalName,
      size,
      null
    );
    metaData.replacement = this.isReplacement(metaData);

    this.filesData.emit(metaData);
  }

  // #endregion

  // #endregion

  // #region Gestión de ficheros encriptados

  // Si el objeto está comprimido se descomprime y se sube cada objeto por separado
  async unzipItem(zipFile: File): Promise<void> {
    const zip = new JSZip();
    try {
      const zipObj = await zip.loadAsync(zipFile);
      console.log(zipObj.files);
      const fileList: any = [];
      for (const zobj of Object.values(zipObj.files)) {
        if (zobj.dir) continue;
        const zblob = await zobj.async('blob');
        const file = new File([zblob], zobj.name, {});
        console.log(file);
        fileList.push(file);
      }
      this.prepareFilesList(fileList);
    } catch (err) {
      console.error('Error while unzipping file:', err);
    }
  }

  // Comprueba si es un fichero comprimido
  isZipType(file: File): boolean {
    const splitName: string[] = file.name.split('.');

    const extension: string = splitName[splitName.length - 1];

    switch (extension.toUpperCase()) {
      case StorageOptions.ZIP_FORMAT: {
        return true;
      }
      case StorageOptions.RAR_FORMAT: {
        return true;
      }
      case StorageOptions.SEVEN_ZIP_FORMAT: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  // #endregion

  // #region Ficheros repetidos

  // Comprueba los ficheros a cargar
  checkFiles(files: any[]): void {
    for (const item of files) {
      this.isRepeated(item);
    }
    // Si hay ficheros repetidos (con el mismo nombre que alguno ya subido) se pregunta si desea reenplazarlos
    if (0 < this.documentsLoadedToRemove.length) {
      this.openRepeatedDialog(files);
    } else {
      this.prepareFilesList(files);
    }
  }

  // Los ficheros de nombre repetidos son candidatos a ser reemplazados
  isRepeated(item: File): boolean {
    let isRepeated = false;
    if (0 < this.documentsLoaded?.length) {
      this.documentsLoaded.forEach((element: DocumentData) => {
        if (element.originalName === item.name) {
          this.documentsLoadedToRemove.push(element);
          isRepeated = true;
        }
      });
    }
    return isRepeated;
  }

  // Ventana que pregunta por el destino de los ficheros repetidos
  openRepeatedDialog(files: any[]): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('solfrb.panel.replace-dialog.title'),
          content: this.translate.instant(
            'solfrb.panel.replace-dialog.content'
          ),
          buttonCancel: this.translate.instant(
            'solfrb.panel.replace-dialog.cancel'
          ),
          buttonAccept: this.translate.instant(
            'solfrb.panel.replace-dialog.replace'
          )
        }
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res && res.result === true) {
          this.removeDocumentsLoaded.emit(this.documentsLoadedToRemove);
          this.prepareFilesList(files);
        } else {
          // Reinicia el input de ficheros
          if (this.input) {
            this.input.nativeElement.value = '';
          }
          this.documentsLoadedToRemove = [];
        }
      });
  }

  // Determina si el documento a subir ha reemplazado a otro
  isReplacement(urldata: DocumentData): boolean {
    let isReplacement = false;
    this.documentsLoadedToRemove.forEach((element: DocumentData) => {
      if (element.originalName === urldata.originalName) {
        isReplacement = true;
      }
    });
    return isReplacement;
  }

  // #endregion

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals: number = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
