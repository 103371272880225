import { Injectable } from '@angular/core';

@Injectable()
export class SettingsConstants {
  static readonly FAVORABLE = 1;
  static readonly UNFAVORABLE = 0;
  static readonly ASK_SON = 3;

  static readonly YES = 1;
  static readonly NO = 0;

  static readonly BASIC_REQUIREMENT = 1;
  static readonly BASIC_ADITIONAL = 2;

  static readonly ACM_OPTION = 1;
  static readonly EL_OPTION = 2;
}
