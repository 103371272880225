<div class="ic-searchbox">
  <div class="ic-searchbox-title ic-mr-24">
    <!-- <img
      src="{{ src }}"
      alt=""
      class="ic-searchbox-title-logo ic-ml-20"
    /> -->
    <span
      class="ic-searchbox-title-label ic-text-grey-800 ic-text-18 ic-ml-24"
      >{{ label }}</span
    >
  </div>
  <div class="ic-searchbox-searchbox">
    <ic-searchbox-filter
      id="toolbarSearchboxId"
      [placeHolderText]="placeHolderText"
      x
      [searchboxInputFormControl]="toolbarFormControl"
    >
      <!-- para rellenar este contenido tendremos que crear un <div filter></div> en
      el componente padre @link https://stackoverflow.com/questions/52638718/multiple-ng-content -->
      <ng-content select="[filter]"></ng-content>
    </ic-searchbox-filter>
  </div>
  <div class="ic-searchbox-options ic-mr-16">
    <!-- para rellenar este contenido tendremos que crear un <div buttons></div> en
      el componente padre @link https://stackoverflow.com/questions/52638718/multiple-ng-content -->
    <ng-content select="[buttons]"></ng-content>
  </div>
</div>
