import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesModule } from '@ic-pages/pages.module';

import { Authority } from './constants/authority.constants';

const routes: Routes = [
  {
    path: '',
    data: {
      role: [
        Authority.INSPECTOR,
        Authority.READER,
        Authority.OMISSION,
        Authority.OMISSION_READER,
        Authority.PPC,
        Authority.PPC_READER
      ]
    },
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    PagesModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
