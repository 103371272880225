/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { IActionButton } from '@ic-models/action-button.model';
import { IAdministrativeFileCorrectionContacts } from '@ic-models/prior-inspection/administrative-file.model';
import { IFrbRequestCorrectionContacts } from '@ic-models/prior-inspection/frb-request.model';
import { IPpcAdministrativeFileCorrectionContacts } from '@ic-models/prior-operational/ppc-administrative-file.model';
import { IPpcReturnRequestCorrectionContacts } from '@ic-models/prior-operational/ppc-request.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'ic-input-chip',
  templateUrl: './input-chip.component.html'
})
export class InputChipComponent implements OnInit {
  @Input() inputType?: string;
  @Input() placeHolderText?: string;
  @Input() inputFormControl: FormControl = new FormControl();
  @Input() inputHint?: string;
  @Input() autoCompleteConfig?: string;
  @Input() min?: number;
  @Input() max?: number;
  @Input() inputLabel?: string;
  @Input() inputClass?: string;
  @Input() disabledInput?: boolean | null = false;
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() maxEmailsLength = 2048;
  @Input() required?: boolean;
  @Input() nullValidator?: boolean;
  @Input() inputSeparatorKeyCodes?: number[];
  @Input() chips?:
    | IAdministrativeFileCorrectionContacts[]
    | IFrbRequestCorrectionContacts[]
    | IPpcAdministrativeFileCorrectionContacts[]
    | IPpcReturnRequestCorrectionContacts[];
  @Input() isRemovable?: boolean = true;
  @Input() iconClass?: string;
  @Input() firstLineClass?: string;
  @Input() secondLineClass?: string;
  @Input() removeIcon?: string;
  @Input() chipIcon?: string;
  @Input() firstLineKey?: string;
  @Input() secondLineKey?: string;
  @Input() unknownName?: string;
  @Input() chipsFiltered?: Observable<any[]>;

  @Output() chipRemove: EventEmitter<
    | IAdministrativeFileCorrectionContacts
    | IFrbRequestCorrectionContacts
    | IPpcAdministrativeFileCorrectionContacts
    | IPpcReturnRequestCorrectionContacts
  > = new EventEmitter<
    | IAdministrativeFileCorrectionContacts
    | IFrbRequestCorrectionContacts
    | IPpcAdministrativeFileCorrectionContacts
    | IPpcReturnRequestCorrectionContacts
  >();
  @Output() chipAdd: EventEmitter<MatChipInputEvent> =
    new EventEmitter<MatChipInputEvent>();
  @Output() chipSelect: EventEmitter<MatAutocompleteSelectedEvent> =
    new EventEmitter<MatAutocompleteSelectedEvent>();

  appearance: MatFormFieldAppearance = 'fill';

  ngOnInit(): void {
    if (this.disabledInput) {
      this.inputFormControl.disable({
        onlySelf: true,
        emitEvent: false
      });
    }

    const validators: ValidatorFn | ValidatorFn[] | null = [];
    if (this.maxLength) {
      validators.push(Validators.maxLength(this.maxLength as number));
    }
    if (validators && validators.length >= 1) {
      this.inputFormControl?.addValidators(validators);
    }
  }

  getPropertyValue(
    obj:
      | IAdministrativeFileCorrectionContacts
      | IFrbRequestCorrectionContacts
      | IPpcAdministrativeFileCorrectionContacts
      | IPpcReturnRequestCorrectionContacts,
    key: string
  ): string | number | boolean | null | undefined | IActionButton[] | null {
    if (key in obj) {
      return obj[
        key as keyof (
          | IAdministrativeFileCorrectionContacts
          | IFrbRequestCorrectionContacts
          | IPpcAdministrativeFileCorrectionContacts
          | IPpcReturnRequestCorrectionContacts
        )
      ];
    } else {
      return null;
    }
  }

  onInput(event: Event): void {
    if (
      this.inputFormControl &&
      (this.inputFormControl.value as string).length >=
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.inputFormControl.errors?.maxlength.actualLength
    ) {
      event.preventDefault();
      this.inputFormControl.markAllAsTouched();
    }
  }

  removeChip(
    chip:
      | IAdministrativeFileCorrectionContacts
      | IFrbRequestCorrectionContacts
      | IPpcAdministrativeFileCorrectionContacts
      | IPpcReturnRequestCorrectionContacts
  ): void {
    this.chipRemove.emit(chip);
  }

  addToChips(event: MatChipInputEvent): void {
    let charactersCount = this.countCharacterLengthProperty(
      this.firstLineKey as string
    );
    charactersCount =
      charactersCount !== undefined
        ? charactersCount + event.value.length
        : event.value.length;
    if (
      !this.inputFormControl.hasError('maxlength') &&
      charactersCount < this.maxEmailsLength
    ) {
      this.chipAdd.emit(event);
    }
    if (charactersCount > this.maxEmailsLength) {
      this.inputFormControl.setErrors({ maxEmailsLengthError: true });
    }
  }

  selectedChip(event: MatAutocompleteSelectedEvent): void {
    this.chipSelect.emit(event);
  }

  private countCharacterLengthProperty(key: string): number | undefined {
    return this.chips?.reduce(
      (
        count: number,
        chip:
          | IAdministrativeFileCorrectionContacts
          | IFrbRequestCorrectionContacts
          | IPpcAdministrativeFileCorrectionContacts
          | IPpcReturnRequestCorrectionContacts
      ) => count + (this.getPropertyValue(chip, key) as string).length,
      0
    );
  }
}
