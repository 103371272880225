export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  INSPECTOR = 'ROLE_INSPECTOR',
  READER = 'ROLE_READER',
  OMISSION = 'ROLE_OMISSION',
  OMISSION_READER = 'ROLE_OMISSION_READER',
  PPC = 'ROLE_PPC',
  PPC_READER = 'ROLE_PPC_READER',
  SCIPION_ADMIN = 'ROLE_SCIPION_ADMIN',
  TENANT_ADMIN = 'ROLE_TENANT_ADMIN',
  FRB_BASIC_ADMIN = 'ROLE_FRB_BASIC_ADMIN',
  FRB_ADVANCED_ADMIN = 'ROLE_FRB_ADVANCED_ADMIN',
  CPP_BASIC_ADMIN = 'ROLE_CPP_BASIC_ADMIN',
  CPP_ADVANCED_ADMIN = 'ROLE_CPP_ADVANCED_ADMIN'
}
