<!-- <div class="textarea-container"> -->
<mat-form-field
  class="ic-input-textarea-container"
  [ngClass]="textAreaClass"
  *ngIf="textAreaControl"
>
  <mat-label>{{ textAreaLabel }}</mat-label>
  <textarea
    matInput
    class="ic-input-textarea-container-form-textarea"
    [type]="textAreaType ?? ''"
    [placeholder]="placeHolderText ?? ''"
    [formControl]="textAreaControl"
    [ngClass]="textAreaInputClass"
    (input)="onInput($event)"
  ></textarea
  ><mat-error
    *ngIf="
      textAreaControl.hasError('required') ||
      textAreaControl.hasError('nullValidator')
    "
    >{{ 'components.input-textarea.errors.required' | translate }}</mat-error
  >
  <mat-error
    *ngIf="
      textAreaControl.hasError('maxlength') &&
      (textAreaControl.dirty || textAreaControl.touched)
    "
  >
    {{
      'components.input-textarea.errors.maxLength'
        | translate : { maxLengthNumber: maxLength }
    }}
  </mat-error>
  <mat-hint>{{ inputHint ? inputHint : '' }}</mat-hint>
</mat-form-field>
<!-- </div> -->
