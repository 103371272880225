/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  HostListener
} from '@angular/core';

/**
 * @see https://medium.com/@vivsvaan/custom-directive-with-angular-mat-autocomplete-1ec06ed02dd1
 * @see https://github.com/vivsvaan/Angular_mat-autocomplete_directive/tree/master/CustomAutocomplete
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appAutoSelectValue]'
})
export class AutoSelectValueDirective {
  @Input() dropdownList: Set<string> = new Set();
  topValue: string | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('blur') AutoSelectValue(): void {
    const value = this.el.nativeElement.value;
    this.topValue = this.dropdownList.values().next().value;
    if (!this.topValue || !value) {
      (this.el.nativeElement as HTMLInputElement).value = '';
    } else {
      (this.el.nativeElement as HTMLInputElement).value = this.topValue;
    }
  }
}
