import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConstantsModule } from '@ic-app/constants/constants.module';
import { MyFontAwesomeModule } from '@ic-app/myfontawesome.module';
import { ComponentsModule } from '@ic-components/components.module';
import { DirectivesModule } from '@ic-core/directives/directives.module';
import { MaterialModule } from '@ic-material/material.module';
import { LayoutTopComponent } from '@ic-shared/layout/layout-top.component';
import { LayoutComponent } from '@ic-shared/layout/layout.component';
import { LoaderComponent } from '@ic-shared/loader/loader.component';
import { MenuListItemComponent } from '@ic-shared/menu-list-item/menu-list-item.component';
import { SidebarComponent } from '@ic-shared/sidebar/sidebar.component';
import { TopNavComponent } from '@ic-shared/top-nav/top-nav.component';

const SHARED_COMPONENTS = [
  TopNavComponent,
  SidebarComponent,
  MenuListItemComponent,
  LayoutComponent,
  LoaderComponent,
  LayoutTopComponent
];

const PROJECT_MODULES = [
  MaterialModule,
  MyFontAwesomeModule,
  ComponentsModule,
  DirectivesModule,
  ConstantsModule
];

@NgModule({
  declarations: [...SHARED_COMPONENTS],
  exports: [...SHARED_COMPONENTS],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, ...PROJECT_MODULES]
})
export class SharedModule {}
