<div
  *ngIf="
    (spinnerService.visibility | async) ||
    ((spinnerService.visibilityExt | async) &&
      ((spinnerService.visibility | async) === false ||
        (spinnerService.visibility | async) === null ||
        (spinnerService.visibility | async) === undefined))
  "
  class="overlay"
>
  <mat-progress-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
  >
  </mat-progress-spinner>
</div>
