<mat-form-field
  class="ic-input-container"
  [ngClass]="inputClass"
  [appearance]="appearance"
>
  <mat-label>{{ inputLabel ? inputLabel : null }}</mat-label>
  <input
    matInput
    class="ic-input-container-form-input"
    [type]="inputType ?? ''"
    [placeholder]="placeHolderText ?? ''"
    [formControl]="inputFormControl"
    [autocomplete]="autoCompleteConfig"
    [min]="min"
    [max]="max"
    (input)="onInput($event)"
  />
  <mat-error
    *ngIf="
      inputFormControl.hasError('required') ||
      inputFormControl.hasError('nullValidator')
    "
    >{{ 'components.input.errors.required' | translate }}</mat-error
  >
  <mat-error
    *ngIf="
      inputFormControl.hasError('maxlength') &&
      (inputFormControl.dirty || inputFormControl.touched)
    "
  >
    {{
      'components.input.errors.maxLength'
        | translate : { maxLengthNumber: maxLength }
    }}
  </mat-error>
  <mat-hint>{{ inputHint ? inputHint : '' }}</mat-hint>
</mat-form-field>
