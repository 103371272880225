import { Routes } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-core/guards/auth.guard';

import { AdministrativeFilesModule } from './administrative-files/administrative-files.module';
import { FrbRequestModule } from './frb-request/frb-request.module';
import { OperationalComponent } from './operational.component';
import { SignaturesModule } from './signatures/signatures.module';

export const OPERATIONAL_ROUTE: Routes = [
  {
    path: '',
    component: OperationalComponent,
    data: {
      role: [Authority.INSPECTOR, Authority.READER],
      title: 'sidebar.menu.label.prior-inspection.operational'
    },
    canActivate: [CustomAuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/priorinspection/operational/administrative-file/list',
        pathMatch: 'full'
      },
      {
        path: 'solfrb',
        loadChildren: async (): Promise<typeof FrbRequestModule> =>
          (await import('./frb-request/frb-request.module')).FrbRequestModule
      },
      {
        path: 'administrative-file',
        loadChildren: async (): Promise<typeof AdministrativeFilesModule> =>
          (await import('./administrative-files/administrative-files.module'))
            .AdministrativeFilesModule
      },
      {
        path: 'signatures',
        loadChildren: async (): Promise<typeof SignaturesModule> =>
          (await import('./signatures/signatures.module')).SignaturesModule
      }
    ]
  }
];
