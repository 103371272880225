<mat-tree
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl"
  class="tree mat-tree-position"
>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <div
      *ngIf="node.nodeVisible"
      [ngClass]="{
        'tree-parent': !node.parentId && node.treeId,
        '': node.parentId || !node.treeId,
        'root-node': !node.parentId && !node.treeId
      }"
    >
      <li
        *ngIf="node.nodeVisible"
        class="mat-tree-node"
        [ngStyle]="{ color: node.color }"
      >
        <mat-icon
          *ngIf="node.deleted === false"
          svgIcon="fondo"
          color="cross"
          class="text-bold legal-source-icon"
          [ngStyle]="{
            'margin-left': '-' + node.depth + 'px',
            'padding-right': node.depth + 'px'
          }"
        ></mat-icon>
        <mat-icon
          *ngIf="node.deleted === true"
          svgIcon="fondo"
          color="cross"
          class="text-bold legal-source-icon"
          [ngStyle]="{
            'margin-left': '-' + node.depth + 'px',
            'padding-right': node.depth + 'px'
          }"
          matTooltip="{{ 'common.deleted' | translate }}"
        ></mat-icon>
        <!--  INICIO ICONOS ARBOL SIMULACION -->
        <mat-icon
          *ngIf="
            node.completed &&
            node.result === 1 &&
            !node.parentIcon &&
            isTreeForSimulation === true
          "
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="
            node.completed &&
            node.result === 0 &&
            !node.parentIcon &&
            isTreeForSimulation === true
          "
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        >
        <mat-icon
          *ngIf="
            node.completed &&
            (node.result === 1 || node.result === 0) &&
            node.parentIcon &&
            isTreeForSimulation === true
          "
          [ngStyle]="{ color: '#aaaaaa' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >subdirectory_arrow_right</mat-icon
        >
        <!--  FIN ICONOS ARBOL SIMULACION -->

        <!--  INICIO ICONOS ARBOL NO SIMULACION -->
        <mat-icon
          *ngIf="
            node.completed &&
            node.result === 1 &&
            !node.parentIcon &&
            isTreeForSimulation === false &&
            node.ifYes !== 3 &&
            node.ifNo !== 3
          "
          [ngStyle]="{ color: '#0a8407' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >check</mat-icon
        >
        <mat-icon
          *ngIf="
            node.completed &&
            node.result === 0 &&
            !node.parentIcon &&
            isTreeForSimulation === false &&
            node.ifYes !== 3 &&
            node.ifNo !== 3
          "
          [ngStyle]="{ color: '#e80202' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >clear</mat-icon
        >
        <mat-icon
          *ngIf="
            node.completed &&
            (node.result === 1 || node.result === 0) &&
            node.parentIcon &&
            isTreeForSimulation === false &&
            (node.ifYes === 3 || node.ifNo === 3)
          "
          [ngStyle]="{ color: '#aaaaaa' }"
          [ngClass]="{ 'text-bold': !node.parentId }"
          >subdirectory_arrow_right</mat-icon
        >
        <!--  FIN ICONOS ARBOL NO SIMULACION -->
        <span
          [ngClass]="{
            'text-bold': !node.parentId,
            'tree-label-result':
              node.completed && (node.result === 0 || node.result === 1),
            'tree-label': !node.completed
          }"
        >
          {{ node.wording }}
        </span>
        <div class="tree-button">
          <ic-tree-action-button
            class="button"
            *ngFor="let action of node.actionButtons"
            (actionPressed)="nodeButtonPressed($event)"
            [onlySee]="onlySee"
            [labelButton]="action.btnLabel"
            [actionButton]="action.action"
            [currentNode]="node"
            [customColor]="action.color ? action.color : 'default'"
            [pushedButton]="action.pushed"
          ></ic-tree-action-button>
        </div>
        <ng-container *ngFor="let actionIcon of node.actionIcons">
          <ic-tree-icon-button
            *ngIf="showActionsIcons"
            [iconName]="actionIcon.iconName"
            [titleIcon]="actionIcon.title | translate"
            [matIconClass]="actionIcon.class"
            [actionButton]="actionIcon.action"
            [currentNode]="node"
            (executedActionIcons)="nodeIconPressed($event)"
          >
          </ic-tree-icon-button>
        </ng-container>
      </li>
      <ul [class.tree-invisible]="!nestedTreeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </div>
  </mat-nested-tree-node>
</mat-tree>
