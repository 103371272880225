<mat-tree
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl"
  class="example-tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node">
    <li class="mat-tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
      <ic-icon-button
        *ngIf="node.isSelectable"
        [color]="'primary'"
        [matTooltip]="'components.input-tree-detail.tooltip' | translate"
        [matIcon]="'wysiwyg'"
        (click)="showTree(node)"
      >
      </ic-icon-button>
      <ic-stroked-button
        *ngIf="node.isSelectable"
        [color]="'primary'"
        (click)="selectedNode(node)"
        [matTooltip]="node.parentName"
        [textInput]="node.name"
        [cssClasses]="'border-none'"
      >
      </ic-stroked-button>
      <span *ngIf="!node.isSelectable">{{ node.name }}</span>
    </li>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <li>
      <div class="mat-tree-node">
        <button
          type="button"
          mat-icon-button
          matTreeNodeToggle
          (click)="selectedParent(node)"
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{
              nestedTreeControl.isExpanded(node) && node.expandable
                ? 'expand_more'
                : 'chevron_right'
            }}
          </mat-icon>
        </button>
        <span [matTooltip]="node.description">{{ node.name }}</span>
      </div>
      <ul [class.example-tree-invisible]="!nestedTreeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
