import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@ic-core/directives/directives.module';
import { MaterialModule } from '@ic-material/material.module';
import { ErrorModule } from '@ic-pages/error/error.module';
import { OmissionInspectionModule } from '@ic-pages/omission-inspection/omission-inspection.module';
import { PagesComponent } from '@ic-pages/pages.component';
import { PAGES_ROUTE } from '@ic-pages/pages.routing';
import { PriorControlModule } from '@ic-pages/prior-control/prior-control.module';
import { PriorInspectionModule } from '@ic-pages/prior-inspection/prior-inspection.module';
import { SharedModule } from '@ic-shared/shared.module';

const PROJECT_MODULES = [
  SharedModule,
  MaterialModule,
  PriorControlModule,
  PriorInspectionModule,
  OmissionInspectionModule,
  DirectivesModule,
  ErrorModule
];
const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [PagesComponent],
  exports: [PagesComponent],
  imports: [
    ...ANGULAR_MODULES,
    RouterModule.forChild(PAGES_ROUTE),
    ...PROJECT_MODULES
  ]
})
export class PagesModule {}
