/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { IAutoCompleteElement } from '@ic-components/form/input-autocomplete/input-autocomplete.component';
import {
  isEmptyNullOrUndefinedObject,
  getActualFiscalYearFromTime,
  filterArrayOfObjects
} from '@ic-core/util/global-util';
import { IEntityUser } from '@ic-models/entity.model';
import { IFiscalYear } from '@ic-models/fiscal-year.model';
import { ISpendingPhase } from '@ic-models/spendingPhase.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class AppConfigService {
  version?: string;
  defaultDateFormat?: string;
  defaultTimeout?: number;
  dayFormat?: string;
  defaultLocale?: string;
  defaultTreeItemsColor?: string;
  httpSpinnerTimeout?: number;
  errorDuration?: number;
  auth0RolesProperty?: number;
  auth0LangProperty?: number;
  authorities: string[] = [];
  lang?: string;
  isLoggedIn = false;
  timeZone?: string;
  panelSizeFull?: string;
  panelSizeLg?: string;
  panelSizeMdPlus?: string;
  panelSizeMd?: string;
  panelSizeMd65?: string;
  panelSizeS?: string;
  panelSizeXs?: string;
  autocompleteAFTData: IAutoCompleteElement[] = [];
  autocompleteAFTPhaseOData: IAutoCompleteElement[] = [];
  autocompleteOUData: IAutoCompleteElement[] = [];
  autocompletePPCAFTData: IAutoCompleteElement[] = [];
  responsibleAutocompleteOUData: IAutoCompleteElement[] = [];
  autocompleteBudgetaryDescriptionData: IAutoCompleteElement[] = [];
  autocompleteBudgetaryItemData: IAutoCompleteElement[] = [];
  autocompleteBudgetaryItemFilterData: IAutoCompleteElement[] = [];
  autocompleteFiscalYearFilterData: IAutoCompleteElement[] = [];
  autocompleteScipionUsersData: IAutoCompleteElement[] = [];
  autocompleteEntitiesData: IAutoCompleteElement[] = [];
  autocompleteCategoriesByEntityIdData: IAutoCompleteElement[] = [];
  autocompleteEntitiesNotSelectedByEntitiesIdsData: IAutoCompleteElement[] = [];
  autocompleteTenantEntitiesFilterData: IAutoCompleteElement[] = [];
  autocompleteEntitiesFilterData: IAutoCompleteElement[] = [];
  administrativeFileTypeSpendingPhases: ISpendingPhase[] = [];
  private entityUserDataSource = new BehaviorSubject<IEntityUser[]>([]);
  private selectedEntitySource = new BehaviorSubject<IEntityUser | null>(null);
  private fiscalYearDataSource = new BehaviorSubject<IFiscalYear[]>([]);
  private selectedFiscalYearSource = new BehaviorSubject<IFiscalYear | null>(
    null
  );
  private currentUserAlias = new BehaviorSubject<string | null>(null);
  private noDataFiscalYear = 0;
  private allEntityDataSource = new BehaviorSubject<IAutoCompleteElement[]>([]);

  entityUserData$ = this.entityUserDataSource.asObservable();
  selectedEntity$ = this.selectedEntitySource.asObservable();
  fiscalYearData$ = this.fiscalYearDataSource.asObservable();
  selectedFiscalYear$ = this.selectedFiscalYearSource.asObservable();
  userAlias$ = this.currentUserAlias.asObservable();
  allEntitiesData$ = this.allEntityDataSource.asObservable();

  constructor(
    private readonly http: HttpClient,
    private translate: TranslateService,
    private auth: AuthService
  ) {}

  load(): Promise<{
    [key1: string]: unknown;
  }> {
    const data$ = this.http.get('./assets/app.config.json') as Observable<{
      [key1: string]: number | string | unknown;
    }>;
    const promise = lastValueFrom(data$).then(
      (data: { [key1: string]: number | unknown }) => {
        Object.assign(this, data);
        return data;
      }
    );
    return promise;
  }

  getUsersInfo(): Promise<boolean> {
    return new Promise(
      (resolve: (value: boolean | PromiseLike<boolean>) => void) => {
        this.auth.isAuthenticated$.subscribe((authenticated: boolean) => {
          this.isLoggedIn = authenticated;
          if (authenticated !== true) {
            this.auth.loginWithRedirect();
          } else {
            this.getIdTokenInfo().then(() => resolve(true));
          }
        });
      }
    );
  }

  getIdTokenInfo(): Promise<void> {
    return new Promise((resolve: (value: void | PromiseLike<void>) => void) => {
      this.auth.user$.subscribe((user: any) => {
        console.log('user: ', user);
      });
      this.auth.appState$.subscribe((state: any) => {
        console.log('state: ', state);
      });
      this.auth.error$.subscribe((error: any) => {
        console.error('error: ', error);
      });
      this.auth.idTokenClaims$.subscribe((user: any) => {
        this.authorities = user
          ? (user[`${this.auth0RolesProperty}`] as string[])
          : [];
        this.lang = user
          ? user[`${this.auth0LangProperty}`]
            ? (user[`${this.auth0LangProperty}`] as string)
            : 'es'
          : 'es';
        console.log('lang = ', this.lang);
        // this.appConfig.lang = 'ca';
        resolve();
      });
    });
  }

  // function(user, context, callback) {
  //   // if not the app TestApp we do nothing
  //   if (context.clientName !== 'TestApp') {
  //     return callback(null, user, context);
  //   }
  //   //var namespace = "https://scipionapp.com";
  //   const assignedRoles = (context.authorization || {}).roles;
  //   context.idToken['https://scipionapp.com/roles'] = assignedRoles;

  //   user.user_metadata = user.user_metadata || {};
  //   const lang = user.user_metadata.lang || 'es';

  //   context.idToken['https://scipionapp.com/lang'] = lang;

  //   user.app_metadata = user.app_metadata || {};
  //   let permissions = user.app_metadata.roles || ['ROLE_USER'];
  //   let requestedScopes =
  //     context.request.body.scope || context.request.query.scope;
  //   let filteredScopes = requestedScopes.split(' ').filter(function (x) {
  //     return x.indexOf(':') < 0;
  //   });
  //   Array.prototype.push.apply(filteredScopes, permissions);

  //   context.accessToken.scope = filteredScopes.join(' ');
  //   context.accessToken['https://scipionapp.com/email'] = user.email;
  //   //  context.idToken['https://scipionapp.com/roles'] = user.app_metadata.roles;

  //   callback(null, user, context);
  // }

  //   /**
  // * Handler that will be called during the execution of a PostLogin flow.
  // *
  // * @param {Event} event - Details about the user and the context in which they are logging in.
  // * @param {PostLoginAPI} api - Interface whose methods can be used to change the behavior of the login.
  // */
  // exports.onExecutePostLogin = async (event, api) => {

  //   const namespace = "https://scipionapp.com";

  //   // If not the app TestApp we do nothing
  //   if (event.client.name !== 'TestApp') {
  //     return;
  //   }

  //   // Put the user roles stored in Auth0 in the ID token
  //   const assignedRoles = event.authorization?.roles;
  //   api.idToken.setCustomClaim(`${namespace}/roles`, assignedRoles);

  //   // Put the user language stored in Auth0 in the ID token
  //   const lang = event.user.user_metadata?.lang || "es";
  //   api.idToken.setCustomClaim(`${namespace}/lang`, lang);

  //   // Managing user permissions and scopes
  //   event.user.app_metadata = event.user.app_metadata || {};
  //   let permissions = event.user.app_metadata.roles || ["ROLE_USER"];
  //   let requestedScopes = event.transaction?.requested_scopes;
  //   let filteredScopes = requestedScopes?.filter(x => !x.includes(':'));

  //   // const scopeArray = [];

  //   // Add custom scopes to the Access Token
  //   if (filteredScopes) {
  //     filteredScopes.forEach(scope => {
  //       if (!permissions.includes(scope)) {
  //         api.accessToken.addScope(scope);
  //         // scopeArray.push(scope);
  //       }
  //     });
  //   }

  //   // api.idToken.setCustomClaim(`${namespace}/scopeArray`, scopeArray);

  //   // const scopeArray2 = [];

  //   // Add custom scopes to the Access Token
  //  // Include permissions as additional scopes
  //   permissions.forEach(permission => {
  //     if (filteredScopes && !filteredScopes.includes(permission)) {
  //       api.accessToken.addScope(permission);
  //       // scopeArray2.push(permission);
  //     }
  //   });

  //   // api.idToken.setCustomClaim(`${namespace}/scopeArray2`, scopeArray2);

  //   // Put the user email in the Access token
  //   api.accessToken.setCustomClaim(`${namespace}/email`, event.user.email);
  // };

  // /**
  // * Handler that will be invoked when this action is resuming after an external redirect. If your
  // * onExecutePostLogin function does not perform a redirect, this function can be safely ignored.
  // *
  // * @param {Event} event - Details about the user and the context in which they are logging in.
  // * @param {PostLoginAPI} api - Interface whose methods can be used to change the behavior of the login.
  // */
  // // exports.onContinuePostLogin = async (event, api) => {
  // // };

  updateEntityUserDataSource(data: IEntityUser[] | undefined): void {
    this.entityUserDataSource.next(data || []);
  }

  updateFiscalYearDataSource(data: IFiscalYear[] | undefined): void {
    this.fiscalYearDataSource.next(data || []);
  }

  updateSelectedEntity(entitySelected: IEntityUser | undefined): void {
    this.selectedEntitySource.next(entitySelected || null);
  }

  updateSelectedFiscalYear(fiscalYearSelected: IFiscalYear | undefined): void {
    this.selectedFiscalYearSource.next(fiscalYearSelected || null);
  }

  updateEntitySelection(id: number): void {
    const entitySelected = this.entityUserDataSource
      .getValue()
      .find((value: IEntityUser) => value.id === id);
    if (!isEmptyNullOrUndefinedObject(entitySelected)) {
      this.updateSelectedEntity(entitySelected);
    }
  }

  updateFiscalYearSelection(id: number): void {
    const fiscalYearSelected = this.fiscalYearDataSource
      .getValue()
      .find((value: IFiscalYear) => value.id === id);
    if (!isEmptyNullOrUndefinedObject(fiscalYearSelected)) {
      this.updateSelectedFiscalYear(fiscalYearSelected);
    }
  }

  updateCurrentUserAlias(data: string): void {
    this.currentUserAlias.next(data);
  }

  updateAllEntityDataSource(data: IAutoCompleteElement[] | undefined): void {
    this.allEntityDataSource.next(data || []);
  }

  getEntityUserDataSourceValue(): IEntityUser[] {
    return this.entityUserDataSource.getValue();
  }

  getSelectedEntity(): IEntityUser | null {
    return this.selectedEntitySource.getValue();
  }

  getFiscalYearDataSourceValue(): IFiscalYear[] {
    return this.fiscalYearDataSource.getValue();
  }

  getSelectedFiscalYear(): IFiscalYear | null {
    return this.selectedFiscalYearSource.getValue();
  }

  getCurrentUserAlias(): string | null {
    return this.currentUserAlias.getValue();
  }

  loadFiscalYearsForEntitySelection(): Promise<any> {
    const promise = this.http
      .get<IFiscalYear[]>(`${baseUrl}/users/fiscal-year`)
      .toPromise()
      .then((data: IFiscalYear[] | undefined) => {
        this.autocompleteFiscalYearFilterData = [];
        data?.forEach((fiscalYear: IFiscalYear, index: number) => {
          if (fiscalYear.description === this.noDataFiscalYear) {
            this.autocompleteFiscalYearFilterData.push({
              id: fiscalYear.id,
              name: this.translate.instant(
                'solfrb.panel.budgetaries.empty-fiscal-year'
              ) as string
            });
            data.splice(index, 1);
          } else {
            this.autocompleteFiscalYearFilterData.push({
              id: fiscalYear.id,
              name: fiscalYear.description.toString()
            });
          }
        });
        this.updateFiscalYearDataSource(data);
        let actualYear = getActualFiscalYearFromTime(data);
        if (!actualYear || actualYear.length === 0) {
          actualYear = filterArrayOfObjects(data, {
            description: Math.max(
              ...(data ? data.map((item: IFiscalYear) => item.description) : [])
            )
          }) as IFiscalYear[];
        }
        if (actualYear && actualYear.length > 0) {
          // Seteamos la entidad activa a la variable global de settingsService
          this.updateFiscalYearSelection(actualYear[0].id);
        }
        return data;
      })
      .catch(() => {
        console.error(
          'ERROR AL OBTENER LOS AÑOS FISCALES ASOCIADOS AL TENANT Y LA ENTIDAD SELECCIONADA'
        );
      });
    return promise;
  }
}
