<div class="list-filter" #container>
  <div class="list-filter-list">
    <ic-icon-button
      [disabled]="false"
      [fontAwesome]="false"
      [matIcon]="'filter_alt'"
      (click)="togglePanel()"
    ></ic-icon-button>
    <!-- <button type="button" mat-icon-button tittle="''" (click)="togglePanel()"> -->
    <!-- <mat-icon class="icon-frb icon-funnel-solid ic-text-grey-600"></mat-icon> -->
    <!-- <fa-icon size="lg" icon="filter"></fa-icon>{{ '' }} -->
    <!-- </button> -->
  </div>
  <div *ngIf="show" class="list-filter-panel ic-margin-top-4">
    <ic-minifab-button
      [disabled]="false"
      [fontAwesome]="true"
      [faIcon]="'times'"
      (icClick)="togglePanel()"
      class="list-filter-panel-closeButton"
    ></ic-minifab-button>
    <!-- <button
      type="button"
      mat-icon-button
      tittle="''"
      (click)="togglePanel()"
      class="list-filter-panel-closeButton"
    > -->
    <!-- <mat-icon class="icon-frb icon-cross ic-text-grey-600"></mat-icon> -->
    <!-- <fa-icon size="lg" icon="times"></fa-icon>{{ '' }} -->
    <!-- </button> -->
    <div class="list-filter-panel-content ic-margin-top-4">
      <div #contentPanelRef>
        <ng-content></ng-content>
      </div>
      <div
        *ngIf="!contentPanelRef.innerHTML.trim()"
        class="placeholder ic-align-center-text ic-basic-text"
      ></div>
    </div>
  </div>
</div>
