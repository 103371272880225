<mat-toolbar color="primary" class="mat-elevation-z1">
  <ic-icon-button
    [disabled]="false"
    [ariaLabel]="'Ocultar/Mostrar barra navegación'"
    [matIcon]="'menu'"
    [iconColor]="'primary'"
    (icClick)="toggleNav()"
    id="menu"
  >
  </ic-icon-button>
  <!-- <button mat-icon-button id="menu" (click)="toggleNav()">
      <mat-icon class="secondary">menu</mat-icon>
    </button> -->
  <span class="toolbar-title">{{ title }}</span>
  <div class="user-options">
    <ic-top-nav-autocomplete
      *ngIf="isSettingsMenu"
      [dataToPick]="settingsEntities"
      [dataPicked]="settingsEntitySelected"
      (dataSelected)="filteredEntitySelected($event)"
    >
    </ic-top-nav-autocomplete>
    <ic-top-nav-picker
      *ngIf="!isSettingsMenu"
      [dataToPick]="userEntities"
      [dataPicked]="userEntitySelected"
      (dataSelected)="entitySelected($event)"
    >
    </ic-top-nav-picker>
    <ic-top-nav-picker
      [dataToPick]="fiscalYears"
      [dataPicked]="fiscalYearSelected"
      (dataSelected)="fiscalYearSelection($event)"
    >
    </ic-top-nav-picker>
    <ic-menu-options
      class="user-options"
      [optionsButtons]="optionButtoms"
      [color]="'primary'"
      [iconName]="'person'"
      (actionClicked)="clickedOption($event)"
    ></ic-menu-options>
  </div>
</mat-toolbar>
