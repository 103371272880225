import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '@ic-models/select-option.model';

@Component({
  selector: 'ic-top-nav-picker',
  templateUrl: './top-nav-picker.component.html'
})
export class TopNavPickerComponent {
  @Input() dataToPick: ISelectOption[] = [];
  @Input() dataPicked?: ISelectOption;

  @Output() dataSelected: EventEmitter<number> = new EventEmitter<number>();

  onDataPickerChanged(optionSelected: ISelectOption): void {
    this.dataSelected.emit(optionSelected.id);
  }
}
