import { registerLocaleData } from '@angular/common';
import {
  HttpClientModule,
  HttpBackend,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import localeCa from '@angular/common/locales/ca';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { ComponentsModule } from '@ic-components/components.module';
import { AuthExpiredInterceptor } from '@ic-core/interceptor/auth-expired.interceptor';
import { CustomHttpInterceptor } from '@ic-core/interceptor/http.interceptor';
import { ParamsInterceptor } from '@ic-core/interceptor/params.interceptor';
import { PagesModule } from '@ic-pages/pages.module';
import { AppConfigService } from '@ic-services/app-config.service';
import { ServicesModule } from '@ic-services/services.module';
import { SettingsService } from '@ic-services/settings.service';
import { SharedModule } from '@ic-shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Authority } from './constants/authority.constants';
import { CustomReuseStrategy } from './core/strategy/custom-route-reuse-strategy';
import { MyFontAwesomeModule } from './myfontawesome.module';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeCa, 'ca');

export function HttpLoaderFactory(
  httpBackend: HttpBackend
): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(httpBackend, [
    '/assets/i18n/auth/',
    '/assets/i18n/sidebar/',
    '/assets/i18n/tabs/',
    '/assets/i18n/components/',
    '/assets/i18n/common/',
    '/assets/i18n/prior-inspection/operational/detail/',
    '/assets/i18n/prior-inspection/operational/frb-request/',
    '/assets/i18n/prior-inspection/operational/signatures/',
    '/assets/i18n/reports/',
    '/assets/i18n/prior-inspection/operational/administrative-file/',
    '/assets/i18n/omission/',
    '/assets/i18n/prior-control/operational/ppc-administrative-file/',
    '/assets/i18n/prior-control/operational/ppc-inspection/',
    '/assets/i18n/prior-control/operational/ppc-report/',
    '/assets/i18n/prior-control/operational/ppc-detail/',
    '/assets/i18n/prior-control/operational/ppc-signatures/'
  ]);
}

export function getLanguage(settingsService: SettingsService): string {
  return settingsService.getUsersLocale('');
}

export function appInit(appConfigService: AppConfigService, router: Router) {
  return async (): Promise<void> => {
    await appConfigService.load();
    await appConfigService.getUsersInfo();
    // console.log('appInit');
    const roles = appConfigService.authorities;
    // console.log('AUTHORITIES', roles);
    if (
      roles.find(
        (role: string) =>
          role === Authority.INSPECTOR || role === Authority.READER
      )
    ) {
      // console.log('INSPECTOR');
      router.navigate(['/priorinspection']);
    } else if (
      roles.find(
        (role: string) =>
          role === Authority.OMISSION || role === Authority.OMISSION_READER
      )
    ) {
      // console.log('OMISSION');
      router.navigate(['/omissioninspection']);
    } else if (
      roles.find(
        (role: string) =>
          role === Authority.PPC || role === Authority.PPC_READER
      )
    ) {
      // console.log('PPC');
      router.navigate(['/priorcontrol']);
    }
  };

  // await appConfigService.getUsersInfo();
  // appConfigService.load().then(() => appConfigService.getUsersInfo().then(()));
  // return (): void => {};
}

const ANGULAR_MODULES = [
  ServicesModule,
  ComponentsModule,
  PagesModule,
  SharedModule
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ...ANGULAR_MODULES,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot({ prefix: 'ic', separator: '-' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    MyFontAwesomeModule,
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        redirect_uri: environment.redirectUrl,
        audience: environment.audience
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.uriApiWildCard
          }
        ]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ParamsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [SettingsService],
      useFactory: getLanguage
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfigService, Router]
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
