<button
  type="button"
  mat-fab
  [color]="color"
  [disabled]="disabled"
  [attr.aria-label]="ariaLabel"
  (click)="onClick($event)"
>
  <mat-icon *ngIf="!fontAwesome" [color]="iconColor"
    >{{ matIcon ? matIcon : 'face' }}
  </mat-icon>
  <fa-icon *ngIf="fontAwesome" [size]="iconSize" [icon]="faIcon"> </fa-icon
  >{{ '' }}
</button>
