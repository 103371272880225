import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TrackingRoutingModule } from './tracking.routing';

@NgModule({
  declarations: [],
  imports: [CommonModule, TrackingRoutingModule]
})
export class TrackingModule {}
