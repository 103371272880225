/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CurrencyPipe } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[currencyInput]' })
export class CurrencyInputDirective implements OnInit {
  /**
   * The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) currency code,
   * such as `USD` for the US dollar and `EUR` for the euro. The default currency code can be
   * configured using the `DEFAULT_CURRENCY_CODE` injection token.
   */
  @Input() currencyCode = 'EUR';

  /**
   * Decimal representation options, specified by a string in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   * Default is `1`.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   * Default is `2`.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * Default is `2`.
   * If not provided, the number will be formatted with the proper amount of digits,
   * depending on what the [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) specifies.
   * For example, the Canadian dollar has 2 digits, whereas the Chilean peso has none.
   */
  @Input() currencyDigitsInfo = '1.2';

  /**
   *  A locale code for the locale format rules to use.
   * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by default.
   * See [Setting your app locale](guide/i18n#setting-up-the-locale-of-your-app).
   */
  @Input() currencyLocale = 'es-ES';

  /**
   * The format for the currency indicator. One of the following:
   *   - `code`: Show the code (such as `USD`).
   *   - `symbol`(default): Show the symbol (such as `$`).
   *   - `symbol-narrow`: Use the narrow symbol for locales that have two symbols for their
   * currency.
   */
  @Input() currencySymbol = 'symbol';

  /**
   * Separador de fracciones
   */
  @Input() fractionSeparator = ',';

  @Input()
  set maxDigits(maxDigits: number) {
    this.setRegex(maxDigits);
  }

  private digitRegex?: RegExp;
  private fractionRegex?: RegExp;
  private invalidRegex?: RegExp;
  private el: HTMLInputElement;
  private lastValid = '';

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.el = this.elementRef.nativeElement;
    this.setRegex();
    this.setFractionRegexExpression();
    this.setInvalidCharactersExpression();
  }

  ngOnInit(): void {
    const numberFromValue = this.getNumberFromValue(this.el.value);
    this.setCurrencyValue(numberFromValue);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string): void {
    // on focus remove currency formatting
    this.el.value = value.replace(this.invalidRegex as RegExp, '');
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    // on blur, add currency formatting
    const numberFromValue = this.getNumberFromValue(value);
    this.setCurrencyValue(numberFromValue);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedValue = event.clipboardData?.getData('text/plain');
    const checkNumber = Number(pastedValue);
    if (isNaN(checkNumber)) {
      const numberFromPastedValue = this.getNumberFromValue(
        pastedValue as string
      );
      this.lastValid = this.getNumberFromValueToCleanPasted(
        numberFromPastedValue
      );
      this.setCurrencyValue(numberFromPastedValue);
      // console.log('NUMBER FROM PASTED VALUE', numberFromPastedValue);
      // console.log('ACTUALIZAMOS LASTVALID EN PASTED NaN', this.lastValid);
    } else {
      this.lastValid = this.getNumberFromValueToCleanPasted(
        checkNumber.toString()
      );
      this.setCurrencyValue(checkNumber.toString());
      // console.log('CHECK NUMBER', checkNumber);
      // console.log('ACTUALIZAMOS LASTVALID EN PASTED NUMBER', this.lastValid);
    }
  }

  // variable to store last valid input
  @HostListener('input', ['$event.target.value'])
  onInput(element: string): void {
    // on input, run regex to only allow certain characters and format
    const cleanValue = (
      element.match(this.digitRegex as RegExp | string) || []
    ).join('');
    // console.log('INPUT ELEMENT', element);
    // console.log('CLEAN VALUE', cleanValue);
    if (cleanValue || !element) {
      // console.log('ACTUALIZA LASTVALID EN INPUT', this.lastValid);
      this.lastValid = cleanValue;
    }
    this.el.value = cleanValue || this.lastValid;
    // console.log('EL VALUE', this.el.value);
  }

  private setCurrencyValue(val: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const importCurrency = parseFloat(val);
    if (isNaN(importCurrency)) {
      this.el.value = '';
    } else {
      this.el.value = this.currencyPipe.transform(
        importCurrency,
        this.currencyCode,
        this.currencySymbol,
        this.currencyDigitsInfo,
        this.currencyLocale
      ) as string;
    }
  }

  /**
   * Obtiene un string formateado para poder ser convertido a un número...
   * Lo que haremos será eliminar los puntos separadores de miles y sustituir
   * las comas separadoras de fracción por .
   */
  private getNumberFromValue(val: string): string {
    const numberWithoutSeparators = val.replace(/[\s.]/g, '');
    return numberWithoutSeparators.replace(/[\s,]/g, '.');
  }

  private getNumberFromValueToCleanPasted(val: string): string {
    const valNumber = parseFloat(val);
    return valNumber.toString().replace(/[\s.]/g, ',');
  }

  // build the regex based on max pre decimal digits allowed
  private regexString(max?: number): string {
    const maxStr = max ? `{0,${max}}` : `+`;
    return `^(\\d${maxStr}(\\${this.fractionSeparator}\\d{0,2})?|\\${this.fractionSeparator}\\d{0,2})$`;
  }

  private setRegex(maxDigits?: number): void {
    this.digitRegex = new RegExp(this.regexString(maxDigits), 'g');
  }

  private setFractionRegexExpression(): void {
    this.fractionRegex = new RegExp('[' + this.fractionSeparator + ']+', 'g');
  }

  private setInvalidCharactersExpression(): void {
    this.invalidRegex = new RegExp(
      '[^0-9' + this.fractionSeparator + ']+',
      'g'
    );
  }
}
