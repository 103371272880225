import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IActionButton } from '@ic-models/action-button.model';

@Component({
  selector: 'ic-menu-options',
  templateUrl: './menu-options.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      )
    ])
  ]
})
export class MenuOptionsComponent {
  @Input() optionsButtons: IActionButton[] = [];
  @Input() color = '';
  @Input() iconName = '';
  @Input() iconAriaLabel = '';
  @Input() cssClasses = '';
  @Output() actionClicked: EventEmitter<number> = new EventEmitter<number>();

  buttonClicked = false;
  expandedOptions: boolean[] = [];

  executeAction(event: MouseEvent, action?: number): void {
    if (action !== undefined) {
      if (!this.buttonClicked) {
        this.buttonClicked = true;
        this.actionClicked.emit(action);
        setTimeout(() => {
          this.buttonClicked = false;
        }, 1000);
      } else {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        return;
      }
    }
  }

  /**
   * Expande la opcion seleccionada
   * @param event
   * @param optionsButton
   * @param index
   */
  expandChild(event: Event, optionsButton: IActionButton, index: number): void {
    event.stopPropagation();
    // Si la accion seleccionada tiene hijos, expandimos
    // el submenú asociado
    if (
      optionsButton &&
      optionsButton.children &&
      optionsButton.children.length
    ) {
      this.expandedOptions[index] = !this.expandedOptions[index];
    }
  }

  /**
   * Comprueba si está expandida la opción
   * @param index
   * @returns
   */
  isExpanded(index: number): boolean {
    return !!this.expandedOptions[index];
  }
}
