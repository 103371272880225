import { Routes } from '@angular/router';
import { Authority } from '@ic-app/constants/authority.constants';
import { CustomAuthGuard } from '@ic-core/guards/auth.guard';

import { PpcAdministrativeFileModule } from './ppc-administrative-file/ppc-administrative-file.module';
import { PpcRequestModule } from './ppc-request/ppc-request.module';
import { PpcSignaturesModule } from './ppc-signatures/ppc-signatures.module';
import { PriorOperationalComponent } from './prior-operational.component';

export const PPC_OPERATIONAL_ROUTE: Routes = [
  {
    path: '',
    component: PriorOperationalComponent,
    data: {
      role: [Authority.PPC, Authority.PPC_READER],
      title: 'sidebar.menu.label.omission-inspection.operational'
    },
    canActivate: [CustomAuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/priorcontrol/operational/ppc-administrative-file/list',
        pathMatch: 'full'
      },
      {
        path: 'ppc-administrative-file',
        loadChildren: async (): Promise<typeof PpcAdministrativeFileModule> =>
          (
            await import(
              './ppc-administrative-file/ppc-administrative-file.module'
            )
          ).PpcAdministrativeFileModule
      },
      {
        path: 'solppc',
        loadChildren: async (): Promise<typeof PpcRequestModule> =>
          (await import('./ppc-request/ppc-request.module')).PpcRequestModule
      },
      {
        path: 'ppc-signatures',
        loadChildren: async (): Promise<typeof PpcSignaturesModule> =>
          (await import('./ppc-signatures/ppc-signatures.module'))
            .PpcSignaturesModule
      }
    ]
  }
];
