import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ListFilterComponent } from '@ic-components/form/list-filter/list-filter.component';

@Component({
  selector: 'ic-toolbar-list',
  templateUrl: './toolbar-list.component.html'
})
export class ToolbarListComponent {
  @ViewChild(ListFilterComponent) listFilter?: ListFilterComponent;

  @Input() label = '';
  // @Input() src: string;
  @Input() placeHolderText = '';
  @Input() toolbarFormControl: FormControl = new FormControl();

  getListFilterComponent(): ListFilterComponent {
    return this.listFilter as ListFilterComponent;
  }
}
