import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchboxFilterComponent } from '@ic-components/form/searchbox-filter/searchbox-filter.component';

@Component({
  selector: 'ic-toolbar-searchbox',
  templateUrl: './toolbar-searchbox.component.html'
})
export class ToolbarSearchboxComponent {
  @ViewChild(SearchboxFilterComponent)
  searchBoxFilter?: SearchboxFilterComponent;

  @Input() label = '';
  // @Input() src: string;
  @Input() placeHolderText = '';
  @Input() toolbarFormControl: FormControl = new FormControl();
  @Input() showFilter = true;

  getSearchboxFilterComponent(): SearchboxFilterComponent {
    return this.searchBoxFilter as SearchboxFilterComponent;
  }
}
