export interface IBudgetAppsEntitiesTreeComponent {
  id: number;
  name: string;
  isSelectable: boolean;
  children?: IBudgetAppsEntitiesTreeComponent[] | null;
  description?: string | null;
}

export class BudgetAppsEntitiesTreeComponent
  implements IBudgetAppsEntitiesTreeComponent
{
  constructor(
    public id: number,
    public name: string,
    public isSelectable: boolean = true,
    public children?: IBudgetAppsEntitiesTreeComponent[] | null,
    public description?: string | null
  ) {}
}
