/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  ComponentPortal,
  DomPortalOutlet,
  PortalOutlet
} from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  InjectionToken,
  Injector
} from '@angular/core';
import { Subject } from 'rxjs';

export const PANEL_TOP_DATA = new InjectionToken('PANEL_TOP_DATA');

// TODO: Hay varios cambios que en futuras vesiones harán que este servicio no funcione correctamente.
// Será necesario adaptarlo correctamente
@Injectable()
export class PanelTopService {
  private panelPortalOutlet?: PortalOutlet;

  private openSource = new Subject<void>();
  private closeSource = new Subject<void>();

  private panelTopWidthSource = new Subject<string>();
  panelTopWidth$ = this.panelTopWidthSource.asObservable();

  open$ = this.openSource.asObservable();
  close$ = this.closeSource.asObservable();

  constructor(
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) {}

  open(): void {
    this.openSource.next();
  }

  close(): void {
    this.closeSource.next();
  }

  /** Used to create the data injected to the component
   * that's going to be attached to the panel PortalOutlet.
   * @param {any} data - The data to be injected into the component.
   * @returns {Injector} Returns the injector that's needed when
   * creating a ComponentPortal instance.
   * @see https://stackoverflow.com/questions/47469844/angular-cdk-how-to-set-inputs-in-a-componentportal/64356268#64356268
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createData(data: any): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: PANEL_TOP_DATA, useValue: data }]
    });
  }
  // createData(data: any): PortalInjector {
  //   const injectorTokens = new WeakMap().set(PANEL_DATA, data);

  //   return new PortalInjector(this.injector, injectorTokens);
  // }

  /** Used to attach a ComponentPortal instance to the panel PortalOutlet.
   * @param {ComponentPortal} portal - The ComponentPortal instance that's
   * going to be attached to the sidenav PortalOutlet.
   * @param {ComponentFactoryResolver} componentFactoryResolver - The component
   * factory resolver instance of the module which the instantiated component belongs to.
   * @param {Injector} injector - The injector instance of the module which the
   * instantiated component belongs to.
   * @returns {ComponentRef} Reference to the instantiated component.
   */
  // TODO: Solucionar el ComponentFactoryResolver deprecado
  attachComponent(
    portal: ComponentPortal<any>,
    componentFactoryResolver: ComponentFactoryResolver,
    injector: Injector
  ): ComponentRef<any> | null {
    const panelOutlet: Element | null =
      document.querySelector('#top-panel-outlet');

    if (panelOutlet && panelOutlet !== null) {
      this.panelPortalOutlet = new DomPortalOutlet(
        panelOutlet,
        componentFactoryResolver,
        this.applicationRef,
        injector
      );

      return this.panelPortalOutlet.attach(portal);
    }
    return null;
  }

  /** Used to detach a ComponentPortal from the sidenav PortalOutlet.*/
  detachComponent(): void {
    this.panelPortalOutlet && this.panelPortalOutlet.detach();
  }

  /** Used to set panel width dinamically */
  panelWidth(width: string): void {
    this.panelTopWidthSource.next(width);
  }
}
