import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { IUsersFilter } from '@ic-app/models/users-filter.model';
import { getUserTimeZone } from '@ic-core/util/global-util';
import {
  createRequestOption,
  getTimestampAtStartOfDay
} from '@ic-core/util/request-util';
import { IPpcDetailData } from '@ic-models/prior-control/ppc-detail.model';
import { IPpcAdministrativeFileFilter } from '@ic-models/prior-operational/ppc-administrative-file-filter.model';
import {
  IPpcAdministrativeFileAdded,
  PpcAdministrativeFile
} from '@ic-models/prior-operational/ppc-administrative-file.model';
import { IPpcAreaType } from '@ic-models/prior-operational/ppc-area-type.model';
import { IPpcProcedureDetail } from '@ic-models/prior-operational/ppc-procedure-detail.model';
import {
  IPpcAdded,
  IPpcCorrectionData,
  IPpcCorrectionDataDTO,
  IPpcCorrectionDataToEdit,
  IPpcCorrectionDataType,
  IPpcCorrectionOSData,
  IPpcRequest,
  IPpcReturnRequestCorrectionContacts,
  PpcCorrectionRequest,
  PpcRequest
} from '@ic-models/prior-operational/ppc-request.model';
import { IPpcTopicType } from '@ic-models/prior-operational/ppc-topic-type.model';
import { IDocumentsForm } from '@ic-models/storage/document-data';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class PpcService {
  constructor(private http: HttpClient) {}

  findPpcRequest(
    filter: IPpcAdministrativeFileFilter,
    pageNumber: number,
    pageSize: number
  ): Observable<IPpcRequest[]> {
    //Todo quitamos offset por zoneId: getUserTimeZone(),
    //const offset = moment().utcOffset();
    const req = {
      searchBox: filter.searchBox,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      ppcAdministrativeFileType: filter.ppcAdministrativeFileType?.id as number,
      procedureResultCodes: filter.procedureResultCodes,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      fileNumber: filter.fileNumber,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      //offset: offset
      zoneId: getUserTimeZone(),
      ppcRequestTopic:
        !!filter.topic && filter.topic.length > 0
          ? JSON.stringify(filter.topic)
          : undefined,
      ppcRequestArea:
        !!filter.area && filter.area.length > 0
          ? JSON.stringify(filter.area)
          : undefined,
      isUrgent: filter.urgentToggle,
      usersIds:
        !!filter.usersFilterId && filter.usersFilterId.length > 0
          ? JSON.stringify(filter.usersFilterId)
          : undefined
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcRequest[]>(`${baseUrl}/ppcrequest/list`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IPpcRequest[]>) => resp.body as IPpcRequest[]),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las solicitudes de control permanente previo del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countPpcRequest(filter: IPpcAdministrativeFileFilter): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      ppcAdministrativeFileType: filter.ppcAdministrativeFileType?.id as number,
      fileNumber: filter.fileNumber,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      procedureResultCodes: filter.procedureResultCodes,
      ppcRequestTopic:
        !!filter.topic && filter.topic.length > 0
          ? JSON.stringify(filter.topic)
          : undefined,
      ppcRequestArea:
        !!filter.area && filter.area.length > 0
          ? JSON.stringify(filter.area)
          : undefined,
      isUrgent: filter.urgentToggle,
      usersIds:
        !!filter.usersFilterId && filter.usersFilterId.length > 0
          ? JSON.stringify(filter.usersFilterId)
          : undefined
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/ppcrequest/countrequests`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar las solicitudes de control permanente previo del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  downloadPpcRequestFiles(
    filter: IPpcAdministrativeFileFilter,
    ppcFileTypeCode: number
  ): Observable<string> {
    const req = {
      searchBox: filter.searchBox,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      ppcAdministrativeFileType: filter.ppcAdministrativeFileType?.id as number,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      fileNumber: filter.fileNumber,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      procedureResultCodes: filter.procedureResultCodes,
      typeCode: ppcFileTypeCode,
      ppcAdministrativeFileStatus:
        !!filter.ppcAdministrativeFileStatus &&
        filter.ppcAdministrativeFileStatus.length > 0
          ? JSON.stringify(filter.ppcAdministrativeFileStatus)
          : undefined,
      topic:
        !!filter.topic && filter.topic.length > 0
          ? JSON.stringify(filter.topic)
          : undefined,
      area:
        !!filter.area && filter.area.length > 0
          ? JSON.stringify(filter.area)
          : undefined,
      isUrgent: filter.urgentToggle
    };

    // if (
    //   !!filter.administrativeFileStatus &&
    //   filter.administrativeFileStatus.length > 0
    // )
    //   req['administrativeFileStatus'] = JSON.stringify(
    //     filter.administrativeFileStatus
    //   );

    const options = createRequestOption(req);

    return this.http
      .get<string>(
        `${baseUrl}/downloadfiles/excel`,
        //.get<any>(`https://devtower.scipionapp.com/api/downloadfiles/excel`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response',
          responseType: 'text' as 'json'
        }
      )
      .pipe(
        map((resp: HttpResponse<string>) => resp.body as string),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al descargar los datos de las solicitudes CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcRequest(ppcRequest: PpcRequest): Observable<IPpcAdded> {
    //Todo zoneId
    const req = {
      zoneId: getUserTimeZone()
    };
    const options = createRequestOption(req);
    return this.http
      .post<IPpcAdded>(`${baseUrl}/ppcrequest`, ppcRequest, {
        params: options
      })
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la solicitud de Control Permanente Previo en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updatePpcRequest(
    ppcRequest: PpcRequest,
    procedureId: number
  ): Observable<IPpcAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // let initDay = moment().startOf('day').valueOf();
    //Todo zoneId
    const zoneId = getUserTimeZone();
    const req = {
      procedureId: procedureId,
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .put<IPpcAdded>(`${baseUrl}/ppcrequest/update`, ppcRequest, {
        params: options
      })
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la solicitud de Control Permanente Previo en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updatePpcRequestDocuments(
    ppcRequest: PpcRequest,
    ppcProcedureId: number
  ): Observable<boolean> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .put<boolean>(`${baseUrl}/ppcrequest/updateDocuments`, ppcRequest, {
        params: options
      })
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  acceptPpcRequest(ppcProcedureId: number): Observable<IPpcAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      ppcProcedureId: ppcProcedureId,
      initDay: initDay,
      zoneId: zoneId
      // offset: offset
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcAdded>(`${baseUrl}/ppcrequest/accept`, {
        params: options
      })
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al aceptar la solicitud de Control Permanente Previo en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  uploadFilesData(
    filesData: IDocumentsForm,
    fileId: number,
    validate: boolean
  ): Observable<IPpcAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      fileId: fileId,
      validate: validate,
      initDay: initDay,
      zoneId: zoneId
      // offset: offset
    };
    const options = createRequestOption(req);
    return this.http
      .post<IPpcAdded>(`${baseUrl}/ppcrequest/uploadFilesData`, filesData, {
        headers: HttpConstants.GET_HEADERS,
        params: options
      })
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la información de los archivos en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findPpcRequestDetail(ppcProcedureId: number): Observable<IPpcDetailData> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcDetailData>(`${baseUrl}/ppcrequest/ppcDetail`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcDetailData>) => resp.body as IPpcDetailData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar un expediente cpp del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findPpcProcedureByPpcProcedureId(
    ppcProcedureId: number
  ): Observable<IPpcProcedureDetail> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcProcedureDetail>(
        `${baseUrl}/ppcrequest/ppcProcedureByPpcProcedureId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcProcedureDetail>) =>
            resp.body as IPpcProcedureDetail
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los trámites en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionData(ppcProcedureId: number): Observable<IPpcCorrectionData> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcCorrectionData>(`${baseUrl}/ppcrequest/ppcCorrectionData`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionData>) =>
            resp.body as IPpcCorrectionData
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la información para crear una subsanación CPP',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionTypesData(): Observable<IPpcCorrectionDataType[]> {
    const options = createRequestOption();
    return this.http
      .get<IPpcCorrectionDataType[]>(
        `${baseUrl}/ppcrequest/ppcCorrectionTypesData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionDataType[]>) =>
            resp.body as IPpcCorrectionDataType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de una subsanación CPP',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionReasonsData(
    typeId: number,
    initDate: string,
    ouFileNumber: string,
    templateUri: string,
    offset: number,
    subject?: string
  ): Observable<IPpcCorrectionDataDTO> {
    // TODO: actualizamos el valor initDateMillis a partir de un initDate string from Java Instant
    // const initDateMillis = new Date(Date.parse(initDate)).getTime();
    const req = {
      typeId: typeId,
      // initDate: initDateMillis,
      initDate: initDate, // Java Instant va como un String con formato Instant: '2023-04-17T10:03:01.567486Z'
      ouFileNumber: ouFileNumber,
      templateUri: templateUri,
      zoneId: getUserTimeZone(),
      // offset: offset,
      subject: subject
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcCorrectionDataDTO>(
        `${baseUrl}/ppcrequest/ppcCorrectionReasonsData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionDataDTO>) =>
            resp.body as IPpcCorrectionDataDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los motivos de una subsanación CPP',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionContacts(
    organizationalUnitId: number
  ): Observable<IPpcReturnRequestCorrectionContacts[]> {
    const req = {
      organizationalUnitId: organizationalUnitId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcReturnRequestCorrectionContacts[]>(
        `${baseUrl}/ppcrequest/ppcOrganizationalUnitContacts`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcReturnRequestCorrectionContacts[]>) =>
            resp.body as IPpcReturnRequestCorrectionContacts[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los contactos CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcCorrection(
    ppcCorrectionData: PpcCorrectionRequest
  ): Observable<number> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      zoneId: zoneId
      // offset: offset
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(
        `${baseUrl}/ppcrequest/savePpcCorrection`,
        ppcCorrectionData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcCorrectionToEdit(
    ppcProcedureId: number
  ): Observable<IPpcCorrectionDataToEdit> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcCorrectionDataToEdit>(
        `${baseUrl}/ppcrequest/ppcCorrectionDataEdit`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionDataToEdit>) =>
            resp.body as IPpcCorrectionDataToEdit
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la subsanación CPP del servidor',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deletePpcCorrectionRequest(ppcProcedureId: number): Observable<boolean> {
    //TODO zoneid
    const req = {
      ppcProcedureId: ppcProcedureId,
      zoneId: getUserTimeZone()
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/ppcrequest/deletePpcCorrectionRequest`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de subsanación CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcRequestCorrectionAndSend(
    ppcCorrectionData: PpcCorrectionRequest,
    send: boolean = true
  ): Observable<number> {
    const req = {
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      zoneId: getUserTimeZone(),
      // offset: moment().utcOffset(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcrequest/savePpcRequestCorrectionAndSend`,
        ppcCorrectionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y enviar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editPpcCorrection(
    ppcCorrectionData: PpcCorrectionRequest,
    ppcOfficialNoteId: number
  ): Observable<number> {
    const req = {
      ppcOfficialNoteId: ppcOfficialNoteId
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcrequest/editPpcCorrection`,
        ppcCorrectionData,
        {
          headers: HttpConstants.PUT_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editPpcCorrectionAndSend(
    ppcCorrectionData: PpcCorrectionRequest,
    ppcOfficialNoteId: number,
    send: boolean = true
  ): Observable<number> {
    const req = {
      ppcOfficialNoteId: ppcOfficialNoteId,
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      zoneId: getUserTimeZone(),
      // offset: moment().utcOffset(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/ppcrequest/editPpcCorrectionAndSend`,
        ppcCorrectionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y enviar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Servicio para enviar un oficio de subsanación de solicitud CPP a un conjunto de emails
   * @param fileStreamToSend
   * @param fileNumber
   * @param fileName
   * @param contactEmails
   * @returns
   */
  sendEmailsWithPpcCorectionReports(
    fileStreamToSend: Blob,
    fileNumber: string,
    fileName: string,
    contactEmails: string[]
  ): Observable<boolean> {
    const file = new FormData();
    file.append('file', fileStreamToSend);
    const req = {
      fileNumber: fileNumber,
      fileName: fileName,
      contactEmails: contactEmails
    };
    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/ppcrequest/sendEmail/ppcCorrectionReport`,
        file,
        { params: options }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar los correos a los contactos cargados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcAmendmentData(
    ppcProcedureId: number
  ): Observable<IPpcCorrectionOSData> {
    const req = {
      ppcProcedureId: ppcProcedureId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcCorrectionOSData>(`${baseUrl}/ppcrequest/ppcAmendmentData`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcCorrectionOSData>) =>
            resp.body as IPpcCorrectionOSData
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los datos de la solicitud CPP a subsanar del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  createPpcAmendmentRequest(
    ppcAmendment: PpcRequest,
    ppcProcedureId: number
  ): Observable<IPpcAdded> {
    // TODO: Sustituimos moment, añadimos zonedId
    const zoneId = getUserTimeZone();
    const req = {
      ppcProcedureId: ppcProcedureId,
      zoneId: zoneId
    };

    const options = createRequestOption(req);

    return this.http
      .post<IPpcAdded>(`${baseUrl}/ppcrequest/savePpcAmendment`, ppcAmendment, {
        params: options
      })
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  createPpcAmendmentRequestAndValidate(
    ppcAmendment: PpcRequest,
    ppcProcedureId: number
  ): Observable<IPpcAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      zoneId: zoneId,
      // offset: offset,
      ppcProcedureId: ppcProcedureId
    };

    const options = createRequestOption(req);

    return this.http
      .post<IPpcAdded>(
        `${baseUrl}/ppcrequest/savePpcAmendmentAndValidate`,
        ppcAmendment,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y validar la subsanación CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  savePpcRequestAndValidate(ppcRequest: PpcRequest): Observable<IPpcAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      zoneId: zoneId
      // offset: offset
    };

    const options = createRequestOption(req);

    return this.http
      .post<IPpcAdded>(
        `${baseUrl}/ppcrequest/validateAndAcceptPpcRequest`,
        ppcRequest,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y validar la solicitud CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updatePpcRequestAndAccept(
    ppcRequest: PpcRequest,
    ppcProcedureId: number
  ): Observable<IPpcAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      ppcProcedureId: ppcProcedureId,
      initDay: initDay,
      zoneId: zoneId
      // offset: offset
    };
    const options = createRequestOption(req);
    return this.http
      .put<IPpcAdded>(
        `${baseUrl}/ppcrequest/updateAndAcceptPpcRequest`,
        ppcRequest,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IPpcAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y aceptar la solicitud CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deletePpcRequest(ppcAdministrativeFileId: number): Observable<boolean> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/ppcrequest/delete`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud CPP del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcTopicTypes(): Observable<IPpcTopicType[]> {
    const options = createRequestOption();
    return this.http
      .get<IPpcTopicType[]>(`${baseUrl}/ppcrequest/getPpcTopicTypes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcTopicType[]>) => resp.body as IPpcTopicType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las materias para el filtro de solicitud CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getPpcAreaTypes(): Observable<IPpcAreaType[]> {
    const options = createRequestOption();
    return this.http
      .get<IPpcAreaType[]>(`${baseUrl}/ppcrequest/getPpcAreaTypes`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IPpcAreaType[]>) => resp.body as IPpcAreaType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los ambitos para el filtro de solicitud CPP:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deleteUrgentCondition(ppcAdministrativeFileId: number): Observable<boolean> {
    const req = {
      urgent: false,
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/ppcrequest/updateurgent`, {
        params: options
      })
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la solicitud en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updatePpcAdministrativeFile(
    ppcAdministrativeFile: PpcAdministrativeFile,
    ppcAdministrativeFileId: number,
    deletePronouncements: boolean,
    deletePpcObservations: boolean,
    deletePpcSubjectiveImprovements: boolean,
    deletePpcComments: boolean
  ): Observable<IPpcAdministrativeFileAdded> {
    //TODO zoneid
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId,
      deletePronouncements: deletePronouncements,
      deletePpcObservations: deletePpcObservations,
      deletePpcSubjectiveImprovements: deletePpcSubjectiveImprovements,
      deletePpcComments: deletePpcComments,
      zoneId: getUserTimeZone()
    };
    const options = createRequestOption(req);
    return this.http
      .put<IPpcAdministrativeFileAdded>(
        `${baseUrl}/ppcadministrativefile/update`,
        ppcAdministrativeFile,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IPpcAdministrativeFileAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  updatePpcAdministrativeFileDocuments(
    ppcAdministrativeFile: PpcAdministrativeFile,
    ppcAdministrativeFileId: number
  ): Observable<boolean> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .put<boolean>(
        `${baseUrl}/ppcadministrativefile/updatePpcDocuments`,
        ppcAdministrativeFile,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar el expediente CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findPpcProceduresByPpcAdministrativeFileId(
    ppcAdministrativeFileId: number
  ): Observable<IPpcProcedureDetail[]> {
    const req = {
      ppcAdministrativeFileId: ppcAdministrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IPpcProcedureDetail[]>(
        `${baseUrl}/ppcadministrativefile/ppcProceduresByPpcAdministrativeFileId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IPpcProcedureDetail[]>) =>
            resp.body as IPpcProcedureDetail[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los trámites CPP en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los usuarios de la entidad a la que pertenece el usuario, para el filtrado
   * @returns
   */
  getUsers(): Observable<IUsersFilter[]> {
    const options = createRequestOption();
    return this.http
      .get<IUsersFilter[]>(`${baseUrl}/ppcrequest/users`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IUsersFilter[]>) => resp.body as IUsersFilter[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al cargar los usuarios para el filtro:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
