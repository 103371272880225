import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production || environment.develop) {
  console.log('ENVIRONMENT NOT LOCAL');
  console.log(environment.production);
  console.log(environment.develop);
  enableProdMode();
  window.console.log = (): void => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: Error) => console.error(err));
