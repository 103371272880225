import { Injectable } from '@angular/core';
import { IListActionButtons } from '@ic-models/prior-operational/ppc-report/ppc-observations/ppc-observation';

@Injectable({
  providedIn: 'root'
})
export class Icons {
  static CHANGE_UP_POSITION_OPTION = 1;
  static CHANGE_DOWN_POSITION_OPTION = 2;
  static EDIT_OPTION = 3;
  static DELETE_OPTION = 4;
  static ADD_OPTION = 5;
  static ADD_NOTE = 6;

  static ICON_ARROW_UP: IListActionButtons = {
    iconName: 'arrow_circle_up',
    title: '',
    action: Icons.CHANGE_UP_POSITION_OPTION
  };

  static ICON_ARROW_DOWN: IListActionButtons = {
    iconName: 'arrow_circle_down',
    title: '',
    action: Icons.CHANGE_DOWN_POSITION_OPTION
  };

  static ICON_ARROW_CROSS: IListActionButtons = {
    iconName: 'close',
    action: Icons.DELETE_OPTION,
    title: 'components.table.remove'
  };

  static ICON_ADD_OPTION: IListActionButtons = {
    iconName: 'add_box',
    action: Icons.ADD_OPTION,
    title: 'components.table.add'
  };

  static ICON_EDIT_OPTION: IListActionButtons = {
    iconName: 'edit',
    action: Icons.EDIT_OPTION,
    title: 'components.table.edit'
  };

  static ICON_EMPTY: IListActionButtons = {
    iconName: '',
    title: ''
  };

  static ICON_ADD_NOTE: IListActionButtons = {
    iconName: 'post_add',
    action: Icons.ADD_NOTE,
    title: 'components.table.add-note'
  };

  static ICON_EDIT_NOTE: IListActionButtons = {
    iconName: 'description',
    action: Icons.ADD_NOTE,
    title: 'components.table.edit-note'
  };
}
