import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@ic-components/components.module';
import { MaterialModule } from '@ic-material/material.module';
import { TranslateModule } from '@ngx-translate/core';

import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports.routing';

const PROJECT_MODULES = [
  ComponentsModule,
  MaterialModule,
  ReportsRoutingModule
];

const ANGULAR_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  declarations: [ReportsComponent],
  imports: [...ANGULAR_MODULES, TranslateModule, ...PROJECT_MODULES],
  exports: [ReportsComponent]
})
export class ReportsModule {}
