<div class="search-container">
  <mat-icon class="search-icon" (click)="toggleSearch()">{{
    isSearchOpen ? 'cancel' : 'search'
  }}</mat-icon>
  <mat-form-field
    class="search-mat-form-field"
    [@searchExpand]="isSearchOpen ? 'expanded' : 'collapsed'"
  >
    <input
      class="search-mat-form-field-input"
      matInput
      placeholder="Search"
      (input)="onSearch($event)"
      [style.display]="isSearchOpen ? 'block' : 'none'"
    />
  </mat-form-field>
</div>
