import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { createRequestOption } from '@ic-app/core/util/request-util';
import { ICommonFilter } from '@ic-app/models/common-filter.model';
import { IAddItemNew } from '@ic-app/models/settings/prior-inspection/items/add-item/add-item-new.model';
import {
  IAdministrativeFileTypeItemDetailList,
  IItemsEntity,
  ISettingsPriorInspectionItems,
  SettingItemManageInModelToSave,
  SettingsItemToSave
} from '@ic-app/models/settings/prior-inspection/items/settings-prior-inspection-items.model';
import { ISettingsItems } from '@ic-app/models/settings/prior-inspection/items/settings-prior-inspection-items.model';
import { ITableAdministrativeFileTypesList } from '@ic-app/models/settings/prior-inspection/observations/table-administrative-file-type';
import { environment } from 'environments/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable()
export class SettingsPriorInspectionItemsService {
  constructor(private http: HttpClient) {}

  /**
   * Obtiene los items de FRB de la tabla principal
   * @param filter
   * @param pageNumber
   * @param pageSize
   * @returns
   */
  findItems(
    filter: ICommonFilter,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<ISettingsItems[]> {
    const req = {
      searchBox: filter.searchBox,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ISettingsItems[]>(`${baseUrl}/settingsPriorInspection/findItems`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<ISettingsItems[]>) =>
            resp.body as ISettingsItems[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los items de FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene el número de items de FRB de la tabla principal
   * @param filter
   * @param mainTable
   * @returns
   */
  countItems(
    filter: ICommonFilter,
    mainTable: boolean,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      mainTable: mainTable,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/settingsPriorInspection/countItems`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar los items de FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene las entidades de un item de FRB
   * @param searchBox
   * @param inspectionItemId
   * @param pageNumber
   * @param pageSize
   * @returns
   */
  findEntitiesByInspectionItemId(
    searchBox: string,
    inspectionItemId: number,
    pageNumber: number,
    pageSize: number,
    filteredEntityId: number
  ): Observable<IItemsEntity[]> {
    const req = {
      searchBox: searchBox,
      inspectionItemId: inspectionItemId,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IItemsEntity[]>(
        `${baseUrl}/settingsPriorInspection/findEntitiesByInspectionItemIdOrEntityId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IItemsEntity[]>) => resp.body as IItemsEntity[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las entidades de un item en FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene el número de entidades de un item de FRB
   * @param searchBox
   * @param inspectionItemId
   * @returns
   */
  countEntitiesByInspectionItemIdOrEntityId(
    searchBox: string,
    inspectionItemId: number,
    filteredEntityId: number
  ): Observable<number> {
    const req = {
      searchBox: searchBox,
      inspectionItemId: inspectionItemId,
      filteredEntityId: filteredEntityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(
        `${baseUrl}/settingsPriorInspection/countEntitiesByInspectionItemIdOrEntity`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al contar las entidades de un item en FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los tipos de expedientes para la tabla
   * @param entitiesSelected
   * @param inspectionItemId
   * @returns
   */
  getAdministrativeFileTypesItems(
    entitiesSelected: number[],
    inspectionItemId: number
  ): Observable<IAdministrativeFileTypeItemDetailList[]> {
    const req = {
      entityIds: entitiesSelected,
      inspectionItemId: inspectionItemId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileTypeItemDetailList[]>(
        `${baseUrl}/settingsPriorInspection/listAdministrativeFileTypesItems`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileTypeItemDetailList[]>) =>
            resp.body as IAdministrativeFileTypeItemDetailList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente en los items de ' +
              'configuración FRB del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Guarda los items de FRB
   * @param settingsItemsToSave
   * @returns
   */
  saveSettingItems(itemToSaveDTO: SettingsItemToSave): Observable<number> {
    return this.http
      .post<number>(
        `${baseUrl}/settingsPriorInspection/saveSettingItems`,
        itemToSaveDTO
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            if (err.status === 409) {
              console.error(
                'Se ha producido un error al guardar los items de la configuracion FRB:',
                err.error
              );
              return new Error(err.error as string);
            }
            console.error(
              'Se ha producido un error al guardar los items de la configuracion FRB:',
              err.message
            );
            return new Error(
              'settings-prior-inspection.error-messages.save-item'
            );
          });
        })
      );
  }

  /**
   * Obtiene los item para un arbol
   * @param treeId
   * @param isItemDeleted
   * @param itemId
   * @returns
   */
  getItemsTree(
    treeId: number,
    isItemDeleted: boolean,
    itemId: number
  ): Observable<ISettingsPriorInspectionItems[]> {
    const req = {
      treeId: treeId,
      isItemDeleted: isItemDeleted,
      itemId: itemId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ISettingsPriorInspectionItems[]>(
        `${baseUrl}/settingsPriorInspection/getItemsTree`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ISettingsPriorInspectionItems[]>) =>
            resp.body as ISettingsPriorInspectionItems[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los items del arbol:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Permite hablitar un item
   * @param itemId
   * @returns
   */
  enableItem(itemId: number): Observable<number> {
    const req = {
      itemId: itemId
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(`${baseUrl}/settingsPriorInspection/enableItem`, options)
      .pipe(
        map((resp: number) => resp),
        catchError((err: Error) => {
          console.error(
            `Se ha producido un error al habilitar un item de la configuracion de FRB:`,
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Permite guardar items de FRB
   * @param settingItemManageInModelToSave
   * @returns
   */
  saveSettingItemManageInModel(
    settingItemManageInModelToSave: SettingItemManageInModelToSave
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${baseUrl}/settingsPriorInspection/saveSettingItemManageInModel`,
        settingItemManageInModelToSave
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al guardar el item en el modelo:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los tipos de expediente comunes a las entidades seleccionadas de un item
   * @param entitiesSelected
   * @param categoryCodeDescription
   * @returns
   */
  getCopyItemAdministrativeFileTypes(
    entityId: number,
    reusedItemId: number
  ): Observable<ITableAdministrativeFileTypesList[]> {
    const req = {
      entityId: entityId,
      reusedItemId: reusedItemId
    };
    const options = createRequestOption(req);
    return this.http
      .get<ITableAdministrativeFileTypesList[]>(
        `${baseUrl}/settingsPriorInspection/listCopyItemAdministrativeFileTypes`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<ITableAdministrativeFileTypesList[]>) =>
            resp.body as ITableAdministrativeFileTypesList[]
        ),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expediente a reutilizar de un item ' +
              'configuración FRB del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getEntityByEntityId(entityId: number): Observable<IItemsEntity> {
    const req = {
      entityId: entityId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IItemsEntity>(
        `${baseUrl}/settingsPriorInspection/getEntityByEntityId`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IItemsEntity>) => resp.body as IItemsEntity),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar las entidades de un item en FRB:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findAvaliableItemsForAdministrativeFile(
    entityId: number,
    administrativeFileTypeId: number
  ): Observable<IAddItemNew[]> {
    const req = {
      entityId: entityId,
      administrativeFileTypeId: administrativeFileTypeId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAddItemNew[]>(
        `${baseUrl}/settingsPriorInspection/findAvailableItemsForAdministrativeFile`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<IAddItemNew[]>) => resp.body as IAddItemNew[]),
        catchError((err: Error) => {
          console.error(
            'Se ha producido un error al cargar los items disponibles para un tipo de expediente:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
