import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { MenuOptions } from '@ic-app/constants/app.menu-options';
import { isEmptyNullOrUndefinedString } from '@ic-app/core/util/global-util';
import { DialogComponent } from '@ic-components/form/dialog/dialog.component';
import { IEntityUser } from '@ic-models/entity.model';
import { IFiscalYear } from '@ic-models/fiscal-year.model';
import { IDialogResponse } from '@ic-models/prior-inspection/frb-request.model';
import { ISelectOption, SelectOption } from '@ic-models/select-option.model';
import { AppConfigService } from '@ic-services/app-config.service';
import { NavService } from '@ic-services/nav.service';
import { SettingsService } from '@ic-services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ic-top-nav',
  templateUrl: './top-nav.component.html'
})
export class TopNavComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  title = '';

  userEntities: ISelectOption[] = [];
  fiscalYears: ISelectOption[] = [];

  userEntitySelected: ISelectOption | undefined = {
    id: 0,
    text: ''
  };

  fiscalYearSelected: ISelectOption | undefined = {
    id: 0,
    text: ''
  };

  optionButtoms = [
    {
      action: MenuOptions.TOP_NAV_LOGOUT_OPTION,
      btnLabel: this.translate.instant(
        'sidebar.top-nav.actions.logout'
      ) as string
    }
  ];

  constructor(
    private navService: NavService,
    private translate: TranslateService,
    private auth: AuthService,
    private appConfig: AppConfigService,
    private router: Router,
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.navService.menuTitleChange$.subscribe((title: string) => {
      this.title = title;
    });
    this.manageUserEntities();
    this.manageFiscalYears();
  }

  toggleNav(): void {
    this.navService.toggleNav();
  }

  manageUserEntities(): void {
    this.appConfig.entityUserData$.subscribe((userEntities: IEntityUser[]) => {
      this.userEntities = userEntities.map((entityUser: IEntityUser) => {
        return new SelectOption(entityUser.id, entityUser.entityName);
      });
    });
    this.appConfig.selectedEntity$.subscribe(
      (activeEntity: IEntityUser | null) => {
        if (activeEntity) {
          this.userEntitySelected = new SelectOption(
            activeEntity.id,
            activeEntity.entityName
          );
        }
      }
    );
  }

  manageFiscalYears(): void {
    this.appConfig.fiscalYearData$.subscribe((fiscalYears: IFiscalYear[]) => {
      this.fiscalYears = fiscalYears.map((fiscalYear: IFiscalYear) => {
        return new SelectOption(
          fiscalYear.id,
          fiscalYear.description.toString()
        );
      });
    });
    this.appConfig.selectedFiscalYear$.subscribe(
      (activeFiscalYear: IFiscalYear | null) => {
        if (activeFiscalYear) {
          this.fiscalYearSelected = new SelectOption(
            activeFiscalYear.id,
            activeFiscalYear.description.toString()
          );
        }
      }
    );
  }

  clickedOption(event: number): void {
    switch (event) {
      case MenuOptions.TOP_NAV_LOGOUT_OPTION: {
        this.auth.logout({
          logoutParams: { returnTo: environment.redirectUrl }
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  logout(): void {
    this.auth.logout({
      logoutParams: { returnTo: environment.redirectUrl }
    });
  }

  entitySelected(event: number): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant(
            'common.message.change-entity-title'
          ) as string,
          content: this.translate.instant(
            'common.message.change-entity-warning'
          ) as string,
          buttonCancel: this.translate.instant(
            'common.button.cancel'
          ) as string,
          buttonAccept: this.translate.instant('common.button.accept') as string
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: IDialogResponse) => {
        if (res.result === true) {
          this.userEntitySelected = this.userEntities.find(
            (value: SelectOption) => value.id === event
          );
          this.appConfig.updateEntitySelection(event);
          this.appConfig.loadFiscalYearsForEntitySelection().then(() => {
            Promise.all([
              this.settingsService.loadAdministrativeFileTypes(),
              this.settingsService.loadAdministrativeFileTypesPhaseO(),
              this.settingsService.loadOrganizationalUnits(),
              this.settingsService.loadResponsibleOrganizationalUnits(),
              this.settingsService.loadBudgetaryDescriptions(),
              this.settingsService.loadBudgetaryItems(),
              this.settingsService.loadBudgetaryItemsFilter(),
              this.settingsService.loadAdministrativeFileTypeSpendingPhases()
            ]);
            this.reloadComponent();
          });
        }
      });
  }

  fiscalYearSelection(event: number): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant(
            'common.message.change-fiscal-year-title'
          ) as string,
          content: this.translate.instant(
            'common.message.change-fiscal-year-warning'
          ) as string,
          buttonCancel: this.translate.instant(
            'common.button.cancel'
          ) as string,
          buttonAccept: this.translate.instant('common.button.accept') as string
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: IDialogResponse) => {
        if (res.result === true) {
          this.fiscalYearSelected = this.fiscalYears.find(
            (value: SelectOption) => value.id === event
          );
          this.appConfig.updateFiscalYearSelection(event);
          this.reloadComponent();
        }
      });
  }

  reloadComponent(): void {
    let route = this.route;

    // Traverse up the route tree to find the route with the required data
    while (route.firstChild) {
      route = route.firstChild;
      if (route.snapshot.data['shouldReload'] !== undefined) {
        // Found the route with the 'shouldReload' data
        break;
      }
    }

    const shouldReload = route.snapshot.data.shouldReload as boolean;

    if (shouldReload) {
      const currentUrl = this.router.url.split('?')[0];
      const uniqueKey = new Date().getTime();
      this.router.navigate([currentUrl], {
        queryParams: { refresh: uniqueKey },
        queryParamsHandling: 'merge'
      });
    } else {
      const currentUrlSegments = this.route.firstChild?.snapshot.routeConfig
        ?.path as string;
      const moduleRootPath = this.getModuleRootPath(currentUrlSegments);
      this.router.navigate([moduleRootPath]);
    }
  }

  private getModuleRootPath(url: string): string {
    return !isEmptyNullOrUndefinedString(url) ? `${url}` : '/';
  }
}
