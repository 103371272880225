/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ic-searchbox-filter',
  templateUrl: './searchbox-filter.component.html'
})
export class SearchboxFilterComponent {
  @Input() searchboxInputFormControl: FormControl = new FormControl();
  @Input() placeHolderText = '';
  @Input() showFilter = true;

  show = false;
  isDisabled = false;

  openPanel(): void {
    if (!this.show) {
      this.show = !this.show;
    }
  }

  closePanel(): void {
    if (this.show) {
      this.show = !this.show;
    }
  }

  getVal(event: Event): void {
    if ((event.target as HTMLInputElement)?.value) {
      this.onInput((event.target as HTMLInputElement)?.value);
    }
  }

  onInput(value: string): void {
    this.searchboxInputFormControl.setValue(value);
  }
}
