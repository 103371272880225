export interface IEntitiesTreeComponent {
  id: number;
  name: string;
  isSelectable: boolean;
  children?: IEntitiesTreeComponent[] | null;
  description?: string | null;
}

export class EntitiesTreeComponent implements IEntitiesTreeComponent {
  constructor(
    public id: number,
    public name: string,
    public isSelectable: boolean = true,
    public children?: IEntitiesTreeComponent[] | null,
    public description?: string | null
  ) {}
}
