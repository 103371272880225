<button
  type="button"
  mat-icon-button
  [color]="color"
  [disabled]="disabled"
  [attr.aria-label]="ariaLabel"
  [title]="ariaLabel"
  [class]="getCssClasses()"
  (click)="onClick($event)"
>
  <mat-icon
    *ngIf="!fontAwesome"
    [color]="iconColor"
    [class]="matIconClass"
    [inline]="inline"
    >{{ matIcon ? matIcon : 'face' }}</mat-icon
  >
  <fa-icon
    *ngIf="fontAwesome"
    [size]="iconSize"
    [icon]="faIcon"
    [class]="faIconClass"
  >
  </fa-icon
  >{{ '' }}
</button>
