/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
// 2 Tablas:
// - 1º Observaciones para el Informe
//   * Observación de informe => Ppc_Report_Observation => Ppc_Supported_Report_Observation (Ppc_Supported_Report_Observation_Translation) (CODIGO 250)
//   * Observación de modelo para informe TRAÍDA DEL MODELO => Ppc_Report_Observation => Ppc_Supporte_Report_Observation y Ppc_Observation_Model (CODIGO 300)
//   * Observación de modelo para informe NUEVA CREACIÓN => Ppc_Report_Observation => Ppc_Supporte_Report_Observation y Ppc_Observation_Model (CODIGO 200)
// - 2º Observaciones del modelo
//   * Observación de modelo NUEVA CREACIÓN => Ppc_Report_Observation => Ppc_Supporte_Report_Observation y Ppc_Observation_Model (CODIGO 200)
//   * Observación de modelo TRAÍDA DEL MODELO => Ppc_Report_Observation => Ppc_Supporte_Report_Observation y Ppc_Observation_Model (CODIGO 300)

import { Icons } from '@ic-app/constants/app.icons';
import {
  getTimestampBasedId,
  isEmptyNullOrUndefinedString,
  isNullOrUndefined
} from '@ic-core/util/global-util';

import { IListActionButtons } from '@ic-app/models/list-action-buttons.model';

export const enum PpcObservationTypes {
  REPORT_OBSERVATION_SCIPION_CATALOG = 100,
  REPORT_OBSERVATION_NEW_MODEL = 200,
  REPORT_OBSERVATION_NO_MODEL = 250,
  REPORT_OBSERVATION_EXISTING = 300
}

export const enum PpcObservationImpactLevelTypes {
  REPORT_OBSERVATION_UNCATEGORIZEDIMPACT = 0,
  REPORT_OBSERVATION_NO_IMPACT = 100,
  REPORT_OBSERVATION_SUMMARY_REPORT_IMPACT = 200,
  REPORT_OBSERVATION_DEFICIENCY = 300,
  REPORT_OBSERVATION_CONCOMITANT_REPORT = 400
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPpcObservationsListDTO {
  ppcObservationReportList: IPpcObservationReportList[];
  ppcObservationModelDefinitionList: IPpcObservationModelList[] | null;
  ppcObservationReportListToDelete?: number[];
  ppcObservationModelListToDelete?: number[] | null;
}

export class PpcObservationToSave implements IPpcObservationsListDTO {
  ppcObservationReportList: IPpcObservationReportList[];
  ppcObservationModelDefinitionList: IPpcObservationModelList[] | null;
  ppcObservationReportListToDelete: number[];
  ppcObservationModelListToDelete: number[] | null;

  constructor(
    ppcObservationReportList: IPpcObservationReportList[],
    ppcObservationModelDefinitionList: IPpcObservationModelList[] | null,
    ppcObservationReportListToDelete: number[],
    ppcObservationModelListToDelete: number[] | null
  ) {
    this.ppcObservationReportList = ppcObservationReportList;
    this.ppcObservationModelDefinitionList = ppcObservationModelDefinitionList;
    this.ppcObservationReportListToDelete = ppcObservationReportListToDelete;
    this.ppcObservationModelListToDelete = ppcObservationModelListToDelete;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IPpcObservationToDeleteDTO {
  ppcModelObservationId: number | null;
  ppcReportObservationId: number | null;
  isPpcObservationFromReportPpc: boolean;
  isDeletedFromReportPpc?: boolean | null;
  ppcReportProcedureId: number;
}

export class PpcObservationToDeleteDTO implements IPpcObservationToDeleteDTO {
  ppcModelObservationId: number | null;
  ppcReportObservationId: number | null;
  isPpcObservationFromReportPpc: boolean;
  isDeletedFromReportPpc?: boolean | null;
  ppcReportProcedureId: number;
  ppcObservationsListDTO: PpcObservationToSave;

  constructor(
    ppcModelObservationId: number | null,
    ppcReportObservationId: number | null,
    isPpcObservationFromReportPpc: boolean,
    isDeletedFromReportPpc: boolean | null,
    ppcReportProcedureId: number,
    ppcObservationToSave: PpcObservationToSave
  ) {
    this.ppcModelObservationId = ppcModelObservationId;
    this.ppcReportObservationId = ppcReportObservationId;
    this.isPpcObservationFromReportPpc = isPpcObservationFromReportPpc;
    this.isDeletedFromReportPpc = isDeletedFromReportPpc;
    this.ppcReportProcedureId = ppcReportProcedureId;
    this.ppcObservationsListDTO = ppcObservationToSave;
  }
}

// Interface para la primera tabla => Observaciones para el Informe
export interface IPpcObservationReportList {
  code?: number;
  ppcSupportedReportObservation: IPpcSupportedReportObservation;
  ppcReportObservation: IPpcReportObservation;
  actions?: IListActionButtons[];
  ppcAdministrativeFileId: number;
  ppcCurrentProcedure: number;
  categoryLabel: string | undefined;
  categoryIcon: string | undefined;
  categoryIconColor: string | undefined;
  categoryCode?: number;
  impactLevelLabel: string | undefined;
  impactLevelCode?: number;
  impactLevelIconColor: string | undefined;
  changed: boolean;
  ppcModelObservationId?: number;
}

export class PpcObservationReportList implements IPpcObservationReportList {
  code?: number;
  ppcSupportedReportObservation: IPpcSupportedReportObservation;
  ppcReportObservation: IPpcReportObservation;
  actions?: IListActionButtons[];
  ppcAdministrativeFileId: number;
  ppcCurrentProcedure: number;
  categoryLabel: string | undefined;
  categoryIcon: string | undefined;
  categoryIconColor: string | undefined;
  impactLevelLabel: string | undefined;
  impactLevelIconColor: string | undefined;
  changed: boolean;
  ppcModelObservationId?: number;

  constructor(
    wording: string | null,
    orderObservation: number,
    ppcReportProcedureId: number,
    isPpcObservationModel: boolean | null,
    ppcObservationModel: IPpcObservationModelList | null,
    ppcAdministrativeFileId: number,
    ppcCurrentProcedure: number,
    code: number | null,
    localId: string | null = null
  ) {
    if (isPpcObservationModel === true) {
      this.code = 200;
      this.categoryIconColor = 'warning';
      this.categoryLabel = 'ppc-report.observation-panel.category-labels.model';
      this.actions = [
        Icons.ICON_EMPTY,
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
      this.categoryIcon = 'u_letter';
      this.impactLevelIconColor = 'stopped';
      this.impactLevelLabel =
        'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    }
    if (isPpcObservationModel === false) {
      this.code = 250;
      this.categoryIconColor = 'no-model';
      this.categoryLabel =
        'ppc-report.observation-panel.category-labels.no-model';
      this.actions = [
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_EDIT_OPTION,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
      this.categoryIcon = 'u_letter';
      this.impactLevelIconColor = 'stopped';
      this.impactLevelLabel =
        'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    }
    if (isPpcObservationModel === null && code !== null) {
      if (code === 300) {
        this.categoryIconColor = 'stopped';
        this.categoryLabel =
          'ppc-report.observation-panel.category-labels.existing';
        this.ppcSupportedReportObservation =
          ppcObservationModel?.ppcSupportedReportObservation as IPpcSupportedReportObservation;
        this.categoryIcon = 'u_letter';
        this.impactLevelIconColor = 'stopped';
        this.impactLevelLabel =
          'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
      } else if (code === 100) {
        this.ppcSupportedReportObservation = new PpcSupportedReportObservation(
          wording,
          isEmptyNullOrUndefinedString(localId)
            ? getTimestampBasedId()
            : localId
        );

        this.impactLevelIconColor = ppcObservationModel?.impactLevelIconColor;
        this.impactLevelLabel = ppcObservationModel?.impactLevelLabel;
        this.categoryIcon = ppcObservationModel?.categoryIcon;
        this.categoryIconColor = ppcObservationModel?.categoryIconColor;
        this.categoryLabel = ppcObservationModel?.categoryLabel;
      } else {
        this.categoryIconColor = 'warning';
        this.categoryLabel =
          'ppc-report.observation-panel.category-labels.model';
        this.ppcSupportedReportObservation = new PpcSupportedReportObservation(
          wording,
          isEmptyNullOrUndefinedString(localId)
            ? getTimestampBasedId()
            : localId
        );
        this.categoryIcon = 'u_letter';
        this.impactLevelIconColor = 'stopped';
        this.impactLevelLabel =
          'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
      }
      this.code = code;
      this.actions = [
        Icons.ICON_EMPTY,
        Icons.ICON_ARROW_UP,
        Icons.ICON_ARROW_DOWN,
        Icons.ICON_ADD_NOTE,
        Icons.ICON_ARROW_CROSS
      ];
    } else {
      this.ppcSupportedReportObservation = new PpcSupportedReportObservation(
        wording,
        isEmptyNullOrUndefinedString(localId) ? getTimestampBasedId() : localId
      );
    }

    this.ppcAdministrativeFileId = ppcAdministrativeFileId;
    this.ppcCurrentProcedure = ppcCurrentProcedure;
    this.ppcReportObservation = new PpcReportObservation(
      orderObservation,
      ppcReportProcedureId
    );

    this.changed = true;
  }
}

// Interface para la segunda tabla => Observaciones de modelo
export interface IPpcObservationModelList {
  code: number;
  createdDate?: Date;
  insertMoment?: Date;
  ppcSupportedReportObservation: IPpcSupportedReportObservation;
  ppcReportObservationModelObservation: IPpcReportObservationModelDefinition;
  actions?: IListActionButtons[];
  categoryLabel: string;
  categoryIcon: string;
  categoryIconColor: string;
  categoryCode?: number;
  impactLevelLabel: string;
  impactLevelCode?: number;
  impactLevelIconColor: string;
  changed: boolean;
  isPpcObservationReport?: boolean;
  isNewPpcObservation?: boolean;
  checked?: boolean;
  observationGroup?: number;
}

export class PpcObservationModelList implements IPpcObservationModelList {
  code: number;
  insertMoment?: Date;
  ppcSupportedReportObservation: IPpcSupportedReportObservation;
  ppcReportObservationModelObservation: IPpcReportObservationModelDefinition;
  actions?: IListActionButtons[];
  categoryLabel = '';
  categoryIcon: string;
  categoryIconColor: string;
  impactLevelLabel: string;
  impactLevelIconColor: string;
  changed: boolean;
  isPpcObservationReport?: boolean;
  isNewPpcObservation?: boolean;

  constructor(
    wording: string,
    ppcAdministrativeFileId: number,
    ppcCurrentProcedureId: number,
    localId: string | null = null
  ) {
    this.code = 200;
    this.insertMoment = new Date();
    this.ppcSupportedReportObservation = new PpcSupportedReportObservation(
      wording,
      isNullOrUndefined(localId) ? getTimestampBasedId() : localId
    );
    this.ppcReportObservationModelObservation =
      new PpcReportObservationModelDefinition(
        ppcAdministrativeFileId,
        ppcCurrentProcedureId
      );
    this.impactLevelIconColor = 'stopped';
    this.actions = [
      Icons.ICON_EMPTY,
      Icons.ICON_EDIT_OPTION,
      Icons.ICON_ARROW_CROSS
    ];
    this.categoryIcon = 'u_letter';
    this.categoryIconColor = 'warning';
    this.impactLevelLabel =
      'ppc-report.observation-panel.impact-labels.uncategorizedimpact';
    this.changed = true;
    this.isPpcObservationReport = true;
    this.isNewPpcObservation = true;
  }
}

export interface IImpactLevel {
  id?: number;
  code: number;
  description: string;
  level: number;
}

export class ImpactLevel implements IImpactLevel {
  code: number;
  description: string;
  level: number;

  constructor() {
    this.code = 0;
    this.description = 'UNCATEGORIZEDIMPACT';
    this.level = 0;
  }
}

export interface IPpcSupportedReportObservation {
  id?: number;
  localId?: string | null;
  code?: number;
  impactLevel: IImpactLevel;
  idTranslation?: number;
  wording: string | null;
}

export class PpcSupportedReportObservation
  implements IPpcSupportedReportObservation
{
  impactLevel: IImpactLevel;
  wording: string | null;
  localId?: string | null;

  constructor(wording: string | null, localId: string | null = null) {
    this.impactLevel = new ImpactLevel();
    this.wording = wording;
    this.localId = localId;
  }
}

export interface IPpcReportObservation {
  id?: number;
  localId?: string;
  deleted: boolean;
  orderObservation: number;
  ppcReportProcedureId: number;
  ppcParentSupportedReportObservationId?: number | null;
  ppcParentSupportedReportObservationWording?: string | null;
  ppcParentSupportedReportObservationLocalId?: string | null;
  explanatoryNote?: string;
}

export class PpcReportObservation implements IPpcReportObservation {
  deleted: boolean;
  orderObservation: number;
  ppcReportProcedureId: number;
  ppcParentSupportedReportObservationId: number | null;

  constructor(orderObservation: number, ppcReportProcedureId: number) {
    this.deleted = false;
    this.orderObservation = orderObservation;
    this.ppcReportProcedureId = ppcReportProcedureId;
    this.ppcParentSupportedReportObservationId = null;
  }
}

export interface IPpcReportObservationModelDefinition {
  id?: number;
  localId?: string;
  observationEdits?: number;
  observationRelevance?: number;
  observationUses?: number;
  areaId?: number;
  ppcAdministrativeFileId?: number;
  ppcCurrentProcedureId?: number;
  topicId?: number;
  deleted: boolean;
}

export class PpcReportObservationModelDefinition
  implements IPpcReportObservationModelDefinition
{
  ppcAdministrativeFileId: number;
  ppcCurrentProcedureId: number;
  deleted: boolean;

  constructor(ppcAdministrativeFileId: number, ppcCurrentProcedureId: number) {
    this.ppcAdministrativeFileId = ppcAdministrativeFileId;
    this.ppcCurrentProcedureId = ppcCurrentProcedureId;
    this.deleted = false;
  }
}
export { IListActionButtons };
