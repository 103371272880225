import { Injectable } from '@angular/core';
import {
  AutoCompleteElement,
  IAutoCompleteElement
} from '@ic-components/form/input-autocomplete/input-autocomplete.component';
import { AppConfigService } from '@ic-services/app-config.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class AutoCompleteOmissionAFTService {
  constructor(private appConfig: AppConfigService) {}

  getData(
    filter: { searchValue: string } = { searchValue: '' }
  ): Observable<AutoCompleteElement[]> {
    // let phaseOAFT: IAutoCompleteElement[] = [];

    // this.appConfig.autocompleteAFTData.
    // this.appConfig.administrativeFileTypeSpendingPhases.forEach((element) => {
    //   if (element.administrativeFileTypeId == administrativeFileTypeId) {
    //     this.spendingPhases.push(element);
    //   }
    // });

    return of(
      this.appConfig.autocompleteAFTPhaseOData
        .filter((autocompleteAFTData: IAutoCompleteElement) => {
          // console.log(autocompleteAFTData);
          return (
            autocompleteAFTData.name
              .toLowerCase()
              .includes(filter.searchValue.toLowerCase()) ||
            autocompleteAFTData.code
              ?.toLowerCase()
              .includes(filter.searchValue.toLowerCase())
          );
        })
        .map((autocompleteAFTData: IAutoCompleteElement) => {
          return new AutoCompleteElement(
            autocompleteAFTData.id,
            autocompleteAFTData.name,
            autocompleteAFTData.parentName,
            autocompleteAFTData.code
          );
        })
    );
  }
}
