import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PanelTopService } from '@ic-services/panel-top.service';

@Component({
  selector: 'ic-layout-top',
  templateUrl: './layout-top.component.html'
})
export class LayoutTopComponent implements OnInit, AfterViewInit {
  @Input() mode = '';

  @ViewChild('panelTop') panelRef: MatSidenav | undefined;

  panelTopWidth = '';

  constructor(private panelTopService: PanelTopService) {}

  ngOnInit(): void {
    this.getPanelWidth();
    this.listenOnSidenavOpen();
    this.listenOnSidenavClose();
  }

  ngAfterViewInit(): void {
    this.listenOnMatSidenavCloseFinish();
  }

  getPanelWidth(): void {
    this.panelTopService.panelTopWidth$.subscribe((value: string) => {
      this.panelTopWidth = value;
    });
  }

  listenOnSidenavOpen(): void {
    this.panelTopService.open$.subscribe(() => this.panelRef?.open());
  }

  listenOnSidenavClose(): void {
    this.panelTopService.close$.subscribe(() => this.panelRef?.close());
  }

  listenOnMatSidenavCloseFinish(): void {
    this.panelRef?.openedChange.subscribe((status: boolean) => {
      if (!status) {
        this.panelTopService.detachComponent();
      }
    });
  }
}
