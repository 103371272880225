import { Injectable } from '@angular/core';
import { IPageMap } from '@ic-models/tab-item.model';
import { TranslateService } from '@ngx-translate/core';

import { Authority } from './authority.constants';

@Injectable()
export class TabItems {
  static TABS: IPageMap;

  static initialize(translate: TranslateService): void {
    this.TABS = {
      SettingsComponent: [
        {
          label: translate.instant(
            'tabs.configuration.settings.budgeting-applications'
          ) as string,
          link: '/configuration/settings/list',
          index: 0,
          roles: [Authority.SCIPION_ADMIN, Authority.TENANT_ADMIN]
        },
        // {
        //   label: translate.instant(
        //     'tabs.configuration.settings.management-authorities'
        //   ) as string,
        //   link: '',
        //   index: 1,
        //   roles: [Authority.SCIPION_ADMIN, Authority.TENANT_ADMIN]
        // },
        {
          label: translate.instant(
            'tabs.configuration.settings.entity-authorities'
          ) as string,
          link: '/configuration/settings/authorities',
          index: 1,
          roles: [Authority.SCIPION_ADMIN]
        },
        // {
        //   label: translate.instant(
        //     'tabs.configuration.settings.competent-authorities'
        //   ) as string,
        //   link: '',
        //   index: 2,
        //   roles: [Authority.SCIPION_ADMIN, Authority.TENANT_ADMIN]
        // },
        {
          label: translate.instant(
            'tabs.configuration.settings.tenants'
          ) as string,
          link: '/configuration/settings/tenants',
          index: 2,
          roles: [Authority.SCIPION_ADMIN]
        },
        {
          label: translate.instant(
            'tabs.configuration.settings.entities'
          ) as string,
          link: '',
          index: 3,
          roles: [Authority.SCIPION_ADMIN]
        },
        {
          label: translate.instant(
            'tabs.configuration.settings.users'
          ) as string,
          link: '',
          index: 4,
          roles: [Authority.SCIPION_ADMIN]
        },
        {
          label: translate.instant(
            'tabs.configuration.settings.replication'
          ) as string,
          link: '',
          index: 5,
          roles: [Authority.SCIPION_ADMIN]
        }
      ],
      SettingsPriorInspectionComponent: [
        {
          label: translate.instant(
            'tabs.configuration.frb.observations'
          ) as string,
          link: '/configuration/prior-inspection/observations',
          index: 0,
          roles: [
            Authority.SCIPION_ADMIN,
            Authority.FRB_ADVANCED_ADMIN,
            Authority.FRB_BASIC_ADMIN
          ]
        },
        {
          label: translate.instant(
            'tabs.configuration.frb.file-types-categories'
          ) as string,
          link: '/configuration/prior-inspection/category-administrative-file-types',
          index: 1,
          roles: [Authority.SCIPION_ADMIN, Authority.FRB_ADVANCED_ADMIN]
        },
        {
          label: translate.instant(
            'tabs.configuration.frb.file-types'
          ) as string,
          link: '/configuration/prior-inspection/administrative-file-types',
          index: 2,
          roles: [Authority.SCIPION_ADMIN, Authority.FRB_ADVANCED_ADMIN]
        },
        {
          label: translate.instant('tabs.configuration.frb.items') as string,
          link: '/configuration/prior-inspection/items',
          index: 3,
          roles: [Authority.SCIPION_ADMIN, Authority.FRB_ADVANCED_ADMIN]
        }
      ],
      SettingsPriorControlComponent: [
        {
          label: translate.instant(
            'tabs.configuration.cpp.observations'
          ) as string,
          link: '',
          index: 0,
          roles: [
            Authority.SCIPION_ADMIN,
            Authority.CPP_ADVANCED_ADMIN,
            Authority.CPP_BASIC_ADMIN
          ]
        },
        {
          label: translate.instant(
            'tabs.configuration.cpp.subjective-improvements'
          ) as string,
          link: '',
          index: 1,
          roles: [
            Authority.SCIPION_ADMIN,
            Authority.CPP_ADVANCED_ADMIN,
            Authority.CPP_BASIC_ADMIN
          ]
        },
        {
          label: translate.instant(
            'tabs.configuration.cpp.item-comments'
          ) as string,
          link: '',
          index: 2,
          roles: [
            Authority.SCIPION_ADMIN,
            Authority.CPP_ADVANCED_ADMIN,
            Authority.CPP_BASIC_ADMIN
          ]
        },
        {
          label: translate.instant('tabs.configuration.cpp.domains') as string,
          link: '',
          index: 3,
          roles: [Authority.SCIPION_ADMIN, Authority.CPP_ADVANCED_ADMIN]
        },
        {
          label: translate.instant('tabs.configuration.cpp.topics') as string,
          link: '',
          index: 4,
          roles: [Authority.SCIPION_ADMIN, Authority.CPP_ADVANCED_ADMIN]
        },
        {
          label: translate.instant(
            'tabs.configuration.cpp.file-types'
          ) as string,
          link: '',
          index: 5,
          roles: [Authority.SCIPION_ADMIN, Authority.CPP_ADVANCED_ADMIN]
        },
        {
          label: translate.instant('tabs.configuration.cpp.items') as string,
          link: '',
          index: 6,
          roles: [Authority.SCIPION_ADMIN, Authority.CPP_ADVANCED_ADMIN]
        },
        {
          label: translate.instant(
            'tabs.configuration.cpp.applicable-regulations'
          ) as string,
          link: '',
          index: 7,
          roles: [
            Authority.SCIPION_ADMIN,
            Authority.CPP_ADVANCED_ADMIN,
            Authority.CPP_BASIC_ADMIN
          ]
        },
        {
          label: translate.instant(
            'tabs.configuration.cpp.legal-provisions'
          ) as string,
          link: '',
          index: 8,
          roles: [
            Authority.SCIPION_ADMIN,
            Authority.CPP_ADVANCED_ADMIN,
            Authority.CPP_BASIC_ADMIN
          ]
        }
      ]
    };
  }
}
