import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpConstants } from '@ic-app/constants/http.constants';
import { IUsersFilter } from '@ic-app/models/users-filter.model';
import { getUserTimeZone } from '@ic-core/util/global-util';
import {
  createRequestOption,
  getTimestampAtStartOfDay,
  getTimestampAtStartOfDayFromTimestamp,
  toCurrencyNumber
} from '@ic-core/util/request-util';
import { IAdministrativeFileFilter } from '@ic-models/prior-inspection/administrative-file-filter.model';
import {
  AdministrativeFileCorrectionRequest,
  AdministrativeFileReturn,
  IAdministrativeFileCorrectionContacts,
  IAdministrativeFileCorrectionDataDTO,
  IAdministrativeFileCorrectionDataEditDTO,
  IAdministrativeFileRequest,
  IAdministrativeFileReturnRequestEditDTO,
  ResponseReport
} from '@ic-models/prior-inspection/administrative-file.model';
import { IDetailData } from '@ic-models/prior-inspection/detail.model';
import {
  IFrbAdded,
  IFrbCorrectionDataType,
  InspectionRequest
} from '@ic-models/prior-inspection/frb-request.model';
import { IStatusType } from '@ic-models/statusType.model';
import { environment } from 'environments/environment';
// import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const baseUrl = environment.baseUrl;

@Injectable()
export class AdministrativeFileService {
  constructor(private http: HttpClient) {}

  findAdministrativeFiles(
    filter: IAdministrativeFileFilter,
    pageNumber: number,
    pageSize: number,
    internalAdministrativeFileTypeCode: number
  ): Observable<IAdministrativeFileRequest[]> {
    // let offset = moment().utcOffset();
    const req = {
      searchBox: filter.searchBox,
      importMinInput: filter.importMin
        ? toCurrencyNumber(filter.importMin)
        : null,
      importMaxInput: filter.importMax
        ? toCurrencyNumber(filter.importMax)
        : null,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      administrativeFileType: filter.administrativeFileType?.id as number,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      fileNumber: filter.fileNumber,
      spendingPhaseId: filter.spendingPhaseId,
      omissionReasonId: filter.omissionReasonId,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      budgetaryItem: filter.budgetaryItem?.id as number,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      // offset: offset,
      zoneId: getUserTimeZone(),
      internalAdministrativeFileTypeCode,
      administrativeFileStatus:
        !!filter.administrativeFileStatus &&
        filter.administrativeFileStatus.length > 0
          ? JSON.stringify(filter.administrativeFileStatus)
          : undefined,
      usersIds:
        !!filter.usersFilterId && filter.usersFilterId.length > 0
          ? JSON.stringify(filter.usersFilterId)
          : undefined
    };

    // if (
    //   !!filter.administrativeFileStatus &&
    //   filter.administrativeFileStatus.length > 0
    // ) {
    // req['administrativeFileStatus'] = JSON.stringify(
    //   filter.administrativeFileStatus
    // );
    // }

    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileRequest[]>(
        `${baseUrl}/administrativefile/listAdministrativeFiles`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileRequest[]>) =>
            resp.body as IAdministrativeFileRequest[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los expedientes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  countAdministrativeFileRequest(
    filter: IAdministrativeFileFilter,
    internalAdministrativeFileTypeCode: number
  ): Observable<number> {
    const req = {
      searchBox: filter.searchBox,
      importMinInput: filter.importMin
        ? toCurrencyNumber(filter.importMin)
        : null,
      importMaxInput: filter.importMax
        ? toCurrencyNumber(filter.importMax)
        : null,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      administrativeFileType: filter.administrativeFileType?.id as number,
      fileNumber: filter.fileNumber,
      spendingPhaseId: filter.spendingPhaseId,
      omissionReasonId: filter.omissionReasonId,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      budgetaryItem: filter.budgetaryItem?.id as number,
      internalAdministrativeFileTypeCode,
      administrativeFileStatus:
        !!filter.administrativeFileStatus &&
        filter.administrativeFileStatus.length > 0
          ? JSON.stringify(filter.administrativeFileStatus)
          : undefined,
      usersIds:
        !!filter.usersFilterId && filter.usersFilterId.length > 0
          ? JSON.stringify(filter.usersFilterId)
          : undefined
    };

    // if (
    //   !!filter.administrativeFileStatus &&
    //   filter.administrativeFileStatus.length > 0
    // )
    //   req['administrativeFileStatus'] = JSON.stringify(
    //     filter.administrativeFileStatus
    //   );

    const options = createRequestOption(req);
    return (
      this.http
        //.get<number>('http://localhost:4200/frb-request/count', {
        .get<number>(`${baseUrl}/administrativefile/countAdministrativeFiles`, {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        })
        .pipe(
          map((resp: HttpResponse<number>) => resp.body as number),
          catchError((err: HttpErrorResponse) => {
            console.error(
              'Se ha producido un error al contar los expedientes del servidor:',
              err.message
            );
            return throwError(() => new Error(err.message));
          })
        )
    );
  }

  getStatusTypes(): Observable<IStatusType[]> {
    const options = createRequestOption();
    return this.http
      .get<IStatusType[]>(`${baseUrl}/administrativefile/status`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IStatusType[]>) => resp.body as IStatusType[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles firmantes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getOmissionStatusTypes(): Observable<IStatusType[]> {
    const options = createRequestOption();
    return this.http
      .get<IStatusType[]>(`${baseUrl}/administrativefile/omission/status`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<IStatusType[]>) => resp.body as IStatusType[]),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los posibles firmantes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  findCurrentAdministrativeFile(fileId: number): Observable<IDetailData> {
    const req = {
      id: fileId
    };
    const options = createRequestOption(req);
    return (
      this.http
        //.get<number>('http://localhost:4200/frb-request/detail', {
        .get<IDetailData>(`${baseUrl}/administrativefile/findCurrent`, {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        })
        .pipe(
          map((resp: HttpResponse<IDetailData>) => resp.body as IDetailData),
          catchError((err: HttpErrorResponse) => {
            console.error(
              'Se ha producido un error al cargar la solicitud de Fiscalización del servidor:',
              err.message
            );
            return throwError(() => new Error(err.message));
          })
        )
    );
  }

  // Obtiene los datos del expediente para una inspección en determinada iteración
  findAdministrativeFileDetail(
    fileId: number,
    iteration: number
  ): Observable<IDetailData> {
    const req = {
      id: fileId,
      iteration: iteration
    };
    const options = createRequestOption(req);
    return (
      this.http
        //.get<number>('http://localhost:4200/frb-request/detail', {
        .get<IDetailData>(`${baseUrl}/administrativefile/fileDetail`, {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        })
        .pipe(
          map((resp: HttpResponse<IDetailData>) => resp.body as IDetailData),
          catchError((err: HttpErrorResponse) => {
            console.error(
              'Se ha producido un error al cargar un expediente del servidor:',
              err.message
            );
            return throwError(() => new Error(err.message));
          })
        )
    );
  }

  deleteAdministrativeFileCorrectionRequest(
    fileId: number
  ): Observable<boolean> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/administrativefile/deleteCorrectionRequest`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de subsanación del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  deleteAdministrativeFileReturnRequest(fileId: number): Observable<boolean> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/administrativefile/deleteReturnRequest`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al eliminar la solicitud de devolucion del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionContacts(
    organizationalUnitId: number
  ): Observable<IAdministrativeFileCorrectionContacts[]> {
    const req = {
      organizationalUnitId: organizationalUnitId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileCorrectionContacts[]>(
        `${baseUrl}/administrativefile/organizationalUnitContacts`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileCorrectionContacts[]>) =>
            resp.body as IAdministrativeFileCorrectionContacts[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los contactos del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionData(
    fileId: number
  ): Observable<IAdministrativeFileCorrectionDataDTO> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileCorrectionDataDTO>(
        `${baseUrl}/administrativefile/correctionData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileCorrectionDataDTO>) =>
            resp.body as IAdministrativeFileCorrectionDataDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar las información para crear una subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  sendCorrection(
    fileId: number,
    contactsToSendEmails: string[],
    contactIdsToSend: number[],
    fileNumber: string,
    send: boolean = true
  ): Observable<boolean> {
    // const offset = moment().utcOffset();
    const req = {
      fileId: fileId,
      contactsToSendEmails: contactsToSendEmails,
      contactIdsToSend: contactIdsToSend,
      fileNumber: fileNumber,
      // offset: offset,
      zoneId: getUserTimeZone(),
      send: send
    };

    const options = createRequestOption(req);
    return this.http
      .get<boolean>(
        `${baseUrl}/administrativefile/sendAdministrativeFileCorrection`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar una solicitud de subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveCorrectionAndSend(
    correctionData: AdministrativeFileCorrectionRequest,
    send: boolean = true
  ): Observable<number> {
    const req = {
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/administrativefile/saveAdministrativeFileCorrectionAndSend`,
        correctionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y enviar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editCorrectionAndSend(
    correctionData: AdministrativeFileCorrectionRequest,
    officialNoteId: number,
    send: boolean = true
  ): Observable<number> {
    const req = {
      officialNoteId: officialNoteId,
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/administrativefile/editAdministrativeFileCorrectionAndSend`,
        correctionData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y enviar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getAdministrativeFileTypeName(
    administrativeFileTypeId: number
  ): Observable<string> {
    return this.http
      .get<string>(
        `${baseUrl}/administrativefile/typeName/${administrativeFileTypeId}`,
        {
          headers: HttpConstants.GET_HEADERS,
          observe: 'response',
          responseType: 'text' as 'json'
        }
      )
      .pipe(
        map((resp: HttpResponse<string>) => {
          return resp.body as string;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de expedientes del servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionTypesData(): Observable<IFrbCorrectionDataType[]> {
    const options = createRequestOption();
    return this.http
      .get<IFrbCorrectionDataType[]>(
        `${baseUrl}/inspection/correctionTypesData`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IFrbCorrectionDataType[]>) =>
            resp.body as IFrbCorrectionDataType[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar los tipos de una subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveCorrection(
    correctionData: AdministrativeFileCorrectionRequest
  ): Observable<number> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset(),
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(
        `${baseUrl}/administrativefile/saveCorrection`,
        correctionData,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReturnFile(returnData: AdministrativeFileReturn): Observable<number> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    // const offset = moment().utcOffset(),
    const zoneId = getUserTimeZone();
    const req = {
      initDay: initDay,
      // offset: offset
      zoneId: zoneId
    };
    const options = createRequestOption(req);
    return this.http
      .post<number>(`${baseUrl}/administrativefile/saveReturn`, returnData, {
        headers: HttpConstants.GET_HEADERS,
        params: options
      })
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveReturnAndSend(
    returnData: AdministrativeFileReturn,
    send: boolean = true
  ): Observable<number> {
    const req = {
      // TODO: Sustituimos moment
      // initDay: moment().startOf('day').valueOf(),
      initDay: getTimestampAtStartOfDay(),
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/administrativefile/saveAdministrativeFileReturnAndSend`,
        returnData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al guardar y enviar la devolucion del expediente en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editReturnAndSend(
    returnData: AdministrativeFileReturn,
    officialNoteId: number,
    send: boolean = true
  ): Observable<number> {
    const req = {
      officialNoteId: officialNoteId,
      // offset: moment().utcOffset(),
      zoneId: getUserTimeZone(),
      send: send
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/administrativefile/editAdministrativeFileReturnAndSend`,
        returnData,
        { params: options }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar y enviar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  sendReturnData(
    procedureId: number,
    contactsToSendEmails: string[],
    contactIdsToSend: number[],
    fileNumber: string,
    send: boolean = true
  ): Observable<boolean> {
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    const req = {
      procedureId: procedureId,
      contactsToSendEmails: contactsToSendEmails,
      contactIdsToSend: contactIdsToSend,
      fileNumber: fileNumber,
      // offset: offset,
      zoneId: zoneId,
      send: send
    };

    const options = createRequestOption(req);
    return this.http
      .get<boolean>(`${baseUrl}/administrativefile/sendReturnData`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<boolean>) => resp.body as boolean),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar una solicitud de subsanación',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editCorrection(
    correctionData: AdministrativeFileCorrectionRequest,
    officialNoteId: number
  ): Observable<number> {
    const req = { officialNoteId: officialNoteId };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/administrativefile/updateAdministrativeFileCorrection`,
        correctionData,
        {
          headers: HttpConstants.PUT_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la subsanación en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  editReturnData(
    returnData: AdministrativeFileReturn,
    officialNoteId: number
  ): Observable<number> {
    const req = {
      officialNoteId: officialNoteId
    };
    const options = createRequestOption(req);
    return this.http
      .put<number>(
        `${baseUrl}/administrativefile/updateAdministrativeFileReturn`,
        returnData,
        {
          headers: HttpConstants.PUT_HEADERS,
          params: options
        }
      )
      .pipe(
        map((resp: number) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al modificar la devolucion en el servidor:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getCorrectionToEdit(
    fileId: number
  ): Observable<IAdministrativeFileCorrectionDataEditDTO> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileCorrectionDataEditDTO>(
        `${baseUrl}/administrativefile/correctionDataEdit`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileCorrectionDataEditDTO>) =>
            resp.body as IAdministrativeFileCorrectionDataEditDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la subsanación del servidor',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  getReturnDataToEdit(
    fileId: number
  ): Observable<IAdministrativeFileReturnRequestEditDTO> {
    const req = {
      fileId: fileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<IAdministrativeFileReturnRequestEditDTO>(
        `${baseUrl}/administrativefile/returnDataEdit`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response'
        }
      )
      .pipe(
        map(
          (resp: HttpResponse<IAdministrativeFileReturnRequestEditDTO>) =>
            resp.body as IAdministrativeFileReturnRequestEditDTO
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al cargar la subsanación del servidor',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  saveResponseReport(
    responseReport: ResponseReport,
    administrativeFileId: number
  ): Observable<boolean> {
    // const offset = moment().utcOffset();
    const zoneId = getUserTimeZone();
    // TODO: Sustituimos moment
    // const initDay = moment(responseReport.responseDate)
    //   .startOf('day')
    //   .valueOf();
    const initDay = getTimestampAtStartOfDayFromTimestamp(
      responseReport.responseDate
    );
    const req = {
      zoneId: zoneId,
      // offset: offset,
      initDay: initDay
    };

    const options = createRequestOption(req);

    return this.http
      .put<boolean>(
        `${baseUrl}/administrativefile/responseReport/${administrativeFileId}/`,
        responseReport,
        {
          params: options
        }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.log(
            `Error al responser el oficio del expediente ${administrativeFileId}`,
            err
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Recupera el id del procedure de la solicitud de fiscalización activa
  getActiveRequestProcedureIdFromAdministrativeFile(
    administrativeFileId: number
  ): Observable<number> {
    const req = {
      fileId: administrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/administrativefile/activeprocedurerequest`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al obetener los datos del procedimietno:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Recupera el id del procedure de la inspección de fiscalización activa
  getActiveInspectionProcedureIdFromAdministrativeFile(
    administrativeFileId: number
  ): Observable<number> {
    const req = {
      fileId: administrativeFileId
    };
    const options = createRequestOption(req);
    return this.http
      .get<number>(`${baseUrl}/administrativefile/activeprocedureinspection`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map((resp: HttpResponse<number>) => resp.body as number),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al obetener los datos del procedimietno:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  // Crea enmieda al expediente
  createAmendmentAdministrativeFile(
    amendment: InspectionRequest,
    procedureId: number,
    returned: boolean
  ): Observable<IFrbAdded> {
    const req = {
      procedureId: procedureId,
      returned: returned,
      zoneId: getUserTimeZone()
    };

    const options = createRequestOption(req);

    return this.http
      .post<IFrbAdded>(
        `${baseUrl}/administrativefile/saveAmendment`,
        amendment,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          if (returned) {
            console.error(
              'Se ha producido un error al guardar la reapertura en el servidor:',
              err.message
            );
          } else {
            console.error(
              'Se ha producido un error al guardar la subsanación en el servidor:',
              err.message
            );
          }
          return throwError(() => new Error(err.message));
        })
      );
  }

  createAmendmentAdministrativeFileAndValidate(
    amendment: InspectionRequest,
    procedureId: number,
    returned: boolean
  ): Observable<IFrbAdded> {
    // Usamos como parámetro la fecha a la que daría comienzo el día con el horario local
    // TODO: Sustituimos moment
    // const initDay = moment().startOf('day').valueOf();
    const initDay = getTimestampAtStartOfDay();
    const zoneId = getUserTimeZone();
    // const offset = moment().utcOffset();
    const req = {
      initDay: initDay,
      // offset: offset,
      zoneId: zoneId,
      procedureId: procedureId,
      returned: returned
    };

    const options = createRequestOption(req);

    return this.http
      .post<IFrbAdded>(
        `${baseUrl}/administrativefile/saveAmendmentAndValidate`,
        amendment,
        {
          params: options
        }
      )
      .pipe(
        map((resp: IFrbAdded) => resp),
        catchError((err: HttpErrorResponse) => {
          if (returned) {
            console.error(
              'Se ha producido un error al guardar y validar la reapertura en el servidor:',
              err.message
            );
          } else {
            console.error(
              'Se ha producido un error al guardar y validar la subsanación en el servidor:',
              err.message
            );
          }
          return throwError(() => new Error(err.message));
        })
      );
  }

  downloadAdministrativeFiles(
    filter: IAdministrativeFileFilter,
    fileTypeCode: number
  ): Observable<string> {
    const req = {
      searchBox: filter.searchBox,
      importMinInput: filter.importMin
        ? toCurrencyNumber(filter.importMin)
        : null,
      importMaxInput: filter.importMax
        ? toCurrencyNumber(filter.importMax)
        : null,
      initDateStartInput: filter.initDateStart?.toString(),
      initDateEndInput: filter.initDateEnd?.toString(),
      endDateStartInput: filter.endDateStart?.toString(),
      endDateEndInput: filter.endDateEnd?.toString(),
      organizationalUnit: filter.organizationalUnit?.id as number,
      administrativeFileType: filter.administrativeFileType?.id as number,
      sortActive: filter.sortActive,
      sortDirection: filter.sortDirection,
      fileNumber: filter.fileNumber,
      spendingPhaseId: filter.spendingPhaseId,
      omissionReasonId: filter.omissionReasonId,
      organizationalUnitResponsible: filter.organizationalUnitResponsible
        ?.id as number,
      budgetaryItem: filter.budgetaryItem?.id as number,
      frbStatusFilter: filter.frbStatusFilter,
      procedureResultCodes: filter.procedureResultCodes,
      typeCode: fileTypeCode,
      administrativeFileStatus:
        !!filter.administrativeFileStatus &&
        filter.administrativeFileStatus.length > 0
          ? JSON.stringify(filter.administrativeFileStatus)
          : undefined,
      userIds:
        !!filter.usersFilter && filter.usersFilter.length > 0
          ? filter.usersFilter
          : undefined
    };

    // if (
    //   !!filter.administrativeFileStatus &&
    //   filter.administrativeFileStatus.length > 0
    // )
    //   req['administrativeFileStatus'] = JSON.stringify(
    //     filter.administrativeFileStatus
    //   );

    const options = createRequestOption(req);

    return this.http
      .get<string>(
        `${baseUrl}/downloadfiles/excel`,
        //.get<any>(`https://devtower.scipionapp.com/api/downloadfiles/excel`,
        {
          headers: HttpConstants.GET_HEADERS,
          params: options,
          observe: 'response',
          responseType: 'text' as 'json'
        }
      )
      .pipe(
        map((resp: HttpResponse<string>) => resp.body as string),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al descargar los datos de los expedientes:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  wakeUpLambda(): Observable<string> {
    const options = createRequestOption();
    return this.http
      .get<string>(`${baseUrl}/downloadfiles/wakeuplambda`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response',
        responseType: 'text' as 'json'
      })
      .pipe(
        map((resp: HttpResponse<string>) => resp.body as string),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Servicio para enviar un oficio de solicitud de información a un conjunto de emails
   * @param fileStreamToSend
   * @param fileNumber
   * @param fileName
   * @param contactEmails
   * @returns
   */
  sendEmailsWithCorrectionReports(
    fileStreamToSend: Blob,
    fileNumber: string,
    fileName: string,
    contactEmails: string[]
  ): Observable<boolean> {
    const file = new FormData();
    file.append('file', fileStreamToSend);
    const req = {
      fileNumber: fileNumber,
      fileName: fileName,
      contactEmails: contactEmails
    };
    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/administrativefile/sendEmail/correctionReport`,
        file,
        { params: options }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar los correos a los contactos cargados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Servicio para enviar un oficio de devolución de expediente a un conjunto de emails
   * @param fileStreamToSend
   * @param fileNumber
   * @param fileName
   * @param contactEmails
   * @returns
   */
  sendEmailsWithReturnReports(
    fileStreamToSend: Blob,
    fileNumber: string,
    fileName: string,
    contactEmails: string[]
  ): Observable<boolean> {
    const file = new FormData();
    file.append('file', fileStreamToSend);
    const req = {
      fileNumber: fileNumber,
      fileName: fileName,
      contactEmails: contactEmails
    };
    const options = createRequestOption(req);

    return this.http
      .post<boolean>(
        `${baseUrl}/administrativefile/sendEmail/returnReport`,
        file,
        { params: options }
      )
      .pipe(
        map((resp: boolean) => resp),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error al enviar los correos a los contactos cargados:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }

  /**
   * Obtiene los usuarios de la entidad a la que pertenece el usuario, para el filtrado
   * @returns
   */
  getUsers(): Observable<IUsersFilter[]> {
    const options = createRequestOption();
    return this.http
      .get<IUsersFilter[]>(`${baseUrl}/administrativefile/users`, {
        headers: HttpConstants.GET_HEADERS,
        params: options,
        observe: 'response'
      })
      .pipe(
        map(
          (resp: HttpResponse<IUsersFilter[]>) => resp.body as IUsersFilter[]
        ),
        catchError((err: HttpErrorResponse) => {
          console.error(
            'Se ha producido un error del servidor al cargar los usuarios para el filtro:',
            err.message
          );
          return throwError(() => new Error(err.message));
        })
      );
  }
}
