import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { of } from 'rxjs';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IAutoCompleteElement {
  id: any;
  name: string;
  parentName?: string;
  code?: string;
}

export class AutoCompleteElement implements IAutoCompleteElement {
  id: any;
  name: string;
  parentName?: string;
  code?: string;
  constructor(value: any, display: string, parentName?: string, code?: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.id = value;
    this.name = display;
    this.parentName = parentName;
    this.code = code;
  }
}

@Component({
  selector: 'ic-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      )
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class InputAutocompleteComponent implements OnInit {
  @ViewChild('trigger') triggerComponent?: MatAutocompleteTrigger;
  @ViewChild('auto') autoCompleteComponent?: MatAutocomplete;

  @Input() placeHolderText = '';
  @Input() autocompleteFormControl: FormControl = new FormControl();
  @Input() iconColor = '';
  @Input() matIcon = '';
  @Input() source: any;
  @Input() resetAutoComplete = false;
  @Input() autocompleteLabel = '';
  @Input() autocompleteClass = '';
  @Input() inputClass = '';
  @Input() showTreeGroups = false;
  @Input() hideShow?: boolean;
  @Input() hint?: boolean;
  @Input() isDisabled = false;

  @Output() showTrees: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() optionSelectedAuto: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  filterElements?: AutoCompleteElement[];

  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return,
   @typescript-eslint/no-unsafe-assignment */
  ngOnInit(): void {
    // this.autoCompleteFormControl.valueChanges.subscribe((value) => {
    //   console.log('FormControl Value Changes');
    // });

    if (this.isDisabled)
      this.autocompleteFormControl?.disable({
        onlySelf: true,
        emitEvent: false
      });

    this.autocompleteFormControl?.valueChanges
      .pipe(
        debounceTime(30),
        tap(() => (this.isLoading = true)),
        switchMap((value: string) => {
          if (value && value.length > 0) {
            return this.source
              .getData({
                searchValue: value
              })
              .pipe(finalize(() => (this.isLoading = false)));
          } else if (this.resetAutoComplete) {
            this.isLoading = false;
            this.resetAutoInput(
              null,
              this.triggerComponent,
              this.autoCompleteComponent
            );
            return of([]);
          } else {
            this.isLoading = false;
            return of([]);
          }
        })
      )
      .subscribe((elements: any) => {
        this.filterElements = elements.sort(
          (a: { name: any }, b: { name: any }) => a.name.localeCompare(b.name)
        );
      });
  }
  /* eslint-enable */

  displayFn(element?: AutoCompleteElement): string {
    return element ? element.name : '';
  }

  onEnter(element: AutoCompleteElement): void {
    if (element.name && element.id) {
      this.optionSelectedAuto.emit(element);
    }
  }

  resetAutoInput(
    optVal: unknown | null,
    trigger: MatAutocompleteTrigger | undefined,
    auto: MatAutocomplete | undefined
  ): void {
    // setTimeout((_) => {
    auto?.options.forEach((item: MatOption) => {
      item.deselect();
    });
    this.autocompleteFormControl?.reset('');
    trigger?.closePanel();
    // }, 100);
  }

  showTree($event: Event): void {
    this.showTreeGroups = !this.showTreeGroups;
    this.showTrees.emit(this.showTreeGroups);
    $event.stopPropagation();
  }

  selected(element: AutoCompleteElement): void {
    this.optionSelectedAuto.emit(element);
  }

  openCloseTree(event: Event): void {
    this.showTreeGroups = event ? true : false;
    event.stopPropagation();
  }
}
