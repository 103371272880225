/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@ic-services/app-config.service';
import { PanelService } from '@ic-services/panel.service';
import { SpinnerService } from '@ic-services/spinner.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  timer: any;

  constructor(
    public router: Router,
    private spinnerService: SpinnerService,
    private appConfig: AppConfigService,
    private panelService: PanelService
  ) {}

  cancelTimeout(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  isFileUploadUrls(req: HttpRequest<any>): boolean {
    if (
      req.url.includes('s3.eu-west-1.amazonaws.com') ||
      req.url.includes('storageurl') ||
      req.url.includes('multipartuploadid') ||
      req.url.includes('multipartuplloadurls') ||
      req.url.includes('closemultipartupload')
    ) {
      return true;
    }
    return false;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isFileUploadUrls(req)) {
      this.cancelTimeout();
      this.timer = setTimeout(
        () => this.spinnerService.show(),
        this.appConfig.httpSpinnerTimeout
      );

      return next.handle(req).pipe(
        // eslint-disable-next-line @typescript-eslint/typedef
        catchError((error) => {
          this.spinnerService.hide();
          this.cancelTimeout();
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.error('Error Event');
              return throwError(error);
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              switch (error.status) {
                case 0:
                  this.panelService.close();
                  this.router.navigateByUrl('/error/error503');
                  break;
                case 401:
                  this.panelService.close();
                  this.router.navigateByUrl('/error/error401');
                  break;
                case 403: //forbidden
                  this.panelService.close();
                  this.router.navigateByUrl('/error/error403');
                  break;
                // case 404: //bad request
                //   this.router.navigateByUrl('/error/error404');
                //   break;
                case 500:
                  this.panelService.close();
                  this.router.navigateByUrl('/error/error500');
                  break;
                case 503:
                  this.panelService.close();
                  this.router.navigateByUrl('/error/error503');
                  break;
                default:
                  return throwError(error);
              }
            }
          }
          return of(error);
        }),
        finalize(() => {
          this.spinnerService.hide();
          this.cancelTimeout();
        })
      );
    }
    return next.handle(req);
  }
}
