/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { NavService } from '@ic-services/nav.service';
import { SettingsService } from '@ic-services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { NavItems } from './constants/app.nav-items';
import { TabItems } from './constants/app.tab-items';

@Component({
  selector: 'ic-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'scipion';

  private unsubscribe$ = new Subject<void>();

  constructor(
    translate: TranslateService,
    public settingsService: SettingsService,
    private navService: NavService,
    private router: Router,
    private elementRef: ElementRef
  ) {
    settingsService.loadInitialData().then((value: boolean) => {
      if (value && !settingsService.allSettingsLoaded) {
        NavItems.initialize(translate);
        TabItems.initialize(translate);
        this.navService.updateCurrentUrl();
        settingsService.allSettingsLoaded = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: any) => {
        if (
          event instanceof ActivationStart &&
          event.snapshot &&
          event.snapshot.data &&
          event.snapshot.data['theme']
        ) {
          this.elementRef.nativeElement.ownerDocument.body.className =
            'mat-typography ' + event.snapshot?.data?.['theme'];
          // console.log(this.elementRef.nativeElement.ownerDocument.body.className);
          // console.log(event.snapshot?.data?.['theme']);
          // console.log(JSON.stringify(event.snapshot?.data?.['theme']));
        }
        // console.log('END');
      });
  }
}
