<div class="input-dialog">
  <div mat-dialog-content>
    <ic-input-textarea
      [textAreaLabel]="data.title"
      [placeHolderText]="$any(data.subtitle)"
      [textAreaControl]="controls.input"
      [textAreaType]="'text'"
      [textAreaClass]="'full-width'"
      [textAreaInputClass]="'textarea-extra-height'"
      [maxLength]="inputMaxSizes.MAX_SIZE_TEXT_2800"
    >
    </ic-input-textarea>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [color]="'primary'" (click)="accept($event)">
      {{ data.buttonAccept }}
    </button>
    <button mat-raised-button [color]="'warn'" (click)="cancel($event)">
      {{ data.buttonCancel }}
    </button>
  </div>
</div>
