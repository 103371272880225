<mat-checkbox
  class="checkbox"
  [color]="color"
  [checked]="checkboxObj.checked"
  [labelPosition]="labelPositionCheck"
  (change)="checkboxClicked($event)"
  [formControl]="checkboxFormControl"
>
  <span class="ic-basic-text" [hidden]="hidden">{{ checkboxObj.name }}</span>
</mat-checkbox>
